import {
    GET_AUTH_TOKEN,
    GET_CLIENT,
    GET_PHASE,
    GET_PRIVILEGES_FAILED,
    GET_PRIVILEGES_PENDING,
    GET_PRIVILEGES_SUCCESS,
    REFRESH_AUTH_TOKEN,
    REFRESH_ORG_AUTH_TOKEN,
} from './constants';

export const getToken = () => ({
    type: `${GET_AUTH_TOKEN}_PENDING`,
});

export const getTokenSuccess = payload => ({
    type: `${GET_AUTH_TOKEN}_SUCCESS`,
    payload,
});

export const getTokenFailed = (payload = {}) => ({
    type: `${GET_AUTH_TOKEN}_FAILED`,
    payload,
});

export const refreshTokenAction = token => ({
    type: `${REFRESH_AUTH_TOKEN}_PENDING`,
    token,
});

export const refreshTokenActionSuccess = payload => ({
    type: `${REFRESH_AUTH_TOKEN}_SUCCESS`,
    payload,
});

export const refreshTokenActionFailed = (payload = {}) => ({
    type: `${REFRESH_AUTH_TOKEN}_FAILED`,
    payload,
});

export const refreshOrgTokenAction = (token, userId) => ({
    type: `${REFRESH_ORG_AUTH_TOKEN}_PENDING`,
    token,
    userId,
});

export const refreshOrgTokenActionSuccess = payload => ({
    type: `${REFRESH_ORG_AUTH_TOKEN}_SUCCESS`,
    payload,
});

export const refreshOrgTokenActionFailed = (payload = {}) => ({
    type: `${REFRESH_ORG_AUTH_TOKEN}_FAILED`,
    payload,
});

export const getPrivileges = (options, privilegeType) => ({
    type: `${GET_PRIVILEGES_PENDING}`,
    options,
    privilegeType,
});

export const getPrivilegesSuccess = payload => ({
    type: `${GET_PRIVILEGES_SUCCESS}`,
    payload,
});

export const getPrivilegesFailed = (payload = {}) => ({
    type: `${GET_PRIVILEGES_FAILED}`,
    payload,
});

export const getClient = payload => ({
    type: `${GET_CLIENT}_PENDING`,
    payload,
});

export const getClientSuccess = payload => ({
    type: `${GET_CLIENT}_SUCCESS`,
    payload,
});

export const getClientFailed = (payload = {}) => ({
    type: `${GET_CLIENT}_FAILED`,
    payload,
});

export const getPhase = clientId => ({
    type: `${GET_PHASE}_PENDING`,
    clientId,
});

export const getPhaseSuccess = payload => ({
    type: `${GET_PHASE}_SUCCESS`,
    payload,
});

export const getPhaseFailed = (payload = {}) => ({
    type: `${GET_PHASE}_FAILED`,
    payload,
});
