import { KEYS_SLIDE_3, CUSTOM_KEYS_SLIDE_3 } from '../../constants';
import { SHARED_STATE } from './shared';

// eslint-disable-next-line
export const generateSlide1PPT = async ({ data, extraData, slideState, mergeWithMainState }) => {
    let FTE_CHART_MAX_BOUND = 0;
    let COST_CHART_MAX_BOUND = 0;

    Object.values(data).forEach(item => {
        if (item.fte > FTE_CHART_MAX_BOUND) FTE_CHART_MAX_BOUND = item.fte;

        if (item.cost > COST_CHART_MAX_BOUND) COST_CHART_MAX_BOUND = item.cost;
    });

    FTE_CHART_MAX_BOUND += 10;
    COST_CHART_MAX_BOUND += 10;

    const fteTableData = [];
    const costTableData = [];
    const fteTableDataLabel = [];
    const costTableDataLabel = [];

    const CHART_MIN_FTE = FTE_CHART_MAX_BOUND;
    const CHART_MIN_COST = COST_CHART_MAX_BOUND;
    const CHART_MAX = 0;
    const POS_MIN_FTE = 2.21;
    const POS_MAX_FTE = 3.78;
    const POS_MIN_COST = 4.52;
    const POS_MAX_COST = 6.04;
    const DISTANCE_FTE = POS_MAX_FTE - POS_MIN_FTE;
    const DISTANCE_COST = POS_MAX_COST - POS_MIN_COST;
    const N_FTE = CHART_MIN_FTE - CHART_MAX;
    const N_COST = CHART_MIN_COST - CHART_MAX;
    const DISTANCE_PER_PERCENT_FTE = DISTANCE_FTE / N_FTE;
    const DISTANCE_PER_PERCENT_COST = DISTANCE_COST / N_COST;
    const ARRAY_FOR_LOOP = ['too_few', 'overspan_manager', 'geo_exception', 'hidden_player', 'self', 'total'];
    const LABEL_FACTOR = 0.02;
    const LABEL_FACTOR_POSITIVE = 0.2;

    fteTableData.push({
        name: `Chart1`,
        meta_data: {
            min_bound: CHART_MAX,
            max_bound: CHART_MIN_FTE,
        },
        chart_data: [],
    });

    costTableData.push({
        name: `Chart2`,
        meta_data: {
            min_bound: CHART_MAX,
            max_bound: CHART_MIN_COST,
        },
        chart_data: [],
    });

    ARRAY_FOR_LOOP.forEach((item, index) => {
        if ([0, 5].includes(index)) {
            const _heightFte = (data[item].fte || 0) * DISTANCE_PER_PERCENT_FTE;
            const _posFte = POS_MAX_FTE - _heightFte;
            const _heightCost = (data[item].cost || 0) * DISTANCE_PER_PERCENT_COST;
            const _posCost = POS_MAX_COST - _heightCost;
            fteTableData.push({
                name: `bar1${index + 1}`,
                top_position: _posFte,
                height: _heightFte,
            });
            fteTableDataLabel.push({
                name: `lab1${index + 1}`,
                value: data[item].fte,
                top_position:
                    data[item].fte < 0 ? _posFte + _heightFte + LABEL_FACTOR : _posFte - LABEL_FACTOR_POSITIVE,
                is_delete: data[item].fte === 0,
            });
            costTableData.push({
                name: `bar2${index + 1}`,
                top_position: _posCost,
                height: _heightCost,
            });
            costTableDataLabel.push({
                name: `lab2${index + 1}`,
                value: data[item].cost,
                top_position:
                    data[item].cost < 0 ? _posCost + _heightCost + LABEL_FACTOR : _posCost - LABEL_FACTOR_POSITIVE,
                is_delete: data[item].cost === 0,
            });
        } else {
            const ADJUST_HEIGHT_FTE =
                data[ARRAY_FOR_LOOP[index - 1]].fte < 0
                    ? fteTableData[index].top_position + fteTableData[index].height
                    : fteTableData[index].top_position;
            const ADJUST_HEIGHT_COST =
                data[ARRAY_FOR_LOOP[index - 1]].cost < 0
                    ? costTableData[index].top_position + costTableData[index].height
                    : costTableData[index].top_position;
            const _heightFte = Math.abs((data[item].fte || 0) * DISTANCE_PER_PERCENT_FTE);
            const _posFte = data[item].fte < 0 ? ADJUST_HEIGHT_FTE : ADJUST_HEIGHT_FTE - _heightFte;
            const _heightCost = Math.abs((data[item].cost || 0) * DISTANCE_PER_PERCENT_COST);
            const _posCost = data[item].cost < 0 ? ADJUST_HEIGHT_COST : ADJUST_HEIGHT_COST - _heightCost;
            fteTableData.push({
                name: `bar1${index + 1}`,
                height: _heightFte,
                top_position: _posFte,
            });
            fteTableDataLabel.push({
                name: `lab1${index + 1}`,
                value: data[item].fte,
                top_position:
                    data[item].fte < 0 ? _posFte + _heightFte + LABEL_FACTOR : _posFte - LABEL_FACTOR_POSITIVE,
                is_delete: data[item].fte === 0,
            });
            costTableData.push({
                name: `bar2${index + 1}`,
                height: _heightCost,
                top_position: _posCost,
            });
            costTableDataLabel.push({
                name: `lab2${index + 1}`,
                value: data[item].cost,
                top_position:
                    data[item].cost < 0 ? _posCost + _heightCost + LABEL_FACTOR : _posCost - LABEL_FACTOR_POSITIVE,
                is_delete: data[item].cost === 0,
            });
        }
    });

    const extraInfoList = [];
    extraData.forEach(info => {
        const { name, existing_value } = KEYS_SLIDE_3[info.key];
        extraInfoList.push({
            name,
            existing_value,
            value: info.value,
        });
    });

    const updated = [...extraInfoList];

    slideState.data.forEach(_old => {
        const other = extraInfoList.find(_other => _other.name === _old.name);
        if (!other) updated.push(other);
    });

    // sanitizing final data array
    const sanitizedData = [
        ...fteTableData,
        ...costTableData,
        ...fteTableDataLabel,
        ...costTableDataLabel,
        ...updated,
        { name: 'checkme', is_delete: true },
        {
            name: KEYS_SLIDE_3[CUSTOM_KEYS_SLIDE_3.applied_filters_comment].name,
            value: SHARED_STATE.FILTER_STRING,
        },
    ].filter(d => d !== undefined);

    slideState = { ...slideState, data: sanitizedData };

    // merge this slide state with the main state
    mergeWithMainState(slideState);
};
