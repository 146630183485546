import AUTH0 from './auth_config';
import PPTGenerate from './ppt_generate';

const Endpoints = {
    // pptGenerateLogin: PPTGenerate.pptLogin + '/users/login',
    pptGenerateLogin: AUTH0.apiBasePath + '/ppt/generate-token',
    pptGenerate: PPTGenerate.pptGenerate + '/generate-ppt',
    apiBasePath: AUTH0.apiBasePath,
    orglabBasePath: AUTH0.orglabBasePath,
    apiAnalyticsBasePath: AUTH0.apiAnalyticsBasePath,
    apiAnalyticsFilterBasePath: AUTH0.apiAnalyticsBasePath.replace('phases', 'v2'),
    apiBasePathExecutiveSummary: '/orglab/report',
    structures: (clientId, phaseId) => `/${clientId}/${phaseId}/structures`,
    authPath: '/auth/access-token',
    refreshTokenPath: '/auth/refresh-token',
    clientPath: '/clients?client_id=',
    phasePath: '/client',
    comparePath: '/compare',
    executivePath: '/executive',
    filterPath: '/filter/characteristics',
    exportApi: '/structures/change-summary-excel?src_structure_id=',
    managersStats: '/executive/stats/managers_stats?structure_id=',
    managersFTES: '/executive/stats/managers_fte?structure_id=',
    nonManagersStats: '/executive/stats/non_managers_stats?structure_id=',
    totalFTES: '/executive/stats/total_fte?structure_id=',
    layers: '/executive/stats/layers?structure_id=',
    spanReports: '/executive/stats/span_reports?structure_id=',
    executiveFunctions: '/executive/functions?structure_id=',
    executiveN1_Managers: '/executive/n1-managers?structure_id=',
    executiveLocations: '/executive/locations',
    layersJson: phase => `/${phase}/layers.json`,
    spansJson: phase => `/${phase}/spans.json`,
    pivotTableJson: phase => `/${phase}/pivot_table.json`,
    uniqueJobTitles: structure => `/structures/${structure}/roles`,
    opportunityOverviewJson: phase => `/${phase}/opportunity_overview.json`,
    managersJson: phase => `/${phase}/managers.json`,
    filterJson: phase => `/${phase}/filter_options`,
    filterFunctions: structure => `/structures/${structure}/functions`,
    filterLocations: structure => `/structures/${structure}/locations`,
    filterLookups: structure => `/structures/${structure}/lookup_categories`,
    filterRoles: structure => `/structures/${structure}/roles`,
    filterParts: (method, id) => `/${method}/${id}/parts`,
    orgPhases: (method, id) => `/${method}/${id}/phases`,
    orgTeams: (method, id) => `/${method}/${id}/teams`,
    orgStructuredTeams: id => `/structures/${id}/teams`,
    orgChapters: id => `/structures/${id}/chapters`,
    orgRoles: id => `/structures/${id}/roles`,
    getTargets: '/executive/get-design-config',
    setTargets: '/executive/save-design-config',
    getGuidance: '/executive/get-guidance-config',
    setGuidance: '/executive/save-guidance-config',
    apiNNReportBasePath: AUTH0.apiNNReportBasePath,
    nnReportGenerate: '/nnReport/generate',
    nnReportJobStatus: jobId => `/nnReport/get-job-status/${jobId}`,
    nnReportDownload: jobId => `/nnReport/download/${jobId}`,
    clientDetails: id => `/clients/${id}?fields[clients]=login,name`,
    rootRole: structure => `/v2/structures/${structure}?include=root`,
};
export default Endpoints;
