import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getAnalyticsApi } from '../../../StructureContent/apis';
import Shimmer from '../../../../components/shimmer';
import './index.scss';
import NoDataFound from '../../../../components/NoDataFound';
import executiveDashboard from '../../executiveDashboard.enum';
import titleDistributionConfig from '../configs/titleDistribution.config';
import formattedUtilities, { getAnalyticsFilterString } from '../../../../common/utilities';

function Distribution({ isFiltersApplied, filters, structureId, refreshCount, showPercentage, isPayDs }) {
    const [isLoading, setIsLoading] = useState(false);
    const [benchmarkTitles, setBenchmarkTitles] = useState([]);

    const colorCodes = [
        '#bbd6e9',
        '#466982',
        '#8dc541',
        '#46651f',
        '#a9cce7',
        '#6e879b',
        '#083d52',
        '#2c3e14',
        '#1610e0',
        '#98b8d0',
        '#87a3b9',
        '#385468',
        '#314a5b',
        '#2a3f4e',
        '#719e34',
        '#638a2e',
        '#557627',
        '#2d28e3',
        '#4540e6',
        '#2a3b13',
        '#1c270d',
        '#0e1406',
        '#031821',
        '#021219',
        '#020c10',
    ];

    const setDistinctFunctionMap = function (data, showPercentage) {
        const obj = {};
        const total_ftes_by_layers = {};
        const layers = Object.keys(data);
        for (let i = 0; i < layers.length; i += 1) {
            const _data = data[layers[i]].data;
            Object.keys(_data).forEach(el => {
                if (total_ftes_by_layers[i]) {
                    total_ftes_by_layers[i] += _data[el].data.managers + _data[el].data.nonManagers;
                } else {
                    total_ftes_by_layers[i] = _data[el].data.managers + _data[el].data.nonManagers;
                }
            });

            Object.keys(_data).forEach(el => {
                if (obj[_data[el].name]) {
                    const value = (showPercentage ? 
                        (((_data[el].data.managers + _data[el].data.nonManagers) / total_ftes_by_layers[i]) * 100)
                        : (_data[el].data.managers + _data[el].data.nonManagers));
                    obj[_data[el].name][i] = {
                        y: value,
                        value,
                        layer: data[layers[i]].name,
                    };
                } else {
                    const value = (showPercentage ? 
                        ((_data[el].data.managers + _data[el].data.nonManagers) / total_ftes_by_layers[i]) * 100
                        : (_data[el].data.managers + _data[el].data.nonManagers));
                    obj[_data[el].name] = new Array(layers.length).fill({ y: 0, value: 0 });
                    obj[_data[el].name][i] = {
                        y: value,
                        value,
                        layer: data[layers[i]].name,
                    };
                }
            });
        }
        return { distributedTitles: obj, layersCount: layers.length };
    };

    const getTitleDistribution = (titles, showPercentage) => {
        const { distributedTitles, layersCount } = setDistinctFunctionMap(titles, showPercentage);
        const formattedTitles = [];
        for (let i = 0; i < layersCount; i += 1) {
            let isFirstEntry = false;
            const titleBarCountByLayer = Object.keys(distributedTitles).filter(el => {
                return distributedTitles[el][i].y;
            });
            Object.keys(distributedTitles).forEach(el => {
                if (distributedTitles[el][i].y) {
                    const categoryName = !isFirstEntry ? `Layer ${distributedTitles[el][i].layer + 1}` : '';
                    isFirstEntry = true;
                    formattedTitles.push({
                        pointPlacement: -0.2,
                        y: distributedTitles[el][i].y,
                        value: distributedTitles[el][i].value,
                        layerIndex: i,
                        titleByLayerCount: titleBarCountByLayer.length,
                        categoryName,
                        color: colorCodes[i % colorCodes.length],
                        name: el === 'null' ? 'UNKNOWN' : el,
                    });
                }
            });

            formattedTitles.push(
                {
                    pointPlacement: 0,
                    y: 0,
                    categoryName: '',
                    name: '',
                },
                {
                    pointPlacement: 0,
                    y: 0,
                    categoryName: '',
                    name: '',
                }
            );
        }
        setBenchmarkTitles(formattedTitles);
    };

    useEffect(() => {
        setIsLoading(true);
        const secondGrp = isPayDs ? 'lookup(Job or Pay Grade)[0]' : 'lookup(Benchmark Title)[0]';
        const filterString = getAnalyticsFilterString({
            ...filters,
            structureId,
            group_by: ['layer', secondGrp],
        });

        getAnalyticsApi({ method: 'pivotTableJson', filterString })
            .then(({ data }) => {
                getTitleDistribution(data, showPercentage);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [JSON.stringify(filters), refreshCount, showPercentage, isPayDs]);

    return (
        <div className="title-distribution">
            {isLoading ? (
                <Shimmer />
            ) : benchmarkTitles?.length ? (
                <div className="title-distribution__chart">
                    <div className="title-distribution__chart-bar">
                        <HighchartsReact
                            options={{
                                ...titleDistributionConfig,
                                chart: {
                                    type: 'column',
                                    width:
                                        benchmarkTitles.length * 40 < 0.8 * document.body.clientWidth
                                            ? 0.8 * document.body.clientWidth
                                            : benchmarkTitles.length * 40,
                                    height: 500,
                                },
                                series: [{ data: benchmarkTitles }],
                                plotOptions: {
                                    column: {
                                        pointWidth: 30,
                                        pointPadding: 0,
                                        groupPadding: 0,
                                        borderWidth: 0,
                                        borderColor: '#ffffff',
                                        dataLabels: {
                                            enabled: true,
                                            crop: false,
                                            inside: false,
                                            overflow: 'none',
                                            useHTML: true,
                                            formatter: function () {
                                                if (this.point.y) {
                                                    return `${formattedUtilities.floatIntValueFormatter(
                                                        this.point.y, true, 2
                                                    )}${showPercentage ? '%' : ''}`;
                                                }
                                                return '';
                                            },
                                        },
                                        events: {
                                            show: function (chart) {
                                                // This is intentional
                                            },
                                        },
                                    },
                                },
                                xAxis: {
                                    categories: (function () {
                                        return benchmarkTitles.map(el => {
                                            return {
                                                name: el.categoryName,
                                                count: el.titleByLayerCount - 1 || 0,
                                                title: el.name,
                                            };
                                        });
                                    })(),
                                    labels: {
                                        useHTML: true,
                                        autoRotation: false,
                                        rotation: -90,
                                        formatter: function () {
                                            const dynamicPadding = this.value.count <= 2 ? 15 : 17;
                                            return `<span class="title">${this.value.title}
                                                        ${this.value.name
                                                    ? `<span class='layer' style="position: fixed;top:${this
                                                        .value.count *
                                                    dynamicPadding}px;transform: rotate(90deg);transform-origin: left;">${this.value.name
                                                    }</span>`
                                                    : '<span style="width: 0px"></span>'
                                                }</span>
                                                   `;
                                        },
                                    },
                                    className: 'title-distribution__xAxis',
                                },
                                tooltip: {
                                    useHTML: true,
                                    formatter: function () {
                                        return `
                                            <div>
                                                <b>${formattedUtilities.floatIntValueFormatter(this.point.value, true, 2)}${showPercentage ? '%' : ''}</b>
                                                <br/>
                                                ${this.point.name}
                                            </div>`;
                                    },
                                },
                            }}
                            highcharts={Highcharts}
                        />
                    </div>
                </div>
            ) : (
                <NoDataFound
                    mainMessage={`${isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                        }`}
                    secondaryMessage={`${isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                        }`}
                />
            )}
        </div>
    );
}

export default Distribution;
