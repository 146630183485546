import React from 'react';
import './index.scss';

const Card = props => {
    return (
        <section className={`info-card`}>
            <div className="info-card__title">{props.title}</div>
            <div className={`info-card__content ${props.classes} ${props.hasScroll ? 'has-scroll' : ''}`}>
                {props.children}
            </div>
        </section>
    );
};

export default Card;
