import React from 'react';
import { Dialog } from '@material-ui/core';

import AuthStore from '../../common/AuthStore';
import { logoutURL } from '../../utils/functions';
import './index.scss';

function WarningModal() {
    const handleClickOnLogout = () => {
        localStorage.removeItem('structureId');
        AuthStore.deleteAccessToken();
        window.location.href = logoutURL();
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'warningDialog',
            }}
        >
            <h2>
                Your session has expired. <span onClick={handleClickOnLogout}>Click here to login</span>
            </h2>
        </Dialog>
    );
}
export default WarningModal;
