import { fromJS } from 'immutable';
import {
    FETCH_ALL_FILTERS_DESIGN_PROGRESS_PENDING,
    FETCH_ALL_FILTERS_PENDING,
    FETCH_ALL_FILTERS_SUCCESS,
    GET_FILTERS_MANAGER_SUCCESS,
    GET_FILTERS_SELECTED_MANAGER_CLEAR,
    REFRESH_DATA,
    RESET_ALL_FILTERS,
    SET_FILTERS_TO_APPLY,
    UPDATE_ALL_FILTERS,
    UPDATE_MULTI_FILTERS,
    UPDATE_STATIC_FILTERS,
    GET_SELECTED_STRUCTURE_FILTERS_SUCCESS,
    GET_STATIC_FILTERS_SUCCESS,
    SET_MULTI_FILTERS_TO_APPLY,
    FETCH_FILTERS_CONTENT_PENDING,
    FETCH_FILTERS_CONTENT_SUCCESS,
    FETCH_ALL_FILTERS_FAILED,
    UPDATE_FILTERS_CHILDREN,
    SET_MULTI_FILTERS_TRAY,
    SET_FILTERS_TRAY,
} from '../actions/actionTypes';

export const INITIAL_STATE = fromJS({
    designProgressLoading: false,
    addressableOpportunityFilterLoader: false,
    filters: [],
    filtersToApply: {},
    filtersTray: {},
    isFiltersFetched: false,
    isFiltersReadyToApply: false,
    refreshCount: 0,
    staticFilters: {
        overall: [],
        leaders: [],
        functions: [], // add
    },
    isMultiFiltersReadyToApply: false,
    multiStructureFilters: {
        overall: {
            structure1: [],
            structure2: [],
            structure3: [],
        },
        leaders: {
            structure1: [],
            structure2: [],
            structure3: [],
        },
        functions: {
            structure1: [],
            structure2: [],
            structure3: [],
        },
    },
    multiFiltersToApply: {
        overall: {
            structure1: {},
            structure2: {},
            structure3: {},
        },
        leaders: {
            structure1: {},
            structure2: {},
            structure3: {},
        },
        functions: {
            structure1: {},
            structure2: {},
            structure3: {},
        },
    },
    multiFiltersTray: {
        overall: {
            structure1: {},
            structure2: {},
            structure3: {},
        },
        leaders: {
            structure1: {},
            structure2: {},
            structure3: {},
        },
        functions: {
            structure1: {},
            structure2: {},
            structure3: {},
        },
    },
});

const filtersReducer = (state = INITIAL_STATE, { type, payload, arrToRepeat: arrFromAction = [] }) => {
    switch (type) {
        case `${FETCH_ALL_FILTERS_DESIGN_PROGRESS_PENDING}`: {
            return state.set('designProgressLoading', true);
        }
        case `${FETCH_ALL_FILTERS_PENDING}`: {
            return state.set('addressableOpportunityFilterLoader', true);
        }
        case `${FETCH_ALL_FILTERS_SUCCESS}`: {
            let fetchedFilters = [];

            Object.keys(payload).forEach(el => {
                const { name = '', children = [] } = payload[el];
                if (name === 'lookups') {
                    const grouped = Object.values(
                        children.reduce((r, o) => {
                            if (!r[o.lookup_category]) {
                                r[o.lookup_category] = [o];
                                return r;
                            }
                            if (!Array.isArray(r[o.lookup_category])) r[o.lookup_category] = [r[o.lookup_category]];
                            r[o.lookup_category].push(o);
                            return r;
                        }, {})
                    );
                    const mainData = grouped.map(mainChild => ({
                        name: mainChild[0].lookup_category,
                        keyToUse: name,
                        children: mainChild,
                    }));

                    fetchedFilters = [...fetchedFilters, ...mainData];
                } else if (name === 'staticFilters') {
                    Object.keys(children).forEach(el => {
                        fetchedFilters = [
                            ...fetchedFilters,
                            {
                                name: el,
                                keyToUse: el,
                                children: children[el].children,
                                isStaticFilter: true,
                                type: children[el].type,
                                isSelected: children[el].isSelected || false,
                            },
                        ];
                    });
                } else {
                    fetchedFilters.push({ name, keyToUse: name, children });
                }
            });
            return state
                .set('filters', fromJS(fetchedFilters))
                .set('isFiltersFetched', true)
                .set('addressableOpportunityFilterLoader', false)
                .set('designProgressLoading', false);
        }

        case `${GET_SELECTED_STRUCTURE_FILTERS_SUCCESS}`: {
            let fetchedFilters = [];
            Object.keys(payload.filters).forEach(el => {
                const { name = '', children = [] } = payload.filters[el];
                if (name === 'lookups') {
                    const grouped = Object.values(
                        children.reduce((r, o) => {
                            if (!r[o.lookup_category]) {
                                r[o.lookup_category] = [o];
                                return r;
                            }
                            if (!Array.isArray(r[o.lookup_category])) r[o.lookup_category] = [r[o.lookup_category]];
                            r[o.lookup_category].push(o);
                            return r;
                        }, {})
                    );
                    const mainData = grouped.map(mainChild => ({
                        name: mainChild[0].lookup_category,
                        keyToUse: name,
                        children: mainChild,
                    }));

                    fetchedFilters = [...fetchedFilters, ...mainData];
                } else if (name === 'staticFilters') {
                    Object.keys(children).forEach(el => {
                        fetchedFilters = [
                            ...fetchedFilters,
                            {
                                name: el,
                                keyToUse: el,
                                children: children[el].children,
                                isStaticFilter: true,
                                type: children[el].type,
                                isSelected: children[el].isSelected || false,
                            },
                        ];
                    });
                } else {
                    fetchedFilters.push({ name, keyToUse: name, children });
                }
            });
            return state
                .setIn(['multiStructureFilters', payload.module, payload.structureNumber], fromJS(fetchedFilters))
                .setIn(['designProgressLoading'], false);
        }

        case `${GET_STATIC_FILTERS_SUCCESS}`: {
            let fetchedFilters = [];
            Object.keys(payload).forEach(el => {
                const { children = [] } = payload[el];
                Object.keys(children).forEach(el => {
                    fetchedFilters = [
                        ...fetchedFilters,
                        {
                            name: el,
                            keyToUse: el,
                            children: children[el].children,
                            isStaticFilter: true,
                            type: children[el].type,
                            isSelected: children[el].isSelected || false,
                        },
                    ];
                });
            });

            const filtersToApply = {
                structure1: {},
                structure2: {},
                structure3: {},
            };

            const filtersTray = {
                structure1: {},
                structure2: {},
                structure3: {},
            };

            const selectMultiStaticInnerChild = (structureNumber, mainParent = [], mainParentKey = '') => {
                mainParent
                    .filter(({ isSelected = false }) => isSelected)
                    .forEach(({ children = [], key = '', id = '', name = '', isStaticFilter, keyToUse = '' }) => {
                        if (children.length) {
                            selectMultiStaticInnerChild(structureNumber, children, keyToUse);
                        } else if (filtersToApply[structureNumber][key]) {
                            filtersToApply[structureNumber][key].push(id);
                            filtersTray[structureNumber][key].push({
                                name,
                                id: id,
                                key: mainParentKey,
                                isStatic: true,
                            });
                        } else {
                            filtersToApply[structureNumber][key] = [id];
                            filtersTray[structureNumber][key] = [{ name, id, key: mainParentKey, isStatic: true }];
                        }
                    });
            };

            selectMultiStaticInnerChild('structure1', fetchedFilters);
            selectMultiStaticInnerChild('structure2', fetchedFilters);
            selectMultiStaticInnerChild('structure3', fetchedFilters);

            return state
                .setIn(['staticFilters', 'overall'], fromJS(fetchedFilters))
                .setIn(['staticFilters', 'leaders'], fromJS(fetchedFilters))
                .setIn(['staticFilters', 'functions'], fromJS(fetchedFilters))
                .setIn(['multiFiltersToApply', 'overall'], fromJS(filtersToApply))
                .setIn(['multiFiltersToApply', 'functions'], fromJS(filtersToApply))
                .setIn(['multiFiltersToApply', 'leaders'], fromJS(filtersToApply))
                .setIn(['multiFiltersTray', 'overall'], fromJS(filtersTray))
                .setIn(['multiFiltersTray', 'leaders'], fromJS(filtersTray))
                .setIn(['multiFiltersTray', 'functions'], fromJS(filtersTray));
        }

        case `${GET_FILTERS_MANAGER_SUCCESS}`: {
            const fltrs = payload.module
                ? [...state.toJS().multiStructureFilters[payload.module][payload.structureNumber]]
                : [...state.toJS().filters];

            const filters = [...fltrs];

            const managerIndex = filters.findIndex(el => el.name === 'manager');

            if (managerIndex >= 0) {
                filters[managerIndex].children = payload.children;
                filters[managerIndex].message = payload.children.length ? '' : 'No Data Found';
            }

            return payload.module
                ? state.setIn(['multiStructureFilters', payload.module, payload.structureNumber], filters)
                : state.set('filters', filters);
        }

        case `${GET_FILTERS_SELECTED_MANAGER_CLEAR}`: {
            const filters = payload?.module
                ? [...state.toJS().multiStructureFilters[payload.module][payload.structureNumber]]
                : [...state.toJS().filters];
            const updateTo = payload?.module
                ? ['multiStructureFilters', payload.module, payload.structureNumber]
                : ['filters'];
            const managerIndex = filters.findIndex(el => el.name === 'manager');
            if (managerIndex >= 0) {
                filters[managerIndex].isSelected = false;
                filters[managerIndex].selectedOption = {};
                filters[managerIndex].children = filters[managerIndex].children.map(item => {
                    return { ...item, isSelected: false };
                });
            }
            return state.setIn(updateTo, filters);
        }

        case `${FETCH_FILTERS_CONTENT_PENDING}`: {
            return state.setIn([...arrFromAction.slice(0, -1), 'isLoading'], true);
        }
        case `${FETCH_FILTERS_CONTENT_SUCCESS}`: {
            const { arrToRepeat = [], children = [] } = payload;
            const arrayToLoaded = [...arrToRepeat.slice(0, -1), 'isLoaded'];
            const arrayToLoading = [...arrToRepeat.slice(0, -1), 'isLoading'];
            const arrayToOpen = [...arrToRepeat.slice(0, -1), 'isOpen'];
            return state
                .setIn(arrToRepeat, fromJS(children))
                .setIn(arrayToLoaded, true)
                .setIn(arrayToOpen, true)
                .setIn(arrayToLoading, false);
        }
        case `${FETCH_ALL_FILTERS_FAILED}`: {
            const { arrToRepeat = [] } = payload;
            const arrayToLoaded = [...arrToRepeat.slice(0, -1), 'isLoaded'];
            const arrayToLoading = [...arrToRepeat.slice(0, -1), 'isLoading'];
            return state
                .setIn(arrayToLoaded, true)
                .setIn(arrayToLoading, false)
                .set('addressableOpportunityFilterLoader', false)
                .set('designProgressLoading', false);
        }

        case UPDATE_ALL_FILTERS:
            return state.updateIn(['filters'], () => fromJS(payload));

        case UPDATE_MULTI_FILTERS:
            return state.updateIn(['multiStructureFilters', payload.module, payload.structureNumber], () =>
                fromJS(payload.filters)
            );

        case UPDATE_STATIC_FILTERS:
            return state.updateIn(['staticFilters', payload.module], () => fromJS(payload.filters));

        case SET_FILTERS_TO_APPLY: {
            return state.updateIn(['filtersToApply'], () => fromJS(payload)).set('isFiltersReadyToApply', true);
        }
        case SET_FILTERS_TRAY: {
            return state.updateIn(['filtersTray'], () => fromJS(payload));
        }
        case SET_MULTI_FILTERS_TO_APPLY: {
            return state
                .updateIn(['multiFiltersToApply', payload.module], () => fromJS(payload.filters))
                .set('isMultiFiltersReadyToApply', true);
        }
        case SET_MULTI_FILTERS_TRAY: {
            return state.updateIn(['multiFiltersTray', payload.module], () => fromJS(payload.filters));
        }
        case REFRESH_DATA: {
            return state.set('refreshCount', state.toJS().refreshCount + 1);
        }
        case RESET_ALL_FILTERS: {
            const initialState = INITIAL_STATE.toJS();
            const {
                staticFilters,
                multiFiltersToApply,
                multiFiltersTray,
                isMultiFiltersReadyToApply,
                multiStructureFilters,
            } = state.toJS();
            return payload.all
                ? INITIAL_STATE
                : fromJS({
                      ...initialState,
                      staticFilters,
                      multiFiltersToApply,
                      multiFiltersTray,
                      isMultiFiltersReadyToApply,
                      multiStructureFilters,
                  });
        }
        case UPDATE_FILTERS_CHILDREN: {
            const { arrayToOpen = [], isOpen = false } = payload;
            return state.setIn(arrayToOpen, isOpen);
        }
        default:
            return state;
    }
};

export default filtersReducer;
