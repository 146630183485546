import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Shimmer from '../../../../components/shimmer';
import executiveDashboard from '../../executiveDashboard.enum';
import NoDataFound from '../../../../components/NoDataFound';
import stackingConfig from '../configs/stacking.config';
import { getAnalyticsApi } from '../../../StructureContent/apis';
import formattedUtilities, { getAnalyticsFilterString } from '../../../../common/utilities';
import colors from '../configs/colors.config';

function Stacking({ isFiltersApplied, filters, structureId, refreshCount }) {
    const NA = 'Not Categorized';
    const [isLoading, setIsLoading] = useState(false);
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const getSeriesCategories = data => {
        const categories = [];
        const series = [];
        Object.keys(data).forEach(key => {
            const { name, data: titles } = data[key];

            Object.keys(titles).forEach(el => {
                const { name: titleName, data: stats } = titles[el];
                if (name === titleName) {
                    categories.push(name || NA);
                    series.push({
                        name: titleName || NA,
                        y: stats.managers + stats.nonManagers,
                        color: colors[categories.length - 1],
                        dataLabels: {
                            enabled: true,
                            useHTML: true,
                            crop: false,
                            overflow: 'none',
                            formatter: function() {
                                return formattedUtilities.floatIntValueFormatter(stats.managers + stats.nonManagers);
                            },
                        },
                    });
                }
            });
        });

        return [categories, series];
    };

    useEffect(() => {
        setIsLoading(true);

        const filterString = getAnalyticsFilterString({
            ...filters,
            structureId,
            group_by: ['lookup(Benchmark Title)[0]', 'manager.lookup(Benchmark Title)[0]'],
        });

        getAnalyticsApi({ method: 'pivotTableJson', filterString })
            .then(({ data }) => {
                const [categories, series] = getSeriesCategories(data);
                setCategories(categories);
                setSeries(series);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [JSON.stringify(filters), refreshCount]);

    return (
        <Fragment>
            {isLoading ? (
                <Shimmer />
            ) : series.length ? (
                <div className="stacking__chart">
                    <HighchartsReact
                        options={{
                            ...stackingConfig,
                            chart: {
                                ...stackingConfig.chart,
                                width:
                                    categories.length * 100 < 0.4 * document.body.clientWidth
                                        ? 0.4 * document.body.clientWidth
                                        : categories.length * 100,
                            },
                            xAxis: {
                                ...stackingConfig.xAxis,
                                categories: categories.map(el => {
                                    return { name: el };
                                }),
                                labels: {
                                    useHTML: true,
                                    autoRotation: false,
                                    formatter: function() {
                                        return `<span style="font-size: 10px;word-break: break-word;text-transform: capitalize;font-family: 'Lato', sans-serif;">${
                                            this.value.name ? this.value.name.toUpperCase() : ''
                                        }</span>`;
                                    },
                                },
                            },
                            series: [
                                {
                                    name: 'Stacking',
                                    colorByPoint: true,
                                    data: series,
                                },
                            ],
                            tooltip: {
                                useHTML: true,
                                formatter: function() {
                                    return `${this.point.name} : ${formattedUtilities.floatIntValueFormatter(
                                        this.point.y
                                    )}`;
                                },
                            },
                        }}
                        highcharts={Highcharts}
                    />
                </div>
            ) : (
                <NoDataFound
                    mainMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                    }`}
                    secondaryMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                    }`}
                />
            )}
        </Fragment>
    );
}

export default Stacking;
