import React, { Fragment } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { toFixed } from '../../../../utils/functions';
import { backgroundArr } from '../../../../utils/constants';
import Shimmer from '../../../../components/shimmer';
import executiveDashboard from '../../executiveDashboard.enum';
import NoDataFound from '../../../../components/NoDataFound';
import './index.scss';
import { LightTooltip, useOrgShapeStyles } from './styles';
import { getMaxFte } from '../../../PPTExport/actions/exec-dashboard/shared';

function OrgShape({ isFiltersApplied, getOrgshapeFunctionsData, countFilterValue }) {
    const { total=[], layers, ftes, loading=true } = getOrgshapeFunctionsData;
    const classes = useOrgShapeStyles();

    const maxFte = getMaxFte(layers, total);

    const getWidth = (v1, v2, sum = v1 + v2) => {
        const width = ((v1 + v2) * 100) / sum;
        if (width < 0.1) {
            return `calc(${Math.ceil(width)}%)`;
        }
        if (Number.isNaN(width)) {
            return 0;
        }
        return `calc(${width}%)`;
    };

    const tableCellWidth = total && total.length > 0 && (window.innerWidth - 220) / total.length;

    return (
        <Fragment>
            {loading ? (
                <Shimmer />
            ) : total?.length > 0 && layers?.length > 0 ? (
                <TableContainer component={Paper} className={'tableContainer'}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableBody>
                            {layers.length > 0 &&
                                layers.map((layer, idx, arr) => {
                                    return (
                                        <TableRow className={'tableRow'} key={`layer_${idx}`}>
                                            <TableCell className={`fixedCell`}> {`Layer ${layer.name + 1}`} </TableCell>
                                            {total.map((fnId, id) => {
                                                return (
                                                    <TableCell
                                                        className={`cell ${idx === arr.length - 1 ? 'borderedCell' : ''
                                                            }`}
                                                        style={{
                                                            minWidth: '150px',
                                                            width: `calc(100%/${total.length}`,
                                                        }}
                                                    >
                                                        <LightTooltip
                                                            title={toFixed(
                                                                `${layer.data[fnId.id]?.data.managers +
                                                                layer.data[fnId.id]?.data.nonManagers}`,
                                                                2
                                                            )}
                                                        >
                                                            <span
                                                                className="bar"
                                                                style={{
                                                                    height: '20px',
                                                                    display: 'block',
                                                                    margin: '0 auto',
                                                                    backgroundColor:
                                                                        backgroundArr[id % backgroundArr.length],
                                                                    width: `${getWidth(
                                                                        layer.data[fnId.id]?.data.managers,
                                                                        layer.data[fnId.id]?.data.nonManagers,
                                                                        maxFte
                                                                    )}`,
                                                                }}
                                                            >
                                                                {''}
                                                            </span>
                                                        </LightTooltip>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            <TableRow className={'legendRow firstLegendRow'}>
                                <TableCell className={`fixedCell boldCell removeBorder`}>{`Functions`}</TableCell>
                                {total.map((fnId, id) => {
                                    return (
                                        <TableCell
                                            key={id}
                                            style={{
                                                minWidth: '150px',
                                                width: `${total.length <= 3 ? `${100 / total.length}%` : `${tableCellWidth}px`
                                                    }`,
                                            }}
                                            className={`cell twice`}
                                        >
                                            <span style={{ textTransform: 'capitalize' }}> {`${fnId.name}`} </span>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow className={'legendRow secondLegendRow'}>
                                <TableCell className={`boldCell fixedCell`}>{`Total ${countFilterValue}`}</TableCell>
                                {ftes.map((val, i) => {
                                    return (
                                        <TableCell
                                            key={i}
                                            className={`cell twice`}
                                            style={{
                                                minWidth: '150px',
                                                width: `${total.length <= 3 ? `${100 / total.length}%` : `${tableCellWidth}px`
                                                    }`,
                                                textAlign: 'center',
                                                paddingLeft: 16,
                                            }}
                                        >
                                            {val}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataFound
                    mainMessage={`${isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                        }`}
                    secondaryMessage={`${isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                        }`}
                />
            )}
        </Fragment>
    );
}

export default OrgShape;
