import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import executiveDashboard from '../../../executiveDashboard.enum';
import BlueCircle from '../../../../../assets/bluecircle.svg';
import GreyCircle from '../../../../../assets/greycircle.svg';
import formattedUtilities, { getBadgeType, getPercentage } from '../../../../../common/utilities';

import './index.scss';

const options = {
    chart: { height: 295 },
    title: { text: '' },
    yAxis: {
        title: { text: '(in millions)' },
    },
    xAxis: {},
    legend: { enabled: false },
    credits: { enabled: false },
    tooltip: { enabled: false },
};

const getSeries = (series, categories, hasData) => {
    return [
        {
            type: 'columnrange',
            pointWidth: 2,
            data: series.map(el => [0, el]),
            color: hasData ? '#6E879A' : '#dedede',
            dataLabels: {
                enabled: false,
            },
        },
        {
            type: 'scatter',
            color: '#6E879A',
            name: 'Cost',
            data: series.map((el, idx) => [{ ...categories[idx], marker: { symbol: 'triangle' } }, el]),
            marker: {
                enabled: true,
                radius: 25,
                fillColor: hasData ? '#6E879A' : '#dedede',
                lineColor: '#fff',
                lineWidth: 1,
                symbol: `url(${hasData ? BlueCircle : GreyCircle})`,
            },
            dataLabels: {
                enabled: true,
                useHTML: true,
                crop: false,
                overflow: 'none',
                formatter: function() {
                    const isTarget = this?.x?.name.toLowerCase() === executiveDashboard.TARGET_TEXT.toLowerCase();
                    return `<span style="font-family: 'Lato', sans-serif;font-size: 14px" class='dta-label'>${
                        hasData || isTarget ? formattedUtilities.floatIntValueFormatter(this.y) : '~'
                    }</span>`;
                },
                style: {
                    fontFamily: 'Lato',
                    fontSize: '15px',
                    color: '#fff',
                    textOutline: 'none',
                    marginTop: '14px',
                    lineHeight: '20px',
                },
            },
        },
    ];
};

const Cost = ({ data, target, ranges }) => {
    const categories = [];
    const series = [];
    let hasData = false;

    Object.keys(data).forEach(el => {
        const catobj = { name: data[el].name, badge: 0 };
        if (data[el].shouldShow) {
            if (data[el].value >= 0) {
                series.push(data[el].value);
                catobj.badge = data[el].value;
                hasData = true;
            } else {
                series.push(50);
                hasData = false;
            }
            categories.push(catobj);
        }
    });

    if (target) {
        series.push(+target);
        categories.push({ name: executiveDashboard.TARGET_TEXT, badge: false });
    }

    return (
        <div className="design-progress-cost">
            {hasData && target > 0 && (
                <div className="badge-infotext">
                    <span>
                        (%) of the <br />
                        target
                    </span>
                </div>
            )}
            <HighchartsReact
                options={{
                    ...options,
                    yAxis: {
                        ...options.yAxis,
                        max: Math.max(...series) + 30,
                    },
                    xAxis: {
                        ...options.xAxis,
                        categories,
                        labels: {
                            autoRotation: false,
                            useHTML: true,
                            formatter: function() {
                                const badgeType = getBadgeType(this.value.badge, target, ranges);
                                return `<div class="data-label" style="text-align: center;">
                                <div class="data-label__text" title=${this.value.name}
                                    style="overflow: hidden;
                                        margin-top: 24px;
                                        display: -webkit-box;
                                        color: rgb(156, 156, 156);
                                        font-size:10px;
                                        font-weight:normal;
                                        width: 100%;
                                        word-break: break-all;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;">${this.value.name}
                                        </div>
                                        <div class="data-label__role">
                                        <div class='badge ${badgeType} ${
                                    hasData && target > 0 && this.value?.badge >= 0 && this.value?.badge !== false
                                        ? ''
                                        : 'hidden'
                                }'>
                                            <div class="badge-count">${getPercentage(this.value.badge, target)}%</div>
                                        </div>
                                        </div>
                                </div>`;
                            },
                        },
                    },
                    series: getSeries(series, categories, hasData),
                }}
                highcharts={Highcharts}
            />
        </div>
    );
};

export default Cost;
