import formattedUtilities from '../../../../common/utilities';

const functionDistributionConfig = {
    chart: {
        type: 'column',
        height: 350,
        events: {
            render() {
                const h = this.chartHeight;
                const style = {
                    'font-size': '11px',
                    'font-weight': 'bold',
                    color: 'rgb(102, 102, 102)',
                    'font-family': "'Lato', sans-serif",
                };
                this.renderer
                    .label('Alignment', 0, h - this.axisOffset[2] + 4)
                    .css(style)
                    .add();
                this.renderer
                    .label('N-1 Leaders', 0, h - this.axisOffset[2] + 40)
                    .css(style)
                    .add();
            },
        },
    },
    title: {
        text: '',
    },
    xAxis: {
        labels: {
            useHTML: true,
            formatter: function() {
                return `<div class="data-label">
                <div class="data-label__text" title=  ${this.value.name || ''}
                    style="overflow: hidden;
                        margin-top: 15px;
                        display: -webkit-box;
                        width: 100%;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;">${this.value.function}
                        </div>
                        <div class="data-label__role">
                        ${this.value.name}
                        </div>
                
            </div>`;
            },
        },
    },
    tooltip: {
        useHTML: true,
        shadow: false,
        borderWidth: 0,
        followPointer: false,
        backgroundColor: 'rgba(0,0,0,0)',
        formatter: function() {
            return `<div class="execDashboard-reports__bar-tooltip"><div>${
                this?.point?.cat
            }: ${formattedUtilities.floatIntValueFormatter(this.point.high - this.point.low)}</div></div>`;
        },
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Headcount',
        },
        gridLineColor: '#197F07',
        gridLineWidth: 0,
        lineWidth: 1,
        staclLabels: {
            enabled: true,
        },
    },
    plotOptions: {
        series: {
            pointWidth: 30,
        },
    },
    legend: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
};

export default functionDistributionConfig;
