import axios from 'axios';
import endpoints from '../../Endpoints';

const getHeaders = token => ({
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
});
const MODE = 'cors';

export const getRootRoleId = async (accessToken, structureId) => {
    const { orglabBasePath, rootRole } = endpoints;
    const url = orglabBasePath + rootRole(structureId);
    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${accessToken} ` },
        });
        return res.json();
    } catch (error) {
        console.error(error);
    }
};

export const startGeneratingNnReport = async (accessToken, reqBody) => {
    const { apiNNReportBasePath, nnReportGenerate } = endpoints;
    const url = apiNNReportBasePath + nnReportGenerate;
    try {
        const res = await fetch(url, {
            method: 'POST',
            mode: MODE,
            headers: getHeaders(accessToken),
            body: JSON.stringify(reqBody),
        });
        return res.json();
    } catch (error) {
        console.error(error);
    }
};

export const getGeneratingNnReportStatus = async (accessToken, jobId) => {
    const { apiNNReportBasePath, nnReportJobStatus } = endpoints;
    const url = apiNNReportBasePath + nnReportJobStatus(jobId);
    try {
        const { data } = await axios.get(url, {
            headers: getHeaders(accessToken),
        });
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const downloadNnReport = async (accessToken, jobId) => {
    const { apiNNReportBasePath, nnReportDownload } = endpoints;
    const url = apiNNReportBasePath + nnReportDownload(jobId);
    try {
        const { data } = await axios.get(url, {
            headers: getHeaders(accessToken),
        });
        return data;
    } catch (error) {
        console.error(error);
    }
};
