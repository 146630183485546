import {
    FETCH_ALL_FILTERS_DESIGN_PROGRESS_PENDING,
    FETCH_ALL_FILTERS_FAILED,
    FETCH_ALL_FILTERS_PENDING,
    FETCH_ALL_FILTERS_SUCCESS,
    FETCH_FILTERS_CONTENT_FAILED,
    FETCH_FILTERS_CONTENT_PENDING,
    FETCH_FILTERS_CONTENT_SUCCESS,
    UPDATE_ALL_FILTERS,
    UPDATE_MULTI_FILTERS,
    UPDATE_STATIC_FILTERS,
    SET_FILTERS_TO_APPLY,
    RESET_ALL_FILTERS,
    REFRESH_DATA,
    GET_FILTERS_MANAGER_PENDING,
    GET_FILTERS_MANAGER_CLEAR,
    GET_FILTERS_SELECTED_MANAGER_CLEAR,
    GET_FILTERS_MANAGER_SUCCESS,
    GET_SELECTED_STRUCTURE_FILTERS_PENDING,
    GET_SELECTED_STRUCTURE_FILTERS_SUCCESS,
    SET_MULTI_FILTERS_TO_APPLY,
    GET_STATIC_FILTERS_PENDING,
    GET_STATIC_FILTERS_SUCCESS,
    UPDATE_FILTERS_CHILDREN,
    SET_MULTI_FILTERS_TRAY,
    SET_FILTERS_TRAY,
} from './actionTypes';

export const fetchingDesignProgressFilters = () => ({
    type: `${FETCH_ALL_FILTERS_DESIGN_PROGRESS_PENDING}`,
});

export const fetchAllFilters = (id, showStaticFilters) => ({
    type: `${FETCH_ALL_FILTERS_PENDING}`,
    id,
    showStaticFilters,
});

export const fetchAllFiltersSuccess = payload => ({
    type: `${FETCH_ALL_FILTERS_SUCCESS}`,
    payload,
});

export const fetchAllFiltersFailed = (payload = {}) => ({
    type: `${FETCH_ALL_FILTERS_FAILED}`,
    payload,
});

export const fetchSecondaryFilters = (attribute = '', id = '', arrToRepeat = [], isSelected = false) => ({
    type: `${FETCH_FILTERS_CONTENT_PENDING}`,
    attribute,
    id,
    arrToRepeat,
    isSelected,
});

export const fetchSecondaryFiltersSuccess = payload => ({
    type: `${FETCH_FILTERS_CONTENT_SUCCESS}`,
    payload,
});

export const fetchSecondaryFiltersFailed = (payload = {}) => ({
    type: `${FETCH_FILTERS_CONTENT_FAILED}`,
    payload,
});

export const updateFilters = (payload = {}) => ({
    type: `${UPDATE_ALL_FILTERS}`,
    payload,
});

export const updateMultiFilters = (payload = {}) => ({
    type: `${UPDATE_MULTI_FILTERS}`,
    payload,
});

export const updateStaticFilters = (payload = {}) => ({
    type: `${UPDATE_STATIC_FILTERS}`,
    payload,
});

export const resetFilters = (all = false) => ({
    type: `${RESET_ALL_FILTERS}`,
    payload: { all },
});

export const getRefreshedData = () => ({
    type: `${REFRESH_DATA}`,
});

export const setFiltersToApply = (payload = {}) => ({
    type: `${SET_FILTERS_TO_APPLY}`,
    payload,
});

export const setFiltersTray = (payload = {}) => ({
    type: `${SET_FILTERS_TRAY}`,
    payload,
});

export const setMultiFiltersToApply = (payload = {}) => ({
    type: `${SET_MULTI_FILTERS_TO_APPLY}`,
    payload,
});

export const setMultiFiltersTray = (payload = {}) => ({
    type: `${SET_MULTI_FILTERS_TRAY}`,
    payload,
});

export const getManagerFilters = (attribute = '', str1 = '', module = '', structureNumber = '') => ({
    type: `${GET_FILTERS_MANAGER_PENDING}`,
    attribute,
    str1,
    module,
    structureNumber,
});

export const getManagerFiltersSuccess = (payload = {}) => ({
    type: `${GET_FILTERS_MANAGER_SUCCESS}`,
    payload,
});

export const clrManagerFilters = () => ({
    type: `${GET_FILTERS_MANAGER_CLEAR}`,
});

export const clrSelectedManager = payload => ({
    type: `${GET_FILTERS_SELECTED_MANAGER_CLEAR}`,
    payload,
});

export const getSelectedStructureFilter = payload => ({
    type: `${GET_SELECTED_STRUCTURE_FILTERS_PENDING}`,
    payload,
});

export const fetchSelectedStructureFilterSuccess = payload => ({
    type: `${GET_SELECTED_STRUCTURE_FILTERS_SUCCESS}`,
    payload,
});

export const getStaticFilters = () => ({
    type: `${GET_STATIC_FILTERS_PENDING}`,
});

export const fetchStaticFilterSuccess = payload => ({
    type: `${GET_STATIC_FILTERS_SUCCESS}`,
    payload,
});

export const updateFiltersChildren = (payload = {}) => ({
    type: UPDATE_FILTERS_CHILDREN,
    payload,
});
