import { createSelector } from 'reselect';

const structures = state => state.get('addressableOpportunityReducer');

const isLoading = () => createSelector(structures, state => state.toJS().loading);
const showResult = () => createSelector(structures, state => state.toJS().showResult);
const outputResult = () => createSelector(structures, state => state.toJS().output);
const userInput = () => createSelector(structures, state => state.toJS().input);
const isInputScreenRdx = () => createSelector(structures, state => state.toJS().isInputScreen);

export { isLoading, showResult, outputResult, userInput, isInputScreenRdx };
