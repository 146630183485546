import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Slide, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Popup({
    id,
    title,
    open = false,
    size = 'sm',
    children,
    closeBtnText = 'Cancel',
    okBtnText = 'Save Changes',
    onSave,
    onCancel,
    showOkCancel = true,
    onLoad,
}) {
    useEffect(() => {
        if (onLoad) onLoad();
    }, []);
    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth={size}
                open={open}
                onClose={onCancel}
                aria-labelledby="max-width-dialog-title"
                id={id}
                TransitionComponent={Transition}
                className="dialog-box"
            >
                <IconButton aria-label="close" className="float-btn-close" onClick={onCancel}>
                    <CloseIcon />
                </IconButton>

                {title && <DialogTitle>Optional sizes</DialogTitle>}
                {children && <DialogContent className="dialog-content">{children}</DialogContent>}

                {showOkCancel && (
                    <div className="action-buttons static">
                        <div className="action-buttons__container">
                            <button type="button" className="cancel" onClick={onCancel}>
                                {closeBtnText}
                            </button>
                            <button type="button" className="save" onClick={onSave}>
                                {okBtnText}
                            </button>
                        </div>
                    </div>
                )}
            </Dialog>
        </React.Fragment>
    );
}
