import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import BarChartOff from 'highcharts-react-official';

const BarChart = ({ chartConfig, series, xAxis, yAxis = {} }) => {
    return (
        <BarChartOff
            highcharts={Highcharts}
            options={{
                chart: {
                    ...chartConfig,
                    type: 'bar',
                },
                title: {
                    text: '',
                },
                xAxis: {
                    lineWidth: 0,
                    ...xAxis,
                },
                yAxis: {
                    title: '',
                    opposite: true,
                    overflow: 'allow',
                    ...yAxis,
                },
                legend: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        borderRadiusTopLeft: '50%',
                        borderRadiusTopRight: '50%',
                        pointWidth: 25,
                    },
                },
                series,
            }}
        />
    );
};

BarChart.propTypes = {
    chartConfig: PropTypes.object.isRequired,
    xAxis: PropTypes.object.isRequired,
    yAxis: PropTypes.object,
    series: PropTypes.array.isRequired,
};

BarChart.defaultProps = {
    yAxis: {},
};

export default BarChart;
