import { Box, Typography } from '@material-ui/core';
import './index.scss';
import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    label: {
        fontSize: '12px', // Adjust padding as needed
        marginLeft: '5px'
    },
});

function ExportPopupBody(props) {
    const { options, setOptions } = props;
    const [selectAll, setSelectAll] = useState(false);
    const [allDisabled, setAllDisabled] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const d = options.flat(Infinity).every(opt => opt.disabled);
        setAllDisabled(d);
    }, [options]);

    const handleSelectAll = () => {
        const updatedSubChildCheckboxes = options.map(checkbox => ({
            ...checkbox,
            checked: checkbox.disabled ? checkbox.checked : !selectAll,
            children: checkbox.children.map(child => ({ ...child, checked: child.disabled ? child.checked : !selectAll })),
        }));
        setOptions(updatedSubChildCheckboxes);
        setIndeterminate(false);
        setSelectAll(!selectAll);
    };

    const handleSubChildCheckboxChange = (id, isNested = false) => {
        let updatedSubChildCheckboxes = [];
        if (isNested) {
            updatedSubChildCheckboxes = options.map(checkbox => {
                if (checkbox.children.some(child => child.id === id)) {
                    const updatedChildren = checkbox.children.map(child =>
                        child.id === id ? { ...child, checked: !child.checked } : child
                    );
                    const allChecked = updatedChildren.filter(s => !s.disabled).every(childCheckbox => childCheckbox.checked);
                    checkbox.checked = allChecked;
                    const allSubSelected = updatedChildren.filter(s => !s.disabled).every(sb => sb.checked);
                    const someSubSelected = updatedChildren.filter(s => !s.disabled).some(sb => sb.checked);
                    return { ...checkbox, children: updatedChildren, indeterminate: (!allSubSelected && someSubSelected) };
                }
                return checkbox;
            });
        } else {
            updatedSubChildCheckboxes = options.map(checkbox =>
                checkbox.id === id ? {
                    ...checkbox,
                    checked: !checkbox.checked,
                    ...(checkbox.children && {
                        children: checkbox.children.map(child => ({ ...child, checked: child.disabled ? child.checked : !checkbox.checked })),
                        indeterminate: false
                    })
                } : checkbox
            );
        }
        setOptions(updatedSubChildCheckboxes);
        const allSelected = updatedSubChildCheckboxes.filter(s => !s.disabled).every(s => s.checked && (s.children && s.children.filter(sb => !sb.disabled).every(sb => sb.checked)));
        const someSelected = updatedSubChildCheckboxes.filter(s => !s.disabled).some(s => s.checked || (s.children && s.children.filter(sb => !sb.disabled).some(sb => sb.checked)));
        setIndeterminate(!allSelected && someSelected)
        setSelectAll(allSelected);
    };

    return (
        <Box pt={2} width={520}>
            <Typography className='export-popup-header'>Export PPT</Typography>
            <Box px={5} py={2}>
                <Typography className='export-popup-box-label'>Select reports to export</Typography>
                <Typography className='export-popup-box-sub-label'>Reports that are disabled in the list below do not contain any data and will not be included in the PPT export.</Typography>
                <FormControl style={{ marginTop: 10, overflow: 'scroll', maxHeight: 300, paddingLeft: '10px' }} >
                    <FormGroup >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                    size='small'
                                    style={{color: !allDisabled ? "#154466" : '#D8D8D8', 
                                        padding: '7px 0px' }}
                                    indeterminate={indeterminate}
                                    disabled={allDisabled}
                                />
                            }
                            label="Select all"
                            classes={{
                                label: classes.label
                            }}
                        />
                        <Grid container>
                            {options?.map(checkbox => (
                                <Grid item xs={12} key={checkbox.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkbox.checked}
                                                onChange={() => handleSubChildCheckboxChange(checkbox.id)}
                                                style={{
                                                    color: !checkbox.disabled ? "#154466" : '#D8D8D8',
                                                    padding: '7px 0px',
                                                }}
                                                indeterminate={checkbox.indeterminate}
                                                size='small'
                                                disabled={checkbox.disabled}
                                            />
                                        }
                                        label={checkbox.label}
                                        classes={{
                                            label: classes.label
                                        }}
                                    />
                                    {checkbox.children && checkbox.children.map((child, j) => (
                                        <Grid item xs={12} key={child.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={child.checked}
                                                        onChange={() => handleSubChildCheckboxChange(child.id, true)}
                                                        style={{
                                                            color: !child.disabled ? "#154466" : '#D8D8D8',
                                                            padding: '3px 0px',
                                                        }}
                                                        size='small'
                                                        disabled={child.disabled}
                                                    />
                                                }
                                                label={child.label}
                                                classes={{
                                                    label: classes.label
                                                }}
                                                style={{
                                                    marginTop: j ? '0px' : '-4px',
                                                    marginBottom: j === checkbox.children.length - 1 ? '4px' : '0px',
                                                    paddingLeft: '20px'
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    );
}

ExportPopupBody.propTypes = {
    options: PropTypes.array.isRequired,
    setOptions: PropTypes.func.isRequired
}

export default ExportPopupBody;