import React from 'react';
import './index.scss';

const Range = ({ isEditedMode = false, ranges, onRangeChange, referer }) => {
    const { min, midLow, midHigh, max } = ranges;
    return (
        <div className={`ranges ${isEditedMode ? 'editedMode' : ''}`}>
            <div className="ranges__item">
                <div className="indicator" style={{ backgroundColor: 'red' }} />
                <div className="info">
                    <div className="label">Min. range</div>
                    {!isEditedMode && <div className="value">&lt;{min}% of the target</div>}
                </div>
                {isEditedMode && (
                    <div className="ranges__item-input">
                        <span className="symbol">&lt;</span>
                        <span className="input">
                            <input
                                type="text"
                                defaultValue={min}
                                onChange={event => onRangeChange(event, 'min', referer)}
                            />
                        </span>
                        <span className="percent">%</span>
                    </div>
                )}
            </div>
            <div className="ranges__item">
                <div className="indicator" style={{ backgroundColor: 'orange' }} />
                <div className="info">
                    <div className="label">Mid range</div>
                    {!isEditedMode && (
                        <div className="value">
                            {midLow}-{midHigh}% of the target
                        </div>
                    )}
                </div>
                {isEditedMode && (
                    <div className="ranges__item-input">
                        <span className="input">
                            <input
                                type="text"
                                defaultValue={midLow}
                                onChange={event => onRangeChange(event, 'midLow', referer)}
                            />
                        </span>
                        <span className="symbol" style={{ textAlign: 'center' }}>
                            -
                        </span>
                        <span className="input">
                            <input
                                type="text"
                                defaultValue={midHigh}
                                onChange={event => onRangeChange(event, 'midHigh', referer)}
                            />
                        </span>
                        <span className="percent">%</span>
                    </div>
                )}
            </div>
            <div className="ranges__item">
                <div className="indicator" style={{ backgroundColor: 'green' }} />
                <div className="info">
                    <div className="label">Max. range</div>
                    {!isEditedMode && <div className="value">&gt;{max}% of the target</div>}
                </div>
                {isEditedMode && (
                    <div className="ranges__item-input">
                        <span className="symbol">&gt;</span>
                        <span className="input">
                            <input
                                type="text"
                                defaultValue={max}
                                onChange={event => onRangeChange(event, 'max', referer)}
                            />
                        </span>
                        <span className="percent">%</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Range;
