import compact from 'lodash/compact';

const formattedUtilities = (() => {
    function floatIntValueFormatter(value, showDecimal = true, toFixed = 1) {
        if (value) {
            const formattedParsedIntValue = parseInt(value, 10) + '.0';
            const parsedFloatValue = parseFloat(value).toFixed(toFixed);
            return formattedParsedIntValue === parsedFloatValue
                ? showDecimal
                    ? parseInt(value, 10) + '.0'
                    : parseInt(value, 10)
                : parsedFloatValue;
        }
        if (value === 0) return 0;
        return '';
    }

    function getChildIdList(root) {
        if (root.child.length) {
            root.child.forEach(child => {
                if (!root.childList) root.childList = [];
                root.childList = [...root.childList, ...getChildIdList(child)];
            });
        }

        if (!root.childList) root.childList = [];

        root.childList.push(root.id);

        return root.childList;
    }

    function sumUpDemand(root) {
        if (root.subRows && root.subRows.length) {
            root.subRows.forEach(child => {
                root.demand += sumUpDemand(child);
            });
        }

        return root.sumOfTheChildren + root.demand;
    }

    function sumUpValues(root, includePositions = true) {
        if (root.subRows && root.subRows.length) {
            root.subRows.forEach(child => {
                const resp = sumUpValues(child, includePositions);
                Object.keys(resp).forEach(key => (root.sumOfTheChildren[key] += resp[key] || 0));
            });
        }

        Object.keys(root.sumOfTheChildren).forEach(key => (root.sumOfTheChildren[key] += root[key] || 0));
        if (includePositions) {
            root.sumOfTheChildren.positionSumUp += root.positions.length;
        }

        return root.sumOfTheChildren;
    }

    function generateHierarchy(_reference, subChildKey = 'child') {
        const referenceMap = {};
        const idMapping = _reference.reduce((acc, el, i) => {
            acc[el.id] = i;
            return acc;
        }, {});
        _reference.forEach(el => {
            if (el.parentId === null) {
                referenceMap[el.id] = el;
                return;
            }
            const parentEl = _reference[idMapping[el.parentId]];
            parentEl[subChildKey] = [...(parentEl[subChildKey] || []), el];
        });

        return referenceMap;
    }

    function searchTree(tree, target) {
        if (tree.id === target) {
            return tree;
        }

        for (const child of tree.child) {
            const res = searchTree(child, target);

            if (res) {
                return res;
            }
        }
    }

    function generateParentNodesInList(obj, name, val, currentPath) {
        currentPath = currentPath || '';

        let matchingPath;

        if (!obj || typeof obj !== 'object') return;

        if (obj[name] === val) return `${currentPath}`;

        for (const key of Object.keys(obj)) {
            if (key === name && obj[key] === val) {
                matchingPath = currentPath;
            } else {
                const _path = obj[key] && obj[key][name] ? `${currentPath} ${obj[key][name]} ` : currentPath;

                matchingPath = generateParentNodesInList(obj[key], name, val, _path);
            }

            if (matchingPath) break;
        }

        return matchingPath;
    }

    function flattenTreeToList(tree) {
        const _list = [
            {
                id: tree.id,
                name: tree.chapter_name,
                hasChild: Boolean(tree.child.length),
            },
        ];
        const makeList = child => {
            child.forEach(elem => {
                _list.push({
                    id: elem.id,
                    name: elem.chapter_name,
                    parentId: elem.parentId,
                    hasChild: Boolean(elem.child.length),
                });
                elem.child.length && makeList(elem.child);
            });
        };
        tree.child.length && makeList(tree.child);

        return _list;
    }

    return {
        floatIntValueFormatter,
        generateHierarchy,
        searchTree,
        generateParentNodesInList,
        sumUpValues,
        sumUpDemand,
        getChildIdList,
        flattenTreeToList,
    };
})();

export const getAnalyticsFilterString = filters => {
    const filterQSMapper = {
        phase_id: 'phase_id',
        structureId: 'filter[structure_id]',
        function_id: 'function_id[]',
        manager_id: 'filter[leader_id]',
        location_id: 'filter[location_id][]',
        lookup_id: 'filter[lookup_id][]',
        archetypes: 'archetype_id[]',
        span_of_control: 'span_of_control[]',
        span_ranges: 'span_range',
        contractors: 'filter[contractor]',
        assistant: 'filter[assistant]',
        open_roles: 'filter[employee]',
        algorithms: 'filter[calculations]',
        count_by: 'fte_or_hc',
        group_by: 'group_by[]',
        layer_id: 'layer_id',
        perPage: 'perPage',
        filters: 'filters',
        orderDirection: 'orderDirection',
    };

    const manipulateValue = v => {
        if (v === 'exclude_count') return false;
        if (v === 'exclude_count_span') return true;
        return v;
    };

    const newFilters = Object.keys(filters).map(filter => {
        const k = filterQSMapper[filter];
        const v = manipulateValue(filters[filter]);
        if (k && v !== '__') {
            if (Array.isArray(v)) {
                // if value is array
                // loop through all the values and push into newfilter
                // flat array in case of lookup
                return v
                    .flat()
                    .map(val => {
                        if (filter === 'manager_id') {
                            val = val.split('|')[1];
                        }
                        return `${k}=${val}`;
                    })
                    .join('&');
            }
            return `${k}=${v}`;
        }
        return '';
    });

    // join filters and return
    return compact(newFilters).join('&');
};

export const computeAccess = (usersPrivileges, clientPrivileges) => {
    const userPrivilegesIncluded = usersPrivileges.included || [];
    const clientPrivilegesIncluded = clientPrivileges.included || [];

    const isSummaryReportAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' && attributes.subject === 'summary_report' && attributes.action === 'show'
    ).length;

    const isExecutiveDashboardAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' &&
            attributes.subject === 'executive_dashboard' &&
            attributes.action === 'show'
    ).length;

    const isExecutiveDashboardTeamsAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' &&
            attributes.subject === 'executive_dashboard_teams' &&
            attributes.action === 'show'
    ).length;

    const isSupplyDemandAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' && attributes.subject === 'supply_demand' && attributes.action === 'show'
    ).length;

    const isTeamChaptersAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' && attributes.subject === 'team_chapters' && attributes.action === 'show'
    ).length;

    const isAddressableOpportunityAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' &&
            attributes.subject === 'addressable_opportunity' &&
            attributes.action === 'show'
    ).length;

    const isDesignLinkAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'prototype' && attributes.subject === 'workspace' && attributes.action === 'manage'
    ).length;

    const isDiagnosticLinkAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'controller' && attributes.subject === 'summary' && attributes.action === 'layers'
    ).length;

    const isDirectoryLinkAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' && // prototype
            attributes.subject === 'directory' && // workspace
            attributes.action === 'show' // manage
    ).length;

    const isNewDesignLinkAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'prototype' && attributes.subject === 'workspace' && attributes.action === 'manage'
    ).length;

    const isExecutiveDashboardOrgShapeAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' &&
            attributes.subject === 'executive_dashboard_orgshape' &&
            attributes.action === 'show'
    ).length;

    const isExportPPTAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' &&
            attributes.subject === 'executive_dashboard_export_ppt' &&
            attributes.action === 'show'
    ).length;

    const isNNExportPPTAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'feature' &&
            attributes.subject === 'executive_dashboard_NN_export_ppt' &&
            attributes.action === 'show'
    ).length;

    const isAdminAllowed = !!clientPrivilegesIncluded.filter(
        ({ attributes = {} }) =>
            attributes.category === 'model' && attributes.subject === 'client' && attributes.action === 'implicit'
    ).length;

    const executiveDashboardAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'executive_dashboard' &&
                attributes.action === 'show'
        ).length || isExecutiveDashboardAllowed;

    const executiveDashboardOrgShapeAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'executive_dashboard_orgshape' &&
                attributes.action === 'show'
        ).length || isExecutiveDashboardOrgShapeAllowed;

    const exportPPTAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'executive_dashboard_export_ppt' &&
                attributes.action === 'show'
        ).length || isExportPPTAllowed;

    const NNexportPPTAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'executive_dashboard_NN_export_ppt' &&
                attributes.action === 'show'
        ).length || isNNExportPPTAllowed;

    const supplyDemandAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'supply_demand' &&
                attributes.action === 'show'
        ).length || isSupplyDemandAllowed;

    const teamChaptersAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'team_chapters' &&
                attributes.action === 'show'
        ).length || isTeamChaptersAllowed;

    const addressableOpportunityAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'addressable_opportunity' &&
                attributes.action === 'show'
        ).length || isAddressableOpportunityAllowed;

    const executiveDashboardTeamsAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'executive_dashboard_teams' &&
                attributes.action === 'show'
        ).length || isExecutiveDashboardTeamsAllowed;

    const summaryReportAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' &&
                attributes.subject === 'summary_report' &&
                attributes.action === 'show'
        ).length || isSummaryReportAllowed;

    const isDesignLinkAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'prototype' &&
                attributes.subject === 'workspace' &&
                attributes.action === 'manage'
        ).length || isDesignLinkAllowed;

    const isDiagnosticLinkAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'controller' &&
                attributes.subject === 'summary' &&
                attributes.action === 'layers'
        ).length || isDiagnosticLinkAllowed;

    const isDirectoryLinkAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'feature' && // prototype
                attributes.subject === 'directory' && // workspace
                attributes.action === 'show' // manage
        ).length || isDirectoryLinkAllowed;

    const isIntegrativeWorkspaceLinkAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'prototype' && // prototype
                attributes.subject === 'workspace' && // workspace
                attributes.action === 'manage' // manage
        ).length || isNewDesignLinkAllowed;

    const isAdminAccess =
        !!userPrivilegesIncluded.filter(
            ({ attributes = {} }) =>
                attributes.category === 'model' && attributes.subject === 'client' && attributes.action === 'implicit'
        ).length || isAdminAllowed;

    let isGlobalAnalyticsUser = false;

    const privileges = {
        privilegesConditions: {
            privilegeCondition1: false,
            privilegeCondition2: false,
            privilegeCondition3: false,
        },
        employeePrivilegesNames: {
            name: false,
            bio: false,
            employee_number: false,
            employee_rating: false,
            image_url: false,
            top_talent: false,
            created_at: false,
            updated_at: false,
            lookup_id: false,
        },
        rolePrivilegesNames: {
            archetype_id: false,
            assistant: false,
            contractor: false,
            created_at: false,
            critical_role: false,
            cs_id: false,
            description: false,
            employee_id: false,
            fte_share: false,
            function_id: false,
            location_id: false,
            lookup_id: false,
            mck_function_id: false,
            name: false,
            new_hire: false,
            role_salary: false,
            special_text: false,
            strategic_value: false,
            target_cost: false,
            target_fte: false,
            target_span: false,
            updated_at: false,
        },
    };

    const employeePrivilegesNames = Object.keys(privileges.employeePrivilegesNames);
    const rolePrivilegesNames = Object.keys(privileges.rolePrivilegesNames);

    for (let i = 0; i < userPrivilegesIncluded.length; i++) {
        const {
            attributes: { action, category, subject, model, name },
            type,
        } = userPrivilegesIncluded[i];
        if (type === 'properties') {
            if (employeePrivilegesNames.includes(name) && model === 'Employee') {
                privileges.employeePrivilegesNames[name] = true;
            }
            if (rolePrivilegesNames.includes(name) && model === 'Role') {
                privileges.rolePrivilegesNames[name] = true;
            }
        } else if (type === 'privileges') {
            const condition1 =
                (action === 'read' || action === 'manage') && category === 'attribute' && subject == 'employee#custom';
            const condition2 =
                (action === 'read' || action === 'manage') && category === 'attribute' && subject == 'role#custom';
            const condition3 =
                (action === 'read' || action === 'implicit') && category === 'model' && subject == 'role';

            if (condition1) privileges.privilegesConditions.privilegeCondition1 = true;
            if (condition2) privileges.privilegesConditions.privilegeCondition2 = true;
            if (condition3) privileges.privilegesConditions.privilegeCondition3 = true;
        }
    }

    const privilegesValues = [
        ...Object.values(privileges.privilegesConditions),
        ...Object.values(privileges.employeePrivilegesNames),
        ...Object.values(privileges.rolePrivilegesNames),
    ];
    // final check if all the privileges are set to true
    if (!privilegesValues.includes(false)) {
        isGlobalAnalyticsUser = true;
    }

    return {
        executiveDashboardAccess: isAdminAccess || executiveDashboardAccess || executiveDashboardOrgShapeAccess,
        executiveDashboardOrgShapeAccess: isAdminAccess || executiveDashboardOrgShapeAccess,
        exportPPTAccess: isAdminAccess || exportPPTAccess,
        NNexportPPTAccess: isAdminAccess || NNexportPPTAccess,
        summaryReportAccess: isAdminAccess || (isGlobalAnalyticsUser && summaryReportAccess),
        designLinkAccess: isAdminAccess || isDesignLinkAccess,
        diagnosticLinkAccess: isAdminAccess || isDiagnosticLinkAccess,
        directoryLinkAccess: isAdminAccess || isDirectoryLinkAccess,
        integrativeWorkspaceLinkAccess: isAdminAccess || isIntegrativeWorkspaceLinkAccess,
        isAdminAccess: isAdminAccess,
        teamsDashboardAccess: isAdminAccess || executiveDashboardTeamsAccess,
        supplyDemandAccess: isAdminAccess || supplyDemandAccess,
        teamChaptersAccess: isAdminAccess || teamChaptersAccess,
        addressableOpportunityAccess: isAdminAccess || addressableOpportunityAccess,
    };
};

export const getPercentage = (value, target) => {
    if (!target) return 0;
    return ((value * 100) / target).toFixed(1);
};

export const getMaxWidth = (value, maxValue) => {
    if (!value || !maxValue) return 0;
    return (value * 100) / maxValue;
};

export const getBadgeType = (value, target, comparable) => {
    if (!target) return 'error';
    const percent = parseFloat(((value * 100) / target).toFixed(1));
    if (percent < comparable.min) return 'error';
    if (percent > comparable.max) return 'success';
    if (comparable.midLow <= percent <= comparable.midHigh) return 'warning';
    return '';
};

export default formattedUtilities;
