import React, { Fragment, useEffect, useState } from 'react';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Popover,
    Select as Dropdown,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import './index.scss';
import Select from 'react-select';
// import Structures from '../../../../components/Structures';
import DropdownsAndRanges from './DropdownsAndRanges';
import LeadersStats from './LeadersStats';
import formattedUtilities, { getAnalyticsFilterString } from '../../../../common/utilities';
import { getAnalyticsApi, getTargetsApi, setTargetsApi, getOrgFilters } from '../../../StructureContent/apis';
import MultiSelect from '../../../../components/Multiselect';
import Dialog from '../../../../components/Dialog';
import LocalisedLoader from '../../../../components/LocalisedLoader';
import Range from '../Overall/Range';
import selectLeaderDefaultView from '../../../../assets/selectLeaderDefaultView.svg';
import FILE_TYPES from './functions.enum';
import Loader from '../../../../components/Loader';

const INITAL_STRUCTURE_VALUES = {
    structure1: [],
    structure2: [],
    structure3: [],
};
const PAGINATION_COUNT = 5;

const INITIAL_DATA_FETCH_MAP = {
    structure1: false,
    structure2: false,
    structure3: false,
};
const INITIAL_TARGET_LEVERS = {
    'span-of-control': { name: 'Span of Control', value: '-' },
    layers: { name: 'Layers', value: '-' },
    ftes: { name: 'FTEs', value: '-' },
    'total-cost': { name: 'Total Cost (in millions)', value: '-' },
    'managers-with-2-or-less-direct-reports': { name: 'No. of teams < to 2 direct reports', value: '-' },
};
const Leaders = ({
    structures,
    isEditable,
    ranges,
    activeStructures,
    filters,
    setActiveStructures,
    leadersTargetDialog,
    setLeadersTargetDialog,
    getSelectedStructureFilter,
    bulkEditLeaderDialog,
    setBulkEditLeaderDialog,
    editRangeLeaderDialog,
    setEditRangeLeaderDialog,
    updateRanges,
    staticFilterCountsBy,
    refreshCount,
    updateMultiFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    designFilters,
}) => {
    const [targets, setTargets] = useState({});
    const [bulkTargets, setBulkTargets] = useState({});
    const [rangeError, setRangeError] = useState(false);
    const [layerId, setLayerId] = useState(1);
    const [rangesList, setRangesList] = useState(ranges);
    const [page, setPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [perpage, setPerPage] = useState(PAGINATION_COUNT);
    const [countFilterValue, setCountFilterValue] = useState('Headcount');
    const [isLoading, setLoading] = useState(false);
    const [targetLeaderLoading, setTargetLeaderLoading] = useState(false);
    const [leadersSelectedList, selectLeaders] = useState([]);

    const [leaders, setLeaders] = useState(JSON.parse(JSON.stringify(INITAL_STRUCTURE_VALUES)));

    const [displayLeaders, setDisplayLeaders] = useState([]);
    const [pagedRows, setPagedRows] = useState([]);
    const [applyCount, setApplyCount] = useState(0);
    const [activeStructuresDataFetch, setActiveStructuresDataFetch] = useState({
        structure1: true,
        structure2: true,
        structure3: true,
    });

    const [leaderInEditMode, setLeaderInEditMode] = useState({});
    const [targetLevers, setTargetLevers] = useState(JSON.parse(JSON.stringify(INITIAL_TARGET_LEVERS)));

    useEffect(() => {
        getRangesAndTargets();
    }, []);

    useEffect(() => {
        setPagedRows(displayLeaders.slice(0, perpage));
    }, [displayLeaders, perpage]);

    useEffect(() => {
        getLeadersDisplayList();
        setPage(1);
    }, [applyCount, JSON.stringify(leaders)]);

    // triggers on dropdown change (structure 1,2,3) and filter change
    useEffect(() => {
        const countFilterSelected = staticFilterCountsBy?.isSelected ? 'FTEs' : 'Headcount';
        setCountFilterValue(countFilterSelected);
        const apis = [];
        Object.keys(activeStructures).forEach(el => {
            if (activeStructures[el].id && activeStructuresDataFetch[el]) {
                // const filterString = getAnalyticsFilterString({
                //     ...filters[el],
                //     structureId: activeStructures[el].id,
                //     orderDirection: 'ASC',
                //     perPage: 1000,
                //     layer_id: layerId,
                // });
                apis.push({
                    type: el,
                    api: getOrgFilters({
                        structureId: activeStructures[el].id,
                        method: 'filterFunctions',
                        filterString: `page[size]=1000`,
                    }),
                });
                // apis.push({ type: el, api: getAnalyticsApi({ method: 'managersJson', filterString }) });
            }
        });

        generateData(apis);
        setActiveStructuresDataFetch({
            structure1: true,
            structure2: true,
            structure3: true,
        });
    }, [
        activeStructures.structure1.id,
        activeStructures.structure2.id,
        activeStructures.structure3.id,
        layerId,
        refreshCount,
        JSON.stringify(filters),
    ]);

    const getRangesAndTargets = () => {
        const client_id = localStorage.getItem('clientId');
        const phase_id = localStorage.getItem('phaseId');
        getTargetsApi(client_id, phase_id, FILE_TYPES.RANGE)
            .then(response => {
                if (Object.keys(response.data.data).length) {
                    setRangesList({ ...response.data.data.range });
                }
            })
            .catch(() => {
                setTargetLeaderLoading(false);
            });
        getTargetsApi(client_id, phase_id, FILE_TYPES.ALL)
            .then(response => {
                setTargets(response.data.data);
            })
            .catch(err => {
                console.error(err);
            });

        getTargetsApi(client_id, phase_id, FILE_TYPES.BULK)
            .then(response => {
                if (Object.keys(response.data.data).length > 0) {
                    setBulkTargets(response.data.data);
                } else {
                    setBulkTargets(JSON.parse(JSON.stringify(INITIAL_TARGET_LEVERS)));
                }
            })
            .catch(err => {
                console.error(err);
            });
    };

    const generateData = (apis = []) => {
        if (apis.length) {
            const fetchApis = apis.map(el => el.api);
            const leadersList = { ...leaders };
            setLoading(true);

            Promise.allSettled(fetchApis)
                .then(response => {
                    const leadersApi = [];
                    const leadersApiMeta = [];
                    response.forEach((el, index) => {
                        const type = apis[index].type;
                        if (el.status === 'fulfilled') {
                            const data = el.value.data;
                            // const { managers } = data;
                            const functionsList = data.data;
                            functionsList.forEach(mgr => {
                                // managers.forEach(mgr => {
                                leadersApiMeta.push({ type, mgr });
                                leadersApi.push(
                                    getAnalyticsApi({
                                        method: 'layersJson',
                                        filterString: getAnalyticsFilterString({
                                            ...filters[type],
                                            structureId: activeStructures[type].id,
                                            // manager_id: mgr.id,
                                            function_id: mgr.id,
                                            // function_id: mgr.function.part_of.id,
                                        }),
                                    })
                                );
                            });
                        }
                        leadersList[type] = [];
                    });

                    Promise.all(leadersApi)
                        .then(responses => {
                            responses.forEach(({ data }, idx) => {
                                leadersList[leadersApiMeta[idx].type].push({
                                    ...leadersApiMeta[idx].mgr,
                                    fte_share: data.totals?.fteCount,
                                    role_salary: (data.totals?.managerCost || 0) + (data.totals?.nonManagerCost || 0),
                                    currentSpan: data.totals?.currentSpan,
                                    layer: data.layers.length,
                                    directReporters: data.totals?.spanBands[0],
                                    layers: data.layers,
                                });
                            });

                            setLeaders(leadersList);
                            setLoading(false);
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const getLeaders = () => {
        function calculateLayersCount(array = []) {
            return array.reduce((acc, item) => {
                if (item.fteCount) {
                    acc++;
                }
                return acc;
            }, 0);
        }

        function calculateHeadCount(array = []) {
            return array.reduce((acc, item) => {
                if (item.fteCount) {
                    acc += item.fteCount;
                }
                return acc;
            }, 0);
        }
        const leadersList = [];
        let uniqueKey = 0;
        Object.keys(leaders).forEach(elem => {
            leaders[elem].forEach(el => {
                uniqueKey++;
                leadersList.push({
                    id: uniqueKey,
                    leader_id: `${el.id}${elem}`,
                    label: (
                        <div className="search-option">
                            <span className="name">
                                {el.attributes?.name || ''} at {/* {el.function?.part_of?.name || ''} at{' '} */}
                                <span style={{ fontWeight: 'bold' }}>{activeStructures[elem].name}</span>
                            </span>
                        </div>
                    ),
                    value: `${el.id} ${el.attributes?.name || ''} ${elem}`,
                    // value: `${el.id} ${el.function?.part_of?.name || ''}`,
                    name: el.employee ? el.employee.name : '',
                    // designation: el.function.part_of.name, // TODO
                    designation: el.attributes.name,
                    elem: elem,
                    fte_share: formattedUtilities.floatIntValueFormatter(calculateHeadCount(el.layers), true),
                    layer: calculateLayersCount(el.layers),
                    currentSpan: formattedUtilities.floatIntValueFormatter(el.currentSpan, true),
                    role_salary: '$' + (el.role_salary / 1000000).toFixed(2) + 'M',
                    role_salary_value: el.role_salary,
                    directReporters: el.directReporters,
                    /** Generating unique id using function and manager id to achieve function specific editing */
                    // function_id: `${el.function.id}-${el.id}`,
                    function_id: el.id,
                });
            });
        });
        return leadersList;
    };

    // get all the functions data for the table
    const onStructureChange = (structureMap, type) => {
        // setting the structure(1|2|3) to true in local state
        setActiveStructuresDataFetch({ ...JSON.parse(JSON.stringify(INITIAL_DATA_FETCH_MAP)), [type]: true });
        const resetAppliedFilters = (filters, designFilters, key) => {
            setMultiFiltersToApply({
                filters: {
                    ...filters,
                    [key]: {
                        span_of_control: ['too_few', 'in_range', 'too_many', 'non_manager', 'unknown'],
                        count_by: filters[key]?.count_by?.[0] || 'headcount',
                        algorithms: filters[key]['algorithms'],
                    },
                },
                module: 'leaders',
            });
            setMultiFiltersTray({
                filters: {
                    ...designFilters,
                    [key]: {
                        span_of_control: [
                            { name: 'Managing Too Few', id: 'too_few' },
                            { name: 'Managing In Range', id: 'in_range' },
                            { name: 'Managing Too Many', id: 'too_many' },
                            { name: 'Non-Manager', id: 'non_manager' },
                            { name: 'Unknown', id: 'unknown' },
                        ],
                        count_by: [
                            {
                                name: (filters[key]?.count_by?.[0] === 'fte' ? 'FTEs' : '') || 'Headcount',
                                id: filters[key]?.count_by?.[0] || 'headcount',
                            },
                        ],
                        algorithms: designFilters[key]['algorithms'],
                    },
                },
                module: 'leaders',
            });
        };

        Object.entries(structureMap).forEach(([key, value], idx) => {
            // if default value is selected (Please Select...)
            if (value.name === 'Please Select...') {
                structureMap[key]['name'] = `Structure ${idx + 1}`;
                // reset filters for current structure for functions
                updateMultiFilters({ filters: [], module: 'functions', structureNumber: key });
                resetAppliedFilters(filters, designFilters, key);
            }
        });

        if (type) {
            resetAppliedFilters(filters, designFilters, type);
        }

        setActiveStructures(structureMap);

        // if structure does not exist in the map
        // TODO
        if (!structureMap[type].id) {
            setLeaders({ ...leaders, [type]: [] });
        }
        // if structure exists in the map
        if (structureMap[type]?.id) {
            getSelectedStructureFilter({
                moduleType: 'functions',
                structureNumber: type,
                structureId: structureMap[type]?.id,
            });
        }
    };

    const onLeaderChange = value => {
        setLayerId(value);
    };

    const onLeaderSelect = values => {
        selectLeaders(values);
        setApplyCount(applyCount + 1);
    };

    const onDeselectChip = id => {
        const leadersSelectedListUpdated = leadersSelectedList;
        const selectedOptions = leadersSelectedListUpdated.filter(el => {
            return el.id !== id;
        });
        selectLeaders(selectedOptions);
        setApplyCount(applyCount + 1);
    };

    const onReset = () => {
        selectLeaders([]);
        setApplyCount(0);
    };

    const onTargetSave = type => {
        setTargetLeaderLoading(true);
        const file_type = type === FILE_TYPES.BULK ? type : '' + leaderInEditMode.function_id;
        const client_id = localStorage.getItem('clientId');
        const phase_id = localStorage.getItem('phaseId');
        setTargetsApi({ file_type, config: { ...targetLevers }, client_id, phase_id })
            .then(response => {
                getRangesAndTargets();
                setTargetLeaderLoading(false);
                if (type === FILE_TYPES.BULK) {
                    setBulkEditLeaderDialog(false);
                }
            })
            .catch(() => {
                setTargetLeaderLoading(false);
            });
    };

    const getLeadersDisplayList = () => {
        const leadersList = getLeaders().map(a => ({ ...a }));
        if (!leadersSelectedList.length) {
            setAnchorEl(null);
        }
        if (!applyCount || !leadersSelectedList.length) {
            setDisplayLeaders(leadersList);
            return;
        }
        setDisplayLeaders(leadersList.filter(el => leadersSelectedList.find(item => item.id === el.id)));
    };

    const setCurrentPage = (e, value) => {
        setPage(value);
        setPagedRows(displayLeaders.slice(perpage * (value - 1), perpage * value));
    };

    const onTargetSet = (event, type) => {
        const value = event.target.value.replace(/[^0-9.]/g, '');
        setTargetLevers({
            ...targetLevers,
            [type]: {
                ...targetLevers[type],
                value,
            },
        });
    };

    const setTargetLeversResponse = response => {
        const leverObj = JSON.parse(JSON.stringify(INITIAL_TARGET_LEVERS));
        Object.keys(leverObj).forEach(el => {
            if (response[el]) {
                leverObj[el].value = response[el].value || '';
            }
        });

        setTargetLevers(leverObj);
    };

    const onRangeChange = (evt, type, referer) => {
        if (referer === 'leaders') {
            setRangesList({ ...rangesList, [type]: parseInt(evt.target.value, 10) }, referer);
        }
    };

    return (
        <div className="design-progress__view leaders">
            {isLoading ? <Loader showBackground background="transparent" /> : null}
            <div className={`${leadersSelectedList.length > 0 ? 'disabled' : ''}`}>
                {/* Functions + Design Structure(1..3) + Ranges */}
                <DropdownsAndRanges
                    referer="leaders"
                    structures={structures}
                    onChange={onStructureChange}
                    onLeaderChange={onLeaderChange}
                    isEditable={isEditable}
                    activeStructures={activeStructures}
                    ranges={rangesList}
                    rangeError={rangeError}
                    onRangeChange={onRangeChange}
                    showLeaders
                />
            </div>
            <div className="search-leaders">
                <div className={`search-box ${getLeaders().length > 0 ? '' : 'disabled'}`}>
                    <div className="label" />
                    {/* Search by input field */}
                    <MultiSelect
                        className="search-leaders-select-dropdown"
                        options={getLeaders()}
                        selectedOptions={leadersSelectedList}
                        onChange={onLeaderSelect}
                        placeholder="Search by Function Name "
                        noOptionsMessage={() => {
                            return 'No results found';
                        }}
                        styles={{
                            menu: () => ({
                                width: 500,
                                background: 'white',
                                border: '1px solid #e0e0e0',
                                margin: '0 10px',
                                position: 'relative',
                                left: '-1px',
                            }),
                        }}
                    />
                </div>
                <div className="search-results">
                    {leadersSelectedList.length > 0 && (
                        <span className="selected-text" onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                            {leadersSelectedList.length} selected
                        </span>
                    )}

                    {leadersSelectedList.length > 0 && (
                        <Popover
                            id={'selected-leaders'}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            onClose={() => setAnchorEl(null)}
                        >
                            <List component="nav" aria-label="selected leaders" className="leaders-list">
                                {leadersSelectedList.map((el, idx) => (
                                    <Fragment key={`list_${idx}`}>
                                        <ListItem button>
                                            <ListItemText
                                                className="leaderValues"
                                                primary={el.name}
                                                secondary={el.designation}
                                            />
                                            <ListItemSecondaryAction className="cross-icon">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => onDeselectChip(el.id)}
                                                    className="clr-leader"
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </List>
                        </Popover>
                    )}
                </div>
                <div className="search-buttons">
                    {leadersSelectedList.length > 0 && (
                        <Fragment>
                            <div className="reset" onClick={onReset}>
                                Reset all
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>

            {
                <LeadersStats
                    activeStructures={activeStructures}
                    leaders={pagedRows}
                    countFilterValue={countFilterValue}
                    targets={targets}
                    bulkTargets={bulkTargets}
                    ranges={rangesList}
                    layerId={layerId}
                />
            }
            {!isLoading && (
                <div className="leaders-pagination">
                    {displayLeaders.length > 0 && (
                        <div className="paginationOuter">
                            <div className="textContent">
                                Showing {perpage * (page - 1) + 1} to{' '}
                                {perpage * page < displayLeaders.length ? perpage * page : displayLeaders.length} out of{' '}
                                {displayLeaders.length} results
                            </div>
                            <span className="page-separator"> | </span>
                            <div className="show-per-page">
                                <span>Show</span>
                                <Dropdown
                                    labelId="perpage-select-label"
                                    id="perpage"
                                    value={perpage}
                                    onChange={({ target }) => setPerPage(+target.value)}
                                    className="perpage-dd"
                                >
                                    <MenuItem className="leader-view-item-count" value={5}>
                                        5 per view
                                    </MenuItem>
                                    <MenuItem className="leader-view-item-count" value={10}>
                                        10 per view
                                    </MenuItem>
                                    <MenuItem className="leader-view-item-count" value={15}>
                                        15 per view
                                    </MenuItem>
                                    <MenuItem className="leader-view-item-count" value={20}>
                                        20 per view
                                    </MenuItem>
                                </Dropdown>
                            </div>
                            <span className="page-separator"> | </span>
                            <Pagination
                                count={Math.ceil(displayLeaders.length / perpage)}
                                page={page}
                                onChange={setCurrentPage}
                            />
                        </div>
                    )}
                </div>
            )}

            {leadersTargetDialog && (
                <Dialog
                    id="leaders-target-dialog"
                    open={leadersTargetDialog}
                    size="md"
                    showOkCancel={!!leaderInEditMode.id}
                    onCancel={() => {
                        setLeaderInEditMode({});
                        setLeadersTargetDialog(false);
                    }}
                    onSave={() => {
                        onTargetSave();
                    }}
                >
                    <section className="leaders-target">
                        <div className="leaders-target__title">Edit target value by function</div>
                        <div className="leaders-target-dropdown-save">
                            <div className="dropdown-leaders">
                                <div className="label">Select function</div>
                                <Select
                                    options={getLeaders()}
                                    placeholder="Select Function"
                                    defaultValue={{ label: 'Select Function', value: '' }}
                                    onChange={leader => {
                                        setTargetLeaderLoading(true);
                                        setTargetLevers(JSON.parse(JSON.stringify(INITIAL_TARGET_LEVERS)));
                                        const clientId = localStorage.getItem('clientId');
                                        const phaseId = localStorage.getItem('phaseId');
                                        setLeaderInEditMode(leader);
                                        getTargetsApi(clientId, phaseId, leader.function_id)
                                            .then(response => {
                                                setTargetLeaderLoading(false);
                                                const levers = response.data.data;
                                                const leverObj = JSON.parse(JSON.stringify(INITIAL_TARGET_LEVERS));
                                                Object.keys(leverObj).forEach(el => {
                                                    leverObj[el].value =
                                                        (levers[el] && levers[el].value !== '-' && levers[el].value) ||
                                                        (bulkTargets[el] &&
                                                            bulkTargets[el].value !== '-' &&
                                                            bulkTargets[el].value) ||
                                                        '-';
                                                });

                                                setTargetLeversResponse(leverObj);
                                            })
                                            .catch(() => {
                                                setTargetLeaderLoading(false);
                                            });
                                    }}
                                />
                            </div>
                        </div>
                        {leaderInEditMode.id ? (
                            !targetLeaderLoading ? (
                                <div className="targets-table">
                                    <div className="row">
                                        <div className="row-item">
                                            <span>LEVERS</span>
                                        </div>
                                        <div className="row-item">
                                            <span>ENTER VALUE</span>
                                        </div>
                                    </div>
                                    {Object.keys(targetLevers).map(el => {
                                        return (
                                            <div className="row">
                                                <div className="row-item">
                                                    <span>{targetLevers[el].name}</span>
                                                </div>
                                                <div className="row-item">
                                                    <span>
                                                        <input
                                                            type="text"
                                                            value={
                                                                targetLevers[el].value !== '-'
                                                                    ? targetLevers[el].value
                                                                    : bulkTargets[el].value
                                                            }
                                                            onChange={event => onTargetSet(event, el)}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="targets-loader">
                                    <LocalisedLoader />
                                </div>
                            )
                        ) : (
                            <div className="default-view">
                                <div>
                                    <img src={selectLeaderDefaultView} alt="Select Leader Default View" />
                                </div>
                                <div className="label">Select function to get started</div>
                            </div>
                        )}
                    </section>
                </Dialog>
            )}

            {bulkEditLeaderDialog && (
                <Dialog
                    id="leaders-target-dialog"
                    open={bulkEditLeaderDialog}
                    size="md"
                    showOkCancel
                    onCancel={() => {
                        setLeaderInEditMode({});
                        setBulkEditLeaderDialog(false);
                    }}
                    onSave={() => {
                        onTargetSave(FILE_TYPES.BULK);
                    }}
                    onLoad={() => {
                        const clientId = localStorage.getItem('clientId');
                        const phaseId = localStorage.getItem('phaseId');
                        setTargetLeaderLoading(true);
                        getTargetsApi(clientId, phaseId, FILE_TYPES.BULK)
                            .then(response => {
                                setTargetLeaderLoading(false);
                                setTargetLeversResponse(response.data.data);
                            })
                            .catch(() => {
                                setTargetLeaderLoading(false);
                            });
                    }}
                >
                    <section className="leaders-target">
                        <div className="leaders-target__title">Edit target value by functions</div>
                        {!targetLeaderLoading ? (
                            <div className="targets-table">
                                <div className="row">
                                    <div className="row-item">
                                        <span>LEVERS</span>
                                    </div>
                                    <div className="row-item">
                                        <span>ENTER VALUE</span>
                                    </div>
                                </div>
                                {Object.keys(targetLevers).map(el => {
                                    return (
                                        <div className="row">
                                            <div className="row-item">
                                                <span>{targetLevers[el].name}</span>
                                            </div>
                                            <div className="row-item">
                                                <span>
                                                    <input
                                                        type="text"
                                                        value={targetLevers[el].value}
                                                        onChange={event => onTargetSet(event, el)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="targets-loader">
                                {' '}
                                <LocalisedLoader />
                            </div>
                        )}
                    </section>
                </Dialog>
            )}

            {editRangeLeaderDialog && (
                <Dialog
                    id="leaders-target-dialog"
                    open={editRangeLeaderDialog}
                    size="md"
                    showOkCancel
                    onCancel={() => {
                        setEditRangeLeaderDialog(false);
                    }}
                    onSave={() => {
                        const client_id = localStorage.getItem('clientId');
                        const phase_id = localStorage.getItem('phaseId');
                        const { min, midLow, midHigh, max } = rangesList;
                        const validElements = [min, midLow, midHigh, max].every(el => {
                            return el < 100 && el > 0;
                        });
                        if (min === midLow && midHigh === max && midHigh > midLow && validElements) {
                            setRangeError(false);

                            updateRanges({ ...rangesList }, 'leaders');
                            setTargetsApi({
                                file_type: FILE_TYPES.RANGE,
                                config: { range: { ...rangesList } },
                                client_id,
                                phase_id,
                            })
                                .then(response => {
                                    setTargetLeaderLoading(false);
                                })
                                .catch(() => {
                                    setTargetLeaderLoading(false);
                                });

                            setEditRangeLeaderDialog(false);
                        } else {
                            setRangeError(true);
                        }
                    }}
                >
                    <section className="leaders-target">
                        <div className="leaders-target__title">Edit range</div>
                        <div className="leaders-edit-ranges">
                            {rangeError && <div className="ranges-error">* Please enter the valid ranges</div>}
                            <Range isEditedMode referer="leaders" ranges={rangesList} onRangeChange={onRangeChange} />
                        </div>
                    </section>
                </Dialog>
            )}
        </div>
    );
};

export default Leaders;
