import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { clrManagerFilters } from '../StructureContent/actions';
// import { downloadPPTAction } from '../PPTExport/actions';
import { downloadPPTAction } from '../PPTExport/actions/addressable-opportunity';

import { getFilters, getPhaseStructureInfo, strCompared, getStructure, strCompare } from '../StructureContent/selector';
import AddressableOpportunity from './index';
import { selectPrivileges } from '../Login/selector';
// import { resetFilters } from '../Filters/actions/actions';
// import { selectRefreshCount } from '../Filters/selectors/selector';
import { getAddressableReport, goToInputSection, resetState, setIsInputScreenAction } from './actions';
import { isLoading, showResult, outputResult, userInput, isInputScreenRdx } from './selector';

import {
    selectAddressableOpportunityFilterLoader,
    selectFilters,
    selectFiltersReadyToApply,
    selectFiltersToApply,
    selectFiltersTray,
    selectRefreshCount,
} from '../Filters/selectors/selector';
import { resetFilters, setFiltersToApply, updateFilters, setFiltersTray } from '../Filters/actions/actions';

const stateToProps = createStructuredSelector({
    strCompareFetched: strCompared(),
    primaryFilters: getFilters(),
    privileges: selectPrivileges(),
    phaseStructureInfo: getPhaseStructureInfo(),
    refreshCount: selectRefreshCount(),
    isLoading: isLoading(),
    showResult: showResult(),
    outputResult: outputResult(),
    userInput: userInput(),
    // update
    structure: getStructure(),
    filtersToApply: selectFiltersToApply(),
    filtersTray: selectFiltersTray(),
    filterList: selectFilters(),
    isFiltersReadyToApply: selectFiltersReadyToApply(),
    strCompareData: strCompare(),
    isInputScreenRdx: isInputScreenRdx(),
    addressableOpportunityFilterLoader: selectAddressableOpportunityFilterLoader(),
});

const dispatchToProps = {
    clrManagerFilters,
    resetFilters,
    getAddressableReport,
    goToInputSection,
    resetState,
    setFiltersToApply,
    setFiltersTray,
    updateFilters,
    downloadPPTAction,
    setIsInputScreenAction,
};

export default connect(stateToProps, dispatchToProps)(AddressableOpportunity);
