import { call, put, takeLatest } from 'redux-saga/effects';
import { getReportSuccess, getReportFailed } from './actions';
import { GET_REPORT } from './constants';
import getAddressableOpportunityApi from './apis';

export function* getAddressableOpportunityData({ payload }) {
    try {
        const phase_id = localStorage.getItem('phaseId');
        const { data } = yield call(getAddressableOpportunityApi, { ...payload, phase_id });
        if (data.success) yield put(getReportSuccess(data.data));
        else yield put(getReportFailed({ detail: '', status: '' }));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getReportFailed({ detail, status }));
    }
}

const addressableOpportunitySagas = [takeLatest(`${GET_REPORT}_PENDING`, getAddressableOpportunityData)];

export default addressableOpportunitySagas;
