import React, { useState, useEffect, useRef } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '../Tooltip';
import { slugify } from '../../utils/functions';
import './index.scss';

const Ticket = ({
    isEditable,
    children,
    hasScroll,
    title,
    onTargetChange,
    selecetedTarget,
    placeholder = 'Enter target value',
}) => {
    const ip = useRef();
    const [error, setError] = useState('');
    const onChange = ({ target }) => {
        const value = target.value.replace(/[^0-9.]/g, '');

        if (title.toLowerCase().includes('ratio')) {
            if (value > 100) {
                setError('Value must be bweteen 0 to 100.');
                return false;
            }
            setError('');
        }

        target.value = value;
        onTargetChange({ key: slugify(title), value });
        return true;
    };

    useEffect(() => {
        if (!isEditable && title.toLowerCase().includes('ratio')) {
            setError('');
        }
    }, [isEditable]);

    useEffect(() => {
        if (!selecetedTarget && ip?.current) {
            ip.current.value = '';
        }
    }, [selecetedTarget]);
    return (
        <section className={`ticket`}>
            <div className="ticket__title">
                <div className="value">{title}</div>
                {isEditable && (
                    <div className="target">
                        <div className="input-wrapper">
                            <input
                                ref={ip}
                                type="text"
                                placeholder={placeholder}
                                onChange={onChange}
                                class={`${error ? 'has-error' : ''}`}
                                value={selecetedTarget}
                            />
                            {error && (
                                <Tooltip title={error} className="infoerror">
                                    <InfoIcon />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={`ticket__content ${hasScroll ? 'has-scroll' : ''}`}>{children}</div>
        </section>
    );
};

export default Ticket;
