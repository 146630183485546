import React, { Fragment } from 'react';
import Card from '../../../components/Card';
import './index.scss';
import Progress from '../../../components/Progress';
import Shimmer from '../../../components/shimmer';
import NoDataFound from '../../../components/NoDataFound';
import executiveDashboard from '../../ExecutiveDashboard/executiveDashboard.enum';

const headers = [
    { name: 'Role Name' },
    { name: 'Location' },
    { name: 'Associated Teams' },
    { name: 'Team Value' },
    { name: 'Allocation' },
];
const showHeaders = () => {
    return (
        <div className="critical-row-capacity__headers">
            {headers.map(el => (
                <div className="header-item">{el.name}</div>
            ))}
        </div>
    );
};

const showRoles = criticalRoleCapacityMap => {
    return Object.keys(criticalRoleCapacityMap).map(role => {
        const { role_name, teams, totalAllocation, location } = criticalRoleCapacityMap[role];
        return (
            <Fragment>
                <div className="role">
                    <div className="name" title={role_name}>
                        {role_name}
                    </div>
                    <div className="name">{location ? location.join(' - ') : ''}</div>
                    <div className="team">
                        {Object.keys(teams).map(el => (
                            <div title={teams[el].teamName}>{teams[el].teamName}</div>
                        ))}
                    </div>
                    <div className="value">
                        {Object.keys(teams).map(el => (
                            <div>{teams[el].teamValue ? `$${teams[el].teamValue / 1000000}M` : '-'}</div>
                        ))}
                    </div>
                    <div className="allocation">
                        {Object.keys(teams).map(el => (
                            <div className="allocation__value">
                                <div className="percentage">{`${teams[el].allocation.toFixed(1)}%`}</div>
                                <div className="bar">
                                    <Progress
                                        width={`${teams[el].allocation > 100 ? 100 : teams[el].allocation}%`}
                                        color="#cccccc"
                                    />
                                </div>
                            </div>
                        ))}

                        <div className="allocation__value total">
                            <div className="percentage">Total {totalAllocation}%</div>
                            <div className="bar">
                                <Progress
                                    width={`${totalAllocation > 100 ? 100 : totalAllocation}%`}
                                    color={`${
                                        totalAllocation > 100
                                            ? '#fe7002'
                                            : totalAllocation < 100
                                            ? '#4db6ac'
                                            : '#019688'
                                    }`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    });
};

const CriticalRoleCapacity = ({ criticalRoleCapacityMap, isLoading }) => {
    return isLoading ? (
        <Shimmer />
    ) : (
        <Card title="Critical Role Capacity" classes={'critical-role-capacity-card'}>
            {Object.keys(criticalRoleCapacityMap).length > 0 ? (
                <Fragment>
                    <div className="critical-role-capacity">{showHeaders()}</div>
                    <div className="critical-role-capacity__content">{showRoles(criticalRoleCapacityMap)}</div>
                </Fragment>
            ) : (
                <NoDataFound
                    mainMessage={executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE}
                    secondaryMessage={executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE}
                />
            )}
        </Card>
    );
};

export default CriticalRoleCapacity;
