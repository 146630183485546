import { KEYS_SLIDE_2, CUSTOM_KEYS_SLIDE_2, CATEGORIES_SLIDE_2 as CATS } from '../../constants';
import { SHARED_STATE } from './shared';

const SpanOfControlSlideState = {
    slideIndex: 2, // custom property
    identify_shape_name: 'checkme',
    identify_shape_value: 'Span_of_control_overview',
    data: [
        {
            name: 'checkme',
            is_delete: 'true',
        },
        {
            name: 'sub_title',
            existing_value: '~XX',
            value: '75',
        },
        {
            name: 'chart_1',
            type: 'chart',
            sub_type: 'bar',
            chart_data: [],
        },
    ],
};

// eslint-disable-next-line
export const generateSpans2PPT = async (data, clientName, addWithMainState) => {
    let slideState = {
        ...SpanOfControlSlideState,
    };
    // categories map
    const _CAT = {
        [CATS.TOO_MANY]: 'Managing Too Many',
        [CATS.IN_RANGE]: 'Managing In Range',
        [CATS.TOO_FEW]: 'Managing Too Few',
        [CATS.UNKNOWN]: 'Unknown',
    };
    // from bottom to top series value (for final json)
    // too_many series (3)
    // in_range series (2)
    // too_few  series (1)
    // unknown  series (0)
    const dataByCategories = data.series;
    const valuesByCategories = {
        [CATS.UNKNOWN]: [],
        [CATS.TOO_MANY]: [],
        [CATS.IN_RANGE]: [],
        [CATS.TOO_FEW]: [],
    };
    const chartData = []; // for json

    valuesByCategories[CATS.TOO_FEW] = dataByCategories.filter(e => e.name === _CAT[CATS.TOO_FEW])[0].data;
    valuesByCategories[CATS.IN_RANGE] = dataByCategories.filter(e => e.name === _CAT[CATS.IN_RANGE])[0].data;
    valuesByCategories[CATS.TOO_MANY] = dataByCategories.filter(e => e.name === _CAT[CATS.TOO_MANY])[0].data;
    valuesByCategories[CATS.UNKNOWN] = dataByCategories.filter(e => e.name === _CAT[CATS.UNKNOWN])[0].data;

    const tooFewSum = valuesByCategories[CATS.TOO_FEW].reduce((acc, curr) => (acc += curr), 0);
    const inRangeSum = valuesByCategories[CATS.IN_RANGE].reduce((acc, curr) => (acc += curr), 0);
    const tooManySum = valuesByCategories[CATS.TOO_MANY].reduce((acc, curr) => (acc += curr), 0);
    const unknownSum = valuesByCategories[CATS.UNKNOWN].reduce((acc, curr) => (acc += curr), 0);

    // using this only to access the index
    let MAX_VALUE = -Infinity;
    let SUM_OF_FIRST_COL = 0;
    let SUM_OF_FIRST_TWO_COLS = 0;
    const BOTTOM_POSITION = 5.7;
    const TOP_POSITION = 2.5;
    const TOTAL_SUM = tooFewSum + inRangeSum + tooManySum + unknownSum;

    const SUM_COL_MAP = {};

    // considering "too_few" randomly as all categories will have same length
    for (let i = 0; i < valuesByCategories[CATS.TOO_FEW].length; i++) {
        // const node = { "row_index": 0, "column_index": 0 };
        const node = {};
        // at every index level check the same indexes for each category data array
        // from there decide the value of the "series" for each node
        // eslint-disable-next-line
        let sum = 0; // sum of each values in column (index wise)
        for (const key in valuesByCategories) {
            if (valuesByCategories.hasOwnProperty(key)) {
                // const value = valuesByCategories[key][i];
                node['index'] = i;
                node['column_index'] = i;

                // this function is used for each if condition (based on categories)
                // eslint-disable-next-line
                const computeForEachCondition = (category, series) => {
                    const value = valuesByCategories[category][i];
                    const _node = { ...node };
                    _node['row_index'] = series;
                    _node['series'] = series;
                    _node['value'] = value === 0 ? '0.0' : value;
                    // _node["label"] 		= value === 0 ? "" : value;
                    MAX_VALUE = Math.max(value, MAX_VALUE);
                    sum += value;
                    chartData.push(_node);
                };

                // from bottom to top series value (for final json)
                // too_many series (3)
                // in_range series (2)
                // too_few  series (1)
                // unknown  series (0)

                if (key === CATS.TOO_MANY) computeForEachCondition(CATS.TOO_MANY, 3);
                if (key === CATS.IN_RANGE) computeForEachCondition(CATS.IN_RANGE, 2);
                if (key === CATS.TOO_FEW) computeForEachCondition(CATS.TOO_FEW, 1);
                if (key === CATS.UNKNOWN) computeForEachCondition(CATS.UNKNOWN, 0);
            }
        } // end of key loop

        //  map of sum of values on each each column
        SUM_COL_MAP[i] = sum;

        // accumulating the sum of first two columns elements
        if (i < 2) {
            SUM_OF_FIRST_TWO_COLS += sum;
        }
        // accumulating the sum of first column elements
        if (i < 1) {
            SUM_OF_FIRST_COL += sum;
        }

        MAX_VALUE = Math.max(sum, MAX_VALUE);
    } // end of for loop

    // this denotes the percentage of people managers have 4 or less direct reports
    // ie. sum of values on the first two bars and its percent on total
    const firstTwoColsPercentage = ((SUM_OF_FIRST_TWO_COLS / TOTAL_SUM) * 100 || 0).toFixed(0);
    const firstColPercentage = ((SUM_OF_FIRST_COL / TOTAL_SUM) * 100 || 0).toFixed(0);

    slideState = {
        ...slideState,
        data: slideState.data.map(d => {
            if (d.name === 'chart_1') {
                return { ...d, chart_data: chartData, meta_data: { min_bound: 0, max_bound: MAX_VALUE } };
            }
            return d;
        }),
    };

    // placholders values (denotes the total of values on each each bar (each col))
    const placholders = [];
    for (let i = 0; i <= 6; i++) {
        placholders.push({
            name: `Text Placeholder 1${i}`,
            value: SUM_COL_MAP[i],
            top_position:
                ((TOP_POSITION - BOTTOM_POSITION) / 100) * ((SUM_COL_MAP[i] / MAX_VALUE) * 100) + BOTTOM_POSITION,
        });
    }

    slideState = {
        ...slideState,
        data: [
            ...slideState.data,
            {
                name: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.applied_filters_comment].name,
                value: SHARED_STATE.FILTER_STRING,
            },
            {
                name: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.company_name_comment].name,
                existing_value: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.company_name_comment].existing_value,
                value: clientName,
            },
            {
                name: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.percentage].name,
                existing_value: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.percentage].existing_value,
                value: firstTwoColsPercentage,
            },
            {
                name: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.percentage_comment].name,
                existing_value: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.percentage_comment].existing_value,
                value: firstColPercentage,
            },
            ...placholders,
        ],
    };
    addWithMainState(slideState);
};
