import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Filters from './index';
import {
    clrSelectedManager,
    fetchAllFilters,
    fetchSecondaryFilters,
    getManagerFilters,
    setFiltersToApply,
    setFiltersTray,
    updateFilters,
    updateFiltersChildren,
} from './actions/actions';
import { fetchFilterStatus, selectFilters } from './selectors/selector';

const stateToProps = createStructuredSelector({
    filters: selectFilters(),
    isFiltersFetched: fetchFilterStatus(),
});

const dispatchToProps = {
    fetchAllFilters,
    updateFilters,
    fetchSecondaryFilters,
    setFiltersToApply,
    setFiltersTray,
    getManagerFilters,
    clrSelectedManager,
    updateFiltersChildren,
};

export default connect(stateToProps, dispatchToProps)(Filters);
