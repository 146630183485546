import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.scss';

const SummaryInfoCard = ({ icon, title, value, isLoading }) => {
    return (
        <div className="summary-info-card">
            <div className="summary-info-card__label">
                <div className="summary-info-card__icon">
                    <img src={icon} alt={title} />
                </div>
                <div className="summary-info-card__title">{title}</div>
            </div>
            <div className="summary-info-card__value">
                {isLoading ? <CircularProgress color={'theme'} size={20} /> : value || 0}
            </div>
        </div>
    );
};

export default SummaryInfoCard;
