import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    FETCH_ALL_FILTERS_PENDING,
    FETCH_FILTERS_CONTENT_PENDING,
    GET_FILTERS_MANAGER_PENDING,
    GET_SELECTED_STRUCTURE_FILTERS_PENDING,
    GET_STATIC_FILTERS_PENDING,
} from '../actions/actionTypes';

import {
    fetchingDesignProgressFilters,
    fetchAllFiltersFailed,
    fetchAllFiltersSuccess,
    fetchSecondaryFiltersFailed,
    fetchSecondaryFiltersSuccess,
    getManagerFiltersSuccess,
    fetchSelectedStructureFilterSuccess,
    fetchStaticFilterSuccess,
} from '../actions/actions';
import { getOrgFilters, getAnalyticsApi, getOrgResources } from '../../StructureContent/apis';
import { getAnalyticsFilterString } from '../../../common/utilities';
import compact from 'lodash/compact';

const getFilterValues = (source, key, valueKey, isSelected = false) => {
    return source[key]
        ? source[key].map(({ id, label }) => {
              const selected = typeof isSelected !== 'boolean' ? id === isSelected.split(' ')[0] : isSelected;
              return { id, name: label, key: valueKey, isSelected: selected };
          })
        : [];
};

const prepareFilters = ({ filters1, functions1, locations1, lookups1 }, showStaticFilters) => {
    const lookupCategory = {},
        lookups_children = [];
    (lookups1.data || []).forEach(({ attributes, relationships }) => {
        if (relationships?.values?.data) {
            relationships.values.data.forEach(({ id }) => {
                lookupCategory[id] = attributes?.name;
            });
        }
    });

    (lookups1.included || []).forEach(({ id, attributes }) => {
        if (lookupCategory[id]) {
            lookups_children.push({
                key: 'lookup_id',
                id,
                lookup_category: lookupCategory[id],
                name: attributes?.name,
            });
        }
    });

    let payload = {
        manager: {
            name: 'manager',
            keyToUse: 'manager',
            children: [],
            selectedOption: {},
            isSelected: false,
        },
        functions: {
            name: 'functions',
            children: (functions1.data || []).map(({ id, attributes }) => {
                return {
                    key: 'function_id',
                    id,
                    name: attributes?.name,
                };
            }),
        },
        locations: {
            name: 'locations',
            children: (locations1.data || []).map(({ id, attributes }) => {
                return {
                    key: 'location_id',
                    id,
                    name: attributes?.name,
                };
            }),
        },
        lookups: {
            name: 'lookups',
            children: lookups_children,
        },
    };

    const staticFilters = prepareStaticFilters(filters1);

    if (showStaticFilters) payload = { ...payload, ...staticFilters };

    return payload;
};

const prepareStaticFilters = filters => {
    return {
        staticFilters: {
            name: 'staticFilters',
            children: {
                archetypes: {
                    type: 'managerFilter',
                    children: getFilterValues(filters, 'archetypes', 'archetypes'),
                },
                span_of_control: {
                    type: 'managerFilter',
                    children: getFilterValues(filters, 'span_of_control', 'span_of_control', true),
                    isSelected: true,
                },
                '#_of_Direct_Reports': {
                    type: 'managerFilter',
                    children: [
                        { id: '__', name: 'All', key: 'span_ranges' },
                        ...getFilterValues(filters, 'span_ranges', 'span_ranges'),
                    ],
                },
                Archetype_Algorithm: {
                    type: 'calculationAssumptionsFilter',
                    children: [...getFilterValues(filters, 'algorithms', 'algorithms', 'conservative (Default)')],
                    isSelected: true,
                },
                open_Roles: {
                    type: 'calculationAssumptionsFilter',
                    children: [
                        { id: '__', name: 'Include Open Roles', key: 'open_roles' },
                        ...getFilterValues(filters, 'open_roles', 'open_roles'),
                    ],
                },
                contractors: {
                    type: 'calculationAssumptionsFilter',
                    children: [
                        { id: '__', name: 'Include Contractors', key: 'contractors' },
                        ...getFilterValues(filters, 'contractors', 'contractors'),
                    ],
                },
                assistants: {
                    type: 'calculationAssumptionsFilter',
                    children: [
                        { id: '__', name: 'Include Assistants', key: 'assistant' },
                        ...getFilterValues(filters, 'assistants', 'assistant'),
                    ],
                },
                counts: {
                    type: 'calculationAssumptionsFilter',
                    children: getFilterValues(filters, 'fte_or_hc', 'count_by', 'fte (Default)'),
                    isSelected: true,
                },
            },
        },
    };
};

export function* fetchAllFilters({ id, showStaticFilters }) {
    try {
        if (!id) return false;

        const filterString = getAnalyticsFilterString({
            structureId: id,
            filters:
                'algorithms,archetypes,span_of_control,span_ranges,functions,managers,locations,lookups,layers,contractors,contractor_incl,assistants,assistant_incl,open_roles,fte_or_hc',
        });
        const [{ data: filters1 }, { data: functions1 }, { data: locations1 }, { data: lookups1 }] = yield all([
            call(getAnalyticsApi, { method: 'filterJson', filterString }),
            call(getOrgFilters, { structureId: id, method: 'filterFunctions', filterString: 'page[size]=1000' }),
            call(getOrgFilters, { structureId: id, method: 'filterLocations', filterString: 'page[size]=1000' }),
            call(getOrgFilters, { structureId: id, method: 'filterLookups', filterString: '' }),
        ]);

        const payload = prepareFilters({ filters1, functions1, locations1, lookups1 }, showStaticFilters);

        yield put(fetchAllFiltersSuccess(payload));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(fetchAllFiltersFailed({ detail, status }));
    }
}

export function* fetchSelectedStructureFilter({ payload }) {
    yield put(fetchingDesignProgressFilters());
    const id = payload.structureId;
    if (!id) return false;
    try {
        const [{ data: functions1 }, { data: locations1 }, { data: lookups1 }] = yield all([
            call(getOrgFilters, { structureId: id, method: 'filterFunctions', filterString: 'page[size]=1000' }),
            call(getOrgFilters, { structureId: id, method: 'filterLocations', filterString: 'page[size]=1000' }),
            call(getOrgFilters, { structureId: id, method: 'filterLookups', filterString: '' }),
        ]);

        const filters = prepareFilters({ filters1: {}, functions1, locations1, lookups1 }, true);

        yield put(
            fetchSelectedStructureFilterSuccess({
                filters,
                module: payload.moduleType,
                structureNumber: payload.structureNumber,
            })
        );
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(fetchAllFiltersFailed({ detail, status }));
    }
}

function* fetchStaticFilters() {
    try {
        const filterString = getAnalyticsFilterString({
            filters:
                'algorithms,archetypes,span_of_control,span_ranges,functions,managers,locations,lookups,layers,contractors,contractor_incl,assistants,assistant_incl,open_roles,fte_or_hc',
        });
        const { data: filters1 } = yield call(getAnalyticsApi, { method: 'filterJson', filterString });
        const staticFilters = prepareStaticFilters(filters1);
        yield put(fetchStaticFilterSuccess(staticFilters));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(fetchAllFiltersFailed({ detail, status }));
    }
}

export function* fetchSecondaryFilter({ attribute, id, arrToRepeat, isSelected }) {
    const filterKeyMap = {
        locations: 'location_id',
        functions: 'function_id',
        lookups: 'lookup_id',
    };
    try {
        const { data } = yield call(getOrgResources, {
            id: id,
            method: attribute,
            filterString: 'page[size]=1000',
        });

        const { data: oldData = [] } = data;
        const children = oldData.map(oldInfo => ({
            id: oldInfo.id,
            key: filterKeyMap[attribute],
            name: oldInfo.attributes.name,
            isSelected,
        }));
        yield put(fetchSecondaryFiltersSuccess({ children, arrToRepeat }));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(fetchSecondaryFiltersFailed({ detail, status, arrToRepeat }));
    }
}

export function* fetchManagerFilters({ attribute: name, str1, module, structureNumber }) {
    try {
        const [n, key] = name.split('|');
        const { data } = yield call(getOrgFilters, {
            structureId: str1,
            method: 'filterRoles',
            // filterString: 'include=employee&page[size]=10&filter[name_or_employee_name]={{*' + key + '*}}',
            filterString: `include=employee&page[size]=10&query={name=~"(?i).*${key}.*"} OR employee{name=~"(?i).*${key}.*"}`,
        });

        const empMapping = (data.included || []).reduce((acc, { id, attributes }) => {
            acc[id] = attributes.name;
            return acc;
        }, {});
        const children = data.data.map(({ id, attributes, relationships }) => {
            return {
                key: 'manager_id',
                id: `${attributes.cs_id}|${id}`,
                name: compact([attributes.name, empMapping[relationships?.employee?.data?.id]]).join(' - '),
            };
        });

        const payload = { name: n, children, module, structureNumber };
        yield put(getManagerFiltersSuccess(payload));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(fetchAllFiltersFailed({ detail, status }));
    }
}

const filtersSagas = [
    takeEvery(`${FETCH_ALL_FILTERS_PENDING}`, fetchAllFilters),
    takeEvery(`${GET_FILTERS_MANAGER_PENDING}`, fetchManagerFilters),
    takeEvery(`${FETCH_FILTERS_CONTENT_PENDING}`, fetchSecondaryFilter),
    takeLatest(`${GET_SELECTED_STRUCTURE_FILTERS_PENDING}`, fetchSelectedStructureFilter),
    takeLatest(`${GET_STATIC_FILTERS_PENDING}`, fetchStaticFilters),
];
export default filtersSagas;
