import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Dialog, IconButton, Button, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ExportPopupBody from './ExportPopupBody';

const useStyles = makeStyles({
    btn: {
        height: '38px',
        width: '132px',
        textTransform: 'none',
        borderRadius: '8px'
    },
    cancelBtn: {
        border: "1px solid #154466",
        color: "#154466"
    },
    exportBtn: {
        backgroundColor: "#154466",
        color: "#fff",
        marginLeft: 10
    },
    disabledExportBtn: {
        cursor: 'none',
        backgroundColor: "#00000061",
        color: "#154466",
        marginLeft: 10
    }
});

export function ExportPopup(props) {
    const classes = useStyles();
    const { open, onCancel, onExport, slides, setSlideList } = props;


    const handleCancel = () => {
        onCancel();
    }

    const isFormValid = useMemo(() => slides?.some(s => s.checked || (s.children && s.children.some(sb => sb.checked))), [slides])

    return (
        <Dialog
            size="small"
            open={open}
            onClose={handleCancel}
            id={'export-ppt-dialog'}
            className="dialog-box"
        >
            <IconButton aria-label="close" className="float-btn-close" onClick={handleCancel}>
                <CloseIcon />
            </IconButton>
            <ExportPopupBody options={slides} setOptions={setSlideList} />
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} pb={3} pt={2}>
                <Button onClick={handleCancel} variant='outlined' className={classNames(classes.btn, classes.cancelBtn)}>
                    Cancel
                </Button>
                <Button variant='contained' className={classNames(classes.btn, isFormValid ? classes.exportBtn : classes.disabledExportBtn)} onClick={onExport} disabled={!isFormValid}>
                    Export
                </Button>
            </Box>
        </Dialog>
    );
}

ExportPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    slides: PropTypes.array.isRequired,
    setSlideList: PropTypes.func.isRequired
}

export default ExportPopup;