import React from 'react';
import './index.scss';

const Badge = ({ type, percentage }) => {
    return (
        <div className={`badge ${type}`}>
            <div className="badge-count">{percentage}</div>
        </div>
    );
};

export default Badge;
