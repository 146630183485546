const AUTH0 = {
    apiBasePath: process.env.REACT_APP_API_BASEPATH,
    authBasePath: process.env.REACT_APP_ORG_AUTH_BASEPATH,
    orgApiPath: process.env.REACT_APP_ORG_API_BASEPATH,
    orgAdminPath: process.env.REACT_APP_ORG_ADMIN_BASEPATH,
    orgBasePath: process.env.REACT_APP_ORGLAB_BASEPATH,
    orglabPath: process.env.REACT_APP_ORGLAB_PATH,
    apiAnalyticsBasePath: process.env.REACT_APP_ORGLAB_BASEPATH + process.env.REACT_APP_ORG_API_BASEPATH + "/api/phases",
    apiNNReportBasePath: process.env.REACT_APP_NN_REPORT_BASEPATH,
    orglabBasePath: process.env.REACT_APP_ORGLAB_BASEPATH + process.env.REACT_APP_ORG_API_BASEPATH + "/api",
};

export default AUTH0;
