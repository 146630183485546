import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getAnalyticsApi } from '../../../StructureContent/apis';
import Shimmer from '../../../../components/shimmer';
import executiveDashboard from '../../executiveDashboard.enum';
import NoDataFound from '../../../../components/NoDataFound';
import { spanDistributionConfig, spanDistributionMatrix } from '../configs/spanDistribution.config';
import './index.scss';
import { getAnalyticsFilterString } from '../../../../common/utilities';
import { isSpanDataPresent } from '../../shared';

function SpanDistribution({ isFiltersApplied, filters, structureId, refreshCount }) {
    const [isLoading, setIsLoading] = useState(true);
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const filterString = getAnalyticsFilterString({ ...filters, structureId });

        getAnalyticsApi({ method: 'spansJson', filterString })
            .then(({ data }) => {
                const spans = data.series.reverse().map(el => {
                    return {
                        ...el,
                        name: el.name,
                        color: spanDistributionMatrix[el.name].color,
                    };
                });
                setCategories(data.categories.map(each => `> ${each}`));
                setSeries(spans);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [JSON.stringify(filters), refreshCount]);

    return (
        <Fragment>
            {isLoading ? (
                <Shimmer />
            ) : series.length && isSpanDataPresent(series) ? (
                <div className="span-distribution__chart">
                    <HighchartsReact
                        options={{
                            ...spanDistributionConfig,
                            xAxis: { categories },
                            series,
                            tooltip: {
                                useHTML: true,
                                formatter: function() {
                                    return `${this.point.series.name} : ${this.point.y}`;
                                },
                            },
                        }}
                        highcharts={Highcharts}
                    />
                </div>
            ) : (
                <NoDataFound
                    mainMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                    }`}
                    secondaryMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                    }`}
                />
            )}
        </Fragment>
    );
}

export default SpanDistribution;
