import React from 'react';
import Download from '@material-ui/icons/GetApp';
import { Typography } from '@material-ui/core';
import addressableOpportunityConst from './addressableOpportunity.enum';
import generateExcelFile from './generateExcelFile';

function OutputTable({ input, output }) {
    const calculateFteTotal = () => {
        return (
            output.too_few.fte +
            output.overspan_manager.fte +
            output.geo_exception.fte +
            output.hidden_player.fte +
            output.player_coach.fte +
            output.coach.fte +
            output.supervisor.fte +
            output.facilitator.fte +
            output.coordinator.fte
        ).toFixed(2);
    };

    const calculateCostTotal = () => {
        return (
            output.too_few.cost +
            output.overspan_manager.cost +
            output.geo_exception.cost +
            output.hidden_player.cost +
            output.player_coach.cost +
            output.coach.cost +
            output.supervisor.cost +
            output.facilitator.cost +
            output.coordinator.cost
        ).toFixed(2);
    };

    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: 40,
                }}
            >
                <div
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    onClick={() => generateExcelFile(input, output, calculateFteTotal(), calculateCostTotal())}
                >
                    <Download />
                    <Typography style={{ marginTop: 0 }} variant="body">
                        Export
                    </Typography>
                </div>
            </div>
            <table className="output-table">
                <thead>
                    {addressableOpportunityConst.outputTableHeading.map(info => (
                        <th>{info}</th>
                    ))}
                </thead>
                <tbody>
                    <tr>
                        {addressableOpportunityConst.outputTableFirstRow.map(info => (
                            <td>{info}</td>
                        ))}
                        <td className="table-data-box" />
                        <td>{output.too_few.fte}</td>
                        <td>$ {output.too_few.cost}</td>
                    </tr>
                    <tr>
                        <td>{addressableOpportunityConst.outputTableSecondRow}</td>
                        <td>
                            We address only {input.overspan_manager / 100} of overspan people leaders b/c if managers
                            can handle managing too many than no adjustment is necessary
                        </td>
                        <td className="table-data-box">{input.overspan_manager}%</td>
                        <td>{output.overspan_manager.fte}</td>
                        <td>
                            ${' '}
                            {output.overspan_manager.cost < 0
                                ? `(${Math.abs(output.overspan_manager.cost)})`
                                : output.overspan_manager.cost}
                        </td>
                    </tr>
                    <tr>
                        {addressableOpportunityConst.outputTableThirdRow.map(info => (
                            <td>{info}</td>
                        ))}
                        <td className="table-data-box">{input.geo_exception}</td>
                        <td>{output.geo_exception.fte}</td>
                        <td>
                            ${' '}
                            {output.geo_exception.cost < 0
                                ? `(${Math.abs(output.geo_exception.cost)})`
                                : output.geo_exception.cost}
                        </td>
                    </tr>
                    <tr>
                        <td>{addressableOpportunityConst.outputTableFourthRow}</td>
                        <td>
                            {input.hidden_players / 100} of People leaders managing 1-2 are typically converted to
                            “doers”
                        </td>
                        <td className="table-data-box">{input.hidden_players}%</td>
                        <td>{output.hidden_player.fte}</td>
                        <td>
                            ${' '}
                            {output.hidden_player.cost < 0
                                ? `(${Math.abs(output.hidden_player.cost)})`
                                : output.hidden_player.cost}
                        </td>
                    </tr>
                    <tr>
                        <td>{addressableOpportunityConst.outputTableFifthRow}</td>
                        <td className="no-padding">
                            <span className="table-data">
                                People leaders' individual work needs to be preserved even if the role is eliminated.
                                Below is the estimated individual work done by each archetype:
                            </span>
                            <ul>
                                {addressableOpportunityConst.outputTableRationaleList.map(info => (
                                    <li>{info}</li>
                                ))}
                                <li>
                                    <strong>Total:</strong>
                                </li>
                            </ul>
                        </td>
                        <td className="table-data-box">
                            <ul className="no-style-list">
                                {input.archetypes.map(info => (
                                    <li>{info}%</li>
                                ))}
                            </ul>
                        </td>
                        <td className="no-padding">
                            <span className="table-data" />
                            <ul>
                                <li>{output.player_coach.fte}</li>
                                <li>{output.coach.fte}</li>
                                <li>{output.supervisor.fte}</li>
                                <li>{output.facilitator.fte}</li>
                                <li>{output.coordinator.fte}</li>
                                <li>
                                    <strong>
                                        {parseFloat(
                                            output.player_coach.fte +
                                                output.coach.fte +
                                                output.supervisor.fte +
                                                output.facilitator.fte +
                                                output.coordinator.fte
                                        ).toFixed(2)}
                                    </strong>
                                </li>
                            </ul>
                        </td>
                        <td className="no-padding">
                            <span className="table-data" />
                            <ul>
                                <li>
                                    ${' '}
                                    {output.player_coach.cost < 0
                                        ? `(${Math.abs(output.player_coach.cost)})`
                                        : output.player_coach.cost}
                                </li>
                                <li>
                                    $ {output.coach.cost < 0 ? `(${Math.abs(output.coach.cost)})` : output.coach.cost}
                                </li>
                                <li>
                                    ${' '}
                                    {output.supervisor.cost < 0
                                        ? `(${Math.abs(output.supervisor.cost)})`
                                        : output.supervisor.cost}
                                </li>
                                <li>
                                    ${' '}
                                    {output.facilitator.cost < 0
                                        ? `(${Math.abs(output.facilitator.cost)})`
                                        : output.facilitator.cost}
                                </li>
                                <li>
                                    ${' '}
                                    {output.coordinator.cost < 0
                                        ? `(${Math.abs(output.coordinator.cost)})`
                                        : output.coordinator.cost}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>{addressableOpportunityConst.outputTableSixthRow}</td>
                        <td className="table-data-box" />
                        <td>{calculateFteTotal()}</td>
                        <td>$ {calculateCostTotal()}</td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
}

export default OutputTable;
