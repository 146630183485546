
import AUTH0 from '../auth_config';

export const numFormatter = (num = null, numFixed = 1, inMillion = false) => {
    const tempNum = (num < 0) ? -(num) : num;
    if (!tempNum) {
        return 0;
    }
    if ((tempNum > 1000000 && tempNum < 1000000000) || inMillion) {
        if (inMillion) {
            return (num / 1000000).toFixed(numFixed);
        }
        return (num / 1000000).toFixed(numFixed) + 'M'; // convert to M for number from > 1 million
    }
    if (tempNum > 999 && tempNum < 1000000) {
        return (num / 1000).toFixed(numFixed) + 'K'; // convert to K for number from > 1000 < 1 million
    }
    if (tempNum > 1000000000) {
        return (num / 1000000000).toFixed(numFixed) + 'B'; // convert to M for number from > 1 million
    }
    return num.toFixed(1) === '0.0' ? 0 : num.toFixed(numFixed);
};

export const numFormatterWithStyle = (num = null, numFixed = 1, inMillion = false) => {
    if (!num) {
        return 0;
    }
    if ((num > 1000000 && num < 1000000000) || inMillion) {
        if (inMillion) {
            return (num / 1000000).toFixed(numFixed);
        }
        return {
            value: (num / 1000000).toFixed(numFixed),
            denomination: 'M', // convert to M for number from > 1 million
        };
    }
    if (num > 999 && num < 1000000) {
        return {
            value: (num / 1000).toFixed(numFixed),
            denomination: 'K', // convert to M for number from > 1 million
        };
    }
    if (num > 1000000000) {
        return {
            value: (num / 1000000000).toFixed(numFixed),
            denomination: 'B', // convert to M for number from > 1 million
        };
    }
    return {
        value: num.toFixed(1) === '0.0' ? 0 : num.toFixed(numFixed),
        denomination: '',
    };
};

export const toFixed = (value, decimals = 1) => {
    if (value === '') return '';
    return parseFloat(value).toFixed(decimals);
};

export const slugify = text =>
    text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');

export const parseJwt = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const delay = time => new Promise(resolve => setTimeout(resolve, time));

export const orgAuthURL = () => {
    switch (window.location.hostname) {
        case 'analytics.orglabsolutions.com':
            return {
                authPath: 'https://auth.orglabsolutions.com',
                basePath: 'https://www.orglabsolutions.com',
                orglabPath: '/!/',
                adminPath: 'https://solutions.mckinsey.com/dre/org-admin',
            };
        case 'analytics-uat.orglabsolutions.com':
            return {
                authPath: 'https://auth-uat.orglabsolutions.com',
                basePath: 'https://www2.orglabsolutions.com',
                orglabPath: '/!/',
                adminPath: 'https://solutions.mckinsey.com/dre-org-uat/org-admin',
            };
        case 'analytics-staging.orglabsolutions.com':
            return {
                authPath: 'https://auth-staging.orglabsolutions.com',
                basePath: 'https://staging.orglabsolutions.com',
                orglabPath: '/!/',
                adminPath: 'https://solutions.mckinsey.com/dre-org-staging/org-admin',
            };
        case 'analytics-current.orglabsolutions.com':
            return {
                authPath: 'https://auth-current.orglabsolutions.com',
                basePath: 'https://staging-current.orglabsolutions.com',
                orglabPath: '/!/',
                adminPath: 'https://solutions.mckinsey.com/dre-org-staging-current/org-admin',
            };
        case 'analytics-next.orglabsolutions.com':
            return {
                authPath: 'https://auth-next.orglabsolutions.com',
                basePath: 'https://staging-next.orglabsolutions.com',
                orglabPath: '/!/',
                adminPath: 'https://solutions.mckinsey.com/dre-org-staging-next/org-admin',
            };
        case 'analytics-pentest.orglabsolutions.com':
            return {
                authPath: 'https://auth-pentest.orglabsolutions.com',
                basePath: 'https://staging-pentest.orglabsolutions.com',
                orglabPath: '/!/',
                adminPath: 'https://solutions.mckinsey.com/dre-pentest/org-admin',
            };
        default:
            return {
                authPath: AUTH0.authBasePath,
                apiPath: AUTH0.orgApiPath,
                basePath: AUTH0.orgBasePath,
                orglabPath: AUTH0.orglabPath,
                adminPath: AUTH0.orgAdminPath,
            };
    }
};

/**
 * Matches the McKID Anonymous SSO UUID
 */

const uuidRegex = new RegExp('/sso/?((?:[a-f0-9]+-){4}[a-f0-9]+)?$');

/**
 * Provides the org-auth URL to redirect to on logout.
 */

export const logoutURL = () => {
    const { authPath, basePath, orglabPath } = orgAuthURL();
    const url = new URL(authPath + '/logout');
    const destination = new URL(basePath + orglabPath).href;
    const uuidMatches = window.location.pathname.match(uuidRegex);
    const issuer = uuidMatches ? { issuer: uuidMatches[1] || '' } : null;
    url.search = new URLSearchParams({ destination, ...issuer }).toString();
    return url.href;
};
