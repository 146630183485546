import React from 'react';
import './NoDataFound.scss';

const NoDataFound = props => {
    return (
        <div className="no-data-found">
            <div className="no-data-found__logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="158" viewBox="0 0 150 158">
                    <defs>
                        <linearGradient id="mn3gf5a3ka" x1="50%" x2="50%" y1="27.489%" y2="157.007%">
                            <stop offset="0%" stop-color="#F7F7F7" />
                            <stop offset="100%" stop-color="#FFF" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g>
                            <g transform="translate(-645 -470) translate(645 470)">
                                <ellipse cx="74.741" cy="150.777" fill="url(#mn3gf5a3ka)" rx="61.152" ry="7.118" />
                                <g>
                                    <g>
                                        <g fill="#CFCAF9">
                                            <path
                                                d="M87.27 55.467H12.051C5.488 55.467.168 50.155.168 43.602V.067h77.22c5.457 0 9.881 4.417 9.881 9.866V45.5c0 3.31.639 5.71 1.916 7.202.727.849 1.809 1.484 2.94 1.99.753.337 1.778.583 3.074.737h-7.93v.037z"
                                                transform="translate(53.847 22.2)"
                                            />
                                        </g>
                                        <path
                                            fill="#DBDDE1"
                                            d="M141.117 77.667H65.899c-6.564 0-11.884-5.312-11.884-11.865V22.267h77.22c5.457 0 9.882 4.417 9.882 9.866V67.7c0 3.31.638 5.71 1.915 7.202.727.849 1.809 1.484 2.94 1.99.753.337 1.778.583 3.074.738h-7.93v.036z"
                                        />
                                        <path
                                            fill="#CED1D6"
                                            d="M130.725 77.667H55.507c-6.563 0-11.884-5.312-11.884-11.865V22.267h77.22c5.457 0 9.882 4.417 9.882 9.866V67.7c0 3.31.638 5.71 1.915 7.202.727.849 1.81 1.484 2.94 1.99.753.337 1.778.583 3.075.738h-7.93v.036z"
                                            opacity=".47"
                                        />
                                        <g fill="#F0F1F3">
                                            <path
                                                d="M87.276 100.618c0 3.378-2.744 6.298-6.129 6.298l-75.018-.183c-3.385 0-6.129-2.737-6.129-6.115V9.067c0-4.971 4.039-9 9.02-9h86.316c-2.728.473-4.74 1.62-6.038 3.444-1.297 1.823-1.971 4.451-2.022 7.883z"
                                                transform="translate(35.898 22.2)"
                                            />
                                        </g>
                                        <path
                                            fill="#DBDDE1"
                                            d="M30 129.116c-6.563 0-11.883-5.312-11.883-11.865V.067h77.22c5.457 0 9.882 4.417 9.882 9.866v109.253c0 3.31.638 5.71 1.915 7.203.727.848 1.809 1.484 2.94 1.99.753.336 1.778.582 3.074.737z"
                                        />
                                        <path
                                            fill="#CED1D6"
                                            d="M28.39 128.95c-5.767 0-10.441-5.308-10.441-11.856V0h67.838c4.795 0 8.682 4.414 8.682 9.858V119.03c0 3.307.57 5.705 1.71 7.195.65.848 1.617 1.483 2.627 1.988.674.336 1.59.582 2.748.737z"
                                        />
                                        <path
                                            fill="#F0F1F3"
                                            d="M87.276 100.618c0 3.378-2.744 6.115-6.129 6.115H6.13c-3.385 0-6.129-2.737-6.129-6.115V9.067c0-4.971 4.039-9 9.02-9h86.316c-2.728.473-4.74 1.62-6.038 3.444-1.297 1.823-1.971 4.451-2.022 7.883z"
                                        />
                                    </g>
                                    <g transform="translate(27.883 35.777)">
                                        <path
                                            fill="#B9BDC4"
                                            d="M12.826 6.343L5.102 18.076c-1.519 2.307-.88 5.407 1.427 6.926.816.537 1.772.824 2.75.824h15.448c2.761 0 5-2.239 5-5 0-.978-.287-1.934-.824-2.75L21.18 6.343c-1.519-2.306-4.62-2.945-6.926-1.426-.568.373-1.053.859-1.427 1.426z"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M17.211 7.975c.545 0 .987.442.987.987v.028l-.248 8.924c-.011.4-.34.72-.74.72-.401 0-.729-.32-.74-.72l-.246-8.924c-.015-.546.415-1 .96-1.015h.027z"
                                        />
                                        <circle cx="17.211" cy="21.171" r="1.015" fill="#FFF" />
                                    </g>
                                    <g>
                                        <path
                                            fill="#9FA6B1"
                                            d="M33.727 4.83c-7.394 0-14.791 2.82-20.434 8.463-5.642 5.643-8.464 13.039-8.464 20.434 0 7.394 2.822 14.789 8.464 20.432 5.643 5.642 13.04 8.464 20.434 8.464 7.394 0 14.79-2.822 20.432-8.464 5.643-5.643 8.464-13.038 8.464-20.432 0-7.395-2.821-14.79-8.464-20.434C48.518 7.65 41.121 4.83 33.727 4.83M9.878 9.878C16.464 3.293 25.094 0 33.727 0c8.63 0 17.262 3.293 23.848 9.878 6.586 6.586 9.878 15.216 9.877 23.849 0 8.63-3.292 17.262-9.877 23.848-6.586 6.585-15.219 9.877-23.848 9.877-8.63 0-17.263-3.292-23.849-9.877C3.293 50.989 0 42.357 0 33.727c0-8.633 3.292-17.263 9.878-23.849"
                                            transform="translate(11.676 19.193)"
                                        />
                                        <path
                                            fill="#9FA6B1"
                                            d="M54.16 57.575c-.944-.944-.944-2.473 0-3.416.943-.943 2.472-.943 3.415 0l7.982 7.983c.943.941.943 2.471 0 3.416-.944.942-2.472.942-3.416 0l-7.982-7.983z"
                                            transform="translate(11.676 19.193)"
                                        />
                                        <path
                                            fill="#9FA6B1"
                                            d="M62.468 69.299c-1.887-1.887-1.887-4.946 0-6.832 1.885-1.887 4.944-1.887 6.83 0l25.728 25.728c1.886 1.886 1.886 4.945 0 6.83-1.886 1.888-4.945 1.888-6.83 0L62.467 69.3z"
                                            transform="translate(11.676 19.193)"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M14.587 19.953c-.514.712-1.507.874-2.219.361-.712-.511-.874-1.505-.36-2.218.426-.591.87-1.16 1.333-1.701l.075-.083c.481-.56.944-1.065 1.387-1.508.624-.623 1.633-.623 2.257 0 .622.622.622 1.633 0 2.255-.472.47-.881.91-1.232 1.316l-.07.087c-.422.496-.813.994-1.171 1.49m2.473 30.442c.622.62.622 1.632 0 2.254-.624.623-1.633.623-2.257 0-2.556-2.556-4.49-5.512-5.8-8.674-1.358-3.28-2.036-6.768-2.036-10.247 0-1.623.15-3.263.452-4.897.298-1.598.744-3.18 1.338-4.726.313-.819 1.23-1.229 2.05-.915.82.313 1.23 1.232.915 2.05-.519 1.35-.91 2.744-1.173 4.164-.262 1.411-.393 2.86-.393 4.324 0 3.082.597 6.158 1.789 9.038 1.145 2.766 2.85 5.365 5.115 7.629m36.508-3.93c.475-.74 1.46-.955 2.2-.48.74.476.954 1.46.479 2.2-.509.792-1.07 1.568-1.676 2.321-.613.759-1.255 1.475-1.921 2.143-.623.623-1.634.623-2.258 0-.621-.622-.621-1.633 0-2.254.624-.624 1.19-1.252 1.697-1.882.532-.658 1.025-1.343 1.479-2.048m-3.176-29.405c-.621-.622-.621-1.633 0-2.255.624-.623 1.635-.623 2.258 0 2.556 2.556 4.49 5.513 5.8 8.675 1.353 3.271 2.03 6.757 2.03 10.248h.006c0 2.492-.348 4.99-1.043 7.41-.24.846-1.123 1.337-1.97 1.097-.846-.24-1.336-1.122-1.097-1.97.614-2.13.92-4.333.92-6.537h.006c0-3.074-.599-6.15-1.795-9.039-1.145-2.766-2.85-5.364-5.115-7.629"
                                            transform="translate(11.676 19.193)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="no-data-found__label">{props.mainMessage}</div>
            <div className="no-data-found__label-info">{props.secondaryMessage}</div>
        </div>
    );
};

export default NoDataFound;
