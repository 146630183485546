import * as T from '../../types';
import AuthStore from '../../../../common/AuthStore';
import { mergeSlideStateToMainState, downloadPPT, addSlideStateToMainState } from '../common';
import { SHARED_STATE } from './shared';

import { generateSpans2PPT } from './spansSlide';
import { generateOrgShapePPT } from './orgShapeSlide';
import { generateDistributionSlide } from './titleDistributionSlide';
import { generateLayersPPT } from './layersSlide';
import { Slides } from "../../constants";


let STATE = {
    template_file_name: 'Analytics ppt export_v4.pptx',
    output_file_name: 'Analytics ppt export_v4_processed.pptx',
    slides: [
        {
            slideIndex: 0,
            identify_shape_name: 'checkme',
            identify_shape_value: 'confidential',
            data: [
                {
                    name: 'checkme',
                    is_delete: true,
                },
            ],
        },
    ],
};

// temporary (deep clone) to reset the state at any point
const TEMP = JSON.parse(JSON.stringify(STATE));

// eslint-disable-next-line
export const downloadPPTAction = (values, filterString) => async (dispatch, getState) => {
    const { clientName = '' } = AuthStore;
    if (!clientName) return;
    // reset state
    STATE = TEMP;
    const TEMP_STATE = [];
    // FILTER_STRING = filterString;
    SHARED_STATE.FILTER_STRING = filterString;
    const { layersData, spanData, functionsData, leadersData, payData, titleData, managerialData } = values;
    const { structure } = Object.fromEntries(getState().get('structuresRedux'));
    const { name: structureName } = Object.fromEntries(structure);
    const fileName = `${clientName}_${structureName}`;
    dispatch({
        type: T.GENERATE_PPT_REQUEST,
    });

    const mergeWithMainState = updatedSlideState => {
        STATE = mergeSlideStateToMainState(STATE, updatedSlideState);
    };

    const addWithMainState = (updatedSlideState, index) => {
        STATE = addSlideStateToMainState(STATE, updatedSlideState, index);
    };

    const isfunction = functionsData && !functionsData.loading;
    const isLeaders = leadersData && !leadersData.loading;
    if (isfunction || isLeaders) {
        // getting shape value depends on layer's length.
        const shapeValueForFunctions = `${functionsData?.layers?.length > 7 ? 'Org_shape15' : 'Org_shape'}`;
        const shapeValueForLeaders = `${leadersData?.layers?.length > 7 ? 'Org_shape15' : 'Org_shape'}`;

        // for the deleting copied slide.
        const getLastSlideToBeDeleted = shapeValue => {
            return {
                slideIndex: 3,
                identify_shape_name: 'checkme',
                identify_shape_value: shapeValue,
                is_delete: true,
                data: [
                    {
                        name: 'checkme',
                        is_delete: 'true',
                    },
                    {
                        name: 'Source',
                        value: 'Source: test (n=162)',
                    },
                ],
            };
        };

        // code for generating duplicating slides (if needed)
        const getTempState = (leadersLength, shapeValue, slideName) => {
            if (leadersLength > 0) {
                const tempLength = 5;
                let t = 0;
                const slideIndex = 2;
                for (let i = 0; i < Math.ceil(leadersLength / 5); i++) {
                    const y = 0;
                    // the slide to be embeded in STATE obejct
                    const createSlides = {
                        slideIndex: 3,
                        slideName,
                        identify_shape_name: 'checkme',
                        identify_shape_value: shapeValue,
                        data: [
                            {
                                name: 'checkme',
                                is_delete: 'true',
                            },
                            {
                                name: 'Source',
                                value: 'Source: test (n=162)',
                            },
                        ],
                    };
                    t++;
                    for (let j = y; j < tempLength; j++) {
                        // enabling is_copy flag for the slides
                        createSlides.slideIndex = slideIndex + (i + 1);
                        if (i < Math.ceil(leadersLength / 5)) {
                            createSlides.is_copy = true;
                        }
                    }
                    // push the created slide into array.
                    TEMP_STATE.push(createSlides);
                }
            }
            return TEMP_STATE;
        };
        // for functions slide
        isfunction && getTempState(functionsData.total.length, shapeValueForFunctions, Slides.functions);
        // for leaders slide
        isLeaders && getTempState(leadersData.total.length, shapeValueForLeaders, Slides.leaders);
        // change the STATE object value with it's original value and pushing newly created slide with deleting slide object
        STATE = {
            ...STATE,
            slides: [
                ...STATE.slides,
                ...TEMP_STATE,
            ],
        };

        if(isfunction) {
            STATE = {
                ...STATE,
                slides: [
                    ...STATE.slides,
                    getLastSlideToBeDeleted(shapeValueForFunctions),
                ],
            };
        }
        if(isLeaders) {
            STATE = {
                ...STATE,
                slides: [
                    ...STATE.slides,
                    getLastSlideToBeDeleted(shapeValueForLeaders),
                ],
            };
        }
        // we will filter with the shapeValue (as the shapeValue for all the orgshape slide will be same)
        const slide3State = (STATE.slides || []).filter(slide => slide.slideName === Slides.functions) || {
            data: [],
        };
        const slide4State = (STATE.slides || []).filter(slide => slide.slideName === Slides.leaders) || {
            data: [],
        };

        isfunction && await generateOrgShapePPT(slide3State, functionsData, mergeWithMainState);
        isLeaders && await generateOrgShapePPT(slide4State, leadersData, mergeWithMainState);
    }

    STATE = {
        ...STATE,
        slides: [...STATE.slides],
    };

    layersData?.showLayersWithoutOpp && await generateLayersPPT(layersData.data, clientName, addWithMainState, Slides.overview1);
    layersData?.showLayersWithoutOpp && await generateLayersPPT(layersData.data, clientName, addWithMainState, Slides.overview1_comment);
    layersData?.showLayersOpp && await generateLayersPPT(layersData.data, clientName, addWithMainState, Slides.overview1_manager);
    layersData?.showLayersOpp && await generateLayersPPT(layersData.data, clientName, addWithMainState, Slides.overview1_manager_comment);

    (managerialData?.data) && await generateDistributionSlide(managerialData, addWithMainState, Slides.managerial_stacking);
    (payData?.data) && await generateDistributionSlide(payData, addWithMainState, Slides.pay_distribution);
    (titleData?.data) && await generateDistributionSlide(titleData, addWithMainState, Slides.title_distribution);
    (spanData) && await generateSpans2PPT(spanData, clientName, addWithMainState);

    const newState = {
        ...STATE,
        slides: [
            ...STATE.slides,
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.overview1,
                is_delete: true
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.overview1_comment,
                is_delete: true
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.overview1_manager,
                is_delete: true
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.overview1_manager_comment,
                is_delete: true
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.title_distribution,
                is_delete: true
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.pay_distribution,
                is_delete: true
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: Slides.managerial_stacking,
                is_delete: true
            },
        ]
    }

    await downloadPPT(fileName, newState);
    dispatch({
        type: T.GENERATE_PPT_SUCCESS,
    });
};
