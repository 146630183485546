import React, { useState, useEffect } from 'react';
import { useExpanded, useTable } from 'react-table';

const Table = ({
    columns: userColumns,
    data,
    className,
    width,
    teamsChaptersData,
    noExtraHeader,
    handleFilterClick,
    selectedFiltersColumnList,
    guidance,
    fteEnvelope,
    chapterRoleMap,
    chapterPositionsStateMap,
}) => {
    const [totalWidth, setTotalWidth] = useState(0);
    const depthMap = {
        0: 'first-row',
        1: 'second-row',
        2: 'third-row',
        3: 'fourth-row',
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsExpanded } = useTable(
        {
            columns: userColumns,
            data: data,
        },
        useExpanded
    );

    useEffect(() => {
        setTotalWidth(width > window.screen.width - 70 ? `${width}px` : `100%`);
    }, [width]);

    return (
        <>
            <table {...getTableProps()} className={className} style={{ width: `${totalWidth}` }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                        width: `${column.width}px`,
                                        zIndex: 100 - (index + 1),
                                        opacity: 1 - Number('0.' + column._depth),
                                    }}
                                    className={column.className || ''}
                                >
                                    {column.render('HeaderComponent', {
                                        teamsChaptersData,
                                        noExtraHeader,
                                        hasChild: column.hasChild,
                                        handleFilterClick,
                                        selectedFiltersColumnList,
                                        guidance,
                                        fteEnvelope,
                                        chapterRoleMap,
                                        chapterPositionsStateMap,
                                    })}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className={depthMap[row.depth] || 'default-row'}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell', { fteEnvelope, guidance })}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default Table;
