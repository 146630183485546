import React from 'react';
import './index.scss';

const ChipDetail = ({ dataConfig }) => {
    return (
        <div className="chip-details">
            <div className="header">
                {dataConfig.map(el => {
                    return <div className="item">{el.header}</div>;
                })}
            </div>
            <div className="values">
                {dataConfig.map(el => {
                    return <div className="item">{el.value}</div>;
                })}
            </div>
        </div>
    );
};

export default ChipDetail;
