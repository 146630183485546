export const CUSTOM_KEYS_SLIDE_1 = {
    total_layers: 'total_layers',
    total_layers_comment: 'total_layers_comment',
    total_ftes_or_headcount: 'total_ftes_or_headcount',
    text11: 'text11',
    average_span_comment: 'average_span_comment',
    ideal_span_comment: 'ideal_span_comment',
    average_span: 'average_span',
    ideal_span: 'ideal_span',
    total_managers: 'total_managers',
    total_non_managers: 'total_non_managers',
    total_fte: 'total_fte',
    table_data: 'table_data',
    company_name_subtitle: 'company_name_subtitle',
    company_name_comment: 'company_name_comment',
    applied_filters_comment: 'applied_filters_comment',
    biggest_gap: 'biggest_gap',
    total_managers_cost: 'total_managers_cost',
    total_managers_opp: 'total_managers_opp',
    total_managers_cost_opp: 'total_managers_cost_opp',
    slide_title: 'slide_title',
    mng_stacking_comment: 'mng_stacking_comment',
    mng_stacking_percentage: 'mng_stacking_percentage',
    total_rows: 'total_rows',
    rows_range_min: 'rows_range_min',
    rows_range_max: 'rows_range_max',
    row_group: 'row_group',
    total_columns: 'total_columns',
    columns_range_min: 'columns_range_min',
    columns_range_max: 'columns_range_max',
    column_group: 'column_group'
};

export const KEYS_SLIDE_1 = {
    [CUSTOM_KEYS_SLIDE_1.total_layers]: { name: '3. Subtitle', existing_value: 'XX' },
    [CUSTOM_KEYS_SLIDE_1.total_layers_comment]: { name: 'comment', existing_value: 'XX' },
    [CUSTOM_KEYS_SLIDE_1.total_ftes_or_headcount]: { name: 'TextBox 93' },
    [CUSTOM_KEYS_SLIDE_1.text11]: { name: 'Text11' },
    [CUSTOM_KEYS_SLIDE_1.average_span_comment]: { name: 'comment', existing_value: 'YY' },
    [CUSTOM_KEYS_SLIDE_1.ideal_span_comment]: { name: 'comment', existing_value: 'ZZ' },
    [CUSTOM_KEYS_SLIDE_1.average_span]: { name: 'Text6T' },
    [CUSTOM_KEYS_SLIDE_1.ideal_span]: { name: 'Text7T' },
    [CUSTOM_KEYS_SLIDE_1.total_managers]: { name: 'Text5T' },
    [CUSTOM_KEYS_SLIDE_1.total_non_managers]: { name: 'Text2T' },
    [CUSTOM_KEYS_SLIDE_1.total_fte]: { name: 'Text1T' },
    [CUSTOM_KEYS_SLIDE_1.total_managers_cost]: { name: 'Text8T' },
    [CUSTOM_KEYS_SLIDE_1.total_managers_opp]: { name: 'Text9T' },
    [CUSTOM_KEYS_SLIDE_1.total_managers_cost_opp]: { name: 'Text10T' },
    [CUSTOM_KEYS_SLIDE_1.table_data]: { name: 'table_data' },
    [CUSTOM_KEYS_SLIDE_1.company_name_subtitle]: { name: '3. Subtitle', existing_value: 'CNAME' },
    [CUSTOM_KEYS_SLIDE_1.company_name_comment]: { name: 'comment', existing_value: 'CNAME' },
    [CUSTOM_KEYS_SLIDE_1.applied_filters_comment]: { name: '5. Source' },
    [CUSTOM_KEYS_SLIDE_1.biggest_gap]: { name: 'comment', existing_value: '3rd' },
    [CUSTOM_KEYS_SLIDE_1.slide_title]: { name: '2. Slide Title' },
    [CUSTOM_KEYS_SLIDE_1.mng_stacking_comment]: {name: 'mng stacking comment', existing_value: 'CNAME'},
    [CUSTOM_KEYS_SLIDE_1.mng_stacking_percentage]: {name: 'mng stacking comment', existing_value: 'XX'},
    [CUSTOM_KEYS_SLIDE_1.total_rows]: {name: 'Total Rows', existing_value: 'XX'},
    [CUSTOM_KEYS_SLIDE_1.rows_range_min]: {name: 'Rows Range', existing_value: 'YY'},
    [CUSTOM_KEYS_SLIDE_1.rows_range_max]: {name: 'Rows Range', existing_value: 'ZZ'},
    [CUSTOM_KEYS_SLIDE_1.row_group]: {name: 'Row Group', hidden: false},
    [CUSTOM_KEYS_SLIDE_1.total_columns]: {name: 'Total Columns', existing_value: 'XX'},
    [CUSTOM_KEYS_SLIDE_1.columns_range_min]: {name: 'Columns Range', existing_value: 'YY'},
    [CUSTOM_KEYS_SLIDE_1.columns_range_max]: {name: 'Columns Range', existing_value: 'ZZ'},
    [CUSTOM_KEYS_SLIDE_1.column_group]: {name: 'Column Group', hidden: false},
};

export const CUSTOM_KEYS_SLIDE_2 = {
    applied_filters_comment: 'applied_filters_comment',
    company_name_comment: 'company_name_comment',
    percentage: 'percentage',
    percentage_comment: 'percentage_comment',
};

export const KEYS_SLIDE_2 = {
    [CUSTOM_KEYS_SLIDE_2.company_name_comment]: { name: 'TextBox 72', existing_value: 'CNAME' },
    [CUSTOM_KEYS_SLIDE_2.applied_filters_comment]: { name: '5. Source' },
    [CUSTOM_KEYS_SLIDE_2.percentage]: { name: '3. Subtitle', existing_value: '~XX' },
    [CUSTOM_KEYS_SLIDE_2.percentage_comment]: { name: 'TextBox 72', existing_value: 'XX' },
};

export const CATEGORIES_SLIDE_2 = {
    TOO_FEW: 'too_few',
    TOO_MANY: 'too_many',
    IN_RANGE: 'in_range',
    UNKNOWN: 'unknown',
};

export const CUSTOM_KEYS_SLIDE_3 = {
    fte_total: 'fte_total',
    cost_total: 'cost_total',
    too_many_managers: 'too_many_managers',
    hidden_players: 'hidden_players',
    min_managers: 'min_managers',
    player_coach: 'player_coach',
    coach: 'coach',
    supervisor: 'supervisor',
    facilitator: 'facilitator',
    coordinator: 'coordinator',
    applied_filters_comment: 'applied_filters_comment',
};

export const KEYS_SLIDE_3 = {
    [CUSTOM_KEYS_SLIDE_3.fte_total]: { name: '3. Subtitle', existing_value: 'Y' },
    [CUSTOM_KEYS_SLIDE_3.cost_total]: { name: '3. Subtitle', existing_value: 'X' },
    [CUSTOM_KEYS_SLIDE_3.too_many_managers]: { name: '4. Footnote', existing_value: 'X1' },
    [CUSTOM_KEYS_SLIDE_3.hidden_players]: { name: '4. Footnote', existing_value: 'X2' },
    [CUSTOM_KEYS_SLIDE_3.min_managers]: { name: '4. Footnote', existing_value: 'X3' },
    [CUSTOM_KEYS_SLIDE_3.player_coach]: { name: '4. Footnote', existing_value: 'X4' },
    [CUSTOM_KEYS_SLIDE_3.coach]: { name: '4. Footnote', existing_value: 'X5' },
    [CUSTOM_KEYS_SLIDE_3.supervisor]: { name: '4. Footnote', existing_value: 'X6' },
    [CUSTOM_KEYS_SLIDE_3.facilitator]: { name: '4. Footnote', existing_value: 'X7' },
    [CUSTOM_KEYS_SLIDE_3.coordinator]: { name: '4. Footnote', existing_value: 'X8' },
    [CUSTOM_KEYS_SLIDE_3.applied_filters_comment]: { name: '5. Source' },
};

export const Slides = {
    functions: "functions",
    leaders: "leaders",
    overview1: "overview1",
    overview1_comment: "overview1_comment",
    overview1_manager: "overview1_manager",
    overview1_manager_comment: "overview1_manager_comment",
    pay_distribution: "pay_distribution",
    title_distribution: "title_distribution",
    managerial_stacking: "managerial_stacking",
}
