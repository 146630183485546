/**
 *
 * @param {string} string
 * @param {number} length
 * @returns {Object} Result
 * @returns {string} Result.value
 * @returns {boolean} Result.sliced
 */
const limitCharacters = (string, length) => {
    if (string && string.length > length) {
        return {
            value: `${string.slice(0, length - 3)}...`,
            sliced: true,
        };
    }
    return { value: string, sliced: false };
};

const ignore = '_';

export { limitCharacters, ignore };
