import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loader from './components/Loader';
import WarningModal from './components/WarningModal';
import Page404 from './containers/Page404';
import AuthStore from './common/AuthStore';
import { getPrivileges, refreshOrgTokenAction, refreshTokenAction } from './containers/Login/actions';
import { selectExpiryTime, selectPrivileges, selectPrivilegesFetched } from './containers/Login/selector';

import StructureContent from './containers/StructureContent';
import ExecutiveDashboard from './containers/ExecutiveDashboard';
import ExecutiveDashboardTeams from './containers/ExecutiveDashboardTeams/index.container';
import getCurrentStatus from './reducers/loaderSelector';
import SupplyDemand from './containers/SupplyDemand/index.container';
import TeamChapters from './containers/TeamChapters/index.container';
import AddressableOpportunity from './containers/AddressableOpportunity/index.container';

const LoginPage = lazy(() => {
    const Structure = import('./containers/Login');
    return Promise.all([Structure, new Promise(resolve => setTimeout(resolve, 300))]).then(
        ([moduleExports]) => moduleExports
    );
});

function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    });
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        const interval = setInterval(tick, delay);
        return () => clearInterval(interval);
    }, [delay]);
}

function Routes({
    currentStatus,
    refreshTokenCall,
    expiryTime,
    refreshOrgTokenCall,
    privileges,
    getPrivileges,
    isPrivilegesFetched,
    history,
}) {
    const { refreshToken = '', userId = '', clientId = '', orgAccessToken = '' } = AuthStore;
    useInterval(() => {
        const timeToCheck = expiryTime - moment().unix();
        if (refreshToken && timeToCheck <= 600) {
            refreshTokenCall(`Bearer ${refreshToken}`);
            refreshOrgTokenCall(`Bearer ${orgAccessToken}`, userId);
        }
    }, 10000);

    useEffect(() => {
        const jwtMatches = window.location.hash.match(/jwt=([a-zA-Z0-9_.-]+)/);
        if (orgAccessToken && userId && clientId) {
            if (!jwtMatches) {
                getPrivileges({ token: `Bearer ${orgAccessToken}`, userId, clientId });
            }
        }
        // eslint-disable-next-line
    }, []);

    const dynamicRoutes = () => {
        const routes = [];
        const routeAccessMap = {
            executiveDashboardAccess: { path: '/executive-dashboard', component: ExecutiveDashboard },
            summaryReportAccess: { path: '/compare', component: StructureContent },
            teamsDashboardAccess: { path: '/teams', component: ExecutiveDashboardTeams },
            supplyDemandAccess: { path: '/supply-demand', component: SupplyDemand },
            teamChaptersAccess: { path: '/team-chapters', component: TeamChapters },
            addressableOpportunityAccess: { path: '/addressable-opportunity', component: AddressableOpportunity },
        };
        Object.keys(privileges).forEach(el => {
            if (privileges[el] && routeAccessMap[el]) {
                routes.push(<Route path={routeAccessMap[el].path} component={routeAccessMap[el].component} />);
            }
        });

        return routes;
    };
    return (
        <div
            className={`contentWrapper clearFix ${
                history.location?.state?.className ? history.location.state.className : ''
            }`}
        >
            {currentStatus === 401 && <WarningModal />}
            <Route
                render={() => (
                    <Switch>
                        <Route
                            path="/"
                            exact
                            render={props => (
                                <Suspense fallback={<Loader />}>
                                    <LoginPage {...props} />
                                </Suspense>
                            )}
                        />
                        {isPrivilegesFetched && dynamicRoutes()}
                        {isPrivilegesFetched && <Route path="/404-not-found" component={Page404} />}
                        {isPrivilegesFetched && <Redirect to="/404-not-found" />}
                    </Switch>
                )}
            />
        </div>
    );
}

Routes.propTypes = {
    currentStatus: PropTypes.number.isRequired,
    expiryTime: PropTypes.number.isRequired,
    refreshTokenCall: PropTypes.func.isRequired,
    refreshOrgTokenCall: PropTypes.func.isRequired,
    getPrivileges: PropTypes.func.isRequired,
    isPrivilegesFetched: PropTypes.bool.isRequired,
    privileges: PropTypes.object.isRequired,
};

const stateToProps = createStructuredSelector({
    currentStatus: getCurrentStatus(),
    expiryTime: selectExpiryTime(),
    privileges: selectPrivileges(),
    isPrivilegesFetched: selectPrivilegesFetched(),
});

const dispatchToProps = {
    refreshTokenCall: refreshTokenAction,
    refreshOrgTokenCall: refreshOrgTokenAction,
    getPrivileges: getPrivileges,
};

export default connect(stateToProps, dispatchToProps)(withRouter(Routes));
