import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { limitCharacters } from '../utils';
// components
import Tooltip from '../../../components/Tooltip';

/**
 * @author Karan Kumar
 * @param {Object} 		props
 * @param {string} 		props.value
 * @param {function} 	props.onChange
 * @param {function} 	props.onSave
 * @returns
 */
const EnvelopeTextbox = ({ value, onChange, onSave, ref }) => {
    const [editing, setEditing] = React.useState(false);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }} ref={ref}>
            {editing == true ? (
                <div
                    style={{
                        display: 'flex',
                        width: 80,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <input
                        value={value}
                        onChange={e => {
                            onChange(e.target.value);
                        }}
                        style={{
                            paddingRight: 3,
                            paddingLeft: 3,
                            borderRadius: 10,
                            width: 60,
                            height: 30,
                            marginRight: 3,
                            fontSize: 12,
                        }}
                    />
                    <p
                        onClick={() => {
                            setEditing(false);
                            onSave();
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <strong>Save</strong>
                    </p>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: 80,
                    }}
                >
                    <Tooltip title={`${limitCharacters(value, 12).sliced ? value : ''}`}>
                        <p style={{ textAlign: 'left' }}>
                            <strong>{limitCharacters(value, 12).value}</strong>
                        </p>
                    </Tooltip>
                    <EditIcon onClick={() => setEditing(true)} style={{ fontSize: 15 }} />
                </div>
            )}
        </div>
    );
};

export default EnvelopeTextbox;
