import { fetchWebApi, postWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

const getAddressableOpportunityApi = (options = {}) => {
    const { apiBasePath, executivePath } = endpoints;
    const url =
        `${apiBasePath}${executivePath}/addressable-opportunity` + (options.filters ? `?${options.filters}` : '');
    return postWebApi(url, { ...options.input, phase_id: options.phase_id }, true).request;
};

export default getAddressableOpportunityApi;
