import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import webFont from 'webfontloader';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import theme from './theme';
import './styles/main.scss';

webFont.load({
    google: {
        families: ['Roboto:100,300,400,500,700,900'],
    },
});

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <App />
            </CssBaseline>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
