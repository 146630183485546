import React, { useEffect, useState, Fragment } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import WarningIcon from '@material-ui/icons/Warning';
import XLSX from 'xlsx';
import { REPORTS_LINK, SITE_TXT } from '../../utils/constants';
import './index.scss';

import { getGuidanceApi, getOrgResources, setGuidanceApi } from '../StructureContent/apis';
import formattedUtilities from '../../common/utilities';
import FilterIcon from '../../assets/filterIcon.svg';
import NoFilterIcon from '../../assets/noFilterIcon.svg';

// components
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import TextBox from '../../components/TextBox';
import SubChapterModal from '../../components/Modal';
import MenuList from '../../components/MenuList';
import NoDataFound from '../../components/NoDataFound';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import DatePickers from '../../components/DatePicker';
import EnvelopeTextbox from './Components/EnvelopeTextbox';
import Tooltip from '../../components/Tooltip';
import { limitCharacters } from './utils';

const Styles = styled.div`
          padding: 0 ;
          width: ${props => {
              return props.width > document.body.clientWidth * 0.7 ? document.body.clientWidth * 0.7 : props.width;
          }}px;
          margin:0 auto;
          max-height: 700px;
          overflow: auto;
          position: relative;
          border:1px solid #8ea3ad;
        
  
        table {
            position: relative;
            border-spacing: 0;
            

            tr {
                :last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        
            th{
              position: sticky;
              top: 0;
              background: #000;
              color: #FFF;
              padding-top:0 !important
            }
        
        
        
            tbody td {
                  position: sticky;
                  left:0;
                  z-index:7;
                
                  :nth-child(1){
                       left:0px;
                       z-index: 8;
                       text-align:left;
                       //padding-left:34px;
                  } 
                                    
                  :nth-child(2){
                     left:250px;
                     z-index: 8;
                        
                  }
                  :nth-child(3){
                    left:400px;
                    z-index: 10 !important;
                 }
            }

        
            thead th{
                :nth-child(1) {
                    left: 0;
                    z-index: 100;
                }
            
                :nth-child(2) {
                    left: 250px;
                    z-index: 100;
                }
                :nth-child(3) {
                    left: 400px;
                    z-index: 100;
                }
            }  
        

        th,
        td {
            margin: 0;
            text-align: center;
            padding:15px 0;
            // background-color: #fff;

            :last-child {
                border-right: 0;
            }
            
            :nth-child(1){
                  //background-color:beige;
            }
            
            :nth-child(2){
                  //background-color:beige;
            }

            :nth-child(3){
                // z-index: 8000,
                // background-color: #fff;
            }
            
        
    }
`;

const StylesSubChapter = styled.div`
    padding: 0;
    width: ${props => {
        return props.width > document.body.clientWidth * 0.7 ? document.body.clientWidth * 0.7 : props.width;
    }}px;
    margin: 0 auto;
    max-height: 700px;
    overflow: auto;
    position: relative;
    border: 1px solid #8ea3ad;

    table {
        position: relative;
        border-spacing: 0;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th {
            position: sticky;
            top: 0;
            background: #000;
            color: #fff;
            padding-top: 0 !important;
        }

        tbody td {
            left: 0;
            z-index: 7;
            text-align: center;

            :nth-child(1) {
                position: sticky;
                left: 0px;
                z-index: 8;
                text-align: left;
                /* the first column tds */
                div {
                    width: 350px !important;
                    overflow: hidden;
                    word-wrap: break-word !important;
                    // white-space: nowrap;
                    text-align: left !important;
                }
            }

            :nth-child(2) {
                position: sticky;
                left: 350px;
                z-index: 8;
                text-align: left;
                /* the first column tds */
                div {
                    width: 150px !important;
                    overflow: hidden;
                    word-wrap: break-word !important;
                    // white-space: nowrap;
                    text-align: left !important;
                }
            }

            /* all the td elements except the first one */
            div {
                width: 120px;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        thead th {
            :nth-child(1) {
                left: 0;
                z-index: 100;
            }
            :nth-child(2) {
                left: 350px;
                z-index: 100;
            }
            :nth-child(3) {
                // left: 100px;
                z-index: 100;
            }
            :nth-child(4) {
                // left: 250px;
                z-index: 100;
            }
        }
    }
`;

const TeamChapters = ({
    strCompareFetched,
    primaryFilters,
    clrManagerFilters,
    privileges,
    resetFilters,
    history,
    phaseStructureInfo,
    refreshCount,
}) => {
    const [fteEnvelope, setFteEnvelope] = React.useState({});
    const onFteEnvelopeSave = (fteEnvelope, guidance) => {
        const client_id = localStorage.getItem('clientId');
        const phase_id = localStorage.getItem('phaseId');
        setGuidanceApi({
            client_id,
            phase_id,
            structure_id: structureId,
            config: { ...guidance, fteEnvelope: { ...fteEnvelope } },
        });
    };
    const { REPORT_SUB } = SITE_TXT;
    const columns = isMainTable => [
        {
            id: 'expander',
            Header: 'TEAMS',
            HeaderComponent: () => {
                return (
                    <div>
                        <div className="header-guidance label">
                            <span className="title">Design Guidance</span>
                        </div>
                        <div className="header-name left-align" style={!isMainTable ? { height: 90 } : {}}>
                            <span style={{ display: 'block' }}>TEAMS</span>
                        </div>
                    </div>
                );
            },
            Cell: ({ row }) => {
                return (
                    <div
                        style={{
                            paddingLeft: `${
                                Object.keys(row.original.subRows || []).length ? row.depth * 8 + 14 : row.depth * 8 + 34
                            }px`,
                        }}
                        className={`${Object.keys(row.original.subRows || []).length ? 'has-icon' : ''}`}
                    >
                        {Object.keys(row.original.subRows || []).length ? (
                            <span {...row.getToggleRowExpandedProps()} title="">
                                {!row.isExpanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </span>
                        ) : null}
                        <span>
                            <span style={{ verticalAlign: 'sub' }}>
                                {row.original.hierarchyLevel > 1 && row.original.parentName ? (
                                    <Tooltip
                                        title={row.original.parentName !== null ? `${row.original.parentName}` : ''}
                                    >
                                        <span>{row.original.name}</span>
                                    </Tooltip>
                                ) : (
                                    <span>{row.original.name}</span>
                                )}
                            </span>
                            <span style={{ color: 'grey', fontWeight: 400, display: 'block' }}>
                                Level {row.original.hierarchyLevel}
                            </span>
                        </span>
                    </div>
                );
            },
            accessor: d => d.name,
            className: 'header teams',
            width: 250,
        },
        {
            Header: 'FTE Envelope',
            Cell: ({ fteEnvelope, guidance, row }) => {
                return (
                    <EnvelopeTextbox
                        value={(fteEnvelope || {})[`${row.original.id}`]}
                        onChange={val => setFteEnvelope(old => ({ ...old, [`${row.original.id}`]: val }))}
                        onSave={() => onFteEnvelopeSave(fteEnvelope, guidance)}
                    />
                );
            },
            className: 'header positions',
            width: 160,
            HeaderComponent: ({ handleFilterClick, selectedFiltersColumnList, guidance, fteEnvelope }) => {
                return (
                    <div>
                        <div className="header-guidance">
                            {/* <TextBox
                                heading="FTE Envelope"
                                id="fte_envelope"
                                isEditMode={!!guidance['fte_envelope']}
                                guidance={guidance}
                                structureId={structureId}
                                onSetGuidance={onSetGuidance}
                                fteEnvelope={fteEnvelope}
                            /> */}
                        </div>
                        <div className="header-name">
                            <div className="header-title">FTE Envelope</div>
                        </div>
                    </div>
                );
            },
        },
        {
            id: 'chapter_demand_supply',
            Header: 'TEAMS',
            HeaderComponent: () => {
                return (
                    <div style={!isMainTable ? { width: 152 } : {}}>
                        <div className="header-guidance label">
                            {/* <span className="title">Design Guidance</span> */}
                        </div>
                        <div className="header-name left-align" style={!isMainTable ? { height: 90 } : {}}>
                            {/* <span style={{ display: 'block' }}>TEAMS</span> */}
                            <span style={{ display: 'block', textAlign: 'left', marginTop: 30 }}>Chapter Supply</span>
                            <span style={{ display: 'block', textAlign: 'left', marginTop: 6 }}>Chapter Demand</span>
                        </div>
                    </div>
                );
            },
            Cell: () => {
                return <div>{'\u00A0'}</div>;
            },
            className: 'header positions',
            width: 150,
        },
        {
            Header: 'TOTAL POSITIONS (FTES)',
            accessor: d => {
                return <div>{d.sumOfTheChildren.positionSumUp}</div>;
            },
            className: 'header positions',
            width: 160,
            HeaderComponent: ({ handleFilterClick, selectedFiltersColumnList, guidance, fteEnvelope }) => {
                return (
                    <div>
                        <div className="header-guidance">
                            <TextBox
                                heading="Total Positions"
                                id="positions"
                                isEditMode={!!guidance['positions']}
                                guidance={guidance}
                                structureId={structureId}
                                onSetGuidance={onSetGuidance}
                                fteEnvelope={fteEnvelope}
                            />
                        </div>
                        <div className="header-name">
                            <div className="header-title">
                                TOTAL POSITIONS (FTES)
                                <img
                                    className="filter-icon"
                                    alt="Filter Icon"
                                    src={
                                        selectedFiltersColumnList.includes('positionSumUp') ? FilterIcon : NoFilterIcon
                                    }
                                    onClick={e => handleFilterClick(e, 'positionSumUp')}
                                />
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: 'Unassigned',
            accessor: d => {
                const unAssignedArr = Object.values(d.sumOfTheChildren);
                unAssignedArr.pop();
                const sumOfUnassignedArr = unAssignedArr.reduce((acc, i, idx, arr) => {
                    return acc + i;
                }, 0);
                return (
                    <div>
                        {d.sumOfTheChildren.positionSumUp - sumOfUnassignedArr != 0
                            ? d.sumOfTheChildren.positionSumUp - sumOfUnassignedArr
                            : 0}
                    </div>
                );
            },
            className: 'header positions',
            width: 160,
            HeaderComponent: ({ handleFilterClick, selectedFiltersColumnList, guidance, fteEnvelope }) => {
                return (
                    <div>
                        <div className="header-guidance">
                            <TextBox
                                heading="Unassigned"
                                id="positions"
                                isEditMode={!!guidance['positions']}
                                guidance={guidance}
                                structureId={structureId}
                                onSetGuidance={onSetGuidance}
                                fteEnvelope={fteEnvelope}
                            />
                        </div>
                        <div className="header-name">
                            <div className="header-title">
                                Unassigned
                                {/* <img
                                    className="filter-icon"
                                    alt="Filter Icon"
                                    src={
                                        selectedFiltersColumnList.includes('unassigned') ? FilterIcon : NoFilterIcon
                                    }
                                    onClick={e => handleFilterClick(e, 'unassigned')}
                                /> */}
                            </div>
                        </div>
                    </div>
                );
            },
        },
    ];
    const [filterApplied, setFilterApplied] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [structureId, setStructureId] = useState('');
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [isLoading, setIsLoading] = useState(true);
    const [tableColumns, setTableColumns] = useState(columns(true)); // column array list
    const [teamsTableMap, setTeamsTableMap] = useState({}); //
    const [teamsChaptersData, setTeamsChaptersData] = useState({ team: null, chapter: null, positionChapterMap: null }); //
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState({ column: [], row: [] });
    const [teamsData, setTeamsData] = useState(null);
    const [chaptersData, setChaptersData] = useState(null);
    const [guidance, setGuidance] = useState({});
    const [filterMap, setFilterMap] = useState({});
    const [selectedFilterColumn, setSelectedFilterColumn] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [renderMenuList, setRenderMenuList] = useState(1);
    const [tableRows, setTableRows] = useState([]);
    const [selectedFiltersColumnList, setSelectedFiltersColumnList] = useState([]);
    const [xlChapterMap, setxlChapterMap] = useState([]);
    const [teamChapterTable, setTeamChapterTable] = useState([]);
    const [chapterRoleMap, setChapterRoleMap] = useState({});
    const [chapterPositionsStateMap, setChapterPositionsStateMap] = useState({});
    const [chapterArr, setChapterArr] = useState([]);

    // on component load calls getTeamsChapters
    useEffect(() => {
        if (structureId) {
            getTeamsChapters(date);
        }
    }, [structureId, date, refreshCount]);

    useEffect(() => {
        const _filtersSelected = {};

        Object.keys(filterMap).forEach(key => {
            filterMap[key].forEach(item => {
                if (item.value) {
                    if (!_filtersSelected[key]) _filtersSelected[key] = [];

                    _filtersSelected[key].push(item.id);
                }
            });
        });

        const _tableRows = [];

        function getMainParentName(currentParentId) {
            const mainParentTeam = teamsChaptersData.team.find(function(teamItem) {
                return teamItem.id == currentParentId;
            });
            let mainParentTeamName = mainParentTeam.name;
            if (mainParentTeam.parentId != null) {
                mainParentTeamName = `${getMainParentName(mainParentTeam.parentId)} > ${mainParentTeamName}`;
            }
            return mainParentTeamName;
        }

        if (teamsChaptersData.team) {
            teamsChaptersData.team.forEach(item => {
                let isPresent = true;
                Object.keys(_filtersSelected).forEach(key => {
                    if (!_filtersSelected[key].includes(item.sumOfTheChildren[key])) isPresent = false;
                });

                let parentTeamName = null;
                if (isPresent) {
                    if (item.parentId != null) {
                        const parentId = item.parentId;
                        const hierarchy_level = item.hierarchyLevel;
                        teamsChaptersData.team.forEach(itm => {
                            if (itm.parentId == null && itm.id == parentId) {
                                parentTeamName = itm.name;
                            } else if (hierarchy_level > 2 && parentId == itm.id) {
                                const currentParentId = itm.parentId;
                                getMainParentName(currentParentId);
                                if (getMainParentName(currentParentId)) {
                                    parentTeamName = `${getMainParentName(currentParentId)} > ${itm.name}`;
                                }
                            }
                        });
                    }
                    _tableRows.push({ ...item, parentName: parentTeamName });
                }
            });
        }

        if (Object.keys(_filtersSelected).length) {
            setSelectedFiltersColumnList(Object.keys(_filtersSelected).map(item => item));
            setTableRows(_tableRows);
        } else {
            setSelectedFiltersColumnList([]);
            setTableRows(Object.values(JSON.parse(JSON.stringify(teamsTableMap))));
        }
    }, [JSON.stringify(filterMap)]);

    /**
     * Function calls teams, chapters and guidance api and stores data in local state
     * @param {string} date -> selected date string
     */
    const getTeamsChapters = date => {
        setIsLoading(true);
        resetState();
        const client_id = localStorage.getItem('clientId');
        const phase_id = localStorage.getItem('phaseId');
        Promise.all([
            getOrgResources({
                id: structureId,
                method: 'teams',
                filterString: `query={start_date <=date('${date}'),start_date %2B duration>= date('${date}')}`,
            }),
            getOrgResources({
                id: structureId,
                method: 'chapters',
            }),
            getGuidanceApi(client_id, phase_id, structureId),
        ])
            .then(response => {
                if (response[0].data.data.length) {
                    // sets guidance data which is in the form of map {column_key_name: text_string}
                    setGuidance(response[2].data.data);
                    setFteEnvelope(response[2].data.data.fteEnvelope);
                    // sets chapters response which is in the form of {data: [], included: []}
                    setChaptersData(response[1].data);
                    //
                    generateTeamsStats(response[0].data, response[1].data); // teams data, chapters data
                } else {
                    resetState();
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    /**
     *
     * @param {*} teams -> all teams details
     * @param {*} chapters -> all chapters details
     * @param {*} positionType
     */
    const generateTeamsStats = async (teams, chapters, positionType) => {
        setIsLoading(true);
        resetState();
        setTeamsData(teams);
        // childParentChapterMap contains {chapterId: parentChapterId} and formattedChapters is the list of all chapters with parent id and child empty array
        const { childParentChapterMap, formattedChapters } = generateSupplyDemandTable(chapters);
        // chaptersHierarchy contain map of chapters with parent child relationship
        const chaptersHierarchy = formattedUtilities.generateHierarchy(formattedChapters);
        // get suuply of parent chapters and save in chapterSupplyMap state
        // const _chapterSupplyMap = await getChapterSupply(Object.keys(chaptersHierarchy));

        const chapterMap = {}; // {chapter_id : table_header_object}
        const positionChapterMap = {}; // {position_id: chapter_id}
        const formattedTeams = []; // list of teams with parent id and child as empty array
        const teamsReferenceMap = {}; // {team_id: parent_team_id }
        const positionRoleMap = {}; // {position_id: true} if that position has some role attached to it
        const teamHierarchyMap = {}; // {team_id: hierarchy_level ==== 1,2,3 }
        const chapterPositionsMap = {};

        (teams.included || []).forEach(elem => {
            if (elem.type === 'positions') {
                if (elem.relationships.role.data) positionRoleMap[elem.id] = true;

                const positionId = elem.id;
                if (elem.relationships.chapter && elem.relationships.chapter.data) {
                    const chapterId = elem.relationships.chapter.data.id;
                    if (chapterPositionsMap[chapterId]) {
                        chapterPositionsMap[chapterId] = {
                            ...chapterPositionsMap[chapterId],
                            [positionId]: true,
                        };
                    } else {
                        chapterPositionsMap[chapterId] = {
                            [positionId]: true,
                        };
                    }
                }
            }
        });

        const getDemand = _chapterIdList => {
            const demand = _chapterIdList.reduce((acc, el) => {
                return acc + Object.keys(chapterPositionsMap[el] || {}).length;
            }, 0);
            return demand;
        };

        const _chapterSupplyMap = {};
        const _chapterRoleMap = {};

        const fetchSupply = id => {
            return new Promise((resolve, reject) => {
                let apiCall = 1;
                let rolesApiAccumulativeData = null;

                function makeRolesApiCall() {
                    getOrgResources({
                        id: structureId,
                        method: 'rolesByChapter',
                        filterString: `query=chapter.part_of*0..{id=[${id}]}&page[number]=${apiCall}&`,
                    })
                        .then(({ data }) => {
                            const totalCalls = Math.ceil(data.meta.stats.total.count / 1000);
                            if (!rolesApiAccumulativeData) rolesApiAccumulativeData = data;
                            else {
                                rolesApiAccumulativeData.data = [...rolesApiAccumulativeData.data, ...data.data];
                                rolesApiAccumulativeData.included = [
                                    ...rolesApiAccumulativeData.included,
                                    ...data.included,
                                ];
                            }
                            if (totalCalls > 1 && apiCall < totalCalls) {
                                apiCall++;
                                makeRolesApiCall();
                            } else {
                                _chapterSupplyMap[id] = rolesApiAccumulativeData.data.length;
                                _chapterRoleMap[id] = rolesApiAccumulativeData.data;
                                resolve();
                            }
                        })
                        .catch(() => {
                            reject();
                        });
                }

                makeRolesApiCall();
            });
        };

        const getChildKeys = obj => {
            const _childList = [];
            function findAllChildList(obj) {
                if (obj) _childList.push(obj.id);

                if (!obj || !obj.child || !obj.child.length) return;

                obj.child.forEach(item => {
                    _childList.push(item.id);

                    if (item.child.length) {
                        item.child.forEach(child => findAllChildList(child));
                    }
                });
            }

            findAllChildList(obj);

            return _childList;
        };

        await Promise.all(Object.keys(chaptersHierarchy).map(chapterId => fetchSupply(chapterId)));

        const chapterIdMap = {};
        Object.keys(chaptersHierarchy).forEach(key => {
            chapterIdMap[key] = [...getChildKeys(chaptersHierarchy[key])];
        });
        const demandValue = {};
        Object.keys(chapterIdMap).forEach(key => {
            demandValue[key] = getDemand(chapterIdMap[key]);
        });

        // set positions in team on the basis of position type, on component load all positions will be pushed to object
        const getPositionsInTeam = (list, positionType) => {
            return (list || []).filter(elem => {
                switch (positionType) {
                    case 0:
                        return true;

                    case 1:
                        return positionRoleMap[elem.id];

                    case 2:
                        return !positionRoleMap[elem.id];

                    default:
                        return true;
                }
            });
        };

        (teams.data || []).forEach(elem => {
            formattedTeams.push({
                id: elem.id,
                name: elem.attributes.name,
                positions: getPositionsInTeam(elem.relationships.positions.data, positionType),
                parentId: null,
                hierarchyLevel: 1,
                sumOfTheChildren: {},
                child: [],
            });

            teamHierarchyMap[elem.id] = 1;

            (elem.relationships.subteams.data || []).forEach(el => {
                teamsReferenceMap[el.id] = elem.id;
            });
        });

        (teams.included || []).forEach(elem => {
            const { type, relationships } = elem;
            if (type === 'positions') {
                const positionId = elem.id;
                // check if position has chapters attached to it and create position chapter map
                if (relationships.chapter && relationships.chapter.data) {
                    const chapterId = relationships.chapter.data.id;
                    positionChapterMap[positionId] = chapterId;
                }
            }

            if (type === 'chapters') {
                // parent chapter id of current chapter if no parent present then current chapter id is there
                const parentId = findParent(elem.id, childParentChapterMap);
                // contains chapter object containing all childs
                // TODO need to check grand child logic here
                const parentChapter = chaptersHierarchy[parentId];
                // const parentChapter = chaptersHierarchy[parentId || elem.id];
                chapterMap[parentChapter.id] = {
                    Header: parentChapter.chapter_name,
                    accessor: d => {
                        return <div>{d.sumOfTheChildren[parentChapter.id] || 0}</div>;
                    },
                    className: 'header chapter',
                    width: 160,
                    HeaderComponent: ({
                        teamsChaptersData,
                        hasChild,
                        handleFilterClick,
                        selectedFiltersColumnList,
                        guidance,
                        fteEnvelope,
                        chapterRoleMap,
                        chapterPositionsStateMap,
                    }) => {
                        return (
                            <div>
                                <div className="header-guidance">
                                    <TextBox
                                        heading={parentChapter.chapter_name}
                                        structureId={structureId}
                                        id={parentChapter.id}
                                        isEditMode={!!guidance[parentChapter.id]}
                                        guidance={guidance}
                                        structure
                                        onSetGuidance={onSetGuidance}
                                        fteEnvelope={fteEnvelope}
                                    />
                                </div>
                                <div className="header-name">
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div className="header-title">
                                            {parentChapter.chapter_name.length > 16 ? (
                                                <Tooltip
                                                    title={`
                                                    ${
                                                        limitCharacters(parentChapter.chapter_name, 16).sliced
                                                            ? limitCharacters(parentChapter.chapter_name).value
                                                            : ''
                                                    }
                                                `}
                                                >
                                                    <div>{limitCharacters(parentChapter.chapter_name, 16).value}</div>
                                                </Tooltip>
                                            ) : (
                                                <div>{parentChapter.chapter_name}</div>
                                            )}

                                            <img
                                                className="filter-icon"
                                                alt="Filter Icon"
                                                src={
                                                    selectedFiltersColumnList.includes(parentChapter.id)
                                                        ? FilterIcon
                                                        : NoFilterIcon
                                                }
                                                onClick={e => handleFilterClick(e, parentChapter.id)}
                                            />
                                        </div>
                                        <div style={{ position: 'absolute', top: 30 }}>
                                            <div>{_chapterSupplyMap[parentChapter.id] || 0}</div>
                                            <div style={{ marginTop: 6 }}>{demandValue[parentChapter.id]}</div>
                                            {hasChild && (
                                                <div
                                                    className="view-subChapters"
                                                    onClick={() =>
                                                        getSubChapters(
                                                            parentChapter.id,
                                                            teamsChaptersData,
                                                            chapterRoleMap,
                                                            chapterPositionsStateMap
                                                        )
                                                    }
                                                >
                                                    View Sub-chapters
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    },
                };
            }

            if (type === 'teams') {
                if (!teamsReferenceMap[elem.id]) {
                    formattedTeams.push({
                        id: elem.id,
                        name: elem.attributes.name,
                        positions: getPositionsInTeam(elem.relationships.positions.data, positionType),
                        parentId: null,
                        hierarchyLevel: 1,
                        sumOfTheChildren: {},
                        child: [],
                    });

                    teamHierarchyMap[elem.id] = 1;
                    teamsReferenceMap[elem.id] = true;
                } else {
                    formattedTeams.push({
                        id: elem.id,
                        name: elem.attributes.name,
                        positions: getPositionsInTeam(elem.relationships.positions.data, positionType),
                        parentId: teamsReferenceMap[elem.id],
                        sumOfTheChildren: {},
                        child: [],
                    });
                }

                (elem.relationships.subteams.data || []).forEach(el => {
                    teamsReferenceMap[el.id] = elem.id;
                });
            }
        });

        // formattedTeams contain team list with parentId, positions and child
        // chapterMap contain {parent_chapter_id : table_header_object}
        // 1. In each team add parent chapter ids in object sumOfTheChildren and set it to 0
        // 2. Iterate over all the positions present in team
        // 3. Find chapter associated with that position
        // 4. If chapter present find its parent
        // 5. For each positiopn find how many chapters are present and add it to teams map
        formattedTeams.forEach(team => {
            Object.keys(chapterMap).forEach(key => (team.sumOfTheChildren[key] = 0)); // { parentChapterId:0 }
            team.sumOfTheChildren.positionSumUp = 0;
            team.positions.forEach(position => {
                const chapterId = positionChapterMap[position.id];
                if (chapterId) {
                    const parentId = findParent(chapterId, childParentChapterMap);
                    // If current chapter has some parent then sets in parent object hasChild = true
                    if (chapterMap[parentId] && chapterId !== parentId) {
                        chapterMap[parentId].hasChild = true;
                    }
                    const parentChapter = chaptersHierarchy[parentId || chapterId];
                    if (chapterMap[parentChapter.id]) {
                        if (team[parentChapter.id]) {
                            team[parentChapter.id]++;
                        } else {
                            team[parentChapter.id] = 1;
                        }
                    }
                }
            });

            if (team.parentId) {
                team.hierarchyLevel = teamHierarchyMap[team.parentId] + 1;
                teamHierarchyMap[team.id] = team.hierarchyLevel;
            }
        });

        const _teamsFlattenList = JSON.parse(JSON.stringify(formattedTeams));

        // add parent chapters to the table column state
        setTableColumns(oldVale => [...oldVale, ...Object.values(chapterMap)]);

        // generates team hierarchy with key subRows containing children
        const teamsHierarchy = formattedUtilities.generateHierarchy(formattedTeams, 'subRows');
        // sum up values
        Object.values(teamsHierarchy).forEach(row => formattedUtilities.sumUpValues(row));

        // {parent_team_id: [] all the possible non repeating positions}
        const filterSetterMap = {};

        const getFilterValues = _list => {
            _list.forEach(row => {
                if (row.subRows) {
                    getFilterValues(row.subRows);
                }

                _teamsFlattenList.find(item => item.id === row.id).sumOfTheChildren = row.sumOfTheChildren;

                Object.keys(row.sumOfTheChildren).forEach(key => {
                    if (!filterSetterMap[key]) {
                        filterSetterMap[key] = new Set();
                    }
                    filterSetterMap[key].add(row.sumOfTheChildren[key]);
                });

                return null;
            });
        };
        getFilterValues(Object.values(teamsHierarchy));

        setTeamsChaptersData({
            team: _teamsFlattenList,
            chapter: chaptersHierarchy,
            positionChapterMap,
        });

        // sorts position options of filterSetterMap {parent_team_id: [] all the possible non repeating positions} and convert it to object
        Object.keys(filterSetterMap).forEach(key => {
            const sortedArray = [...filterSetterMap[key]].sort((a, b) => a - b);
            const arrayObj = [{ id: -1, value: false }];
            sortedArray.forEach(item => arrayObj.push({ id: item, value: false }));
            filterSetterMap[key] = arrayObj;
        });

        setFilterMap(filterSetterMap);

        setTeamsTableMap(teamsHierarchy);
        setTableRows(Object.values(JSON.parse(JSON.stringify(teamsHierarchy))));

        // chapter and related sub-chapter supply:
        const subChapterRoleMapArr = [];
        Object.keys(chaptersHierarchy).forEach(chapterId => {
            const _subChapterRoleMap = {};
            if (_chapterRoleMap[chapterId].length > 0) {
                _chapterRoleMap[chapterId].forEach(item => {
                    const _chapterId = item.relationships?.chapter?.data?.id;
                    if (_chapterId) {
                        _subChapterRoleMap[_chapterId] = _subChapterRoleMap[_chapterId]
                            ? _subChapterRoleMap[_chapterId] + 1
                            : 1;
                    }
                });
                subChapterRoleMapArr.push(_subChapterRoleMap);
            }
        });

        // make json data from teamsChaptersData for the export
        const oneChapterArr = [];
        const teamChaptersForTable = [];
        const chaptersIdArr = [];
        Object.values(chaptersHierarchy).forEach(item => {
            const chapterObject = {};
            const chapterObjectForTable = {};
            (teams.included || []).forEach(elem => {
                if (elem.type == 'chapters') {
                    chaptersIdArr.push(elem.id);
                    if (elem.id == item.id) {
                        chapterObjectForTable['id'] = item.id;
                        chapterObjectForTable['chapter_name'] = item.chapter_name;
                        chapterObjectForTable['level'] = 1;
                        teamChaptersForTable.push(chapterObjectForTable);
                    }
                }
            });
            chapterObject['id'] = item.id;
            chapterObject['chapter_name'] = item.chapter_name;
            chapterObject['level'] = 1;
            const grantId = item.id;
            oneChapterArr.push(chapterObject);
            const levelCount = 1;
            const getChildForChapter = (item, levelCount, grantId) => {
                if (item.child.length > 0) {
                    levelCount += 1;
                    item.child.forEach(chlItm => {
                        const chapterObject = {};
                        const chapterObjectForTable = {};
                        if (chaptersIdArr.includes(chlItm.id)) {
                            chapterObjectForTable['id'] = chlItm.id;
                            chapterObjectForTable['chapter_name'] = chlItm.chapter_name;
                            if (levelCount > 2) {
                                chapterObjectForTable['parentId'] = grantId;
                            } else {
                                chapterObjectForTable['parentId'] = chlItm.parentId;
                            }
                            chapterObjectForTable['level'] = levelCount;

                            teamChaptersForTable.push(chapterObjectForTable);

                            chapterObject['id'] = chlItm.id;
                            chapterObject['chapter_name'] = chlItm.chapter_name;
                            if (levelCount > 2) {
                                chapterObject['parentId'] = grantId;
                            } else {
                                chapterObject['parentId'] = chlItm.parentId;
                            }
                            chapterObject['level'] = levelCount;
                            oneChapterArr.push(chapterObject);
                        }
                        if (chlItm.child.length > 0) {
                            getChildForChapter(chlItm, levelCount, grantId);
                        }
                    });
                }
            };
            getChildForChapter(item, levelCount, grantId);
        });
        setChapterArr(oneChapterArr);
        const getALLChapterArr = JSON.parse(JSON.stringify(oneChapterArr));

        // chapter supply data:
        const secondJsonArrForExport = [];

        for (let i = 0; i < teamChaptersForTable.length; i++) {
            const chapterSupplyDemandValue = {};
            for (let j = 0; j < subChapterRoleMapArr.length; j++) {
                if (Object.keys(subChapterRoleMapArr[j]).includes(teamChaptersForTable[i].id)) {
                    chapterSupplyDemandValue[''] = teamChaptersForTable[i]['chapter_name'];
                    if (teamChaptersForTable[i].level == 1) {
                        chapterSupplyDemandValue['Chapter Supply'] = Object.values(subChapterRoleMapArr[j]).reduce(
                            (total, current) => {
                                return total + current;
                            },
                            0
                        );
                    } else {
                        chapterSupplyDemandValue['Chapter Supply'] =
                            subChapterRoleMapArr[j][teamChaptersForTable[i].id];
                    }
                } else {
                    if (!chapterSupplyDemandValue['']) {
                        chapterSupplyDemandValue[''] = teamChaptersForTable[i]['chapter_name'];
                        chapterSupplyDemandValue['Chapter Supply'] = 0;
                    }
                }
            }
            if (Object.keys(chapterPositionsMap).includes(teamChaptersForTable[i].id)) {
                chapterSupplyDemandValue['Chapter Demand'] = Object.keys(
                    chapterPositionsMap[teamChaptersForTable[i].id] || {}
                ).length;
            } else {
                chapterSupplyDemandValue[''] = teamChaptersForTable[i]['chapter_name'];
                if (teamChaptersForTable[i].level == 1) {
                    chapterSupplyDemandValue['Chapter Demand'] = getDemand(chapterIdMap[teamChaptersForTable[i].id]);
                } else {
                    chapterSupplyDemandValue['Chapter Demand'] = 0;
                }
            }
            secondJsonArrForExport.push(chapterSupplyDemandValue);
        }

        // aoa_to_sheet array =>
        const chapterArray = [];
        const chapterArrayCopy = ['', '', '', '', ''];
        const chapterSupplyArray = [' ', ' ', ' ', ' ', 'Chapter Supply'];
        const chapterDemandArray = [' ', ' ', ' ', ' ', 'Chapter Demand'];
        secondJsonArrForExport.forEach(itm => {
            chapterArray.push(itm['']);
            chapterSupplyArray.push(itm['Chapter Supply']);
            chapterDemandArray.push(itm['Chapter Demand']);
        });

        setChapterPositionsStateMap(chapterPositionsMap);
        setChapterRoleMap(_chapterRoleMap);
        setxlChapterMap(prev => {
            const combinedChapterArr = chapterArrayCopy.concat(chapterArray);
            return [...prev, combinedChapterArr, chapterSupplyArray, chapterDemandArray];
        });

        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const exportTableGenerate = (oneChapterArr, data) => {
        const jsonArrForExport = [];
        data.forEach((item, index, arr) => {
            const getExportedArrayOfObjs = (item, index, arr) => {
                const exportObject = {};
                const unassignedValueArr = Object.values(arr[index].sumOfTheChildren);
                unassignedValueArr.pop();
                const totalAssignedPositons = unassignedValueArr.reduce((total, current) => {
                    return total + current;
                }, 0);
                exportObject['Teams'] = `${arr[index].name}`;
                exportObject['Level'] = `${arr[index].hierarchyLevel}`;
                exportObject['Total Positions'] = arr[index].sumOfTheChildren.positionSumUp;
                exportObject['Unassigned'] = `${arr[index].sumOfTheChildren.positionSumUp - totalAssignedPositons}`;
                exportObject[' '] = ` `;
                Object.keys(item.sumOfTheChildren).forEach(chItem => {
                    let lastItemId = null;
                    let nextLevel = 0;
                    let level = 0;
                    oneChapterArr.forEach(itm => {
                        if (itm['id'].includes(chItem)) {
                            exportObject[itm['chapter_name']] = item.sumOfTheChildren[chItem];
                            lastItemId = itm['id']; // 4
                            level = itm['level'];
                            nextLevel = level + 1;
                        } else {
                            if (lastItemId != null && itm['parentId'] == lastItemId) {
                                if (itm['level'] == level || itm['level'] >= nextLevel) {
                                    let Poscount = 0;
                                    if (item.positions.length > 0) {
                                        item.positions.forEach(posItem => {
                                            if (teamsChaptersData.positionChapterMap[posItem['id']] == itm['id']) {
                                                Poscount += 1;
                                            }
                                        });
                                    }
                                    exportObject[itm['chapter_name']] = Poscount;
                                    level += 1;
                                }
                            }
                        }
                    });
                });
                jsonArrForExport.push(exportObject);

                if (item.subRows && item.subRows.length > 0) {
                    arr[index].subRows.forEach((rowItem, rowIndex, arr1) => {
                        getExportedArrayOfObjs(rowItem, rowIndex, arr1);
                    });
                }
            };
            getExportedArrayOfObjs(item, index, arr);
        });

        return jsonArrForExport;
    };
    /**
     * Function returns childParentChapterMap which contains {chapterId: parentChapterId} and formattedChapters is the list of all chapters with parent id and child empty array
     * @param {*} chapters -> all chapters
     * @returns
     */
    const generateSupplyDemandTable = chapters => {
        const childParentChapterMap = {};
        const chapterReferenceMap = {};
        const formattedChapters = [];
        const filteredChapters = (chapters.included || []).filter(el => el.type === 'chapters');
        (chapters.data || []).forEach(el => {
            formattedChapters.push({
                id: el.id,
                chapter_name: el.attributes.name,
                positions: el.relationships.positions.data,
                parentId: null,
                child: [],
            });
            childParentChapterMap[el.id] = null;
            (el.relationships.parts.data || []).forEach(elem => {
                chapterReferenceMap[elem.id] = el.id;
            });
        });
        filteredChapters.forEach(el => {
            if (!chapterReferenceMap[el.id]) {
                formattedChapters.push({
                    id: el.id,
                    chapter_name: el.attributes.name,
                    positions: el.relationships.positions.data,
                    parentId: null,
                    child: [],
                });
                chapterReferenceMap[el.id] = true;
            } else {
                formattedChapters.push({
                    id: el.id,
                    chapter_name: el.attributes.name,
                    positions: el.relationships.positions.data,
                    parentId: chapterReferenceMap[el.id],
                    child: [],
                });
                childParentChapterMap[el.id] = chapterReferenceMap[el.id];
            }

            (el.relationships.parts.data || []).forEach(elem => {
                chapterReferenceMap[elem.id] = el.id;
            });
        });

        return { childParentChapterMap, formattedChapters };
    };

    /**
     * Function return parent id if present else returns its own id
     * @param {*} id -> chapter id
     * @param {*} map -> map containing {child_chapter_id: parent_chapter_id}
     * @returns
     */
    const findParent = (id, map) => {
        if (map[id]) {
            return findParent(map[id], map);
        }
        return id;
    };

    const handleFilterClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedFilterColumn(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterRows = e => {
        filterCheckHandle(e);
    };

    const filterCheckHandle = e => {
        const _selectedFilterColumn = selectedFilterColumn;
        setFilterMap(oldMap => {
            if (Number(e.target.name) < 0) {
                oldMap[_selectedFilterColumn].forEach(item => (item.value = e.target.checked));
            } else {
                const selectedItem = oldMap[_selectedFilterColumn].find(item => item.id === Number(e.target.name));
                if (selectedItem) selectedItem.value = e.target.checked;
            }

            return oldMap;
        });
        setRenderMenuList(oldVal => oldVal + 1);
    };

    const clearSelectedFilter = () => {
        setFilterMap(oldMap => {
            oldMap[selectedFilterColumn].forEach(item => (item.value = false));
            return oldMap;
        });
        setRenderMenuList(oldVal => oldVal + 1);
        setSelectedFiltersColumnList(oldList => {
            const _index = oldList.findIndex(item => item === selectedFilterColumn);
            oldList.splice(_index, 1);
            return oldList;
        });
    };

    const onSetGuidance = (structure_id, value) => {
        const client_id = localStorage.getItem('clientId');
        const phase_id = localStorage.getItem('phaseId');
        setGuidanceApi({
            client_id,
            phase_id,
            structure_id,
            config: { ...value },
        }).then(response => {
            setGuidance({ ...value });
            // guidanceRef.current.value = { ...value };
        });
    };

    const resetState = () => {
        setTableColumns(columns(true));
        setTeamsTableMap({});
        setTableRows([]);
        setTeamsData(null);
    };

    const generateModalTableData = (chapterId, _teamsChaptersData, chapterRoleMap, chapterPositionsStateMap) => {
        const _subChapterRoleMap = {};
        const { team, chapter, positionChapterMap } = JSON.parse(JSON.stringify(_teamsChaptersData));
        chapterRoleMap[chapterId].forEach(item => {
            const _chapterId = item.relationships?.chapter?.data?.id;
            if (_chapterId) {
                _subChapterRoleMap[_chapterId] = _subChapterRoleMap[_chapterId]
                    ? _subChapterRoleMap[_chapterId] + 1
                    : 1;
            }
        });
        const _subChapters = formattedUtilities.searchTree({ id: null, child: Object.values(chapter) }, chapterId);
        const subChaptersList = formattedUtilities.flattenTreeToList(_subChapters);

        const chapterMap = {};
        subChaptersList.forEach((chapterDetails, index) => {
            chapterMap[chapterDetails.id] = {
                Header: chapterDetails.name,
                accessor: d => {
                    return <div style={{ margin: '0 auto' }}>{d.sumOfTheChildren[chapterDetails.id] || 0}</div>;
                },
                className: 'header chapter',
                width: 200,
                priority: index,
                parentId: chapterDetails.parentId || null,
                _depth: (() => {
                    if (chapterDetails.parentId) {
                        return chapterMap[chapterDetails.parentId]._depth + 1;
                    }

                    return 1;
                })(),

                HeaderComponent: ({ row }) => {
                    return (
                        <div>
                            <div className="header-guidance" style={{ color: 'white' }}>
                                Guidance
                            </div>
                            <div
                                className={`${chapterDetails.hasChild ? 'header-name-hasChild' : 'header-name'}`}
                                style={{ height: 90 }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="header-title">
                                        {chapterDetails.name.length > 16 ? (
                                            <Tooltip
                                                title={`
                                                    ${
                                                        limitCharacters(chapterDetails.name, 16).sliced
                                                            ? limitCharacters(chapterDetails.name).value
                                                            : ''
                                                    }
                                                `}
                                            >
                                                <div>{limitCharacters(chapterDetails.name, 16).value}</div>
                                            </Tooltip>
                                        ) : (
                                            <div>{chapterDetails.name}</div>
                                        )}
                                    </div>
                                    <div style={{ position: 'absolute', top: chapterDetails.hasChild ? 23 : 30 }}>
                                        <div>{_subChapterRoleMap[chapterDetails.id] || 0}</div>
                                        <div style={{ marginTop: 6 }}>
                                            {Object.keys(chapterPositionsStateMap[chapterDetails.id] || {}).length}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                },
            };
        });

        team.forEach(_team => {
            Object.keys(chapterMap).forEach(key => (_team.sumOfTheChildren[key] = 0));
            _team.sumOfTheChildren.positionSumUp = 0;
            _team.positions.forEach(position => {
                const _chapterId = positionChapterMap[position.id];
                if (_chapterId) {
                    if (chapterMap[_chapterId] && chapterId != _chapterId) {
                        if (_team[_chapterId]) {
                            _team[_chapterId]++;
                        } else {
                            _team[_chapterId] = 1;
                        }
                    }
                }
            });
        });

        const sortedHeader = Object.values(chapterMap).sort((a, b) => a.priority - b.priority);

        const teamsHierarchy = formattedUtilities.generateHierarchy(team, 'subRows');
        Object.values(teamsHierarchy).forEach(row => formattedUtilities.sumUpValues(row));
        setModalData({
            column: [columns(false)[0], columns(false)[2], ...sortedHeader],
            row: Object.values(teamsHierarchy),
        });
        setOpenModal(true);
    };

    const getSubChapters = (chapterId, _teamsChaptersData, chapterRoleMap, chapterPositionsStateMap) => {
        generateModalTableData(chapterId, _teamsChaptersData, chapterRoleMap, chapterPositionsStateMap);
    };

    const closeSubChaptersModal = () => {
        setOpenModal(false);
        setModalData({ column: [], row: [] });
    };

    const getFilteredResults = (filterToApply = {}) => {
        setFiltersData({ ...filterToApply });
    };

    const setFiltersData = filtersUsed => {
        setFilters(filtersUsed);
    };

    const phaseUpdated = () => {
        setFilterApplied(false);
    };

    const compareData = str1 => {
        if (str1) {
            setFilters({ ...filters });
        }
    };

    const setStructureData = id => {
        setStructureId(id);
        setFilterApplied(primaryFilters.filter(({ isSelected }) => isSelected));
    };

    const getReportOptions = () => {
        const reportOptions = [];
        Object.keys(REPORTS_LINK).forEach(el => {
            if (privileges[el]) {
                reportOptions.push(REPORTS_LINK[el]);
            }
        });

        return reportOptions;
    };

    const resetManagerFilter = () => {
        // clear manager data
        clrManagerFilters();
        const el = document.getElementById('search');
        if (el) {
            el.value = '';
        }
    };

    const updateReport = index => {
        resetFilters();
        const links = Object.values(REPORTS_LINK);
        const route = links.filter(el => el.value === index);
        history.push(route[0].routeValue);
    };

    const hasStructure = phaseStructureInfo.isApiCallSuccess && phaseStructureInfo.anyStructurePresent;

    const handleClick = () => setDrawerOpen(!drawerOpen);

    const generateChapterExport = async today => {
        let jsonArrForExport = [];
        if (selectedFiltersColumnList.length) {
            jsonArrForExport = exportTableGenerate(chapterArr, tableRows);
        } else {
            jsonArrForExport = exportTableGenerate(chapterArr, Object.values(teamsTableMap));
        }
        const teamChapterTableHeader = ['Teams', 'Level', 'Total Positions', 'Unassigned', ' '];

        const [chapterArray, chapterSupplyArray, chapterDemandArray] = xlChapterMap;

        const teamChapterTableData = [];

        jsonArrForExport.forEach(itm => {
            const newTableDataArr = [];
            newTableDataArr.push(itm['Teams']);
            newTableDataArr.push(itm['Level']);
            newTableDataArr.push(itm['Total Positions']);
            newTableDataArr.push(itm['Unassigned']);
            newTableDataArr.push(itm[' ']);
            chapterArray.forEach((itms, index, arr) => {
                // if(itm[itms] != undefined)
                if (itms != '') newTableDataArr.push(itm[itms]);
            });
            teamChapterTableData.push(newTableDataArr);
        });

        const concatedArray = [teamChapterTableHeader, ...teamChapterTableData];
        // not using now:
        // setTeamChapterTable(concatedArray);

        const ws2 = XLSX.utils.aoa_to_sheet([chapterArray, chapterSupplyArray, chapterDemandArray]);

        XLSX.utils.sheet_add_aoa(ws2, concatedArray, { origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws2, 'Chapter_Supply_Demand_Table');
        XLSX.writeFile(wb, `team_chapter_${today}.xlsx`);
    };

    return (
        <Box className="team-chapters-dashboard clearFix">
            <div className="topHeaders">
                <Header />
                <SubHeader
                    showStructures
                    enableCompare={strCompareFetched}
                    filterApplied={filterApplied}
                    setDrawerOpen={handleClick}
                    primaryFilters={primaryFilters}
                    getFilteredResults={getFilteredResults}
                    phaseUpdated={phaseUpdated}
                    getCurrentStructure={compareData}
                    setStructureId={setStructureData}
                    resetManagerFilter={resetManagerFilter}
                    enableRefreshTab
                    enableFiltersTab
                    showTeamChapterExport
                    generateChapterExport={generateChapterExport}
                />
            </div>

            <section className="selection-content">
                <FormControl className="reportLink">
                    <InputLabel shrink id="reportLink">
                        {REPORT_SUB}
                    </InputLabel>
                    <Select
                        labelId="reportLink"
                        id="reportLinkSelect"
                        value={
                            // getReportOptions().find(el => el.name === 'Distribution of team positions by chapter').value
                            getReportOptions().find(el => el.name === 'Tribe x Chapter Table').value
                        }
                        onChange={event => updateReport(event.target.value)}
                    >
                        {getReportOptions().map((report, repIndex) => (
                            <MenuItem key={report.value} value={report.value}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {hasStructure && <DatePickers label="Teams as of" setDate={setDate} value={date} />}
                <FormControl className="reportLink">
                    <InputLabel shrink id="reportLink">
                        Position Counts
                    </InputLabel>
                    <Select
                        labelId="reportLink"
                        id="reportLinkSelect"
                        defaultValue={0}
                        onChange={event => generateTeamsStats(teamsData, chaptersData, event.target.value)}
                    >
                        {[{ name: 'All' }, { name: 'Staffed' }, { name: 'Unstaffed' }].map((positionType, index) => (
                            <MenuItem key={index} value={index}>
                                {positionType.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </section>
            <section className="teams-chapters">
                {isLoading ? (
                    <Loader showBackground background="transparent" />
                ) : teamsData && teamsData.data && teamsData.data.length ? (
                    <Fragment>
                        {tableColumns && tableColumns.length < 3 && (
                            <div
                                style={{
                                    margin: '0 auto',
                                    width: `${
                                        tableColumns.reduce((acc, value) => {
                                            return acc + value.width;
                                        }, 0) >
                                        document.body.clientWidth * 0.7
                                            ? document.body.clientWidth * 0.7
                                            : tableColumns.reduce((acc, value) => {
                                                  return acc + value.width;
                                              }, 0)
                                    }px`,
                                }}
                            >
                                <div className="no-chapters">
                                    <span className="warning-icon">
                                        <WarningIcon />
                                    </span>
                                    <span className="message"> No chapters found for the selected structure</span>
                                </div>
                            </div>
                        )}
                        <Styles width="100%">
                            <MenuList
                                anchorEl={anchorEl}
                                renderList={renderMenuList}
                                handleClose={handleClose}
                                filterData={filterMap[selectedFilterColumn]}
                                filterRows={filterRows}
                                clearSelectedFilter={clearSelectedFilter}
                            />

                            <Table
                                width={tableColumns.reduce((acc, value) => {
                                    return acc + value.width;
                                }, 0)}
                                className="teams-chapters-table"
                                columns={tableColumns}
                                data={tableRows}
                                guidance={guidance}
                                fteEnvelope={fteEnvelope}
                                structureId={structureId}
                                teamsChaptersData={teamsChaptersData}
                                handleFilterClick={handleFilterClick}
                                selectedFiltersColumnList={selectedFiltersColumnList}
                                chapterRoleMap={chapterRoleMap}
                                chapterPositionsStateMap={chapterPositionsStateMap}
                            />
                        </Styles>
                    </Fragment>
                ) : (
                    <NoDataFound
                        mainMessage={`No teams found for the selected structure`}
                        secondaryMessage={`Please select a different structure`}
                    />
                )}
            </section>
            <SubChapterModal
                open={openModal}
                handleClose={closeSubChaptersModal}
                chapterName={(modalData.column[2] && modalData.column[2].Header) || ''}
            >
                <section className="teams-chapters">
                    <StylesSubChapter
                        width={modalData.column.reduce((acc, value) => {
                            return acc + value.width;
                        }, 0)}
                    >
                        <Table
                            noExtraHeader
                            width={`${modalData.column.reduce((acc, value) => {
                                return acc + value.width;
                            }, 0)}px`}
                            className="teams-chapters-table subChapters"
                            columns={modalData.column}
                            data={modalData.row}
                            teamsChaptersData={teamsChaptersData}
                        />
                    </StylesSubChapter>
                </section>
            </SubChapterModal>
        </Box>
    );
};

export default TeamChapters;
