const addressableOpportunityConst = {
    inputTableHeading: [
        'ADJUSTMENT',
        'Adjusting for Overspan Managers',
        'Geographic Exceptions',
        'Hidden Players',
        'Individual Contribution',
    ],
    inputTableFixedRow: [
        'RATIONALE',
        "Typically only 20% of managers who are overspan need additional resources, the other 80% are able to handle their workload. We have chosen 20% as a result of expert interviews and what we've seen in the past, however this will vary from org to org.",
        'If geographical exceptions should be applied, please change to the minimum number of managers that need to be at each location. If geographical exceptions are not applicable, keep as 0.',
        'Hidden Players = Managers that have 1-2 Direct Reports. Hidden Players often have substantial individual work. We typically see 80% converted into individual contributors, so their management opportunity cannot be attained. However this 80% can vary from org to org',
        'People leaders individual work need to be preserved even if the role is eliminated',
    ],
    toolTipInfo:
        'The model is pre-populated with what is typically seen in most OrgLab studies. If the impact of these factors should be adjusted, use this page to do so.',
    outputTableHeading: ['ADJUSTMENT', 'RATIONALE', 'ASSUMPTIONS', 'FTE OPP.', 'COST OPP($ M)'],
    outputTableFirstRow: ['Opportunity from managers managing too few', ''],
    outputTableSecondRow:
        'Decision: Invest additional resources to correct for people leaders who are over-span and struggling',
    outputTableThirdRow: [
        'Decision: Adjust downward for approved exceptions (e.g., geographic coverage)',
        'Remove the opportunity for managers in locations that only have 1 manager and are not hidden players',
    ],
    outputTableFourthRow: 'Decision: Convert “hidden player” people leaders into individual contributors',
    outputTableFifthRow: 'Decision: Preserve capacity for people leaders’ non-managerial workload',
    outputTableSixthRow: 'Adjustment opportunity',
    outputTableRationaleList: ['Player Coach', 'Coach', 'Supervisor', 'Facilitator', 'Coordinator'],
    chartHeading: 'Addressable opportunity',
    graphConst: [
        { name: 'Opportunity in OrgLab', label: 'too_few' },
        { name: 'Over-Span Managers', label: 'overspan_manager' },
        { name: 'Geographic Exception', label: 'geo_exception' },
        { name: 'Hidden Players', label: 'hidden_player' },
        { name: 'Manager’s Individual Work', label: 'archetypes' },
        { name: 'Addressable Opprtunity', label: 'total' },
    ],
};

export default addressableOpportunityConst;
