import React, { useRef, useState, useEffect } from 'react';
import c from 'classnames';
import {
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Radio,
    Typography,
} from '@material-ui/core';
import { cloneDeep, debounce } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import filtersEnums from '../Filters/filters.enums';
import cross from '../../assets/crossIcon.svg';
import { calculation_assumptions } from '../../common/staticFilters';
import SearchInput from '../Filters/components/SearchInput';

function DesignFilters({
    drawerOpen,
    showStaticFilters,
    designFilters,
    fetchSecondaryFilters,
    setDrawerOpen,
    getManagerFilters,
    clrSelectedManager,
    tabSelected,
    updateMultiFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    designProgressState,
    staticFilters,
    updateStaticFilters,
    updateFiltersChildren,
    designProgressOverallActiveStructures,
    designProgressLeadersActiveStructures,
    designProgressFunctionsActiveStructures,
    getStaticFilters,
}) {
    const managerKey = 'manager';
    const radioFilterKeys = [
        '# of Direct Reports',
        'open_Roles',
        'contractors',
        'assistants',
        'counts',
        '#_of_Direct_Reports',
        'Archetype_Algorithm',
    ];
    const stringVals = [
        'algorithms',
        'direct_reports',
        'open_roles',
        'contractors',
        'assistant',
        'count_by',
        'span_ranges',
    ];
    const [filters, setFilters] = useState(designFilters[designProgressState]);

    useEffect(() => {
        setFilters(designFilters[designProgressState]);
    }, [designFilters, designProgressState]);

    useEffect(() => {
        getStaticFilters();
    }, []);

    const handleClickAway = (index, filtersOf) => {
        const updatedFilters = cloneDeep(filters[filtersOf]);
        if (updatedFilters[index].isOpen) {
            updatedFilters[index].isOpen = false;
            updateMultiFilters({
                filters: updatedFilters,
                module: designProgressState,
                structureNumber: filtersOf,
            });
        }
    };

    const handleStaticClickAway = index => {
        const updatedFilters = cloneDeep(staticFilters[designProgressState]);
        if (updatedFilters[index].isOpen) {
            updatedFilters[index].isOpen = false;
            updateStaticFilters({
                filters: updatedFilters,
                module: designProgressState,
            });
        }
    };

    const handleStaticClick = index => {
        const updatedFilters = cloneDeep(staticFilters[designProgressState]);
        const { isOpen = false } = updatedFilters[index];
        const openItem = updatedFilters.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1 && openItem !== index) {
            updatedFilters[openItem].isOpen = false;
            updatedFilters[index].isOpen = true;
        } else {
            updatedFilters[index].isOpen = !isOpen;
        }

        updateStaticFilters({
            filters: updatedFilters,
            module: designProgressState,
        });
    };

    const getManagerByQ = (q = '', strId, module, structureNumber) => {
        getManagerFilters(`manager|${q}`, strId, module, structureNumber);
    };

    const handleClick = (index, filtersOf) => {
        const updatedFilters = cloneDeep(filters[filtersOf]);
        const { isOpen = false } = updatedFilters[index];
        const openItem = updatedFilters.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1 && openItem !== index) {
            updatedFilters[openItem].isOpen = false;
            updatedFilters[index].isOpen = true;
        } else {
            updatedFilters[index].isOpen = !isOpen;
        }

        updateMultiFilters({
            filters: updatedFilters,
            module: designProgressState,
            structureNumber: filtersOf,
        });
    };

    const callSearchedManagers = (value, strId, module, structureNumber) =>
        getManagerByQ(value, strId, module, structureNumber);
    const callSearchedManagersDebounced = useRef(debounce(callSearchedManagers, 500));

    const removeSelected = (event, mainIndex, childName, filtersOf, isStatic) => {
        event.stopPropagation();
        const f = isStatic ? staticFilters[designProgressState] : filters[filtersOf];
        const childIndex = f[mainIndex].children.findIndex(({ name }) => name === childName);
        if (['manager'].includes(f[mainIndex].keyToUse)) {
            clrSelectedManager({
                module: designProgressState,
                structureNumber: filtersOf,
            });
        } else {
            handleChecked({ checked: false }, [mainIndex, childIndex], filtersOf, isStatic);
        }
    };

    const handleChange = ({ target }, filtersOf) => {
        const { value } = target;
        let updatedFilters = cloneDeep(filters[filtersOf]);

        updatedFilters = updatedFilters.map(item => {
            if (item.keyToUse === managerKey) {
                item.children = [];

                item.message = value.length >= 2 ? "Please wait while we're loading results..." : '';
            }
            return item;
        });

        const activeStructures =
            designProgressState === 'overall'
                ? designProgressOverallActiveStructures
                : designProgressState === 'functions'
                ? designProgressFunctionsActiveStructures
                : designProgressLeadersActiveStructures;

        if (value.length >= 2) {
            callSearchedManagersDebounced.current(
                value,
                activeStructures[filtersOf].id,
                designProgressState,
                filtersOf
            );
        }

        setTimeout(
            () => {
                updateMultiFilters({
                    filters: updatedFilters,
                    module: designProgressState,
                    structureNumber: filtersOf,
                });
            },
            value.length < 2 ? 1000 : 0
        );
    };

    const repeatBlock = (arrToRepeat, indexParent, keyToUse, filtersOf, isStatic = true, needExpandIcon = true) => {
        return arrToRepeat.map(
            (
                {
                    name = '',
                    children = [],
                    isOpen = false,
                    isSelected = false,
                    id = '',
                    isLoaded = false,
                    isLoading = false,
                },
                index
            ) => {
                name = name ? (name.endsWith(' -') ? name.replace(' -', '') : name) : '';
                const expandState = isOpen ? <ExpandLess /> : <ExpandMore />;
                const loaderState = isLoading ? <CircularProgress /> : expandState;
                return (
                    <List component="div" disablePadding key={index}>
                        <ListItem style={{ paddingLeft: indexParent.length * 16 }}>
                            {needExpandIcon && (
                                <span
                                    onClick={() =>
                                        getInnerFilters(
                                            keyToUse,
                                            id,
                                            [...indexParent, index],
                                            isLoaded,
                                            isLoading,
                                            !isOpen,
                                            isSelected,
                                            filtersOf
                                        )
                                    }
                                >
                                    {loaderState}
                                </span>
                            )}

                            {[managerKey].includes(keyToUse) && (
                                <Radio
                                    name={managerKey}
                                    checked={isSelected}
                                    onChange={({ target }) =>
                                        handleChecked(target, [...indexParent, index], filtersOf, isStatic, 'radio')
                                    }
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary radio' }}
                                />
                            )}

                            {![managerKey].includes(keyToUse) &&
                                (radioFilterKeys.includes(keyToUse) ? (
                                    <Radio
                                        name={keyToUse}
                                        checked={isSelected}
                                        onChange={({ target }) =>
                                            handleChecked(target, [...indexParent, index], filtersOf, isStatic, 'radio')
                                        }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary radio' }}
                                    />
                                ) : (
                                    <Checkbox
                                        checked={isSelected}
                                        onChange={({ target }) =>
                                            handleChecked(target, [...indexParent, index], filtersOf, isStatic)
                                        }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                ))}
                            <ListItemText
                                primary={name.replace(/_/g, ' ')}
                                onClick={() =>
                                    getInnerFilters(
                                        keyToUse,
                                        id,
                                        [...indexParent, index],
                                        isLoaded,
                                        isLoading,
                                        !isOpen,
                                        isSelected,
                                        filtersOf
                                    )
                                }
                            />
                        </ListItem>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            {repeatBlock(children, [...indexParent, index], keyToUse, filtersOf, isStatic)}
                        </Collapse>
                    </List>
                );
            }
        );
    };

    const updateChildren = (mainParent = [], isSelected) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = isSelected;
            if (innerChild.children) {
                updateChildren(innerChild.children, isSelected);
            }
        });
    };

    const getInnerFilters = (keyToUse, id, arrToRepeat, isLoaded, isLoading, isOpen, isSelected, filtersOf) => {
        if (!isLoading) {
            const arrayToUse = arrToRepeat.reduce(
                (r, o) => {
                    r.push(o);
                    r.push('children');
                    return r;
                },
                ['multiStructureFilters', designProgressState, filtersOf]
            );
            if (!isLoaded) {
                fetchSecondaryFilters(keyToUse, id, arrayToUse, isSelected);
            } else {
                const arrayToOpen = [...arrayToUse.slice(0, -1), 'isOpen'];
                updateFiltersChildren({ arrayToOpen, isOpen });
            }
        }
    };

    const handleChecked = ({ checked }, indexArr, filtersOf, isStatic = false, component = 'checkbox') => {
        const f = isStatic ? staticFilters[designProgressState] : filters[filtersOf];
        const updatedFilters = cloneDeep(f);
        let { children = [] } = updatedFilters[indexArr[0]];
        if (indexArr.length === 2) {
            const innerChildMain = children[indexArr[1]];
            updateChildren(innerChildMain.children, checked);

            if (component === 'radio') {
                // make is selected false to prev filter option
                children = children.map(item => {
                    item.isSelected = false;
                    return item;
                });
            }

            innerChildMain.isSelected = checked;
            updatedFilters[indexArr[0]].selectedOption = innerChildMain;
            updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
        } else if (indexArr.length === 3) {
            const innerChild = children[indexArr[1]].children[indexArr[2]];
            innerChild.isSelected = checked;
            if (checked) {
                children[indexArr[1]].isSelected = checked;
                updatedFilters[indexArr[0]].isSelected = checked;
            } else {
                children[indexArr[1]].isSelected = !!children[indexArr[1]].children.filter(
                    ({ isSelected }) => isSelected
                ).length;
                updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
            }
            updateChildren(innerChild.children, checked);
        } else {
            const innerChild2 = children[indexArr[1]].children[indexArr[2]].children[indexArr[3]];
            innerChild2.isSelected = checked;
            if (checked) {
                children[indexArr[1]].children[indexArr[2]].isSelected = checked;
                children[indexArr[1]].isSelected = checked;
                updatedFilters[indexArr[0]].isSelected = checked;
            } else {
                children[indexArr[1]].children[indexArr[2]].isSelected = !!children[indexArr[1]].children[
                    indexArr[2]
                ].children.filter(({ isSelected }) => isSelected).length;
                children[indexArr[1]].isSelected = !!children[indexArr[1]].children.filter(
                    ({ isSelected }) => isSelected
                ).length;
                updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
            }
            updateChildren(innerChild2.children, checked);
        }

        if (isStatic) {
            updateStaticFilters({
                filters: updatedFilters,
                module: designProgressState,
            });
        } else {
            updateMultiFilters({
                filters: updatedFilters,
                module: designProgressState,
                structureNumber: filtersOf,
            });
        }
    };

    const getStaticManagersFilters = structure => {
        const staticManagersFilters = [];
        staticFilters[designProgressState].forEach(
            ({ name = '', children = [], keyToUse = '', isOpen = false, type }, indexParent) => {
                if (type === 'managerFilter') {
                    const selectedChild = children.filter(({ isSelected }) => isSelected);
                    const selectedLength = selectedChild.length;
                    const iconExpand = children.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;

                    staticManagersFilters.push(
                        <ClickAwayListener onClickAway={() => handleStaticClickAway(indexParent)} key={name}>
                            <div className="mainListWrap">
                                <ListItem
                                    onClick={() => handleStaticClick(indexParent)}
                                    classes={{ root: 'mainListContent' }}
                                >
                                    <div className={c('nameChild', { shrink: selectedChild.length })}>
                                        {name.replace(/_/g, ' ')}
                                        {iconExpand}
                                    </div>
                                    {selectedLength ? (
                                        <div className="selectedChild">
                                            {selectedChild.map(
                                                ({ name: childName, children: childList = [] }, index) => (
                                                    <span key={index} className="innerChild">
                                                        {childName}
                                                        <span>
                                                            (
                                                            {childList.filter(({ isSelected }) => isSelected).length ||
                                                                1}
                                                            )
                                                        </span>
                                                        <img
                                                            alt=""
                                                            src={cross}
                                                            onClick={e =>
                                                                removeSelected(
                                                                    e,
                                                                    indexParent,
                                                                    childName,
                                                                    structure,
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                )
                                            )}
                                            {iconExpand}
                                        </div>
                                    ) : null}
                                </ListItem>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit={![managerKey].includes(keyToUse)}
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {repeatBlock(
                                        children,
                                        [indexParent],
                                        keyToUse,
                                        structure,
                                        true,
                                        !radioFilterKeys.includes(keyToUse)
                                    )}
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    );
                }
            }
        );
        return staticManagersFilters;
    };

    const getDynamicFilters = structure => {
        const dynamicFilters = [];
        filters[structure].forEach(
            (
                { name = '', children = [], keyToUse = '', isOpen = false, message = '', isStaticFilter },
                indexParent
            ) => {
                if (!isStaticFilter) {
                    const selectedChild = children.filter(({ isSelected }) => isSelected);
                    const selectedLength = selectedChild.length;
                    const iconExpand = children.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;

                    dynamicFilters.push(
                        <ClickAwayListener onClickAway={() => handleClickAway(indexParent, structure)} key={name}>
                            <div className="mainListWrap">
                                <ListItem
                                    onClick={() => handleClick(indexParent, structure)}
                                    classes={{ root: 'mainListContent' }}
                                >
                                    <div className={c('nameChild', { shrink: selectedChild.length })}>
                                        {name.replace(/_/g, ' ')}
                                        {iconExpand}
                                    </div>
                                    {selectedLength ? (
                                        <div className="selectedChild">
                                            {selectedChild.map(
                                                ({ name: childName, children: childList = [] }, index) => (
                                                    <span key={index} className="innerChild">
                                                        {childName}
                                                        <span>
                                                            (
                                                            {childList.filter(({ isSelected }) => isSelected).length ||
                                                                1}
                                                            )
                                                        </span>
                                                        <img
                                                            alt=""
                                                            src={cross}
                                                            onClick={e =>
                                                                removeSelected(
                                                                    e,
                                                                    indexParent,
                                                                    childName,
                                                                    structure,
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                )
                                            )}
                                            {iconExpand}
                                        </div>
                                    ) : null}
                                </ListItem>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit={![managerKey].includes(keyToUse)}
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {keyToUse === managerKey && (
                                        <div className="searchBoxWrapper">
                                            <SearchInput
                                                type="search"
                                                id={'search'}
                                                label="Search Managers"
                                                onChange={e => handleChange(e, structure)}
                                                placeholder="Type at least 2 char to search..."
                                                message
                                            />

                                            {message && <span className="msg">{message}</span>}
                                        </div>
                                    )}
                                    {repeatBlock(
                                        children,
                                        [indexParent],
                                        keyToUse,
                                        structure,
                                        false,
                                        keyToUse !== managerKey
                                    )}
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    );
                }
            }
        );
        return dynamicFilters;
    };

    const getStaticCalculationAssumptionsFilters = structure => {
        const staticCalculationAssumptionsFilters = [];
        staticFilters[designProgressState].forEach(
            ({ name = '', children = [], keyToUse = '', isOpen = false, isStaticFilter, type }, indexParent) => {
                if (isStaticFilter && type === 'calculationAssumptionsFilter') {
                    const selectedChild = children.filter(({ isSelected }) => isSelected);
                    const selectedLength = selectedChild.length;
                    const iconExpand = children.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;

                    staticCalculationAssumptionsFilters.push(
                        <ClickAwayListener onClickAway={() => handleStaticClickAway(indexParent)} key={name}>
                            <div className="mainListWrap">
                                <ListItem
                                    onClick={() => handleStaticClick(indexParent)}
                                    classes={{ root: 'mainListContent' }}
                                >
                                    <div className={c('nameChild', { shrink: selectedChild.length })}>
                                        {name.replace(/_/g, ' ')}
                                        {iconExpand}
                                    </div>
                                    {selectedLength ? (
                                        <div className="selectedChild">
                                            {selectedChild.map(
                                                ({ name: childName, children: childList = [] }, index) => (
                                                    <span key={index} className="innerChild">
                                                        {childName}
                                                        <span>
                                                            (
                                                            {childList.filter(({ isSelected }) => isSelected).length ||
                                                                1}
                                                            )
                                                        </span>
                                                        <img
                                                            alt=""
                                                            src={cross}
                                                            onClick={e =>
                                                                removeSelected(
                                                                    e,
                                                                    indexParent,
                                                                    childName,
                                                                    structure,
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                )
                                            )}
                                            {iconExpand}
                                        </div>
                                    ) : null}
                                </ListItem>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit={![managerKey].includes(keyToUse)}
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {repeatBlock(
                                        children,
                                        [indexParent],
                                        keyToUse,
                                        structure,
                                        true,
                                        !radioFilterKeys.includes(keyToUse)
                                    )}
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    );
                }
            }
        );
        return staticCalculationAssumptionsFilters;
    };

    const applyFilters = () => {
        const filtersToApply = {
            structure1: {},
            structure2: {},
            structure3: {},
        };
        const filtersTray = {
            structure1: {},
            structure2: {},
            structure3: {},
        };

        const selectInnerChild = (structureNumber, mainParent = [], mainParentKey = '', isStatic = false) => {
            if (['structure1', 'structure2', 'structure3'].includes(structureNumber))
                mainParent
                    .filter(({ isSelected = false }) => isSelected)
                    .forEach(
                        ({
                            children = [],
                            key = '',
                            id = '',
                            lookup_category,
                            name = '',
                            keyToUse = mainParentKey,
                        }) => {
                            if (children.length) {
                                selectInnerChild(structureNumber, children, keyToUse, isStatic);
                            } else if (stringVals.includes(key)) {
                                filtersToApply[structureNumber][key] = id;
                                filtersTray[structureNumber][key] = [{ id, name, key: mainParentKey, isStatic }];
                            } else if (filtersToApply[structureNumber][key]) {
                                if (key === 'lookup_id') {
                                    if (filtersToApply[structureNumber][key][lookup_category]) {
                                        filtersToApply[structureNumber][key][lookup_category].push(id);
                                        filtersTray[structureNumber][key][lookup_category].push({
                                            id,
                                            name,
                                            key: mainParentKey,
                                            isStatic,
                                        });
                                    } else {
                                        filtersToApply[structureNumber][key][lookup_category] = [id];
                                        filtersTray[structureNumber][key][lookup_category] = [
                                            { id, name, key: mainParentKey, isStatic },
                                        ];
                                    }
                                } else {
                                    filtersToApply[structureNumber][key].push(id);
                                    filtersTray[structureNumber][key].push({
                                        id,
                                        name,
                                        key: mainParentKey,
                                        isStatic,
                                    });
                                }
                            } else {
                                if (key === 'lookup_id') {
                                    filtersToApply[structureNumber][key] = {
                                        [lookup_category]: [id],
                                    };
                                    filtersTray[structureNumber][key] = {
                                        [lookup_category]: [{ id, name, key: mainParentKey, isStatic }],
                                    };
                                } else {
                                    filtersToApply[structureNumber][key] = [id];
                                    filtersTray[structureNumber][key] = [{ id, name, key: mainParentKey, isStatic }];
                                }
                            }
                        }
                    );
        };

        Object.entries(filters).forEach(([structureNumber, strFilters]) => {
            selectInnerChild(structureNumber, strFilters);
        });

        selectInnerChild('structure1', staticFilters[designProgressState], '', true);
        selectInnerChild('structure2', staticFilters[designProgressState], '', true);
        selectInnerChild('structure3', staticFilters[designProgressState], '', true);

        Object.entries(filtersToApply).forEach(([structureNumber, strFilters]) => {
            if (strFilters.lookup_id) {
                filtersToApply[structureNumber].lookup_id = Object.keys(filtersToApply[structureNumber].lookup_id).map(
                    category => filtersToApply[structureNumber].lookup_id[category]
                );
                filtersTray[structureNumber].lookup_id = Object.keys(filtersTray[structureNumber].lookup_id).map(
                    category => filtersTray[structureNumber].lookup_id[category]
                );
            }
        });

        setDrawerOpen(false);
        setMultiFiltersToApply({
            filters: filtersToApply,
            module: designProgressState,
        });
        setMultiFiltersTray({ filters: filtersTray, module: designProgressState });
    };

    const resetAllFilters = () => {
        const resetFilters = (key, isStatic = false) => {
            const updatedFilters = isStatic ? cloneDeep(staticFilters[key]) : cloneDeep(filters[key]);
            updatedFilters.forEach((ele, index) => {
                updatedFilters[index].isSelected = false;

                let children = [];

                if (!['manager'].includes(updatedFilters[index].keyToUse)) {
                    children = updatedFilters[index].children;
                } else {
                    updatedFilters[index].children = children;
                    updatedFilters[index].selectedOption = {};
                }
                updateChildren(children, false);
                if (ele.name === 'counts') {
                    updatedFilters[index].isSelected = true;
                    updatedFilters[index].children = calculation_assumptions.counts.children;
                }
                if (ele.name === 'Archetype_Algorithm') {
                    updatedFilters[index].isSelected = true;
                    updatedFilters[index].children = calculation_assumptions.Archetype_Algorithm.children;
                }
            });

            return updatedFilters;
        };

        updateMultiFilters({
            filters: resetFilters('structure1'),
            module: designProgressState,
            structureNumber: 'structure1',
        });
        updateMultiFilters({
            filters: resetFilters('structure2'),
            module: designProgressState,
            structureNumber: 'structure2',
        });
        updateMultiFilters({
            filters: resetFilters('structure3'),
            module: designProgressState,
            structureNumber: 'structure3',
        });
        updateStaticFilters({
            filters: resetFilters(designProgressState, true),
            module: designProgressState,
        });

        setMultiFiltersToApply({
            filters: {
                structure1: { count_by: 'fte', algorithms: 'conservative' },
                structure2: { count_by: 'fte', algorithms: 'conservative' },
                structure3: { count_by: 'fte', algorithms: 'conservative' },
            },
            module: designProgressState,
        });
        setMultiFiltersTray({
            filters: {
                structure1: {
                    count_by: [{ id: 'fte', name: 'FTEs' }],
                    algorithms: [{ id: 'conservative', name: 'Conservative' }],
                },
                structure2: {
                    count_by: [{ id: 'fte', name: 'FTEs' }],
                    algorithms: [{ id: 'conservative', name: 'Conservative' }],
                },
                structure3: {
                    count_by: [{ id: 'fte', name: 'FTEs' }],
                    algorithms: [{ id: 'conservative', name: 'Conservative' }],
                },
            },
            module: designProgressState,
        });
    };

    const active = true;

    const activeStructures =
        designProgressState === 'overall'
            ? designProgressOverallActiveStructures
            : designProgressState === 'functions'
            ? designProgressFunctionsActiveStructures
            : designProgressLeadersActiveStructures;

    return (
        <Drawer anchor={'right'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
            <div className={c('rightSideFilters', { drawerOpen })}>
                <div className="upperContent clearFix">
                    <h3>{filtersEnums.FILTERS}</h3>
                    <div className={c('btn', { active })} onClick={applyFilters}>
                        {filtersEnums.APPLY}
                    </div>
                    <div className={c('resetBtn', { active })} onClick={resetAllFilters}>
                        {filtersEnums.RESET}
                    </div>
                </div>

                <Tabs selected={tabSelected} key={tabSelected}>
                    <Tab title="Characteristics">
                        <Tabs selected={0} key={0} classes="inner-tabs">
                            <Tab
                                hoverTitle={
                                    activeStructures.structure1.name.length > 13 ? activeStructures.structure1.name : ''
                                }
                                title={`${(activeStructures.structure1.name.length > 13
                                    ? `${activeStructures.structure1.name.substring(0, 13)}...`
                                    : activeStructures.structure1.name) || 'Structure 1'}`}
                                disabled={filters['structure1'].length <= 0}
                            >
                                <Typography>
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        className="filterList"
                                    >
                                        {getDynamicFilters('structure1')}
                                    </List>
                                </Typography>
                            </Tab>
                            <Tab
                                hoverTitle={
                                    activeStructures.structure2.name.length > 13 ? activeStructures.structure2.name : ''
                                }
                                title={`${(activeStructures.structure2.name.length > 13
                                    ? `${activeStructures.structure2.name.substring(0, 13)}...`
                                    : activeStructures.structure2.name) || 'Structure 2'}`}
                                disabled={filters['structure2'].length <= 0}
                            >
                                <Typography>
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        className="filterList"
                                    >
                                        {getDynamicFilters('structure2')}
                                    </List>
                                </Typography>
                            </Tab>
                            <Tab
                                hoverTitle={
                                    activeStructures.structure3.name.length > 13 ? activeStructures.structure3.name : ''
                                }
                                title={`${(activeStructures.structure3.name.length > 13
                                    ? `${activeStructures.structure3.name.substring(0, 13)}...`
                                    : activeStructures.structure3.name) || 'Structure 3'}`}
                                disabled={filters['structure3'].length <= 0}
                            >
                                <Typography>
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        className="filterList"
                                    >
                                        {getDynamicFilters('structure3')}
                                    </List>
                                </Typography>
                            </Tab>
                        </Tabs>
                    </Tab>

                    {showStaticFilters ? (
                        <Tab title="Manager Analysis" disabled={staticFilters[designProgressState].length <= 0}>
                            <Typography>
                                <List component="nav" aria-labelledby="nested-list-subheader" className="filterList">
                                    {getStaticManagersFilters()}
                                </List>
                            </Typography>
                        </Tab>
                    ) : (
                        <Tab />
                    )}

                    {showStaticFilters ? (
                        <Tab title="Calculation Assumptions" disabled={staticFilters[designProgressState].length <= 0}>
                            <Typography>
                                <List component="nav" aria-labelledby="nested-list-subheader" className="filterList">
                                    {getStaticCalculationAssumptionsFilters()}
                                </List>
                            </Typography>
                        </Tab>
                    ) : (
                        <Tab />
                    )}
                </Tabs>
            </div>
        </Drawer>
    );
}

export default DesignFilters;
