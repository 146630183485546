import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const PurpleSwitch = withStyles({
    switchBase: {
        color: '#0c5978',
        '&$checked': {
            color: '#093d52',
        },
        '&$checked + $track': {
            backgroundColor: '#093d52',
        },
    },
    checked: {},
    track: {},
})(Switch);

function Toggle({ labelLeft, labelRight, onChange }) {
    return (
        <>
            {labelLeft} <PurpleSwitch name="checkedA" onChange={onChange} /> {labelRight}
        </>
    );
}

export default Toggle;
