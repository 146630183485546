import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { fromJS } from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

function configureStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware();
    const middleWares = [sagaMiddleware, thunkMiddleware];
    const store = createStore(rootReducer, fromJS(initialState), composeWithDevTools(applyMiddleware(...middleWares)));
    sagaMiddleware.run(rootSaga);
    return store;
}

export default configureStore();
