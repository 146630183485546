import React, { Fragment } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Shimmer from '../../../../components/shimmer';
import NoDataFound from '../../../../components/NoDataFound';
import executiveDashboard from '../../executiveDashboard.enum';
import './index.scss';
import formattedUtilities from '../../../../common/utilities';
import { spanOfControlConfig, spanOfControlPriorityMatrix } from '../configs/spanOfControl.config';

function SpanOfControl({
    isFiltersApplied,
    countFilterValue,
    isLoading,
    in_range,
    too_few,
    too_many,
    unknown,
    managers,
}) {
    const getSpanOfControl = () => {
        const spans = [
            { span: 'in_range', fte: in_range },
            { span: 'too_few', fte: too_few },
            { span: 'too_many', fte: too_many },
            { span: 'unknown', fte: unknown },
        ];

        return spans.map(el => {
            return {
                name: spanOfControlPriorityMatrix[el.span].title,
                key: el.span,
                y: el.fte,
                color: spanOfControlPriorityMatrix[el.span].color,
            };
        });
    };

    const showLegends = () => {
        return (
            <div>
                <div className="span-of-control__legend-groups">
                    <section className="legend-row">
                        <div className="legend-row__item">
                            <div
                                className="circle"
                                style={{ background: `${spanOfControlPriorityMatrix.too_many.color}` }}
                            />
                            <div>
                                <div className="label">{executiveDashboard.MANAGING_TOO_MANY}</div>{' '}
                                <div className="value">
                                    {formattedUtilities.floatIntValueFormatter(too_many).toLocaleString()}
                                </div>
                            </div>
                        </div>
                        <div className="legend-row__item">
                            <div
                                className="circle"
                                style={{ background: `${spanOfControlPriorityMatrix.in_range.color}` }}
                            />
                            <div>
                                <div className="label">{executiveDashboard.MANAGING_IN_RANGE}</div>{' '}
                                <div className="value">
                                    {formattedUtilities.floatIntValueFormatter(in_range).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="legend-row">
                        <div className="legend-row__item">
                            <div
                                className="circle"
                                style={{ background: `${spanOfControlPriorityMatrix.too_few.color}` }}
                            />
                            <div>
                                <div className="label">{executiveDashboard.MANAGING_TOO_FEW}</div>{' '}
                                <div className="value">
                                    {formattedUtilities.floatIntValueFormatter(too_few).toLocaleString()}
                                </div>
                            </div>
                        </div>
                        <div className="legend-row__item">
                            <div
                                className="circle"
                                style={{ background: `${spanOfControlPriorityMatrix.unknown.color}` }}
                            />
                            <div>
                                <div className="label">{executiveDashboard.UNKNOWN}</div>{' '}
                                <div className="value">
                                    {formattedUtilities.floatIntValueFormatter(unknown).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="span-of-control__total">
                    <span className="label">{`Total ${countFilterValue}`} </span>
                    <span className="value">
                        {formattedUtilities.floatIntValueFormatter(managers).toLocaleString()}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {isLoading ? (
                <Shimmer />
            ) : managers > 0 ? (
                <div className="span-of-control">
                    <div className="span-of-control__chart" data-testid="span-of-control__chart">
                        <HighchartsReact
                            options={{
                                ...spanOfControlConfig,
                                tooltip: {
                                    useHTML: true,
                                    formatter: function() {
                                        return `${this.point.name} : ${this.point.y}`;
                                    },
                                },
                                series: [
                                    {
                                        data: getSpanOfControl(),
                                    },
                                ],
                            }}
                            highcharts={Highcharts}
                        />
                    </div>
                    <div className="span-of-control__legend">{showLegends()}</div>
                </div>
            ) : (
                <NoDataFound
                    mainMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                    }`}
                    secondaryMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                    }`}
                />
            )}
        </Fragment>
    );
}

export default SpanOfControl;
