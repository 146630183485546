import { createSelector } from 'reselect';

const login = state => state.get('login');

const selectLoginLoading = () => createSelector(login, state => state.toJS().isLoading);
const selectError = () => createSelector(login, state => state.toJS().error);
const selectPhasesFetched = () => createSelector(login, state => state.toJS().isPhasesFetched);
const getPhases = () => createSelector(login, state => state.toJS().phases);
const selectExpiryTime = () => createSelector(login, state => state.toJS().expiryTime);
const selectPrivileges = () => createSelector(login, state => state.toJS().privileges);
const selectPrivilegesFetched = () => createSelector(login, state => state.toJS().isPrivilegesFetched);
const selectAnalyticsAllowed = () => createSelector(login, state => state.toJS().isAnalyticsAllowed);
const selectSummaryReportAllowed = () => createSelector(login, state => state.toJS().isSummaryReportAllowed);
const selectExecutiveDashboardAllowed = () => createSelector(login, state => state.toJS().isExecutiveDashboardAllowed);
const selectAnalyticsClientFetched = () => createSelector(login, state => state.toJS().isAnalyticsClientFetched);

export {
    selectLoginLoading,
    selectError,
    selectPhasesFetched,
    getPhases,
    selectExpiryTime,
    selectPrivileges,
    selectAnalyticsAllowed,
    selectSummaryReportAllowed,
    selectExecutiveDashboardAllowed,
    selectAnalyticsClientFetched,
    selectPrivilegesFetched,
};
