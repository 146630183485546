const titleDistributionConfig = {
    credits: {
        enabled: false,
    },

    yAxis: {
        allowDecimals: false,
        opposite: false,
        min: 0,
        gridLineColor: '#197F07',
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
            {
                color: '#000000',
                width: 1,
                value: 0,
            },
        ],
        title: {
            text: '<span style="font-weight:bold">Total FTEs</span>',
        },
    },
    title: {
        text: '',
    },
    legend: {
        enabled: false,
    },
};

export default titleDistributionConfig;
