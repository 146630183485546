import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomToolTip = withStyles({
    tooltip: {
        backgroundColor: 'white',
        color: '#000000',
        maxWidth: 300,
        fontSize: 12,
        borderRadius: '10px',
        padding: '10px 20px',
        boxShadow: '0 0 5px 5px #f7f7f7',
    },
})(Tooltip);

export default CustomToolTip;
