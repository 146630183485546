import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HC_more from 'highcharts/highcharts-more';
import './index.scss';
import { FormControlLabel } from '@material-ui/core';
import SpanOfControl from './SpanOfControl';
import Card from '../../../components/Card';
import executiveDashboard from '../executiveDashboard.enum';
import SpanDistribution from './SpanDistribution';
import Stacking from './Stacking';
import FunctionalDistribution from './FunctionalDistribution';
import Stats from './Stats';
import Layers from './Layers';
import OrgShape from './org-shape';
import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tabs/Tab';
import N1Leaders from './N1Leaders';
import Functions from './Functions';

import { getOrgFilters, getAnalyticsApi } from '../../StructureContent/apis';
import { getAnalyticsFilterString } from '../../../common/utilities';
import OrgShapeN1Leaders from './org-shape/N1Leaders';
import OrgShapeEnterprise from './org-shape/Enterprise';
import { IOSSwitch } from './org-shape/styles';
import OrgShapeEnterpriseLeaders from './org-shape/EnterpriseLeaders';
import Distribution from './Distribution';

HC_more(Highcharts);

function Analysis({
    filtersToApply,
    structureId,
    refreshCount,
    phaseId,
    privileges,
    getOrgshapeFunctionsData,
    getOrgshapeLeadersData,
    dsPercentage,
    setDsPercentage
}) {
    const [countFilterValue, setCountFilterValue] = useState('Headcount');
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({});
    const [layers, setLayers] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [showEnterprise, setShowEnterprise] = useState(false);

    const handleChange = event => {
        setShowEnterprise(event.target.checked);
    };

    useEffect(() => {
        const countFilter = filtersToApply.count_by;
        if (countFilter === 'fte') {
            setCountFilterValue('FTEs');
        } else {
            setCountFilterValue('HeadCounts');
        }

        setIsLoading(true);
        const filterString = getAnalyticsFilterString({ ...filtersToApply, structureId });
        getOrgFilters({ structureId, method: 'filterFunctions', filterString: `page[size]=1000` })
            .then(({ data }) => {
                const sortedFunctionsData = data.data
                    .slice()
                    .sort((a, b) =>
                        a.attributes.name > b.attributes.name ? 1 : b.attributes.name > a.attributes.name ? -1 : 0
                    );
                setFunctions(sortedFunctionsData);
                setIsLoading(false);
            })
            .catch(() => {
                setFunctions([]);
                setIsLoading(false);
            });
        getAnalyticsApi({ method: 'layersJson', filterString })
            .then(({ data }) => {
                const {
                    managers,
                    in_range,
                    too_few,
                    too_many,
                    unknown,
                    fteCount,
                    currentSpan,
                    idealSpan,
                    managerCost,
                    idealManagers,
                    nonManagerCost,
                    managerOpportunity,
                    costOpportunity,
                } = data.totals;
                setStats({
                    managers,
                    in_range,
                    too_few,
                    too_many,
                    unknown,
                    fteCount,
                    currentSpan,
                    idealSpan,
                    managerCost,
                    idealManagers,
                    nonManagerCost,
                    managerOpportunity,
                    costOpportunity,
                });

                setLayers(data.layers);
                setIsLoading(false);
            })
            .catch(() => {
                setStats({});
                setIsLoading(false);
            });
    }, [JSON.stringify(filtersToApply), refreshCount, structureId]);


    const isFiltersApplied = () => {
        return Object.keys(filtersToApply).length > 0;
    };

    const renderDistribution = useCallback(() => {
        return (
            <div className="report__half-view">
                <section className="org-shape">
                    <Card title={executiveDashboard.DISTRIBUTION} classes="has-layer">
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    checked={dsPercentage}
                                    onChange={e => setDsPercentage(e.target.checked)}
                                    name="Show in percentage"
                                />
                            }
                            label="Show in percentage"
                        />
                        <Tabs>
                            <Tab title={executiveDashboard.BENCHMARK_TITLE}>
                                <Distribution
                                    filters={filtersToApply}
                                    structureId={structureId}
                                    isFiltersApplied={isFiltersApplied()}
                                    refreshCount={refreshCount}
                                    showPercentage={dsPercentage}
                                    isPayDs={false}
                                />
                            </Tab>
                            <Tab title={executiveDashboard.JOB_OR_PAY_GRADE}>
                                <Distribution
                                    filters={filtersToApply}
                                    structureId={structureId}
                                    isFiltersApplied={isFiltersApplied()}
                                    refreshCount={refreshCount}
                                    showPercentage={dsPercentage}
                                    isPayDs
                                />
                            </Tab>
                        </Tabs>
                    </Card>
                </section>
            </div>
        )
    }, [setDsPercentage, dsPercentage, filtersToApply, structureId, isFiltersApplied, refreshCount]);

    return (
        <Fragment>
            <div className="analysis-report">
                <div className="report__half-view stats">
                    <Stats isLoading={isLoading} countFilterValue={countFilterValue} {...stats} />
                </div>
                <div className="report__half-view">
                    <section>
                        <Card title={executiveDashboard.SPAN_OF_CONTROL}>
                            <SpanOfControl
                                countFilterValue={countFilterValue}
                                isFiltersApplied={isFiltersApplied()}
                                isLoading={isLoading}
                                {...stats}
                            />
                        </Card>
                    </section>
                    <section>
                        <Card title={executiveDashboard.SPAN_DISTRIBUTION_FOR_ENTIRE_COMPANY}>
                            <SpanDistribution
                                filters={filtersToApply}
                                structureId={structureId}
                                countFilterValue={countFilterValue}
                                isFiltersApplied={isFiltersApplied()}
                                refreshCount={refreshCount}
                            />
                        </Card>
                    </section>
                </div>
                <div className="report__half-view">
                    <section className="avg-layers">
                        <Card title={executiveDashboard.AVERAGE_SPAN_LAYER} classes="has-layer">
                            <Layers
                                {...stats}
                                layers={layers}
                                isFiltersApplied={isFiltersApplied()}
                                isLoading={isLoading}
                            />
                        </Card>
                    </section>
                </div>
                {privileges?.isAdminAccess ? (
                    <>
                        <div className="report__half-view">
                            <section className="org-shape">
                                <Card title={executiveDashboard.ORG_SHAPE} classes="has-layer">
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={showEnterprise}
                                                onChange={handleChange}
                                                name="Show Enterprise"
                                            />
                                        }
                                        label="Show Enterprise"
                                    />
                                    <Tabs style={{ position: 'relative' }}>
                                        <Tab title={executiveDashboard.N1_LEADERS}>
                                            {showEnterprise ? (
                                                <OrgShapeEnterpriseLeaders
                                                    {...stats}
                                                    phaseId={phaseId}
                                                    structureId={structureId}
                                                    // functions={functions}
                                                    filters={filtersToApply}
                                                    layers={layers}
                                                    isFiltersApplied={isFiltersApplied()}
                                                    isLoading={isLoading}
                                                    getOrgshapeLeadersData={getOrgshapeLeadersData}
                                                    countFilterValue={countFilterValue}
                                                />
                                            ) : (
                                                <OrgShapeN1Leaders
                                                    {...stats}
                                                    phaseId={phaseId}
                                                    structureId={structureId}
                                                    filters={filtersToApply}
                                                    layers={layers}
                                                    isFiltersApplied={isFiltersApplied()}
                                                    isLoading={isLoading}
                                                    getOrgshapeLeadersData={getOrgshapeLeadersData}
                                                    countFilterValue={countFilterValue}
                                                />
                                            )}
                                        </Tab>
                                        <Tab title={executiveDashboard.FUNCTIONS}>
                                            {showEnterprise ? (
                                                <OrgShapeEnterprise
                                                    {...stats}
                                                    phaseId={phaseId}
                                                    structureId={structureId}
                                                    // functions={functions}
                                                    filters={filtersToApply}
                                                    layers={layers}
                                                    isFiltersApplied={isFiltersApplied()}
                                                    isLoading={isLoading}
                                                    getOrgshapeFunctionsData={getOrgshapeFunctionsData}
                                                    countFilterValue={countFilterValue}
                                                />
                                            ) : (
                                                <OrgShape
                                                    {...stats}
                                                    phaseId={phaseId}
                                                    structureId={structureId}
                                                    functions={functions}
                                                    filters={filtersToApply}
                                                    layers={layers}
                                                    isFiltersApplied={isFiltersApplied()}
                                                    isLoading={isLoading}
                                                    getOrgshapeFunctionsData={getOrgshapeFunctionsData}
                                                    countFilterValue={countFilterValue}
                                                />
                                            )}
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </section>
                        </div>
                        <div className="report__half-view">
                            <section className="opportunity-overview">
                                <Card title={executiveDashboard.OPPORTUNITY_OVERVIEW}>
                                    <Tabs>
                                        <Tab title={executiveDashboard.N1_LEADERS}>
                                            <N1Leaders
                                                structureId={structureId}
                                                filters={filtersToApply}
                                                countFilterValue={countFilterValue}
                                                isFiltersApplied={isFiltersApplied()}
                                                refreshCount={refreshCount}
                                            />
                                        </Tab>
                                        <Tab title={executiveDashboard.FUNCTIONS}>
                                            <Functions
                                                structureId={structureId}
                                                filters={filtersToApply}
                                                countFilterValue={countFilterValue}
                                                isFiltersApplied={isFiltersApplied()}
                                                refreshCount={refreshCount}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </section>
                        </div>
                        {renderDistribution()}
                        <div className="report__half-view">
                            <section className="function_distribution_section">
                                <Card title={executiveDashboard.FUNCTIONAL_DISTRIBUTION} hasScroll>
                                    <FunctionalDistribution
                                        filters={filtersToApply}
                                        structureId={structureId}
                                        countFilterValue={countFilterValue}
                                        isFiltersApplied={isFiltersApplied()}
                                        refreshCount={refreshCount}
                                    />
                                </Card>
                            </section>
                            <section className="stacking_section">
                                <Card title={executiveDashboard.STACKING} hasScroll>
                                    <Stacking
                                        filters={filtersToApply}
                                        structureId={structureId}
                                        isFiltersApplied={isFiltersApplied()}
                                        refreshCount={refreshCount}
                                    />
                                </Card>
                            </section>
                        </div>
                    </>
                ) : privileges?.executiveDashboardOrgShapeAccess ? (
                    <div className="report__half-view">
                        <section className="org-shape">
                            <Card title={executiveDashboard.ORG_SHAPE} classes="has-layer">
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={showEnterprise}
                                            onChange={handleChange}
                                            name="Show Enterprise"
                                        />
                                    }
                                    label="Show Enterprise"
                                />
                                <Tabs>
                                    <Tab title={executiveDashboard.N1_LEADERS}>
                                        {showEnterprise ? (
                                            <OrgShapeEnterpriseLeaders
                                                {...stats}
                                                phaseId={phaseId}
                                                structureId={structureId}
                                                filters={filtersToApply}
                                                layers={layers}
                                                isFiltersApplied={isFiltersApplied()}
                                                isLoading={isLoading}
                                                getOrgshapeLeadersData={getOrgshapeLeadersData}
                                                countFilterValue={countFilterValue}
                                            />
                                        ) : (
                                            <OrgShapeN1Leaders
                                                {...stats}
                                                phaseId={phaseId}
                                                structureId={structureId}
                                                filters={filtersToApply}
                                                layers={layers}
                                                isFiltersApplied={isFiltersApplied()}
                                                isLoading={isLoading}
                                                getOrgshapeLeadersData={getOrgshapeLeadersData}
                                                countFilterValue={countFilterValue}
                                            />
                                        )}
                                    </Tab>
                                    <Tab title={executiveDashboard.FUNCTIONS}>
                                        {showEnterprise ? (
                                            <OrgShapeEnterprise
                                                {...stats}
                                                phaseId={phaseId}
                                                structureId={structureId}
                                                // functions={functions}
                                                filters={filtersToApply}
                                                layers={layers}
                                                isFiltersApplied={isFiltersApplied()}
                                                isLoading={isLoading}
                                                getOrgshapeFunctionsData={getOrgshapeFunctionsData}
                                                countFilterValue={countFilterValue}
                                            />
                                        ) : (
                                            <OrgShape
                                                {...stats}
                                                phaseId={phaseId}
                                                structureId={structureId}
                                                functions={functions}
                                                filters={filtersToApply}
                                                layers={layers}
                                                isFiltersApplied={isFiltersApplied()}
                                                isLoading={isLoading}
                                                getOrgshapeFunctionsData={getOrgshapeFunctionsData}
                                                countFilterValue={countFilterValue}
                                            />
                                        )}
                                    </Tab>
                                </Tabs>
                            </Card>
                        </section>
                    </div>
                ) : (
                    <>
                        <div className="report__half-view">
                            <section className="opportunity-overview">
                                <Card title={executiveDashboard.OPPORTUNITY_OVERVIEW}>
                                    <Tabs>
                                        <Tab title={executiveDashboard.N1_LEADERS}>
                                            <N1Leaders
                                                structureId={structureId}
                                                filters={filtersToApply}
                                                countFilterValue={countFilterValue}
                                                isFiltersApplied={isFiltersApplied()}
                                                refreshCount={refreshCount}
                                            />
                                        </Tab>
                                        <Tab title={executiveDashboard.FUNCTIONS}>
                                            <Functions
                                                structureId={structureId}
                                                filters={filtersToApply}
                                                countFilterValue={countFilterValue}
                                                isFiltersApplied={isFiltersApplied()}
                                                refreshCount={refreshCount}
                                            />
                                        </Tab>
                                    </Tabs>
                                </Card>
                            </section>
                        </div>
                        {renderDistribution()}
                        <div className="report__half-view">
                            <section className="function_distribution_section">
                                <Card title={executiveDashboard.FUNCTIONAL_DISTRIBUTION} hasScroll>
                                    <FunctionalDistribution
                                        filters={filtersToApply}
                                        structureId={structureId}
                                        countFilterValue={countFilterValue}
                                        isFiltersApplied={isFiltersApplied()}
                                        refreshCount={refreshCount}
                                    />
                                </Card>
                            </section>
                            <section className="stacking_section">
                                <Card title={executiveDashboard.STACKING} hasScroll>
                                    <Stacking
                                        filters={filtersToApply}
                                        structureId={structureId}
                                        isFiltersApplied={isFiltersApplied()}
                                        refreshCount={refreshCount}
                                    />
                                </Card>
                            </section>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
}

export default Analysis;
