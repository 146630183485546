import React from 'react';
import { ERROR_400_MSG } from '../../utils/constants';
import './404.scss';

function Page404() {
    return (
        <div className="page-not-found">
            <div className="page-not-found__label">Oops, page not found</div>
            <div className="page-not-found__label-info">{ERROR_400_MSG}</div>
        </div>
    );
}

export default Page404;
