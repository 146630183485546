import XLSX from 'xlsx';
import addressableOpportunityConst from './addressableOpportunity.enum';
import {
    addressableOpportunityExcelColumns as cols,
    row_2_col_2,
    row_3_col_2,
    row_4_col_2,
} from './addrOppExcelColumns.enum';

const DOWNLOAD_FILE_NAME = 'report';

/**
 * Used to generate an excel report of the final data
 * @author Karan Kumar
 */
export default function generateExcelFile(userInput, outputResult, fteTotal, costTotal) {
    const exportedFileData = [
        // row 1
        {
            [cols.col_1_adjustment]: addressableOpportunityConst.outputTableFirstRow[0],
            [cols.col_2_rationale]: '',
            [cols.col_3_assumption]: '',
            [cols.col_4_fte_opp]: outputResult?.too_few?.fte,
            [cols.col_5_cost_opp]: outputResult?.too_few?.cost,
        },
        // row 2
        {
            [cols.col_1_adjustment]: addressableOpportunityConst.outputTableSecondRow,
            [cols.col_2_rationale]: row_2_col_2((userInput?.overspan_manager || 0) / 100),
            [cols.col_3_assumption]: userInput?.overspan_manager + '%',
            [cols.col_4_fte_opp]: outputResult?.overspan_manager?.fte,
            [cols.col_5_cost_opp]: outputResult?.overspan_manager?.cost,
        },
        // row 3
        {
            [cols.col_1_adjustment]: addressableOpportunityConst.outputTableThirdRow[0],
            [cols.col_2_rationale]: row_3_col_2(userInput?.geo_exception || '-'),
            [cols.col_3_assumption]: userInput?.overspan_manager + '%',
            [cols.col_4_fte_opp]: outputResult?.overspan_manager?.fte,
            [cols.col_5_cost_opp]: outputResult?.overspan_manager?.cost,
        },
        // row 4
        {
            [cols.col_1_adjustment]: addressableOpportunityConst.outputTableFourthRow,
            [cols.col_2_rationale]: row_4_col_2((userInput?.hidden_players || 0) / 100),
            [cols.col_3_assumption]: userInput?.hidden_players + '%',
            [cols.col_4_fte_opp]: outputResult?.hidden_player?.fte,
            [cols.col_5_cost_opp]: outputResult?.hidden_player?.cost,
        },
        // row 5 outputTableFifthRow
        {
            [cols.col_1_adjustment]: addressableOpportunityConst.outputTableFifthRow,
            [cols.col_2_rationale]:
                "People leaders' individual work needs to be preserved even if the role is eliminated. Below is the estimated individual work done by each archetype:",
            [cols.col_3_assumption]: '',
            [cols.col_4_fte_opp]: '',
            [cols.col_5_cost_opp]: '',
        },
        // row 5 : sub row 1
        {
            [cols.col_1_adjustment]: '',
            [cols.col_2_rationale]: 'Player Coach',
            [cols.col_3_assumption]: (userInput.archetypes || [])[0] + '%',
            [cols.col_4_fte_opp]: outputResult?.player_coach?.fte,
            [cols.col_5_cost_opp]: outputResult?.player_coach?.cost,
        },
        // row 5 : sub row 2
        {
            [cols.col_1_adjustment]: '',
            [cols.col_2_rationale]: 'Coach',
            [cols.col_3_assumption]: (userInput.archetypes || [])[1] + '%',
            [cols.col_4_fte_opp]: outputResult?.coach?.fte,
            [cols.col_5_cost_opp]: outputResult?.coach?.cost,
        },
        // row 5 : sub row 3
        {
            [cols.col_1_adjustment]: '',
            [cols.col_2_rationale]: 'Supervisor',
            [cols.col_3_assumption]: (userInput.archetypes || [])[2] + '%',
            [cols.col_4_fte_opp]: outputResult?.supervisor?.fte,
            [cols.col_5_cost_opp]: outputResult?.supervisor?.cost,
        },
        // row 5 : sub row 4
        {
            [cols.col_1_adjustment]: '',
            [cols.col_2_rationale]: 'Facilitator',
            [cols.col_3_assumption]: (userInput.archetypes || [])[3] + '%',
            [cols.col_4_fte_opp]: outputResult?.facilitator?.fte,
            [cols.col_5_cost_opp]: outputResult?.facilitator?.cost,
        },
        // row 5 : sub row 5
        {
            [cols.col_1_adjustment]: '',
            [cols.col_2_rationale]: 'Coordinator',
            [cols.col_3_assumption]: (userInput.archetypes || [])[4] + '%',
            [cols.col_4_fte_opp]: outputResult?.coordinator?.fte,
            [cols.col_5_cost_opp]: outputResult?.coordinator?.cost,
        },
        // row 5 : sub row 6
        {
            [cols.col_1_adjustment]: '',
            [cols.col_2_rationale]: 'Total:',
            [cols.col_3_assumption]: '',
            [cols.col_4_fte_opp]: parseFloat(
                outputResult?.player_coach?.fte +
                    outputResult?.coach?.fte +
                    outputResult?.supervisor?.fte +
                    outputResult?.facilitator?.fte +
                    outputResult?.coordinator?.fte
            ).toFixed(2),
            [cols.col_5_cost_opp]: '',
        },
        // final row (total)
        {
            [cols.col_1_adjustment]: 'Adjustment Opportunity',
            [cols.col_2_rationale]: '',
            [cols.col_3_assumption]: '',
            [cols.col_4_fte_opp]: fteTotal,
            [cols.col_5_cost_opp]: costTotal,
        },
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportedFileData);
    XLSX.utils.book_append_sheet(wb, ws, 'Summary');
    XLSX.writeFile(wb, `${DOWNLOAD_FILE_NAME}.xlsx`);

    // exportFromJSON({ data: exportedFileData, fileName: DOWNLOAD_FILE_NAME, exportType: 'xls' })
}
