import React from 'react';
import './index.scss';

const DesignStructures = ({ activeStructures, leader }) => {
    return (
        <div className="design-structures">
            {activeStructures[leader.elem].id && (
                <div>
                    <span className="label" title={activeStructures[leader.elem].name}>
                        {activeStructures[leader.elem].name}
                    </span>
                </div>
            )}
        </div>
    );
};

export default DesignStructures;
