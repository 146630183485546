import React, { Fragment } from 'react';
import Badge from '../../../../../components/Badge';
import executiveDashboard from '../../../executiveDashboard.enum';
import './index.scss';
import { getBadgeType, getPercentage } from '../../../../../common/utilities';
import employee from '../../../../../assets/employeeIcon.svg';
import fadedEmployee from '../../../../../assets/fadedEmployeeIcon.svg';

const FTEs = ({ data, isTargetVisible, ranges, target }) => {
    let maxValue = target;
    const getIcons = (len, iconType) => {
        const icons = [];
        for (let i = 0; i < len; i += 1) {
            if (iconType === 'employee') {
                icons.push(<img alt="employee" src={employee} className="employee" />);
            }
            if (iconType === 'fadedEmployee') {
                icons.push(<img alt="fadedEmployee" src={fadedEmployee} className="employee" />);
            }
        }
        return icons;
    };

    const getTargetIconsCount = target => {
        return target ? (Math.ceil((target / maxValue) * 10) > 10 ? 10 : Math.ceil((target / maxValue) * 10)) : 8;
    };
    return (
        <div className="design-progress-ftes">
            {Object.keys(data).map((el, index) => {
                const { id, name, value, shouldShow, max } = data[el];
                maxValue = target > max ? target : max;
                const iconsCount = value
                    ? Math.ceil((value / maxValue) * 10) > 10
                        ? 10
                        : Math.ceil((value / maxValue) * 10)
                    : 8;
                const iconType = value ? 'employee' : 'fadedEmployee';
                return (
                    <section key={`fte_${id}${index}`} className={`ftes__structure ${shouldShow ? '' : 'hidden'}`}>
                        <div className="ftes__structure-name">
                            <div className="label">{name}</div>
                            <div className="value">{value ? value.toFixed(1) : '-'}</div>
                        </div>
                        <div className="ftes__structure-progress">{getIcons(iconsCount, iconType)}</div>

                        <div className="ftes__structure-percentage">
                            {target > 0 && value > 0 && (
                                <Fragment>
                                    {!index && <div className="difference-target">(%) of the target</div>}
                                    <Badge
                                        type={getBadgeType(value, target, ranges)}
                                        percentage={`${getPercentage(value, target)}%`}
                                    />
                                </Fragment>
                            )}
                        </div>
                    </section>
                );
            })}
            <section className={`ftes__structure ${target || isTargetVisible ? '' : 'hidden'}`}>
                <div className="ftes__structure-name">
                    <div className="label">{executiveDashboard.TARGET_TEXT}</div>
                    <div className="value">{target ? Number(target).toFixed(1) : '~'}</div>
                </div>
                <div className="ftes__structure-progress">
                    {getIcons(getTargetIconsCount(target), target ? 'employee' : 'fadedEmployee')}
                </div>
                <div className="ftes__structure-percentage" />
            </section>
        </div>
    );
};

export default FTEs;
