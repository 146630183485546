import React, { useEffect } from 'react';
import './index.scss';
import formattedUtilities from '../../../../common/utilities';

const Headers = {
    chapter_name: {
        label: 'CHAPTERS',
        name: 'Chapters',
        flex: 1.5,
    },
    supply: {
        label: 'SUPPLY',
        name: 'Supply',
        flex: 1,
    },
    staffed: {
        label: 'STAFFED',
        name: 'Staffed',
        flex: 1,
    },
    less_than_100_allocated: { label: '<100% Allocated', name: '<100% Allocated', flex: 1 },
    equal_to_100_allocated: { label: '=100% ALLOCATED', name: '=100% Allocated', flex: 1 },
    more_than_100_allocated: { label: '>100% ALLOCATED', name: '>100% Allocated', flex: 1 },
    team_name: {
        label: 'TEAM',
        name: 'Team',
        flex: 1,
    },
};

const StaffedView = ({ staffed, distributionViewClickHandle, teamsMap, distributionTableData }) => {
    useEffect(() => {
        distributionViewClickHandle('staffed');
    }, []);

    return (
        <div className="staffed-view">
            <div className="staffed-view__headers">
                {Object.values(Headers).map(el => {
                    return (
                        <div className="header-item" style={{ flex: el.flex }}>
                            <span style={{ padding: '0 10px' }}>{el.name}</span>
                        </div>
                    );
                })}
            </div>
            <div className="staffed-view__scrollable">
                <div className="staffed-view__content">
                    {Object.keys(staffed).map(el => {
                        return (
                            <div className="row">
                                <div className="row-item" style={{ flex: Headers['chapter_name'].flex }}>
                                    {staffed[el].chapter_name}
                                </div>
                                <div className="row-item" style={{ flex: Headers['supply'].flex }}>
                                    {staffed[el].supply.length}
                                </div>
                                <div className="row-item" style={{ flex: Headers['staffed'].flex }}>
                                    {staffed[el].staffed || 0} roles
                                </div>
                                <div className="row-item" style={{ flex: Headers['less_than_100_allocated'].flex }}>
                                    {staffed[el].less_than_100_allocated}
                                </div>
                                <div className="row-item" style={{ flex: Headers['equal_to_100_allocated'].flex }}>
                                    {staffed[el].equal_to_100_allocated}
                                </div>
                                <div className="row-item" style={{ flex: Headers['more_than_100_allocated'].flex }}>
                                    {staffed[el].more_than_100_allocated}
                                </div>
                                <div className="row-item multiple" style={{ flex: 1 }}>
                                    {staffed[el].teams.map(elem => {
                                        const _path = formattedUtilities.generateParentNodesInList(
                                            distributionTableData,
                                            'id',
                                            elem.id
                                        );
                                        let string = '';
                                        const _arr = _path.split(' ');
                                        for (let i = 0; i < _arr.length; i++) {
                                            if (_arr[i] !== '') {
                                                if (string === '') {
                                                    string += teamsMap[_arr[i]].name;
                                                } else {
                                                    string = string + ' - ' + teamsMap[_arr[i]].name;
                                                }
                                            }
                                        }

                                        return (
                                            <div className="inner-column" title={string} style={{ cursor: 'pointer' }}>
                                                {elem.team_name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default StaffedView;
