import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import DesignProgress from './index';
import {
    getSelectedTargets,
    selectDesignProgressEditMode,
    selectDesignProgressLeadersActiveStructures,
    selectDesignProgressFunctionsActiveStructures,
    selectDesignProgressLeadersRanges,
    selectDesignProgressFunctionsRanges,
    selectDesignProgressOverallActiveStructures,
    selectDesignProgressOverallRanges,
    selectStructures,
} from '../../StructureContent/selector';
import {
    getTargets,
    setDesignProgressEditMode,
    setDesignProgressLeadersActiveStructures,
    setDesignProgressOverallActiveStructures,
    setDesignProgressFunctionsActiveStructures,
    setTargets,
    updateRanges,
    updateTargets,
    setDesignProgressState,
} from '../../StructureContent/actions';
import {
    getSelectedStructureFilter,
    getStaticFilters,
    updateMultiFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
} from '../../Filters/actions/actions';
import {
    selectDesignProgressLoading,
    selectRefreshCount,
    selectMultiFiltersToApply,
    selectStaticFilters,
    selectMultiFiltersTray,
} from '../../Filters/selectors/selector';
import { selectPrivileges } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    structures: selectStructures(),
    overallFiltersToApply: selectMultiFiltersToApply('overall'),
    leadersFiltersToApply: selectMultiFiltersToApply('leaders'),
    functionsFiltersToApply: selectMultiFiltersToApply('functions'),
    overallFiltersTray: selectMultiFiltersTray('overall'),
    leadersFiltersTray: selectMultiFiltersTray('leaders'),
    functionsFiltersTray: selectMultiFiltersTray('functions'),
    refreshCount: selectRefreshCount(),
    designProgressEditMode: selectDesignProgressEditMode(),
    designProgressOverallActiveStructures: selectDesignProgressOverallActiveStructures(),
    designProgressLeadersActiveStructures: selectDesignProgressLeadersActiveStructures(),
    designProgressFunctionsActiveStructures: selectDesignProgressFunctionsActiveStructures(),
    designProgressOverallRanges: selectDesignProgressOverallRanges(),
    designProgressLeadersRanges: selectDesignProgressLeadersRanges(),
    designProgressFunctionsRanges: selectDesignProgressFunctionsRanges(),
    targets: getSelectedTargets(),
    privileges: selectPrivileges(),
    staticFilters: selectStaticFilters(),
    designProgressLoading: selectDesignProgressLoading(),
});

const dispatchToProps = {
    setDesignProgressEditMode,
    setDesignProgressOverallActiveStructures,
    setDesignProgressLeadersActiveStructures,
    setDesignProgressFunctionsActiveStructures,
    getSelectedStructureFilter,
    updateRanges,
    getTargets,
    setTargets,
    updateTargets,
    setDesignProgressState,
    getStaticFilters,
    updateMultiFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
};

export default connect(stateToProps, dispatchToProps)(withRouter(DesignProgress));
