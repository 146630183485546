class AuthStore {
    constructor() {
        if (!AuthStore.instance) {
            this._data = localStorage;
            AuthStore.instance = this;
        }

        return AuthStore.instance;
    }

    set accessToken(value) {
        this._data.setItem('accessToken', value);
    }

    get accessToken() {
        return this._data.getItem('accessToken');
    }

    set orgAccessToken(value) {
        this._data.setItem('orgAccessToken', value);
    }

    get orgAccessToken() {
        return this._data.getItem('orgAccessToken');
    }

    set refreshToken(value) {
        this._data.setItem('refreshToken', value);
    }

    get refreshToken() {
        return this._data.getItem('refreshToken');
    }

    set clientId(value) {
        this._data.setItem('clientId', value);
    }

    get clientId() {
        return this._data.getItem('clientId');
    }

    set phaseId(value) {
        this._data.setItem('phaseId', value);
    }

    get phaseId() {
        return this._data.getItem('phaseId');
    }

    set userName(value) {
        this._data.setItem('userName', value);
    }

    get userName() {
        return this._data.getItem('userName');
    }

    set userId(value) {
        this._data.setItem('userId', value);
    }

    get userId() {
        return this._data.getItem('userId');
    }

    set clientName(value) {
        this._data.setItem('clientName', value);
    }

    get clientName() {
        return this._data.getItem('clientName');
    }

    deleteAccessToken() {
        this._data.removeItem('accessToken');
        this._data.removeItem('orgAccessToken');
        this._data.removeItem('refreshToken');
        this._data.removeItem('clientId');
        this._data.removeItem('phaseId');
        this._data.removeItem('clientName');
        this._data.removeItem('userName');
        this._data.removeItem('userId');
    }
}

const instance = new AuthStore();
Object.freeze(instance);

export default instance;
