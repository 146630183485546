import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getClientFailed,
    getClientSuccess,
    getPhase,
    getPhaseFailed,
    getPhaseSuccess,
    getPrivileges,
    getPrivilegesSuccess,
    getTokenFailed,
    getTokenSuccess,
    refreshOrgTokenActionFailed,
    refreshOrgTokenActionSuccess,
    refreshTokenActionFailed,
    refreshTokenActionSuccess,
} from './actions';
import { getPhaseStructures } from '../StructureContent/actions';
import {
    GET_AUTH_TOKEN,
    GET_CLIENT,
    GET_PHASE,
    GET_PRIVILEGES_PENDING,
    REFRESH_AUTH_TOKEN,
    REFRESH_ORG_AUTH_TOKEN,
} from './constants';
import {
    getClientInfo,
    getClientPrivilegesFromOrgApiUrl,
    getCurrentClientDetails,
    getPrivilegesFromOrgApiUrl,
    getRefreshOrgTokenApiUrl,
    getRefreshTokenApiUrl,
    postAuthApiUrl,
} from './apis';
import AuthStore from '../../common/AuthStore';
import { delay, parseJwt } from '../../utils/functions';
import { computeAccess } from '../../common/utilities';
import { getOrgResources } from '../StructureContent/apis';

function* createTokenSaga() {
    try {
        const { data } = yield call(postAuthApiUrl);
        const { token_type: tokenType, access_token: accessToken, refresh_token: refreshToken } = data;
        const dataFromToken = parseJwt(accessToken);
        const { user = {}, exp: expiryTime = 0 } = dataFromToken;
        const { name = '', id = '' } = user;
        const { clientId = '', orgAccessToken = '' } = AuthStore;

        AuthStore.accessToken = `${tokenType} ${accessToken}`;
        AuthStore.refreshToken = refreshToken;
        AuthStore.userName = name;
        AuthStore.userId = id;

        const { data: clientInfo } = yield call(getCurrentClientDetails, clientId, `Bearer ${orgAccessToken}`);
        AuthStore.clientName = clientInfo.data?.attributes?.name || '';
        yield put(getTokenSuccess({ data, expiryTime }));
        yield put(getPrivileges({ token: `Bearer ${orgAccessToken}`, userId: id, clientId }));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getTokenFailed({ detail, status }));
    }
}

function* refreshTokenSaga({ token }) {
    try {
        const { data } = yield call(getRefreshTokenApiUrl, token);
        const { token_type: tokenType, access_token: accessToken } = data;
        const dataFromToken = parseJwt(accessToken);
        const { exp: expiryTime = 0 } = dataFromToken;
        AuthStore.accessToken = `${tokenType} ${accessToken}`;
        yield call(delay, 1);
        yield put(refreshTokenActionSuccess({ data, expiryTime }));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(refreshTokenActionFailed({ detail, status }));
    }
}

function* refreshOrgTokenSaga({ token, userId }) {
    try {
        const { headers = {} } = yield call(getRefreshOrgTokenApiUrl, token, userId);
        AuthStore.orgAccessToken = headers['auth-token'];
        yield put(refreshOrgTokenActionSuccess(headers));
    } catch ({ response }) {
        yield put(refreshOrgTokenActionFailed(response));
    }
}

function* fetchPrivileges({ options, privilegeType }) {
    const { token, userId, clientId } = options;
    try {
        if (privilegeType === 'client') {
            const [clientPrivileges = {}] = yield all([call(getClientPrivilegesFromOrgApiUrl, token, clientId)]);
            const usersPrivileges = {};
            const payload = computeAccess(usersPrivileges.data || {}, clientPrivileges.data || {});
            yield put(getPrivilegesSuccess(payload));
        } else if (privilegeType === 'users') {
            const [usersPrivileges = {}] = yield all([call(getPrivilegesFromOrgApiUrl, token, userId)]);
            const clientPrivileges = {};
            const payload = computeAccess(usersPrivileges.data || {}, clientPrivileges.data || {});
            yield put(getPrivilegesSuccess(payload));
        } else {
            const [usersPrivileges, clientPrivileges = {}] = yield all([
                call(getPrivilegesFromOrgApiUrl, token, userId),
                call(getClientPrivilegesFromOrgApiUrl, token, clientId),
            ]);
            const payload = computeAccess(usersPrivileges.data || {}, clientPrivileges.data || {});
            yield put(getPrivilegesSuccess(payload));
        }
    } catch ({ response }) {}
}

function* getClientSaga({ payload }) {
    try {
        const { data: clientData = {} } = yield call(getClientInfo, payload);
        const { data = {} } = clientData;
        const { client_id: clientId = '', client_name: clientName = '' } = data;
        AuthStore.clientId = clientId;
        AuthStore.clientName = clientName;
        yield put(getClientSuccess());
        yield put(getPhase(clientId));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getClientFailed({ detail, status }));
    }
}

function* getPhaseSaga({ clientId }) {
    try {
        const { data: phaseOrgData } = yield call(getOrgResources, {
            id: clientId,
            method: 'clients',
            filterString: 'page[size]=1000&filter[archived]=false',
        });

        const data = (phaseOrgData.data || []).map(el => {
            return {
                client_id: clientId,
                client_name: '',
                phase_id: !isNaN(el?.id) ? +el.id : el.id,
                phase_name: el?.attributes?.name,
            };
        });
        yield put(getPhaseSuccess(data));
        const { phaseId = '' } = AuthStore;
        yield put(getPhaseStructures(clientId, phaseId));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getPhaseFailed({ detail, status }));
    }
}

const loginSagas = [
    takeLatest(`${GET_AUTH_TOKEN}_PENDING`, createTokenSaga),
    takeLatest(`${REFRESH_AUTH_TOKEN}_PENDING`, refreshTokenSaga),
    takeLatest(`${REFRESH_ORG_AUTH_TOKEN}_PENDING`, refreshOrgTokenSaga),
    takeLatest(`${GET_PRIVILEGES_PENDING}`, fetchPrivileges),
    takeLatest(`${GET_CLIENT}_PENDING`, getClientSaga),
    takeLatest(`${GET_PHASE}_PENDING`, getPhaseSaga),
];

export default loginSagas;
