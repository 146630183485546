import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { cloneDeep } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { InfoIcon } from './assets/svg';
import { numFormatter } from '../../utils/functions';
import { calculation_assumptions } from '../../common/staticFilters';

function TopContent({
    strCompareData,
    updateFilters,
    setFiltersToApply,
    setFiltersTray,
    sourceStructure,
    targetStructure,
    apiIsLoading,
    filterList,
    filtersToApply,
    filtersTray,
    showResult,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const {
        attributes: oldOptions = [],
        totalFTEChange = {},
        spanOfControl = {},
        nonManager = {},
        manager = {},
    } = strCompareData;

    const [attributes, setAttributes] = useState(oldOptions);

    const [scrolled, setScrolled] = React.useState(false);

    const handleScroll = () => {
        setScrolled(window.scrollY > 100);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        const { attributes: newOptions = [] } = strCompareData;
        setAttributes(newOptions);
    }, [strCompareData]);

    const selectedContent = attributes.filter(({ isSelected }) => isSelected);
    const dummyContent = [1, 2, 3, 4].slice(selectedContent.length);

    const { FTE: fte = {}, cost: fteCost = {} } = totalFTEChange;

    const { FTE: fteManager = {}, cost: managerCost = {} } = manager;
    const { FTE: fteNonManager = {}, cost: nonManagerCost = {} } = nonManager;
    const { idealValue = {}, currentValue = {} } = spanOfControl;
    const { sourceFTECount = 0, targetFTECount = 0 } = fte;
    const { sourceCost = 0, targetCost = 0 } = fteCost;
    const { sourceFTECount: srcMgCount = 0, targetFTECount: trgtMgCount = 0 } = fteManager;
    const { sourceCost: srcMgCost = 0, targetCost: trgtMgCost = 0 } = managerCost;
    const { sourceFTECount: srcNonMgCount = 0, targetFTECount: trgtNonMgCount = 0 } = fteNonManager;
    const { sourceCost: srcNonMgCost = 0, targetCost: trgtNonMgCost = 0 } = nonManagerCost;
    const { sourceCurrentValue = 0, targetCurrentValue = 0 } = currentValue;
    const { sourceIdealValue = 0, targetIdealValue = 0 } = idealValue;

    const updateAttributes = index => {
        const { isSelected } = attributes[index];
        const updatedAttributes = [
            ...attributes.slice(0, index),
            {
                ...attributes[index],
                isSelected: !isSelected,
            },
            ...attributes.slice(index + 1),
        ];
        setAttributes(updatedAttributes);
    };

    const updateChildren = (mainParent = [], isSelected) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = isSelected;
            if (innerChild.children) {
                updateChildren(innerChild.children, isSelected);
            }
        });
    };

    const resetAllFilters = () => {
        const updatedFilters = cloneDeep(filterList);
        updatedFilters.forEach((ele, index) => {
            if (!updatedFilters[index].alwaysShow) {
                updatedFilters[index].isSelected = false;
            }

            let children = [];
            if (!['manager'].includes(updatedFilters[index].keyToUse)) {
                children = updatedFilters[index].children;
            } else {
                updatedFilters[index].children = children;
                updatedFilters[index].selectedOption = {};
            }
            updateChildren(children, false);
            if (ele.name === 'counts') {
                updatedFilters[index].isSelected = true;
                updatedFilters[index].children = calculation_assumptions.counts.children;
            }
            if (ele.name === 'Archetype_Algorithm') {
                updatedFilters[index].isSelected = true;
                updatedFilters[index].children = calculation_assumptions.Archetype_Algorithm.children;
            }
        });
        updateFilters(updatedFilters);
        setFiltersToApply({ count_by: 'fte', algorithms: 'conservative' });
        setFiltersTray({
            count_by: [{ id: 'fte', name: 'FTEs' }],
            algorithms: [{ id: 'conservative', name: 'Conservative' }],
        });
    };

    const selectedAtt = attributes.filter(({ isSelected }) => isSelected).length;

    const selectedFilters = filterList.filter(({ isSelected }) => isSelected);

    const content = `<div><ul><li>Total FTEs <br/> Cost</li><li><b>${sourceFTECount}</b><br/><b>${numFormatter(
        sourceCost
    )}</b><br/>${sourceStructure}</li><li className="lastLi"><b>${targetFTECount}</b><br/><b>${numFormatter(
        targetCost
    )}</b><br/>${targetStructure}</li></ul></div>`;
    const content3 = `<div><ul><li>Total FTEs <br/> Cost</li><li><b>${srcMgCount}</b><br/><b>${numFormatter(
        srcMgCost
    )}</b><br/>${sourceStructure}</li><li className="lastLi"><b>${trgtMgCount}</b><br/><b>${numFormatter(
        trgtMgCost
    )}</b><br/>${targetStructure}</li></ul></div>`;
    const content4 = `<div><ul><li>Total FTEs <br/> Cost</li><li><b>${srcNonMgCount}</b><br/><b>${numFormatter(
        srcNonMgCost
    )}</b><br/>${sourceStructure}</li><li className="lastLi"><b>${trgtNonMgCount}</b><br/><b>${numFormatter(
        trgtNonMgCost
    )}</b><br/>${targetStructure}</li></ul></div>`;
    const content2 = `<div><ul><li>Current<br/> Ideal</li><li><b>${sourceCurrentValue.toFixed(
        2
    )}</b><br/> <b>${sourceIdealValue.toFixed(
        2
    )}</b><br />${sourceStructure}</li><li className="lastLi"><b>${targetCurrentValue.toFixed(
        2
    )} </b><br/><b>${targetIdealValue.toFixed(2)}</b><br/>${targetStructure}</li></ul></div>`;

    const FiltersTypeMap = {
        function_id: 'Characteristics',
        location_id: 'Characteristics',
        manager_id: 'Characteristics',
        lookup_id: 'Characteristics',
        span_of_control: 'Manager Analysis',
        span_ranges: 'Manager Analysis',
        open_roles: 'Calculation Assumptions',
        count_by: 'Calculation Assumptions',
        contractors: 'Calculation Assumptions',
        assistant: 'Calculation Assumptions',
        algorithms: 'Calculation Assumptions',
    };

    const formatFilters = filtersTray => {
        const formattedTray = {};
        let count = 0;
        const filters = {
            structure: JSON.parse(JSON.stringify(filtersTray)),
        };
        Object.keys(filters).forEach(el => {
            Object.keys(filters[el]).forEach(elem => {
                const type = FiltersTypeMap[elem];
                if (type) {
                    if (formattedTray.hasOwnProperty(type)) {
                        if (formattedTray[type].hasOwnProperty(el)) {
                            if (typeof filters[el][elem] === 'object') {
                                formattedTray[type][el].push(...filters[el][elem].flat());
                            } else {
                                formattedTray[type][el].push(filters[el][elem]);
                            }
                        } else {
                            if (typeof filters[el][elem] === 'object') {
                                formattedTray[type][el] = [...filters[el][elem].flat()];
                            } else {
                                formattedTray[type][el] = [filters[el][elem]];
                            }
                        }
                    } else {
                        if (typeof filters[el][elem] === 'object') {
                            formattedTray[type] = {
                                [el]: [...filters[el][elem].flat()],
                            };
                        } else {
                            formattedTray[type] = {
                                [el]: [filters[el][elem]],
                            };
                        }
                    }
                }
            });
        });

        Object.keys(formattedTray).forEach(el => {
            if (el === 'Characteristics') {
                Object.keys(formattedTray[el]).forEach((elem, index) => {
                    count += formattedTray[el][elem].length;
                });
            } else {
                Object.keys(formattedTray[el]).forEach((elem, index) => {
                    if (!index) {
                        count += formattedTray[el][elem].length;
                    }
                });
            }
        });

        return { formattedTray, count };
    };

    const getFiltersTrayView = formattedFiltersTray => {
        const tray = [];
        Object.keys(formattedFiltersTray).forEach(el => {
            if (el === 'Characteristics') {
                tray.push(<div className="filter-category">{el}</div>);
                Object.keys(formattedFiltersTray[el]).forEach(elem => {
                    formattedFiltersTray[el][elem].forEach(filter => {
                        tray.push(<div className="filter-item">{filter.name}</div>);
                    });
                });
            } else {
                tray.push(<div className="filter-category">{el}</div>);
                Object.keys(formattedFiltersTray[el]).forEach((elem, index) => {
                    if (!index) {
                        formattedFiltersTray[el][elem].forEach(filter => {
                            tray.push(<div className="filter-item">{filter.name}</div>);
                        });
                    }
                });
            }
        });

        return tray;
    };

    const { formattedTray, count } = formatFilters(filtersTray);

    if (showResult === false) return null;

    return (
        <div id="filters_applied">
            {selectedFilters.length && Object.keys(filtersToApply).length > 0 ? (
                <div className={`filter-tray ${scrolled ? 'sticky' : ''}`}>
                    <div className="filter-tray__header">
                        <div className="toggler">
                            <span onClick={() => setShowFilters(!showFilters)}>
                                {count} filters applied
                                <span>{showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                            </span>
                        </div>
                        <div className="clear">
                            <span onClick={resetAllFilters}>CLEAR ALL FILTERS</span>
                        </div>
                    </div>
                    <div className={`filter-tray__content ${showFilters ? 'show' : 'hide'}`}>
                        {getFiltersTrayView(formattedTray)}
                    </div>
                </div>
            ) : null}
            {sourceStructure && (
                <Fragment>
                    <section className="dataContent clearFix">
                        {selectedContent.map(({ name, count, cost }) => (
                            <div key={name} className="actualContent">
                                <div className={c({ lowLetter: name.length < 10 })}>{name}</div>
                                <span>
                                    <span className="countValue">
                                        {`${count}`.length > 2 ? count : ('0' + count).slice(-2)}
                                    </span>
                                </span>
                            </div>
                        ))}
                        {apiIsLoading
                            ? dummyContent.map(dummyKey => <div className="dummyContent" key={dummyKey} />)
                            : null}
                        <IconButton
                            classes={{
                                root: c({ anchorEl }),
                            }}
                            data-tip="Customize"
                            aria-controls="long-menu"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{ style: { minWidth: 250 } }}
                        >
                            {attributes.length > 4 && (
                                <MenuItem
                                    classes={{
                                        root: 'attributeHead',
                                    }}
                                >
                                    Select up to 4 options
                                </MenuItem>
                            )}
                            {attributes.map(({ name, isSelected }, index) => (
                                <MenuItem
                                    key={name}
                                    onClick={() => updateAttributes(index)}
                                    classes={{
                                        root: c({
                                            disableSelect:
                                                (!isSelected && selectedAtt === 4) || (isSelected && selectedAtt === 1),
                                        }),
                                    }}
                                >
                                    <Checkbox checked={isSelected} />
                                    {name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </section>
                    <section className="managerContent clearFix">
                        <div>
                            <h2>
                                total Fte Change
                                <div className="infoIcon" data-tip={content} data-html>
                                    <InfoIcon />
                                </div>
                            </h2>
                            <ul>
                                <li>
                                    <span>{numFormatter(fte.value)}</span>FTEs{' '}
                                    <span className={`upIcon ${fte.changeType}`} />
                                </li>
                                <li>
                                    <span>
                                        {numFormatter(fteCost.value, 2, true)}
                                        <span style={{ fontSize: '18px' }}>M</span>
                                    </span>
                                    Cost <span className={`upIcon ${fteCost.changeType}`} />
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>
                                non managers{' '}
                                <div className="infoIcon" data-tip={content4} data-html>
                                    <InfoIcon />
                                </div>
                            </h2>
                            <ul>
                                <li>
                                    <span>{numFormatter(fteNonManager.value)}</span>FTEs{' '}
                                    <span className={`upIcon ${fteNonManager.changeType}`} />
                                </li>
                                <li>
                                    <span>
                                        {numFormatter(nonManagerCost.value, 2, true)}
                                        <span style={{ fontSize: '18px' }}>M</span>
                                    </span>
                                    Cost <span className={`upIcon ${nonManagerCost.changeType}`} />
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <h2>
                                    managers
                                    <div className="infoIcon" data-tip={content3} data-html>
                                        <InfoIcon />
                                    </div>
                                </h2>
                                <ul>
                                    <li>
                                        <span>{numFormatter(fteManager.value)}</span>FTEs{' '}
                                        <span className={`upIcon ${fteManager.changeType}`} />
                                    </li>
                                    <li>
                                        <span>
                                            {numFormatter(managerCost.value, 2, true)}
                                            <span style={{ fontSize: '18px' }}>M</span>
                                        </span>
                                        Cost <span className={`upIcon ${managerCost.changeType}`} />
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2>
                                    span of control
                                    <div className="infoIcon" data-tip={content2} data-html>
                                        <InfoIcon />
                                    </div>
                                </h2>
                                <ul>
                                    <li>
                                        <span>{Math.round((currentValue.value || 0) * 100) / 100}</span>CURRENT VALUE
                                    </li>
                                    <li>
                                        <span>{Math.round((idealValue.value || 0) * 100) / 100}</span>ideal value
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <ReactTooltip html />
                </Fragment>
            )}
        </div>
    );
}

TopContent.propTypes = {
    sourceStructure: PropTypes.string.isRequired,
    targetStructure: PropTypes.string.isRequired,
    strCompareData: PropTypes.object.isRequired,
    apiIsLoading: PropTypes.bool.isRequired,
};

export default TopContent;
