import * as T from '../../types';
import AuthStore from '../../../../common/AuthStore';
import { mergeSlideStateToMainState, downloadPPT } from '../common';
import { SHARED_STATE } from './shared';
// slide json methods
import { generateSlide1PPT } from './slide1';

// // eslint-disable-next-line
// export let FILTER_STRING = "";

let STATE = {
    template_file_name: 'Analytics ppt export_v2.pptx',
    output_file_name: 'Analytics ppt export_v1_processed.pptx',
    slides: [
        // slide 3 : address opportunity
        {
            slideIndex: 3, // custom property
            identify_shape_name: 'checkme',
            identify_shape_value: 'add_slide5',
            data: [
                {
                    name: 'checkme',
                    is_delete: 'true',
                },
                // {
                //   "name": "sub_title",
                //   "existing_value": "~XX",
                //   "value": "75"
                // },
                {
                    name: 'chart_1',
                    type: 'chart',
                    sub_type: 'bar',
                    chart_data: [],
                },
            ],
        },
    ],
};

// temporary (deep clone) to reset the state at any point
const TEMP = JSON.parse(JSON.stringify(STATE));

export const downloadPPTAction = (values, filterString) => async (dispatch, getState) => {
    const { clientName = '' } = AuthStore;
    if (!clientName) return;
    // reset the values
    STATE = TEMP;
    // FILTER_STRING = filterString;
    SHARED_STATE.FILTER_STRING = filterString;
    const { slide1 } = values;
    const { structure } = Object.fromEntries(getState().get('structuresRedux'));
    const { name: structureName } = Object.fromEntries(structure);
    const fileName = `${clientName}_${structureName}`;
    dispatch({
        type: T.GENERATE_PPT_REQUEST,
    });

    const mergeWithMainState = updatedSlideState => {
        STATE = mergeSlideStateToMainState(STATE, updatedSlideState);
    };
    const slide1State = (STATE.slides || []).filter(slide => slide.slideIndex === 3)[0] || { data: [] };
    await generateSlide1PPT({ ...slide1, slideState: slide1State, mergeWithMainState });
    await downloadPPT(fileName, STATE);
    dispatch({
        type: T.GENERATE_PPT_SUCCESS,
    });
};

export const _ignore = '_';
