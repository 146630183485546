import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DesignFilters from './index';
import {
    clrSelectedManager,
    fetchAllFilters,
    fetchSecondaryFilters,
    getManagerFilters,
    setFiltersToApply,
    updateFilters,
    updateMultiFilters,
    updateStaticFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    updateFiltersChildren,
    getStaticFilters,
} from '../Filters/actions/actions';
import { fetchFilterStatus, multiSelectFilters, selectStaticFilters } from '../Filters/selectors/selector';

import {
    selectDesignProgressLeadersActiveStructures,
    selectDesignProgressOverallActiveStructures,
    selectDesignProgressFunctionsActiveStructures,
    selectDesignProgressState,
} from '../StructureContent/selector';

const stateToProps = createStructuredSelector({
    isFiltersFetched: fetchFilterStatus(),
    designProgressState: selectDesignProgressState(),
    designFilters: multiSelectFilters(),
    staticFilters: selectStaticFilters(),
    designProgressOverallActiveStructures: selectDesignProgressOverallActiveStructures(),
    designProgressLeadersActiveStructures: selectDesignProgressLeadersActiveStructures(),
    designProgressFunctionsActiveStructures: selectDesignProgressFunctionsActiveStructures(),
});

const dispatchToProps = {
    fetchAllFilters,
    updateFilters,
    fetchSecondaryFilters,
    setFiltersToApply,
    getManagerFilters,
    clrSelectedManager,
    updateMultiFilters,
    updateStaticFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    updateFiltersChildren,
    getStaticFilters,
};

export default connect(stateToProps, dispatchToProps)(DesignFilters);
