import React, { useEffect, useRef } from 'react';
import c from 'classnames';
import {
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Radio,
    Typography,
} from '@material-ui/core';
import { cloneDeep, debounce } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import filtersEnums from './filters.enums';
import cross from '../../assets/crossIcon.svg';
import { calculation_assumptions } from '../../common/staticFilters';
import SearchInput from './components/SearchInput';
import './index.scss';

function Filters({
    drawerOpen,
    showStaticFilters,
    fetchAllFilters,
    filters,
    updateFilters,
    fetchSecondaryFilters,
    setFiltersToApply,
    setFiltersTray,
    isFiltersFetched,
    structureId,
    setDrawerOpen,
    getManagerFilters,
    clrSelectedManager,
    tabSelected,
    updateFiltersChildren,
    // isDesignProgress,
}) {
    const managerKey = 'manager';
    const radioFilterKeys = [
        '# of Direct Reports',
        'open_Roles',
        'contractors',
        'assistants',
        'counts',
        '#_of_Direct_Reports',
        'Archetype_Algorithm',
    ];
    const stringVals = [
        'algorithms',
        'direct_reports',
        'open_roles',
        'contractors',
        'assistant',
        'count_by',
        'span_ranges',
    ];
    useEffect(() => {
        fetchAllFilters(structureId, showStaticFilters);
    }, [structureId]);

    useEffect(() => {
        if (isFiltersFetched) {
            applyFilters();
        }
    }, [isFiltersFetched]);

    const handleClickAway = index => {
        const updatedFilters = cloneDeep(filters);
        if (updatedFilters[index].isOpen) {
            updatedFilters[index].isOpen = false;

            updateFilters(updatedFilters);
        }
    };

    const getManagerByName = (q = '', structureId) => {
        getManagerFilters(`manager|${q}`, structureId);
    };

    const handleClick = index => {
        const updatedFilters = cloneDeep(filters);
        const { isOpen = false } = updatedFilters[index];
        const openItem = updatedFilters.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1 && openItem !== index) {
            updatedFilters[openItem].isOpen = false;
            updatedFilters[index].isOpen = true;
        } else {
            updatedFilters[index].isOpen = !isOpen;
        }
        updateFilters(updatedFilters);
    };

    const callSearchedManagers = (value, structureId) => getManagerByName(value, structureId);
    const callSearchedManagersDebounced = useRef(debounce(callSearchedManagers, 500));

    const removeSelected = (event, mainIndex, childName) => {
        event.stopPropagation();

        const childIndex = filters[mainIndex].children.findIndex(({ name }) => name === childName);

        if (['manager'].includes(filters[mainIndex].keyToUse)) {
            clrSelectedManager();
        } else {
            handleChecked({ checked: false }, [mainIndex, childIndex]);
        }
    };

    const handleChange = ({ target }) => {
        const { value } = target;
        let updatedFilters = cloneDeep(filters);

        updatedFilters = updatedFilters.map(item => {
            if (item.keyToUse === managerKey) {
                item.children = [];

                item.message = value.length >= 2 ? "Please wait while we're loading results..." : '';
            }
            return item;
        });

        if (value.length >= 2) {
            callSearchedManagersDebounced.current(value, structureId);
        }

        setTimeout(
            () => {
                updateFilters(updatedFilters);
            },
            value.length < 2 ? 1000 : 0
        );
    };

    const repeatBlock = (arrToRepeat, indexParent, keyToUse, needExpandIcon = true) => {
        return arrToRepeat.map(
            (
                {
                    name = '',
                    children = [],
                    isOpen = false,
                    isSelected = false,
                    id = '',
                    isLoaded = false,
                    isLoading = false,
                },
                index
            ) => {
                name = name ? (name.endsWith(' -') ? name.replace(' -', '') : name) : '';
                const expandState = isOpen ? <ExpandLess /> : <ExpandMore />;
                const loaderState = isLoading ? <CircularProgress /> : expandState;
                return (
                    <List component="div" disablePadding key={index}>
                        <ListItem style={{ paddingLeft: indexParent.length * 16 }}>
                            {needExpandIcon && (
                                <span
                                    onClick={() =>
                                        getInnerFilters(
                                            keyToUse,
                                            id,
                                            [...indexParent, index],
                                            isLoaded,
                                            isLoading,
                                            !isOpen,
                                            isSelected
                                        )
                                    }
                                >
                                    {loaderState}
                                </span>
                            )}

                            {[managerKey].includes(keyToUse) && (
                                <Radio
                                    name={managerKey}
                                    checked={isSelected}
                                    onChange={({ target }) => handleChecked(target, [...indexParent, index], 'radio')}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary radio' }}
                                />
                            )}

                            {![managerKey].includes(keyToUse) &&
                                (radioFilterKeys.includes(keyToUse) ? (
                                    <Radio
                                        name={keyToUse}
                                        checked={isSelected}
                                        onChange={({ target }) =>
                                            handleChecked(target, [...indexParent, index], 'radio')
                                        }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary radio' }}
                                    />
                                ) : (
                                    <Checkbox
                                        checked={isSelected}
                                        onChange={({ target }) => handleChecked(target, [...indexParent, index])}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                ))}
                            <ListItemText
                                primary={
                                    keyToUse == 'Archetype_Algorithm' || keyToUse == 'counts'
                                        ? index == 0
                                            ? `${name} (Default)`
                                            : `${name}`
                                        : name.replace(/_/g, ' ')
                                }
                                onClick={() =>
                                    getInnerFilters(
                                        keyToUse,
                                        id,
                                        [...indexParent, index],
                                        isLoaded,
                                        isLoading,
                                        !isOpen,
                                        isSelected
                                    )
                                }
                            />
                        </ListItem>
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            {repeatBlock(children, [...indexParent, index], keyToUse)}
                        </Collapse>
                    </List>
                );
            }
        );
    };

    const updateChildren = (mainParent = [], isSelected) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = isSelected;
            if (innerChild.children) {
                updateChildren(innerChild.children, isSelected);
            }
        });
    };

    const getInnerFilters = (keyToUse, id, arrToRepeat, isLoaded, isLoading, isOpen, isSelected) => {
        if (!isLoading) {
            const arrayToUse = arrToRepeat.reduce(
                (r, o) => {
                    r.push(o);
                    r.push('children');
                    return r;
                },
                ['filters']
            );
            if (!isLoaded) {
                fetchSecondaryFilters(keyToUse, id, arrayToUse, isSelected);
            } else {
                const arrayToOpen = [...arrayToUse.slice(0, -1), 'isOpen'];
                updateFiltersChildren({ arrayToOpen, isOpen });
            }
        }
    };

    const handleChecked = ({ checked }, indexArr, component = 'checkbox') => {
        const updatedFilters = cloneDeep(filters);
        let { children = [] } = updatedFilters[indexArr[0]];
        if (indexArr.length === 2) {
            const innerChildMain = children[indexArr[1]];
            updateChildren(innerChildMain.children, checked);

            if (component === 'radio') {
                // make is selected false to prev filter option
                children = children.map(item => {
                    item.isSelected = false;
                    return item;
                });
            }

            innerChildMain.isSelected = checked;
            updatedFilters[indexArr[0]].selectedOption = innerChildMain;
            updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
        } else if (indexArr.length === 3) {
            const innerChild = children[indexArr[1]].children[indexArr[2]];
            innerChild.isSelected = checked;
            if (checked) {
                children[indexArr[1]].isSelected = checked;
                updatedFilters[indexArr[0]].isSelected = checked;
            } else {
                children[indexArr[1]].isSelected = !!children[indexArr[1]].children.filter(
                    ({ isSelected }) => isSelected
                ).length;
                updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
            }
            updateChildren(innerChild.children, checked);
        } else {
            const innerChild2 = children[indexArr[1]].children[indexArr[2]].children[indexArr[3]];
            innerChild2.isSelected = checked;
            if (checked) {
                children[indexArr[1]].children[indexArr[2]].isSelected = checked;
                children[indexArr[1]].isSelected = checked;
                updatedFilters[indexArr[0]].isSelected = checked;
            } else {
                children[indexArr[1]].children[indexArr[2]].isSelected = !!children[indexArr[1]].children[
                    indexArr[2]
                ].children.filter(({ isSelected }) => isSelected).length;
                children[indexArr[1]].isSelected = !!children[indexArr[1]].children.filter(
                    ({ isSelected }) => isSelected
                ).length;
                updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
            }
            updateChildren(innerChild2.children, checked);
        }
        updateFilters(updatedFilters);
    };

    const getStaticManagersFilters = () => {
        const staticManagersFilters = [];
        filters.forEach(
            (
                { name = '', children = [], keyToUse = '', isOpen = false, message = '', isStaticFilter, type },
                indexParent
            ) => {
                if (isStaticFilter && type === 'managerFilter') {
                    const selectedChild = children.filter(({ isSelected }) => isSelected);
                    const selectedLength = selectedChild.length;
                    const iconExpand = children.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;

                    staticManagersFilters.push(
                        <ClickAwayListener onClickAway={() => handleClickAway(indexParent)} key={name}>
                            <div className="mainListWrap">
                                <ListItem
                                    onClick={() => handleClick(indexParent)}
                                    classes={{ root: 'mainListContent' }}
                                >
                                    <div className={c('nameChild', { shrink: selectedChild.length })}>
                                        {name.replace(/_/g, ' ')}
                                        {iconExpand}
                                    </div>
                                    {selectedLength ? (
                                        <div className="selectedChild">
                                            {selectedChild.map(({ name: childName, children: childList = [] }) => (
                                                <span key={childName} className="innerChild">
                                                    {childName}
                                                    <span>
                                                        ({childList.filter(({ isSelected }) => isSelected).length || 1})
                                                    </span>
                                                    <img
                                                        alt=""
                                                        src={cross}
                                                        onClick={e => removeSelected(e, indexParent, childName)}
                                                    />
                                                </span>
                                            ))}
                                            {iconExpand}
                                        </div>
                                    ) : null}
                                </ListItem>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit={![managerKey].includes(keyToUse)}
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {keyToUse === managerKey && (
                                        <div className="searchBoxWrapper">
                                            <SearchInput
                                                type="search"
                                                id="search"
                                                label="Search Managers"
                                                onChange={handleChange}
                                                placeholder="Type at least 2 char to search..."
                                                message
                                            />

                                            {message && <span className="msg">{message}</span>}
                                        </div>
                                    )}
                                    {repeatBlock(
                                        children,
                                        [indexParent],
                                        keyToUse,
                                        !radioFilterKeys.includes(keyToUse)
                                    )}
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    );
                }
            }
        );
        return staticManagersFilters;
    };

    const getDynamicFilters = () => {
        const dynamicFilters = [];
        filters.forEach(
            (
                { name = '', children = [], keyToUse = '', isOpen = false, message = '', isStaticFilter },
                indexParent
            ) => {
                if (!isStaticFilter) {
                    const selectedChild = children.filter(({ isSelected }) => isSelected);
                    const selectedLength = selectedChild.length;
                    const iconExpand = children.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;

                    dynamicFilters.push(
                        <ClickAwayListener onClickAway={() => handleClickAway(indexParent)} key={name}>
                            <div className="mainListWrap">
                                <ListItem
                                    onClick={() => handleClick(indexParent)}
                                    classes={{ root: 'mainListContent' }}
                                >
                                    <div className={c('nameChild', { shrink: selectedChild.length })}>
                                        {name.replace(/_/g, ' ')}
                                        {iconExpand}
                                    </div>
                                    {selectedLength ? (
                                        <div className="selectedChild">
                                            {selectedChild.map(({ name: childName, children: childList = [] }) => (
                                                <span key={childName} className="innerChild">
                                                    {childName}
                                                    <span>
                                                        ({childList.filter(({ isSelected }) => isSelected).length || 1})
                                                    </span>
                                                    <img
                                                        alt=""
                                                        src={cross}
                                                        onClick={e => removeSelected(e, indexParent, childName)}
                                                    />
                                                </span>
                                            ))}
                                            {iconExpand}
                                        </div>
                                    ) : null}
                                </ListItem>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit={![managerKey].includes(keyToUse)}
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {keyToUse === managerKey && (
                                        <div className="searchBoxWrapper">
                                            <SearchInput
                                                type="search"
                                                id="search"
                                                label="Search Managers"
                                                onChange={handleChange}
                                                placeholder="Type at least 2 char to search..."
                                                message
                                            />

                                            {message && <span className="msg">{message}</span>}
                                        </div>
                                    )}
                                    {repeatBlock(children, [indexParent], keyToUse, keyToUse !== managerKey)}
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    );
                }
            }
        );
        return dynamicFilters;
    };

    const getStaticCalculationAssumptionsFilters = () => {
        const staticCalculationAssumptionsFilters = [];
        filters.forEach(
            (
                { name = '', children = [], keyToUse = '', isOpen = false, message = '', isStaticFilter, type },
                indexParent
            ) => {
                if (isStaticFilter && type === 'calculationAssumptionsFilter') {
                    const selectedChild = children.filter(({ isSelected }) => isSelected);
                    const selectedLength = selectedChild.length;
                    const iconExpand = children.length ? (
                        <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                    ) : null;

                    staticCalculationAssumptionsFilters.push(
                        <ClickAwayListener onClickAway={() => handleClickAway(indexParent)} key={name}>
                            <div className="mainListWrap">
                                <ListItem
                                    onClick={() => handleClick(indexParent)}
                                    classes={{ root: 'mainListContent' }}
                                >
                                    <div className={c('nameChild', { shrink: selectedChild.length })}>
                                        {name.replace(/_/g, ' ')}
                                        {iconExpand}
                                    </div>
                                    {selectedLength ? (
                                        <div className="selectedChild">
                                            {selectedChild.map(({ name: childName, children: childList = [] }) => (
                                                <span key={childName} className="innerChild">
                                                    {childName}
                                                    <span>
                                                        ({childList.filter(({ isSelected }) => isSelected).length || 1})
                                                    </span>
                                                    <img
                                                        alt=""
                                                        src={cross}
                                                        onClick={e => removeSelected(e, indexParent, childName)}
                                                    />
                                                </span>
                                            ))}
                                            {iconExpand}
                                        </div>
                                    ) : null}
                                </ListItem>
                                <Collapse
                                    in={isOpen}
                                    timeout="auto"
                                    unmountOnExit={![managerKey].includes(keyToUse)}
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {keyToUse === managerKey && (
                                        <div className="searchBoxWrapper">
                                            <SearchInput
                                                type="search"
                                                id="search"
                                                label="Search Managers"
                                                onChange={handleChange}
                                                placeholder="Type at least 2 char to search..."
                                                message
                                            />

                                            {message && <span className="msg">{message}</span>}
                                        </div>
                                    )}
                                    {repeatBlock(
                                        children,
                                        [indexParent],
                                        keyToUse,
                                        !radioFilterKeys.includes(keyToUse)
                                    )}
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    );
                }
            }
        );
        return staticCalculationAssumptionsFilters;
    };

    const applyFilters = () => {
        const filtersToApply = {};
        const filtersTray = {};
        const selectInnerChild = (mainParent = []) => {
            mainParent
                .filter(({ isSelected = false }) => isSelected)
                .forEach(({ children = [], key = '', id = '', lookup_category, name = '' }) => {
                    if (children.length) {
                        selectInnerChild(children);
                    } else if (stringVals.includes(key)) {
                        filtersToApply[key] = id;
                        filtersTray[key] = [{ id, name }];
                    } else if (filtersToApply[key]) {
                        if (key === 'lookup_id') {
                            if (filtersToApply[key][lookup_category]) {
                                filtersToApply[key][lookup_category].push(id);
                                filtersTray[key][lookup_category].push({ id, name });
                            } else {
                                filtersToApply[key][lookup_category] = [id];
                                filtersTray[key][lookup_category] = [{ id, name }];
                            }
                        } else {
                            filtersToApply[key].push(id);
                            filtersTray[key].push({ id, name });
                        }
                    } else {
                        if (key === 'lookup_id') {
                            filtersToApply[key] = {
                                [lookup_category]: [id],
                            };
                            filtersTray[key] = {
                                [lookup_category]: [{ id, name }],
                            };
                        } else {
                            filtersToApply[key] = [id];
                            filtersTray[key] = [{ id, name }];
                        }
                    }
                });
        };

        selectInnerChild(filters);

        if (filtersToApply.lookup_id) {
            filtersToApply.lookup_id = Object.keys(filtersToApply.lookup_id).map(
                category => filtersToApply.lookup_id[category]
            );
            filtersTray.lookup_id = Object.keys(filtersTray.lookup_id).map(category => filtersTray.lookup_id[category]);
        }

        setDrawerOpen(false);
        setFiltersToApply(filtersToApply);
        setFiltersTray(filtersTray);
    };

    const resetAllFilters = () => {
        const updatedFilters = cloneDeep(filters);
        updatedFilters.forEach((ele, index) => {
            updatedFilters[index].isSelected = false;

            let children = [];

            if (!['manager'].includes(updatedFilters[index].keyToUse)) {
                children = updatedFilters[index].children;
            } else {
                updatedFilters[index].children = children;
                updatedFilters[index].selectedOption = {};
            }
            updateChildren(children, false);
            if (ele.name === 'counts') {
                updatedFilters[index].isSelected = true;
                updatedFilters[index].children = calculation_assumptions.counts.children;
            }
            if (ele.name === 'Archetype_Algorithm') {
                updatedFilters[index].isSelected = true;
                updatedFilters[index].children = calculation_assumptions.Archetype_Algorithm.children;
            }
        });
        updateFilters(updatedFilters);
        setFiltersToApply({ count_by: 'fte', algorithms: 'conservative' });
        setFiltersTray({
            count_by: [{ id: 'fte', name: 'FTEs' }],
            algorithms: [{ id: 'conservative', name: 'Conservative' }],
        });
    };

    const active = true;

    return (
        <Drawer anchor={'right'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
            <div className={c('rightSideFilters', { drawerOpen })}>
                <div className="upperContent clearFix">
                    <h3>{filtersEnums.FILTERS}</h3>
                    <div className={c('btn', { active })} onClick={applyFilters}>
                        {filtersEnums.APPLY}
                    </div>
                    <div className={c('resetBtn', { active })} onClick={resetAllFilters}>
                        {filtersEnums.RESET}
                    </div>
                </div>
                <Tabs selected={tabSelected} key={tabSelected}>
                    <Tab title="Characteristics">
                        <Typography>
                            <List component="nav" aria-labelledby="nested-list-subheader" className="filterList">
                                {getDynamicFilters()}
                            </List>
                        </Typography>
                    </Tab>

                    {showStaticFilters ? (
                        <Tab title="Manager Analysis">
                            <Typography>
                                <List component="nav" aria-labelledby="nested-list-subheader" className="filterList">
                                    {getStaticManagersFilters()}
                                </List>
                            </Typography>
                        </Tab>
                    ) : (
                        <Tab />
                    )}

                    {showStaticFilters ? (
                        <Tab title="Calculation Assumptions">
                            <Typography>
                                <List component="nav" aria-labelledby="nested-list-subheader" className="filterList">
                                    {getStaticCalculationAssumptionsFilters()}
                                </List>
                            </Typography>
                        </Tab>
                    ) : (
                        <Tab />
                    )}
                </Tabs>
            </div>
        </Drawer>
    );
}

export default Filters;
