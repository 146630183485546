import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import DatePickers from '../../components/DatePicker';
import { REPORTS_LINK, SITE_TXT } from '../../utils/constants';
import './index.scss';
import { getOrgResources } from '../StructureContent/apis';
import NoDataFound from '../../components/NoDataFound';
import Loader from '../../components/Loader';
import Chapters from './Chapters';
import formattedUtilities from '../../common/utilities';
import ChaptersDetails from './ChapterDetails';
import Toggle from '../../components/Toggle';

const SupplyDemand = ({
    strCompareFetched,
    primaryFilters,
    clrManagerFilters,
    privileges,
    resetFilters,
    history,
    phaseStructureInfo,
    refreshCount,
}) => {
    const [filterApplied, setFilterApplied] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [chapters, setChapters] = useState({});
    const [subChaptersList, setSubChaptersList] = useState({});
    const [teamsStats, setTeamsStats] = useState({
        teamsPositionsMap: {},
        positionRoleMap: {},
        positionChapterMap: {},
        chapterPositionsMap: {},
        teamsMap: {},
        teamsNameMap: {},
        rolesPositionsMap: {},
        positionAllocationMap: {},
        allocation_intervalsMap: {},
        roleFteMap: {},
        // teamsHierarchy: {},
        flatTeamsList: [],
        positionTeam: {},
    });
    const [activeChapter, setActiveChapter] = useState({ id: '', name: '', child: '' });
    const [structureId, setStructureId] = useState('');
    const [demand, setDemand] = useState(0);
    const [totalDemand, setTotalDemand] = useState(0);
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

    const [flattenActiveChapterList, setFlattenActiveChapterList] = useState([]);
    const [teamDistributionHierarchy, setTeamDistributionHierarchy] = useState({});
    const [showDistributionTable, setShowDistributionTable] = useState({ status: false, view: '' });
    const [distributionTreeExpandIdList, setDistributionTreeExpandIdList] = useState([]);

    const [lessThan100Allocated, setLessThan100Allocated] = useState(0);
    const [equalTo100Allocated, setEqualTo100Allocated] = useState(0);
    const [moreThan100Allocated, setMoreThan100Allocated] = useState(0);
    const [currentlyStaffed, setCurrentlyStaffed] = useState(0);
    const [fteCurrentlyStaffed, setFteCurrentlyStaffed] = useState(0);

    const [fteDemand, setFteDemand] = useState(0);
    const [totalFteDemand, setTotalFteDemand] = useState(0);
    const [showFteValues, setShowFteValues] = useState(false);
    const [totalDemandValue, setTotalDemandValue] = useState({ id: '', totalDemand: 0 });
    const [chapterObjArr, setChapterObjArr] = useState([]);

    useEffect(() => {
        if (structureId) {
            getSupplyDemand(date);
        }
    }, [structureId, date, refreshCount]);

    useEffect(() => {
        if (chapterObjArr.length > 0) {
            chapterObjArr.forEach(obj => {
                setTotalDemandValue({ id: activeChapter.id, totalDemand: totalDemand });
                // if(activeChapter.id === obj.id && obj.level === 1){
                //     setTotalDemandValue({id:activeChapter.id, totalDemand: totalDemand});
                // }
            });
        }
    }, [activeChapter]);
    const flattenTreeToList = tree => {
        const _list = [{ id: tree.id, name: tree.chapter_name }];
        const makeList = child => {
            child.forEach(elem => {
                _list.push({ id: elem.id, name: elem.chapter_name });
                elem.child.length && makeList(elem.child);
            });
        };
        tree.child.length && makeList(tree.child);

        return _list;
    };
    const getTotalDemand = (_list, chapterPositionsMap) => {
        const _chapterPositionsMap = chapterPositionsMap || teamsStats.chapterPositionsMap;

        return _list.reduce((acc, el) => {
            return acc + Object.keys(_chapterPositionsMap[el.id] || {}).length;
        }, 0);
    };

    const getDemand = (_list, chapterPositionsMap, chapterObjArr, currentChapterId) => {
        // _list is current chapter and it's subchapter list
        const _chapterPositionsMap = chapterPositionsMap || teamsStats.chapterPositionsMap;
        const arrIdObj = [];
        const idLevelObj = {};
        const idChildObj = {};
        const arrLevelObj = [];
        chapterObjArr.forEach(itm => {
            idLevelObj[itm.id] = itm.level;
            idChildObj[itm.id] = itm.child;
            arrIdObj.push(itm.id);
            arrLevelObj.push(itm.level);
        });
        // this if for the main parent chapter only.
        if (
            arrIdObj.includes(
                currentChapterId
            ) /* && idLevelObj[currentChapterId] >=2 && idChildObj[currentChapterId] == 0 */
        ) {
            return Object.keys(_chapterPositionsMap[currentChapterId] || {}).length;
        }
    };

    const getTotalFteDemand = (
        selectedChaptersList,
        chapterPositionsMap,
        positionAllocationMap,
        allocation_intervalsMap
    ) => {
        const _chapterPositionsMap = chapterPositionsMap || teamsStats.chapterPositionsMap;
        const _positionAllocationMap = positionAllocationMap || teamsStats.positionAllocationMap;
        const _allocation_intervalsMap = allocation_intervalsMap || teamsStats.allocation_intervalsMap;
        return selectedChaptersList.reduce((acc, el) => {
            Object.keys(_chapterPositionsMap[el.id] || {}).forEach(positionId => {
                acc += _allocation_intervalsMap[_positionAllocationMap[positionId].allocationId].allocation;
            });
            return acc;
        }, 0);
    };

    const getFteDemand = (
        activeChapterId,
        selectedChaptersList,
        chapterPositionsMap,
        positionAllocationMap,
        allocation_intervalsMap
    ) => {
        const _chapterPositionsMap = chapterPositionsMap || teamsStats.chapterPositionsMap;
        const _positionAllocationMap = positionAllocationMap || teamsStats.positionAllocationMap;
        const _allocation_intervalsMap = allocation_intervalsMap || teamsStats.allocation_intervalsMap;
        return selectedChaptersList.reduce((acc, el) => {
            if (el.id !== activeChapter) {
                Object.keys(_chapterPositionsMap[el.id] || {}).forEach(positionId => {
                    acc += _allocation_intervalsMap[_positionAllocationMap[positionId].allocationId].allocation;
                });
            }
            return acc;
        }, 0);
    };

    const onChapterSelect = (
        id,
        name,
        _chapters,
        chapterPositionsMap,
        positionAllocationMap,
        allocation_intervalsMap
    ) => {
        const _child = Object.values(chapters).length ? Object.values(chapters) : Object.values(_chapters);
        const _subChapters = formattedUtilities.searchTree({ id: null, child: _child }, id);
        const subChaptersList = flattenTreeToList(_subChapters);
        // generate new array of chapter objects with id, name , level, parentid, child
        const getArrChapterObj = [];

        _child.forEach((itm, idx) => {
            const level = 1;
            const chapterObj = {};
            chapterObj['id'] = itm.id;
            chapterObj['chapter_name'] = itm.chapter_name;
            chapterObj['child'] = itm.child.length;
            chapterObj['level'] = level;
            const grantId = itm.id;
            getArrChapterObj.push(chapterObj);
            function getChild(itm, level, grantId) {
                if (itm.child.length > 0) {
                    level += 1;
                    itm.child.forEach(chlItm => {
                        const chapterObj = {};
                        chapterObj['id'] = chlItm.id;
                        chapterObj['chapter_name'] = chlItm.chapter_name;
                        chapterObj['child'] = chlItm.child.length;
                        chapterObj['level'] = level;
                        if (level > 2) {
                            chapterObj['parentId'] = grantId;
                        } else {
                            chapterObj['parentId'] = chlItm.parentId;
                        }
                        chapterObj['level'] = level;
                        getArrChapterObj.push(chapterObj);
                        if (chlItm.child.length > 0) {
                            getChild(chlItm, level, grantId);
                        }
                    });
                }
            }
            getChild(itm, level, grantId);
        });
        setChapterObjArr(getArrChapterObj);

        setSubChaptersList(subChaptersList);
        setFlattenActiveChapterList(subChaptersList);
        setDemand(getDemand(subChaptersList, chapterPositionsMap, getArrChapterObj, id));
        setTotalDemand(getTotalDemand(subChaptersList, chapterPositionsMap));
        setTotalFteDemand(
            getTotalFteDemand(subChaptersList, chapterPositionsMap, positionAllocationMap, allocation_intervalsMap)
        );
        setFteDemand(
            getFteDemand(id, subChaptersList, chapterPositionsMap, positionAllocationMap, allocation_intervalsMap)
        );
        if (teamsStats.flatTeamsList.length) {
            setActiveChapter({ id, name });
            setTeamWiseStaffingData(id);
        }
    };

    const setTeamWiseStaffingData = selectedChapterId => {
        const _child = Object.values(chapters);
        const _subChapters = formattedUtilities.searchTree({ id: null, child: _child }, selectedChapterId);
        const subChaptersList = flattenTreeToList(_subChapters);

        const _newFlatTeamsList = teamsStats.flatTeamsList.map(el => {
            const teamId = el.id;
            const positionsInTeam = teamsStats.teamsPositionsMap[teamId] || [];
            let staffed = 0;
            let fteStaffed = 0;
            let more_than_100_percent_allocated = 0;
            let less_than_100_percent_allocated = 0;
            let equal_to_100_percent_allocated = 0;

            positionsInTeam.forEach(elem => {
                const positionId = elem.id;
                const roleId = teamsStats.positionRoleMap[positionId];
                const chapterId = teamsStats.positionChapterMap[positionId];

                if (roleId && chapterId) {
                    const isStaffed = subChaptersList.find(
                        el => el.id === teamsStats.positionChapterMap[positionId].id
                    );
                    if (isStaffed) {
                        const positionsOnRoleId = teamsStats.rolesPositionsMap[roleId.id] || [];
                        let allocation = 0;
                        positionsOnRoleId.forEach(p_el => {
                            if (teamsStats.positionAllocationMap[p_el.id])
                                allocation +=
                                    teamsStats.allocation_intervalsMap[
                                        teamsStats.positionAllocationMap[p_el.id].allocationId
                                    ].allocation;
                        });

                        if (allocation > 1) {
                            more_than_100_percent_allocated++;
                        } else if (allocation < 1) {
                            less_than_100_percent_allocated++;
                        } else {
                            equal_to_100_percent_allocated++;
                        }

                        staffed++;
                        fteStaffed += teamsStats.roleFteMap[roleId.id];
                    }
                }
            });
            el.staffed = staffed;
            el.fteStaffed = fteStaffed;
            el.more_than_100_percent_allocated = more_than_100_percent_allocated;
            el.less_than_100_percent_allocated = less_than_100_percent_allocated;
            el.equal_to_100_percent_allocated = equal_to_100_percent_allocated;

            return el;
        });

        setTeamsStats(oldStats => {
            oldStats.flatTeamsList = _newFlatTeamsList;
            return oldStats;
        });
    };

    const getSupplyDemand = date => {
        setIsLoading(true);
        resetState();
        Promise.all([
            getOrgResources({
                id: structureId,
                method: 'chapters',
            }),
            getOrgResources({
                id: structureId,
                method: 'teams',
                filterString: `query={start_date <=date('${date}'),start_date %2B duration>= date('${date}')}`,
            }),
        ])
            .then(response => {
                if (response[0].data.data.length) {
                    const chaptersList = generateSupplyDemandTable(response[0].data);
                    const { id, chapter_name } = chaptersList[Object.keys(chaptersList)[0]];
                    setChapters(chaptersList);
                    const teamsStatsList = generateTeamsStats(response[1].data, id, chapter_name, chaptersList);
                    setTeamsStats(teamsStatsList);
                } else {
                    resetState();
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const _chapterList = Object.values(chapters);
        if (_chapterList.length && !activeChapter.id) {
            onChapterSelect(
                _chapterList[0].id,
                _chapterList[0].chapter_name,
                chapters,
                teamsStats.chapterPositionsMap,
                teamsStats.positionAllocationMap,
                teamsStats.allocation_intervalsMap
            );
        }
    }, [JSON.stringify(chapters), JSON.stringify(teamsStats)]);

    const resetState = () => {
        setChapters({});
        setTeamsStats({
            teamsPositionsMap: {},
            positionRoleMap: {},
            positionChapterMap: {},
            chapterPositionsMap: {},
            teamsMap: {},
            teamsNameMap: {},
            rolesPositionsMap: {},
            positionAllocationMap: {},
            allocation_intervalsMap: {},
            roleFteMap: {},
            flatTeamsList: [],
            positionTeam: {},
        });
        setActiveChapter({ id: '', name: '' });
        setDemand(0);
        setFlattenActiveChapterList([]);
        setTeamDistributionHierarchy({});
        setShowDistributionTable({ status: false, view: '' });
        setDistributionTreeExpandIdList([]);
    };

    const generateTeamsStats = (teams, selectedChapterId, selectedChapterName, chaptersList) => {
        const chapterPositionsMap = {};
        const teamsMap = {};
        const teamsNameMap = {};
        const rolesPositionsMap = {};
        const positionAllocationMap = {};
        const allocation_intervalsMap = {};
        const formattedTeams = [];
        const teamsReferenceMap = {};
        const positionTeam = {};
        const positionTeamReferenceMap = {};
        const teamsPositionsMap = {};
        const positionRoleMap = {};
        const positionChapterMap = {};
        const teamHierarchyMap = {};
        const roleFteMap = {};
        const rolesPositionsMap2 = {};

        (teams.data || []).forEach(elem => {
            teamsNameMap[elem.id] = { name: elem.attributes.name };
            if (elem.relationships.positions.data.length) {
                teamsMap[elem.id] = { name: elem.attributes.name };
                teamsPositionsMap[elem.id] = elem.relationships.positions.data;
                elem.relationships.positions.data.forEach(el => {
                    positionTeamReferenceMap[el.id] = elem.id;
                });
            }
            formattedTeams.push({
                id: elem.id,
                name: elem.attributes.name,
                parentId: null,
                child: [],
                hierarchyLevel: 1,
            });

            teamHierarchyMap[elem.id] = 1;

            (elem.relationships.subteams.data || []).forEach(el => {
                teamsReferenceMap[el.id] = elem.id;
            });
        });

        (teams.included || []).forEach(elem => {
            if (elem.type === 'teams') {
                if (elem.relationships.positions.data && elem.relationships.positions.data.length) {
                    teamsPositionsMap[elem.id] = elem.relationships.positions.data;
                    elem.relationships.positions.data.forEach(el => {
                        positionTeamReferenceMap[el.id] = elem.id;
                    });
                }
            }
        });

        (teams.included || []).forEach(elem => {
            const { type, relationships, attributes } = elem;
            if (type === 'positions') {
                const positionId = elem.id;
                if (relationships.chapter && relationships.chapter.data) {
                    const chapterId = relationships.chapter.data.id;
                    if (chapterPositionsMap[chapterId]) {
                        chapterPositionsMap[chapterId] = {
                            ...chapterPositionsMap[chapterId],
                            [positionId]: {
                                teamId: positionTeamReferenceMap[positionId],
                                chapterId,
                            },
                        };
                    } else {
                        chapterPositionsMap[chapterId] = {
                            [positionId]: {
                                teamId: positionTeamReferenceMap[positionId],
                                chapterId,
                            },
                        };
                    }
                }

                if (relationships.allocation_intervals.data) {
                    positionAllocationMap[positionId] = {
                        allocationId: relationships.allocation_intervals.data[0].id,
                    };
                }
                if (relationships.team.data) {
                    positionTeam[positionId] = relationships.team.data.id;
                }
                if (relationships.role.data) {
                    positionRoleMap[positionId] = relationships.role.data;
                }
                if (relationships.chapter.data) {
                    positionChapterMap[positionId] = relationships.chapter.data;
                }
                if (relationships.role?.data?.id) {
                    if (rolesPositionsMap[relationships.role.data.id]?.length) {
                        rolesPositionsMap[relationships.role.data.id].push({ type: 'positions', id: positionId });
                    } else {
                        rolesPositionsMap[relationships.role.data.id] = [{ type: 'positions', id: positionId }];
                    }
                }
            }
            if (type === 'teams') {
                teamsNameMap[elem.id] = { name: attributes.name };
                if (relationships.positions.data && relationships.positions.data.length) {
                    teamsMap[elem.id] = { name: attributes.name };
                }

                if (!teamsReferenceMap[elem.id]) {
                    formattedTeams.push({
                        id: elem.id,
                        name: elem.attributes.name,
                        parentId: null,
                        child: [],
                        hierarchyLevel: 1,
                    });
                    teamHierarchyMap[elem.id] = 1;
                    teamsReferenceMap[elem.id] = true;
                } else {
                    formattedTeams.push({
                        id: elem.id,
                        name: elem.attributes.name,
                        parentId: teamsReferenceMap[elem.id],
                        child: [],
                    });
                }

                (elem.relationships.subteams.data || []).forEach(el => {
                    teamsReferenceMap[el.id] = elem.id;
                });
            }
            if (type === 'roles') {
                rolesPositionsMap2[elem.id] = relationships.positions.data;
                roleFteMap[elem.id] = attributes.fte_share;
            }
            if (type === 'allocation_intervals') {
                allocation_intervalsMap[elem.id] = { allocation: attributes.allocation };
            }
        });

        formattedTeams.forEach(team => {
            if (team.parentId) {
                team.hierarchyLevel = teamHierarchyMap[team.parentId] + 1;
                teamHierarchyMap[team.id] = team.hierarchyLevel;
            }
        });
        return {
            teamsPositionsMap,
            positionRoleMap,
            positionChapterMap,
            positionAllocationMap,
            allocation_intervalsMap,
            chapterPositionsMap,
            teamsMap,
            teamsNameMap,
            rolesPositionsMap,
            positionTeam,
            roleFteMap,
            // teamsHierarchy,
            flatTeamsList: formattedTeams,
        };
    };

    const generateSupplyDemandTable = chapters => {
        const chapterReferenceMap = {};
        const formattedChapters = [];
        const filteredChapters = (chapters.included || []).filter(el => el.type === 'chapters');
        (chapters.data || []).forEach(el => {
            formattedChapters.push({
                id: el.id,
                chapter_name: el.attributes.name,
                supply: '-',
                demand: '-',
                gap: '-',
                staffed: '-',
                less_than_100_percent_allocated: '-',
                more_than_100_percent_allocated: '-',
                parentId: null,
                child: [],
            });
            (el.relationships.parts.data || []).forEach(elem => {
                chapterReferenceMap[elem.id] = el.id;
            });
        });
        filteredChapters.forEach(el => {
            if (!chapterReferenceMap[el.id]) {
                formattedChapters.push({
                    id: el.id,
                    chapter_name: el.attributes.name,
                    supply: '-',
                    demand: '-',
                    gap: '-',
                    staffed: '-',
                    less_than_100_percent_allocated: '-',
                    more_than_100_percent_allocated: '-',
                    parentId: null,
                    child: [],
                });
                chapterReferenceMap[el.id] = true;
            } else {
                formattedChapters.push({
                    id: el.id,
                    chapter_name: el.attributes.name,
                    supply: '-',
                    demand: '-',
                    gap: '-',
                    staffed: '-',
                    less_than_100_percent_allocated: '-',
                    more_than_100_percent_allocated: '-',
                    parentId: chapterReferenceMap[el.id],
                    child: [],
                });
            }

            (el.relationships.parts.data || []).forEach(elem => {
                chapterReferenceMap[elem.id] = el.id;
            });
        });

        return formattedUtilities.generateHierarchy(formattedChapters);
    };

    const { REPORT_SUB } = SITE_TXT;

    const getFilteredResults = (filterToApply = {}) => {
        setFiltersData({ ...filterToApply });
    };

    const setFiltersData = filtersUsed => {
        setFilters(filtersUsed);
    };

    const phaseUpdated = () => {
        setFilterApplied(false);
    };
    const compareData = str1 => {
        if (str1) {
            setFilters({ ...filters });
        }
    };
    const setStructureData = id => {
        setStructureId(id);
        setFilterApplied(primaryFilters.filter(({ isSelected }) => isSelected));
    };
    const getReportOptions = () => {
        const reportOptions = [];
        Object.keys(REPORTS_LINK).forEach(el => {
            if (privileges[el]) {
                reportOptions.push(REPORTS_LINK[el]);
            }
        });
        return reportOptions;
    };

    const resetManagerFilter = () => {
        // clear manager data
        clrManagerFilters();
        const el = document.getElementById('search');
        if (el) {
            el.value = '';
        }
    };

    const hasStructure = phaseStructureInfo.isApiCallSuccess && phaseStructureInfo.anyStructurePresent;

    const updateReport = index => {
        resetFilters();
        const links = Object.values(REPORTS_LINK);
        const route = links.filter(el => el.value === index);
        history.push(route[0].routeValue);
    };

    const handleClick = () => setDrawerOpen(!drawerOpen);

    const teamWiseDemand = type => {
        const teamsPositions = {};
        const _positionsList = Object.values(teamsStats.chapterPositionsMap);
        _positionsList.forEach(elem => {
            Object.keys(elem).forEach(el => {
                if (teamsPositions[elem[el].teamId]) {
                    teamsPositions[elem[el].teamId] = {
                        ...teamsPositions[elem[el].teamId],
                        demand: flattenActiveChapterList.find(ele => ele.id == elem[el].chapterId)
                            ? teamsPositions[elem[el].teamId].demand + 1
                            : teamsPositions[elem[el].teamId].demand,
                    };
                } else {
                    teamsPositions[elem[el].teamId] = {
                        name: teamsStats.teamsMap[elem[el].teamId].name,
                        demand: flattenActiveChapterList.find(ele => ele.id == elem[el].chapterId) ? 1 : 0,
                    };
                }

                if (flattenActiveChapterList.find(ele => ele.id == elem[el].chapterId)) {
                    if (!teamsPositions[elem[el].teamId].fteDemand) {
                        teamsPositions[elem[el].teamId].fteDemand = 0;
                    }
                    teamsPositions[elem[el].teamId].fteDemand +=
                        teamsStats.allocation_intervalsMap[
                            teamsStats.positionAllocationMap[el].allocationId
                        ].allocation;
                }
            });
        });
        let _teamsInChapters = [];
        flattenActiveChapterList.forEach(el => {
            _teamsInChapters = [..._teamsInChapters, ...Object.values(teamsStats.chapterPositionsMap[el.id] || {})];
        });
        const _copyFlatTeamsList = JSON.parse(JSON.stringify(teamsStats.flatTeamsList));
        _teamsInChapters.forEach(elem => {
            const _team = _copyFlatTeamsList.find(copyListItem => copyListItem.id === elem.teamId);
            _team.demand = (teamsPositions[elem.teamId] || { demand: 0 }).demand;
            _team.fteDemand = (teamsPositions[elem.teamId] || { fteDemand: 0 }).fteDemand;
        });

        const _distributionTreeExpandId = [];
        _copyFlatTeamsList.forEach(list => {
            if (list.parentId) _distributionTreeExpandId.push(list.parentId);

            if (!list.demand) list.demand = 0;

            list.sumOfTheChildren = {
                demand: 0,
                staffed: 0,
                fteDemand: 0,
                fteStaffed: 0,
                more_than_100_percent_allocated: 0,
                less_than_100_percent_allocated: 0,
                equal_to_100_percent_allocated: 0,
            };
        });

        setDistributionTreeExpandIdList(_distributionTreeExpandId);
        const teamsHierarchy = formattedUtilities.generateHierarchy(_copyFlatTeamsList, 'subRows');
        Object.values(teamsHierarchy).forEach(row => formattedUtilities.sumUpValues(row, false));

        let _currentlyStaffed = 0;
        let _fteCurrentlyStaffed = 0;
        let _equalTo100Allocated = 0;
        let _moreThan100Allocated = 0;
        let _lessThan100Allocated = 0;
        Object.values(teamsHierarchy).forEach(row => {
            _equalTo100Allocated += row.sumOfTheChildren.equal_to_100_percent_allocated;
            _lessThan100Allocated += row.sumOfTheChildren.less_than_100_percent_allocated;
            _moreThan100Allocated += row.sumOfTheChildren.more_than_100_percent_allocated;
            _currentlyStaffed += row.sumOfTheChildren.staffed;
            _fteCurrentlyStaffed += row.sumOfTheChildren.fteStaffed;
        });
        setLessThan100Allocated(_lessThan100Allocated);
        setMoreThan100Allocated(_moreThan100Allocated);
        setEqualTo100Allocated(_equalTo100Allocated);
        setCurrentlyStaffed(_currentlyStaffed);
        setFteCurrentlyStaffed(_fteCurrentlyStaffed);

        setTeamDistributionHierarchy(teamsHierarchy);
        setShowDistributionTable({ status: true, view: type });
    };

    const closeDistributionTable = () => {
        setShowDistributionTable({ show: false, view: '' });
    };

    const handleToggleChange = e => setShowFteValues(e.target.checked);

    return (
        <Box className="supply-demand-dashboard clearFix">
            <div className="topHeaders">
                <Header />
                <SubHeader
                    showStructures
                    enableCompare={strCompareFetched}
                    filterApplied={filterApplied}
                    setDrawerOpen={handleClick}
                    primaryFilters={primaryFilters}
                    getFilteredResults={getFilteredResults}
                    phaseUpdated={phaseUpdated}
                    getCurrentStructure={compareData}
                    setStructureId={setStructureData}
                    resetManagerFilter={resetManagerFilter}
                    enableRefreshTab
                    enableFiltersTab
                />
            </div>
            <section className="selection-content">
                <FormControl className="reportLink">
                    <InputLabel shrink id="reportLink">
                        {REPORT_SUB}
                    </InputLabel>
                    <Select
                        labelId="reportLink"
                        id="reportLinkSelect"
                        value={getReportOptions().find(el => el.name === 'Chapter supply/demand by teams').value}
                        onChange={event => updateReport(event.target.value)}
                    >
                        {getReportOptions().map((report, repIndex) => (
                            <MenuItem key={report.value} value={report.value}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {hasStructure && <DatePickers label="Teams as of" setDate={setDate} value={date} />}
                {
                    <span style={{ float: 'right', paddingTop: 10 }}>
                        <Toggle labelLeft="Headcount" labelRight="FTE" onChange={handleToggleChange} />
                    </span>
                }
            </section>

            <section className="supply-demand__view">
                {isLoading ? (
                    <Loader showBackground background="transparent" />
                ) : Object.keys(chapters).length ? (
                    <Fragment>
                        <div className="supply-demand_content">
                            <div className="chapters-hierarchy">
                                <Chapters chapters={chapters} onChapterSelect={onChapterSelect} />
                            </div>

                            <div className="chapters-details">
                                <ChaptersDetails
                                    subChaptersList={subChaptersList}
                                    allChaptersList={Object.values(chapters)}
                                    chapterObjArr={chapterObjArr}
                                    totalDemandValue={totalDemandValue}
                                    chapter={activeChapter}
                                    structureId={structureId}
                                    demand={demand}
                                    totalFteDemand={totalFteDemand}
                                    fteDemand={fteDemand}
                                    rolesPositionMap={teamsStats.rolesPositionsMap}
                                    positionAllocationMap={teamsStats.positionAllocationMap}
                                    allocation_intervalsMap={teamsStats.allocation_intervalsMap}
                                    teamWiseDemand={teamWiseDemand}
                                    distributionTableData={teamDistributionHierarchy}
                                    showDistributionTable={showDistributionTable}
                                    closeDistributionTable={closeDistributionTable}
                                    expandIdList={distributionTreeExpandIdList}
                                    chapterPositionsMap={teamsStats.chapterPositionsMap}
                                    teamsMap={teamsStats.teamsMap}
                                    teamsNameMap={teamsStats.teamsNameMap}
                                    positionTeam={teamsStats.positionTeam}
                                    positionRoleMap={teamsStats.positionRoleMap}
                                    showFteValues={showFteValues}
                                    lessThan100Allocated={lessThan100Allocated}
                                    moreThan100Allocated={moreThan100Allocated}
                                    equalTo100Allocated={equalTo100Allocated}
                                    currentlyStaffed={currentlyStaffed}
                                    fteCurrentlyStaffed={fteCurrentlyStaffed}
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <NoDataFound
                        mainMessage={`No chapters found for the selected structure`}
                        secondaryMessage={`Please select a different structure`}
                    />
                )}
            </section>
        </Box>
    );
};

export default SupplyDemand;
