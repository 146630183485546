import { createSelector } from 'reselect';

const filtersReducer = state => state.get('filtersReducer');

const selectAddressableOpportunityFilterLoader = () =>
    createSelector(filtersReducer, state => state.toJS().addressableOpportunityFilterLoader);

const selectFilters = () => createSelector(filtersReducer, state => state.toJS().filters);

const selectFiltersToApply = () => createSelector(filtersReducer, state => state.toJS().filtersToApply);

const selectFiltersTray = () => createSelector(filtersReducer, state => state.toJS().filtersTray);

const selectFiltersReadyToApply = () => createSelector(filtersReducer, state => state.toJS().isFiltersReadyToApply);

const fetchFilterStatus = () => createSelector(filtersReducer, state => state.toJS().isFiltersFetched);

const selectRefreshCount = () => createSelector(filtersReducer, state => state.toJS().refreshCount);

const selectStaticFilters = () => createSelector(filtersReducer, state => state.toJS().staticFilters);

const multiSelectFilters = () => createSelector(filtersReducer, state => state.toJS().multiStructureFilters);
const selectMultiFiltersToApply = type =>
    createSelector(filtersReducer, state => state.toJS().multiFiltersToApply[type]);
const selectMultiFiltersTray = type => createSelector(filtersReducer, state => state.toJS().multiFiltersTray[type]);

const selectDesignProgressLoading = () => createSelector(filtersReducer, state => state.toJS().designProgressLoading);

export {
    selectAddressableOpportunityFilterLoader,
    selectFilters,
    fetchFilterStatus,
    selectFiltersToApply,
    selectFiltersReadyToApply,
    selectRefreshCount,
    multiSelectFilters,
    selectMultiFiltersToApply,
    selectStaticFilters,
    selectMultiFiltersTray,
    selectFiltersTray,
    selectDesignProgressLoading,
};
