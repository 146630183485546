import {
    GET_FILTERS,
    GET_FILTERS_CONTENT,
    GET_PHASE_STRUCTURES,
    GET_REPORT_SUMMARY,
    GET_STRUCTURE_COMPARE,
    GET_STRUCTURE_SUMMARY,
    RESET_FILTERS,
    RESET_LOGOUT_LOADER,
    RESET_STRUCTURE_COMPARE,
    SET_DESIGN_PROGRESS_EDIT_MODE,
    SET_DESIGN_PROGRESS_OVERALL_ACTIVE_STRUCTURES,
    SET_LOADER,
    SET_STATIC_FILTERS,
    SET_STRUCTURE,
    SET_STRUCTURE_COMPARE,
    SORT_RESULTS,
    UPDATE_FILTERS,
    UPDATE_RANGES,
    GET_TARGETS,
    SET_TARGETS,
    UPDATE_TARGETS,
    SET_DESIGN_PROGRESS_LEADERS_ACTIVE_STRUCTURES,
    SET_DESIGN_PROGRESS_FUNCTIONS_ACTIVE_STRUCTURES,
    SET_DESIGN_PROGRESS_STATE,
    SET_ORGSHAPE_FUNCTIONS_DATA,
    SET_ORGSHAPE_LEADERS_DATA,
} from './constants';

export const getPhaseStructures = (clientId = '', phaseId = '') => ({
    type: `${GET_PHASE_STRUCTURES}_PENDING`,
    clientId,
    phaseId,
});

export const getPhaseStructuresSuccess = payload => ({
    type: `${GET_PHASE_STRUCTURES}_SUCCESS`,
    payload,
});

export const getPhaseStructuresFailed = (payload = {}) => ({
    type: `${GET_PHASE_STRUCTURES}_FAILED`,
    payload,
});

export const getStructureCompare = (attribute = '', str1 = '', str2 = '', primaryFilters = {}) => ({
    type: `${GET_STRUCTURE_COMPARE}_PENDING`,
    attribute,
    str1,
    str2,
    primaryFilters,
});

export const getStructureCompareSuccess = payload => ({
    type: `${GET_STRUCTURE_COMPARE}_SUCCESS`,
    payload,
});

export const resetStrCompFetched = () => ({
    type: `${RESET_STRUCTURE_COMPARE}`,
});

export const setStrCompFetched = () => ({
    type: `${SET_STRUCTURE_COMPARE}`,
});

export const getStructureCompareFailed = (payload = {}) => ({
    type: `${GET_STRUCTURE_COMPARE}_FAILED`,
    payload,
});

export const getStructureSummary = (attribute = '', str1 = '', str2 = '', primaryFilters = {}) => ({
    type: `${GET_STRUCTURE_SUMMARY}_PENDING`,
    attribute,
    str1,
    str2,
    primaryFilters,
});

export const getStructureSummarySuccess = payload => ({
    type: `${GET_STRUCTURE_SUMMARY}_SUCCESS`,
    payload,
});

export const getStructureSummaryFailed = (payload = {}) => ({
    type: `${GET_STRUCTURE_SUMMARY}_FAILED`,
    payload,
});

export const setLoader = (payload = false) => ({
    type: SET_LOADER,
    payload,
});

export const setNewFilters = (payload = {}) => ({
    type: RESET_FILTERS,
    payload,
});

export const updateFilters = (payload = {}) => ({
    type: UPDATE_FILTERS,
    payload,
});

export const sortTable = (payload = []) => ({
    type: SORT_RESULTS,
    payload,
});

export const setStaticFilters = filters => ({
    type: `${SET_STATIC_FILTERS}`,
    payload: filters,
});

export const getPrimaryFilters = (attribute = '', str1 = '') => ({
    type: `${GET_FILTERS}_PENDING`,
    attribute,
    str1,
});

export const getManagerFilters = (attribute = '', str1 = '') => ({
    type: `${GET_FILTERS}_Manager_PENDING`,
    attribute,
    str1,
});

export const clrManagerFilters = () => ({
    type: `${GET_FILTERS}_Manager_CLR`,
});

export const clrSelectedManager = () => ({
    type: `${GET_FILTERS}_SELECTED_Manager_CLR`,
});

export const getPrimaryFiltersSuccess = payload => ({
    type: `${GET_FILTERS}_SUCCESS`,
    payload,
});

export const getPrimaryFiltersFailed = (payload = {}) => ({
    type: `${GET_FILTERS}_FAILED`,
    payload,
});

export const getReportSummary = (id = '', primaryFilters = {}) => ({
    type: `${GET_REPORT_SUMMARY}_PENDING`,
    id,
    primaryFilters,
});

export const getReportSummarySuccess = payload => ({
    type: `${GET_REPORT_SUMMARY}_SUCCESS`,
    payload,
});

export const getReportSummaryFailed = (payload = {}) => ({
    type: `${GET_REPORT_SUMMARY}_FAILED`,
    payload,
});

export const getSecondaryFilters = (attribute = '', id = '', arrToRepeat = [], isSelected = false) => ({
    type: `${GET_FILTERS_CONTENT}_PENDING`,
    attribute,
    id,
    arrToRepeat,
    isSelected,
});

export const getSecondaryFiltersSuccess = payload => ({
    type: `${GET_FILTERS_CONTENT}_SUCCESS`,
    payload,
});

export const getSecondaryFiltersFailed = (payload = {}) => ({
    type: `${GET_FILTERS_CONTENT}_FAILED`,
    payload,
});

export const setStructure = (payload = {}) => ({
    type: `${SET_STRUCTURE}`,
    payload,
});

export const resetLogOutLoader = (payload = false) => ({
    type: RESET_LOGOUT_LOADER,
    payload,
});

export const setDesignProgressEditMode = payload => ({
    type: SET_DESIGN_PROGRESS_EDIT_MODE,
    payload,
});

export const setDesignProgressOverallActiveStructures = payload => ({
    type: SET_DESIGN_PROGRESS_OVERALL_ACTIVE_STRUCTURES,
    payload,
});

export const setDesignProgressLeadersActiveStructures = payload => ({
    type: SET_DESIGN_PROGRESS_LEADERS_ACTIVE_STRUCTURES,
    payload,
});

export const setDesignProgressFunctionsActiveStructures = payload => ({
    type: SET_DESIGN_PROGRESS_FUNCTIONS_ACTIVE_STRUCTURES,
    payload,
});

export const updateRanges = (range, referer) => ({
    type: UPDATE_RANGES,
    payload: {
        range,
        referer,
    },
});

export const getTargets = () => ({ type: GET_TARGETS });

export const setTargets = payload => ({ type: SET_TARGETS, payload });

export const updateTargets = payload => ({
    type: UPDATE_TARGETS,
    payload,
});

export const setDesignProgressState = payload => ({ type: SET_DESIGN_PROGRESS_STATE, payload });

export const setOrgshapeFunctionsData = payload => ({ type: SET_ORGSHAPE_FUNCTIONS_DATA, payload });
export const setOrgshapeLeadersData = payload => ({ type: SET_ORGSHAPE_LEADERS_DATA, payload });
