import React from 'react';
import './index.scss';

const LeadersName = ({ leader }) => {
    return (
        <div className="leaders-name">
            <div className="role">{leader.designation}</div>
            {/* <div className="name">{leader.name}</div> */}
        </div>
    );
};

export default LeadersName;
