import { fromJS } from 'immutable';

import {
    GET_FILTERS,
    GET_FILTERS_CONTENT,
    GET_PHASE_STRUCTURES,
    GET_REPORT_SUMMARY,
    GET_STRUCTURE_COMPARE,
    GET_STRUCTURE_SUMMARY,
    RESET_FILTERS,
    RESET_LOGOUT_LOADER,
    RESET_STRUCTURE_COMPARE,
    SET_DESIGN_PROGRESS_EDIT_MODE,
    SET_DESIGN_PROGRESS_OVERALL_ACTIVE_STRUCTURES,
    SET_DESIGN_PROGRESS_LEADERS_ACTIVE_STRUCTURES,
    SET_DESIGN_PROGRESS_FUNCTIONS_ACTIVE_STRUCTURES,
    SET_LOADER,
    SET_STATIC_FILTERS,
    SET_STRUCTURE,
    SET_STRUCTURE_COMPARE,
    SORT_RESULTS,
    UPDATE_FILTERS,
    UPDATE_RANGES,
    SET_TARGETS,
    SET_DESIGN_PROGRESS_STATE,
    SET_ORGSHAPE_FUNCTIONS_DATA,
    SET_ORGSHAPE_LEADERS_DATA,
} from './constants';

export const INITIAL_STATE = fromJS({
    count: 0,
    totalCount: 0,
    totalCompleted: 0,
    isLoading: false,
    strCompFetched: false,
    strSummaryFetched: false,
    structures: [],
    phaseStructureInfo: {
        isApiCallSuccess: false,
        anyStructurePresent: false,
    },
    primaryFilters: [],
    structure: {},
    structureCompare: {
        attributes: [],
    },
    reportSummary: {
        isLoading: true,
        cost: { totalCost: 0, totalICCost: 0, totalManagerCost: 0 },
        ftes: { total: 0, totalICContractors: 0, totalICs: 0, totalManagerContractors: 0, totalManagers: 0 },
        stats: { avgSpan: '', totalFTEs: '', totalLayers: '', uniqueJobs: '', managerUnder4: '', managerUnder2: '' },
    },
    structureSummary: {
        changes: [],
        filters: {
            chanageTypes: [],
        },
    },
    isDesignProgressInEditMode: false,
    designProgressOverallActiveStructures: {
        structure1: { id: '', name: '' },
        structure2: { id: '', name: '' },
        structure3: { id: '', name: '' },
    },
    designProgressLeadersActiveStructures: {
        structure1: { id: '', name: '' },
        structure2: { id: '', name: '' },
        structure3: { id: '', name: '' },
    },
    designProgressFunctionsActiveStructures: {
        structure1: { id: '', name: '' },
        structure2: { id: '', name: '' },
        structure3: { id: '', name: '' },
    },
    designProgressOverallRanges: {
        min: 30,
        midLow: 30,
        midHigh: 60,
        max: 60,
    },
    designProgressLeadersRanges: {
        min: 30,
        midLow: 30,
        midHigh: 60,
        max: 60,
    },
    designProgressFunctionsRanges: {
        min: 30,
        midLow: 30,
        midHigh: 60,
        max: 60,
    },
    targets: {},
    designProgressState: 'overall',
    orgShapeFunctionsData: {},
    orgShapeN1LeadersData: {},
});

const manager = {
    name: 'manager',
    keyToUse: 'manager',
    children: [],
    selectedOption: {},
    isSelected: false,
};

const reducer = (state = INITIAL_STATE, { type, payload, arrToRepeat: arrFromAction = [] }) => {
    switch (type) {
        case `${GET_PHASE_STRUCTURES}_PENDING`: {
            const phaseStructureInfo = {
                isApiCallSuccess: false,
                anyStructurePresent: false,
            };
            return state
                .set('isLoading', true)
                .set('strCompFetched', false)
                .set('strSummaryFetched', false)
                .set('totalCount', 0)
                .set('totalCompleted', 0)
                .set('primaryFilters', fromJS([manager]))
                .set(
                    'structureCompare',
                    fromJS({
                        attributes: [],
                    })
                )
                .set(
                    'structureSummary',
                    fromJS({
                        changes: [],
                        filters: {
                            chanageTypes: [],
                        },
                    })
                )
                .set('structure', fromJS({}))
                .set('phaseStructureInfo', fromJS(phaseStructureInfo));
        }
        case RESET_LOGOUT_LOADER: {
            return state.set('isLoading', payload);
        }
        case `${GET_STRUCTURE_SUMMARY}_PENDING`: {
            return state.update('totalCount', totalCount => totalCount + 1);
        }
        case `${GET_FILTERS}_PENDING`: {
            return state.set('primaryFilters', fromJS([manager]));
        }
        case `${GET_FILTERS}_Manager_CLR`: {
            return state.updateIn(['primaryFilters'], arr => arr.shift().unshift(fromJS(manager)));
        }
        case `${RESET_STRUCTURE_COMPARE}`: {
            return state.set('strCompFetched', false);
        }
        case `${SET_STRUCTURE_COMPARE}`: {
            return state.set('strCompFetched', true);
        }
        case `${GET_STRUCTURE_COMPARE}_PENDING`: {
            return state
                .update('count', count => count + 1)
                .update('totalCount', totalCount => totalCount + 1)
                .set('isLoading', true)
                .set(
                    'structureCompare',
                    fromJS({
                        attributes: [],
                    })
                )
                .set(
                    'structureSummary',
                    fromJS({
                        changes: [],
                        filters: {
                            chanageTypes: [],
                        },
                    })
                );
        }
        case `${GET_STRUCTURE_COMPARE}_SUCCESS`: {
            const mainData = payload.data;
            const key = Object.keys(mainData)[0];
            return state
                .update('count', count => count - 1)
                .update('totalCompleted', totalCompleted => totalCompleted + 1)
                .set('strCompFetched', true)
                .set('isLoading', false)
                .updateIn(['structureCompare', key], () => mainData[key]);
        }
        case `${GET_STRUCTURE_COMPARE}_FAILED`: {
            return state
                .set('error', payload)
                .update('count', count => count - 1)
                .update('totalCompleted', totalCompleted => totalCompleted + 1);
        }
        case SET_LOADER: {
            return state.set('isLoading', payload);
        }
        case `${GET_PHASE_STRUCTURES}_SUCCESS`: {
            const phaseStructureInfo = {
                isApiCallSuccess: true,
                anyStructurePresent: payload.data.length > 0,
            };
            return state
                .set('isLoading', false)
                .set('structures', payload.data)
                .set('phaseStructureInfo', fromJS(phaseStructureInfo))
                .set('isDesignProgressInEditMode', false)
                .set(
                    'designProgressOverallActiveStructures',
                    fromJS({
                        structure1: { id: '', name: '' },
                        structure2: { id: '', name: '' },
                        structure3: { id: '', name: '' },
                    })
                )
                .set(
                    'designProgressOverallRanges',
                    fromJS({
                        min: 30,
                        midLow: 30,
                        midHigh: 60,
                        max: 60,
                    })
                )
                .set(
                    'designProgressLeadersActiveStructures',
                    fromJS({
                        structure1: { id: '', name: '' },
                        structure2: { id: '', name: '' },
                        structure3: { id: '', name: '' },
                    })
                )
                .set(
                    'designProgressFunctionsActiveStructures',
                    fromJS({
                        structure1: { id: '', name: '' },
                        structure2: { id: '', name: '' },
                        structure3: { id: '', name: '' },
                    })
                )
                .set(
                    'designProgressLeadersRanges',
                    fromJS({
                        min: 30,
                        midLow: 30,
                        midHigh: 60,
                        max: 60,
                    })
                )
                .set(
                    'designProgressFunctionsRanges',
                    fromJS({
                        min: 30,
                        midLow: 30,
                        midHigh: 60,
                        max: 60,
                    })
                );
        }
        case `${GET_PHASE_STRUCTURES}_FAILED`: {
            const phaseStructureInfo = {
                isApiCallSuccess: false,
                anyStructurePresent: false,
            };
            return state.set('error', payload).set('phaseStructureInfo', fromJS(phaseStructureInfo));
        }
        case `${GET_REPORT_SUMMARY}_PENDING`: {
            return state.updateIn(['reportSummary', 'isLoading'], () => true);
        }
        case `${GET_REPORT_SUMMARY}_SUCCESS`: {
            return state.set('reportSummary', fromJS(payload.data));
        }

        case `${GET_STRUCTURE_SUMMARY}_SUCCESS`: {
            const costDiffByAttributes = {};
            const { changes = [], attributes = [] } = payload;
            const unique = [
                ...new Set(
                    changes.map(item => {
                        costDiffByAttributes[item.changeType] =
                            (costDiffByAttributes[item.changeType] || 0) + (item.costdiff || 0);
                        return item.changeType;
                    })
                ),
            ];
            attributes.forEach(attr => (attr.cost = costDiffByAttributes[attr.name] || 0));
            const filterToUse = unique.map(name => ({ name, isSelected: true }));
            const { attributes: oldAtt = [] } = state.toJS().structureCompare;
            const lengthToCheck = oldAtt.length >= 4 ? 0 : 4 - oldAtt.length;
            const attributesToSel = attributes
                .slice(0, lengthToCheck)
                .map(innerobj => ({ ...innerobj, isSelected: true }));
            const attributesToNonSel = attributes
                .slice(lengthToCheck)
                .map(innerobj => ({ ...innerobj, isSelected: false }));
            return state
                .set('strSummaryFetched', true)
                .update('totalCompleted', totalCompleted => totalCompleted + 1)
                .updateIn(['structureCompare', 'attributes'], arr => arr.concat(fromJS(attributesToSel)))
                .updateIn(['structureCompare', 'attributes'], arr => arr.concat(fromJS(attributesToNonSel)))
                .updateIn(['structureSummary', 'filters', 'chanageTypes'], arr => arr.concat(fromJS(filterToUse)))
                .updateIn(['structureSummary', 'changes'], arr => arr.concat(fromJS(changes)));
        }
        case `${GET_STRUCTURE_SUMMARY}_FAILED`: {
            return state
                .set('strSummaryFetched', true)
                .set('error', payload)
                .update('totalCompleted', totalCompleted => totalCompleted + 1);
        }

        case `${SET_STATIC_FILTERS}`: {
            let staticFilters = [];
            Object.keys(payload).forEach(name => {
                staticFilters = [
                    ...staticFilters,
                    {
                        name: name,
                        keyToUse: name,
                        children: payload[name].children,
                        isStaticFilter: true,
                        type: payload[name].type,
                        isSelected: payload[name].isSelected || false,
                    },
                ];
            });
            return state.updateIn(['primaryFilters'], arr => arr.concat(fromJS(staticFilters)));
        }
        case `${GET_FILTERS_CONTENT}_PENDING`: {
            return state.setIn([...arrFromAction.slice(0, -1), 'isLoading'], true);
        }
        case `${GET_FILTERS_CONTENT}_SUCCESS`: {
            const { arrToRepeat = [], children = [] } = payload;
            const arrayToLoaded = [...arrToRepeat.slice(0, -1), 'isLoaded'];
            const arrayToLoading = [...arrToRepeat.slice(0, -1), 'isLoading'];
            const arrayToOpen = [...arrToRepeat.slice(0, -1), 'isOpen'];
            return state
                .setIn(arrToRepeat, fromJS(children))
                .setIn(arrayToLoaded, true)
                .setIn(arrayToOpen, true)
                .setIn(arrayToLoading, false);
        }
        case `${GET_FILTERS_CONTENT}_FAILED`: {
            const { arrToRepeat = [] } = payload;
            const arrayToLoaded = [...arrToRepeat.slice(0, -1), 'isLoaded'];
            const arrayToLoading = [...arrToRepeat.slice(0, -1), 'isLoading'];
            return state.setIn(arrayToLoaded, true).setIn(arrayToLoading, false);
        }
        case UPDATE_FILTERS: {
            const { arrayToOpen = [], isOpen = false } = payload;
            return state.setIn(arrayToOpen, isOpen);
        }
        case RESET_FILTERS:
            return state.updateIn(['primaryFilters'], () => fromJS(payload));
        case SORT_RESULTS:
            return state.updateIn(['structureSummary', 'changes'], () => fromJS(payload));
        case SET_STRUCTURE: {
            return state.set('structure', fromJS(payload));
        }
        case SET_DESIGN_PROGRESS_EDIT_MODE: {
            return state.set('isDesignProgressInEditMode', fromJS(payload));
        }
        case SET_DESIGN_PROGRESS_OVERALL_ACTIVE_STRUCTURES: {
            return state.set('designProgressOverallActiveStructures', fromJS(payload));
        }

        case SET_DESIGN_PROGRESS_LEADERS_ACTIVE_STRUCTURES: {
            return state.set('designProgressLeadersActiveStructures', fromJS(payload));
        }
        case SET_DESIGN_PROGRESS_FUNCTIONS_ACTIVE_STRUCTURES: {
            return state.set('designProgressFunctionsActiveStructures', fromJS(payload));
        }
        case UPDATE_RANGES: {
            if (payload.referer === 'overall') {
                return state.set('designProgressOverallRanges', fromJS(payload.range));
            }
            if (payload.referer === 'leaders') {
                return state.set('designProgressLeadersRanges', fromJS(payload.range));
            }
            if (payload.referer === 'functions') {
                return state.set('designProgressFunctionsRanges', fromJS(payload.range));
            }
            return state;
        }
        case SET_TARGETS: {
            return state.set('targets', fromJS(payload));
        }
        case `${SET_DESIGN_PROGRESS_STATE}`: {
            return state.set('designProgressState', payload);
        }
        case SET_ORGSHAPE_FUNCTIONS_DATA: {
            return state.set('orgShapeFunctionsData', fromJS(payload));
        }
        case SET_ORGSHAPE_LEADERS_DATA: {
            return state.set('orgShapeN1LeadersData', fromJS(payload));
        }
        default:
            return state;
    }
};

export default reducer;
