import React from 'react';
import './shimmer.scss';

const Shimmer = () => {
    return (
        <div className="card shimmer br">
            <div className="wrapper">
                <div className="profilePic animate din" />
                <div className="comment br animate w80" />
                <div className="comment br animate" />
                <div className="comment br animate" />
            </div>
        </div>
    );
};

export default Shimmer;
