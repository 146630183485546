import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Today from './index';
import { selectFiltersToApply, selectRefreshCount } from '../../Filters/selectors/selector';

const stateToProps = createStructuredSelector({
    filtersToApply: selectFiltersToApply(),
    refreshCount: selectRefreshCount(),
});

export default connect(stateToProps)(Today);
