import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Card from '../../../components/Card';
import Shimmer from '../../../components/shimmer';
import NoDataFound from '../../../components/NoDataFound';
import executiveDashboard from '../../ExecutiveDashboard/executiveDashboard.enum';
import './index.scss';

const formatTeams = teamsList => {
    const budgets = [];
    const categories = [];
    const filledSeries = [];
    const unfilledSeries = [];
    const emptyPositions = [];
    const sortedTeams = Object.values(teamsList).sort((a, b) => {
        return a.budget > b.budget ? -1 : 1;
    });

    sortedTeams.forEach(({ name, filled, unfilled, budget }) => {
        const total = filled + unfilled || 1;
        categories.push(name);
        filledSeries.push({
            y: (filled / total) * budget,
            allocated: filled,
        });
        unfilledSeries.push({
            y: (unfilled / total) * budget,
            allocated: unfilled,
        });
        if (!(filled + unfilled)) {
            emptyPositions.push({
                y: budget,
                allocated: 0,
            });
        } else {
            emptyPositions.push({
                y: 0,
                allocated: 0,
            });
        }
        budgets.push(budget);
    });

    return {
        categories,
        filledSeries,
        unfilledSeries,
        emptyPositions,
        budgets,
    };
};
const TopValues = ({ isLoading, teamsList }) => {
    const { categories, filledSeries, unfilledSeries, budgets, emptyPositions } = formatTeams(teamsList);
    return (
        <Card title="Top Value Teams" classes={'top-values-card'}>
            {isLoading ? (
                <Shimmer />
            ) : categories.length > 0 ? (
                <div className="top-values">
                    <div className="top-values__title">In millions</div>
                    <div className="top-values__chart">
                        <HighchartsReact
                            options={{
                                chart: {
                                    type: 'bar',
                                    height: categories.length * 50 > 400 ? categories.length * 50 : 400,
                                },
                                title: {
                                    text: '',
                                },
                                xAxis: {
                                    categories: categories.map(el => {
                                        return { name: el };
                                    }),
                                    labels: {
                                        useHTML: true,
                                        formatter: function() {
                                            return `<div title = '${this.value.name}' style="max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}">
                                                    ${this.value.name}
                                                </div>`;
                                        },
                                    },
                                },
                                yAxis: {
                                    min: 0,
                                    stackLabels: {
                                        enabled: true,
                                        useHTML: true,
                                        formatter: function() {
                                            return `$<span style='z-index:0;'>${budgets[this.x]}</span>M`;
                                        },
                                    },
                                    opposite: true,
                                    title: {
                                        text: '',
                                        offset: 40,
                                    },
                                },
                                legend: {
                                    enabled: false,
                                },
                                credits: {
                                    enabled: false,
                                },
                                series: [
                                    {
                                        name: 'Unfilled Positions',
                                        data: unfilledSeries,
                                        color: '#FF6E00',
                                        borderRadiusTopLeft: '50%',
                                        borderRadiusTopRight: '50%',
                                    },
                                    {
                                        name: 'Filled Positions',
                                        data: filledSeries,
                                        color: '#009688',
                                    },
                                    {
                                        name: 'No Positions',
                                        data: emptyPositions,
                                        color: '#dadada',
                                        borderRadiusTopLeft: '50%',
                                        borderRadiusTopRight: '50%',
                                    },
                                ],
                                plotOptions: {
                                    bar: {
                                        stacking: 'normal',
                                        pointWidth: 15,
                                        dataLabels: {
                                            allowOverlap: true,
                                        },
                                    },
                                },
                                tooltip: {
                                    useHTML: true,
                                    backgroundColor: 'white',
                                    borderWidth: 1,
                                    shadow: false,
                                    formatter: function() {
                                        return `<div style="background-color:white;position: relative;left:-4px;display:flex; align-items:center; font-weight:bold">
                                            <span style="width:12px; height: 12px; background-color: ${
                                                this.color
                                            }; border-radius:50%; display: inline-block;">&nbsp;</span> ${this.point
                                            .allocated || '&nbsp;'} (${this.percentage.toFixed(1)}%) ${
                                            this.point?.series?.userOptions?.name
                                        }
                                        </div>`;
                                    },
                                },
                            }}
                            highcharts={Highcharts}
                        />
                    </div>
                    <div className="legendContainer">
                        <div className="legendGroups ">
                            <div className="legends">
                                <div
                                    className="circle"
                                    style={{
                                        background: '#FF6E00',
                                    }}
                                />
                                <div>Unfilled Positions</div>
                            </div>
                            <div className="legends">
                                <div
                                    className="circle"
                                    style={{
                                        background: '#009688',
                                    }}
                                />
                                <div>Filled Positions</div>
                            </div>
                            <div className="legends">
                                <div
                                    className="circle"
                                    style={{
                                        background: '#dadada',
                                    }}
                                />
                                <div>No Positions</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <NoDataFound
                    mainMessage={executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE}
                    secondaryMessage={executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE}
                />
            )}
        </Card>
    );
};

export default TopValues;
