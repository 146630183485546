export const spanOfControlConfig = {
    chart: {
        type: 'pie',
        width: 300,
        height: 300,
    },

    title: {
        text: '',
    },

    credits: {
        enabled: false,
    },

    legend: {
        enabled: false,
    },

    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            },
        },
    },
};

export const spanOfControlPriorityMatrix = {
    too_few: { title: ' Managing Too Few', priority: 1, color: '#d55347' },
    too_many: { title: 'Managing Too many', priority: 2, color: '#083d52' },
    in_range: { title: 'Managing In Range', priority: 3, color: '#a0cf63' },
    unknown: { title: 'Unknown', priority: 4, color: '#d8d8d8' },
};
