import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { toFixed } from '../../../../utils/functions';
import Shimmer from '../../../../components/shimmer';
import executiveDashboard from '../../executiveDashboard.enum';
import NoDataFound from '../../../../components/NoDataFound';
import './index.scss';
import { LightTooltip, useOrgShapeStyles } from './styles';

function OrgShapeEnterprise({ isFiltersApplied, getOrgshapeFunctionsData, countFilterValue }) {
    const { layers, ftes } = getOrgshapeFunctionsData;
    const [isLoading, setIsLoading] = useState(true);
    const [enterpriseCount, setEnterpriseCount] = useState([]);
    const [zeroCount, setZeroCount] = useState();
    const classes = useOrgShapeStyles();

    useEffect(() => {
        if ((layers && layers.length) || (ftes && ftes.length)) {
            setIsLoading(false);
        }
    }, [layers, ftes]);

    useEffect(() => {
        if (layers && layers.length) {
            const count = layers.map(layer => {
                return Object.values(layer.data).map(data => {
                    return data.data.managers + data.data.nonManagers;
                });
            });
            const res = count.map(n =>
                n.reduce((total, value) => {
                    return total + value;
                }, 0)
            );
            setEnterpriseCount(res);
        }
    }, [layers]);

    useEffect(() => {
        if(enterpriseCount.length){
            let maxVal = Math.max(...enterpriseCount);
            let count = 0;
            while(parseInt(maxVal, 10).toString().length >= 3){
                count++;
                maxVal = parseInt(maxVal, 10)/10;
            }
            setZeroCount(count);
        }
    }, [enterpriseCount]);

    const getWidth = useCallback(width => {
        return `${width / (10 ** zeroCount)}%`;
    }, [zeroCount])

    return (
        <Fragment>
            {isLoading ? (
                <Shimmer />
            ) : layers.length > 0 ? (
                <TableContainer component={Paper} className={'tableContainer'}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableBody>
                            {layers.length > 0 &&
                                layers.map((layer, idx, arr) => {
                                    return (
                                        <TableRow className={'tableRow'} key={`layer_${idx}`}>
                                            <TableCell className={`fixedCell`}> {`Layer ${layer.name + 1}`} </TableCell>
                                            <TableCell
                                                className={`cell ${idx === arr.length - 1 ? 'borderedCell' : ''}`}
                                                style={{
                                                    width: `100%`,
                                                }}
                                            >
                                                <LightTooltip title={toFixed(enterpriseCount[idx], 2)}>
                                                    <span
                                                        className="bar"
                                                        style={{
                                                            minWidth: '0.01%',
                                                            height: '20px',
                                                            display: 'block',
                                                            margin: '0 auto',
                                                            backgroundColor: '#083d52',
                                                            width: `${getWidth(enterpriseCount[idx])}`,
                                                        }}
                                                    >
                                                        {''}
                                                    </span>
                                                </LightTooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            <TableRow className={'legendRow secondLegendRow'}>
                                <TableCell className={`boldCell fixedCell`}>{`Total ${countFilterValue}`}</TableCell>
                                <TableCell
                                    className={`cell twice`}
                                    style={{
                                        width: '100%',
                                        margin: '0 auto',
                                        textAlign: 'center',
                                        paddingLeft: 16,
                                    }}
                                >
                                    {toFixed(
                                        enterpriseCount.reduce((total, value) => total + value, 0),
                                        2
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataFound
                    mainMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                    }`}
                    secondaryMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                    }`}
                />
            )}
        </Fragment>
    );
}

export default OrgShapeEnterprise;
