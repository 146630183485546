export const pieChartConfig = {
    chart: {
        type: 'pie',
        height: 315,
        width: 320,
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: false,
        },
    },
    title: {
        text: 'Total FTES - 23,000',
        verticalAlign: 'bottom',
        y: 0,
        style: {
            fontSize: '15px',
        },
    },
    series: [
        {
            data: [
                {
                    y: 100,
                    name: 'Cotractors',
                    color: '#083b5e',
                },
                {
                    y: 50,
                    name: 'Manager',
                    color: '#c8e0f4',
                },
                {
                    y: 15,
                    name: 'Individual contributors',
                    color: '#7ebde9',
                },
            ],
        },
    ],
};

export const costChartConfig = {
    title: {
        text: null,
    },
    xAxis: {
        categories: ['Total cost', 'Managerial cost', 'Individual contributor cost', 'contractor cost'],
        labels: {
            align: 'center',
            verticalAlign: 'bottom',
            x: 150,
            style: {
                color: 'black',
                font: '11px Trebuchet MS, Verdana, sans-serif',
                fontWeight: 'bold',
            },
        },
    },
    colors: ['#dbeefc', '#bad5e9', '#aacce8', '#8bb5d5'],
    series: [
        {
            showInLegend: false,
            data: [{ y: 5000 }, { y: 10000 }, { y: 2000 }, { y: 5000 }],
        },
    ],
};
