import React, { useEffect, useState, Fragment } from 'react';
import SpanOfControl from './spanOfControl';
import FTEs from './FTEs';
import Layers from './Layers';
import DirectReports from './DirectReports';
import ManagersRatio from './ManagersRatio';
import Cost from './Cost';
import Ticket from '../../../../components/Ticket';
import Structures from '../../../../components/Structures';
import executiveDashboard from '../../executiveDashboard.enum';
import { getAnalyticsFilterString } from '../../../../common/utilities';
import { getAnalyticsApi } from '../../../StructureContent/apis';
import { slugify } from '../../../../utils/functions';
import Loader from '../../../../components/Loader';
import Dialog from '../../../../components/Dialog';
import overallDefault from '../../../../assets/overallDefault.svg';

const initialStructuresValues = {
    structure1: { name: 'Structure 1', shouldShow: true },
    structure2: { name: 'Structure 2', shouldShow: true },
    structure3: { name: 'Structure 3', shouldShow: true },
};

const initialDataFetchMap = {
    structure1: false,
    structure2: false,
    structure3: false,
};

const Overall = ({
    designProgressLoading,
    structures,
    isEditable,
    filters,
    designFilters,
    refreshCount,
    activeStructures,
    setActiveStructures,
    ranges,
    updateRanges,
    onRangeChange,
    rangeError,
    targets,
    setTargets,
    getSelectedStructureFilter,
    updateMultiFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [spans, setSpans] = useState(JSON.parse(JSON.stringify(initialStructuresValues)));
    const [layers, setLayers] = useState(JSON.parse(JSON.stringify(initialStructuresValues)));
    const [ftes, setFTE] = useState(JSON.parse(JSON.stringify(initialStructuresValues)));
    const [reporters, setReporters] = useState(JSON.parse(JSON.stringify(initialStructuresValues)));
    const [costs, setCost] = useState(JSON.parse(JSON.stringify(initialStructuresValues)));
    const [managerRatio, setManagerRatio] = useState(JSON.parse(JSON.stringify(initialStructuresValues)));
    const [activeStructuresDataFetch, setActiveStructuresDataFetch] = useState({
        structure1: true,
        structure2: true,
        structure3: true,
    });
    const [countFilterValue, setCountFilterValue] = useState('Headcount');

    useEffect(() => {
        const countFilter = filters.structure1?.count_by;
        if (countFilter === 'fte') {
            setCountFilterValue('FTEs');
        } else {
            setCountFilterValue('Headcount');
        }
        const apis = [];
        const pivot_apis = [];
        const span_apis = [];
        Object.keys(activeStructures).forEach(el => {
            if (activeStructures[el].id && activeStructuresDataFetch[el]) {
                const filterString = getAnalyticsFilterString({ ...filters[el], structureId: activeStructures[el].id });
                apis.push({ type: el, api: getAnalyticsApi({ method: 'layersJson', filterString }) });

                pivot_apis.push({
                    type: el,
                    api: getAnalyticsApi({
                        method: 'pivotTableJson',
                        filterString: getAnalyticsFilterString({
                            ...filters[el],
                            structureId: activeStructures[el].id,
                            group_by: ['layer', 'layer'],
                        }),
                    }),
                });
                span_apis.push({ type: el, api: getAnalyticsApi({ method: 'spansJson', filterString }) });
            }
        });
        generateData(apis.concat(pivot_apis, span_apis));
        setActiveStructuresDataFetch({
            structure1: true,
            structure2: true,
            structure3: true,
        });
    }, [
        activeStructures.structure1.id,
        activeStructures.structure2.id,
        activeStructures.structure3.id,
        refreshCount,
        JSON.stringify(filters),
    ]);

    const generateData = (apis = []) => {
        if (apis.length) {
            const fetchApis = apis.map(el => el.api);
            const span = { ...spans };
            const layer = { ...layers };
            const fte = { ...ftes };
            const reporter = { ...reporters };
            const totalcost = { ...costs };
            const mgrRatio = { ...managerRatio };
            setLoading(true);

            Promise.all(fetchApis)
                .then(response => {
                    setLoading(false);
                    const structuresSelected = response.length / 3;
                    let track = 1;

                    response.forEach(({ data }, index) => {
                        const type = apis[index].type;
                        const { id, name } = activeStructures[type];

                        if (track === 1) {
                            // layers api results..
                            span[type] = {
                                id,
                                name,
                                value: data?.totals?.currentSpan,
                            };

                            fte[type] = {
                                id,
                                name,
                                value: data?.totals?.fteCount,
                            };

                            mgrRatio[type] = {
                                id,
                                name,
                                value: [data?.totals?.managers, data?.totals?.nonManagers],
                            };

                            totalcost[type] = {
                                id,
                                name,
                                value: +((data?.totals?.managerCost + data?.totals?.nonManagerCost) / 1000000).toFixed(
                                    1
                                ),
                            };
                        }

                        if (track === 2) {
                            // process pivot api
                            const values = [];
                            Object.keys(data).forEach(el => {
                                const eldata = data[el].data;
                                const fte =
                                    eldata[Object.keys(eldata)[0]]?.data?.managers +
                                    eldata[Object.keys(eldata)[0]]?.data?.nonManagers;
                                values.push(fte);
                            });
                            const max = Math.max(...values);

                            layer[type] = {
                                id,
                                name,
                                value: values.map(el => {
                                    return { value: (el / max) * 100 };
                                }),
                                actualVal: values,
                            };
                        }

                        if (track === 3) {
                            // process span api result
                            reporter[type] = {
                                id,
                                name,
                                value: data.series.reduce((acc, cur) => {
                                    acc += cur.data[0];
                                    return acc;
                                }, 0),
                            };
                        }

                        if ((index + 1) % structuresSelected === 0) {
                            track += 1;
                        }
                    });
                    setSpans(formatDataView(span));
                    setLayers(formatDataView(layer));
                    setFTE(formatDataView(fte));
                    setReporters(formatDataView(reporter));
                    setManagerRatio(formatDataView(mgrRatio));
                    setCost(formatDataView(totalcost));
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const onStructureChange = (structureMap, type) => {
        setActiveStructuresDataFetch({ ...initialDataFetchMap, [type]: true });

        const resetAppliedFilters = (filters, designFilters, key) => {
            setMultiFiltersToApply({
                filters: {
                    ...filters,
                    [key]: {
                        span_of_control: ['too_few', 'in_range', 'too_many', 'non_manager', 'unknown'],
                        count_by: filters[key]?.count_by?.[0] || 'headcount',
                        algorithms: filters[key]['algorithms'],
                    },
                },
                module: 'overall',
            });

            setMultiFiltersTray({
                filters: {
                    ...designFilters,
                    [key]: {
                        span_of_control: [
                            { name: 'Managing Too Few', id: 'too_few' },
                            { name: 'Managing In Range', id: 'in_range' },
                            { name: 'Managing Too Many', id: 'too_many' },
                            { name: 'Non-Manager', id: 'non_manager' },
                            { name: 'Unknown', id: 'unknown' },
                        ],
                        count_by: [
                            {
                                name: (filters[key]?.count_by?.[0] === 'fte' ? 'FTEs' : '') || 'Headcount',
                                id: filters[key]?.count_by?.[0] || 'headcount',
                            },
                        ],
                        algorithms: designFilters[key]['algorithms'],
                    },
                },
                module: 'overall',
            });
        };

        Object.entries(structureMap).forEach(([key, value], idx) => {
            if (value.name === 'Please Select...') {
                structureMap[key]['name'] = `Structure ${idx + 1}`;
                // reset filters for cur str
                updateMultiFilters({ filters: [], module: 'overall', structureNumber: key });
                resetAppliedFilters(filters, designFilters, key);
            }
        });

        if (type) {
            resetAppliedFilters(filters, designFilters, type);
        }

        setActiveStructures(structureMap);
        if (!structureMap[type].id) {
            setSpans({ ...spans, [type]: { name: type, shouldShow: false } });
            setLayers({ ...layers, [type]: { name: type, shouldShow: false } });
            setFTE({ ...ftes, [type]: { name: type, shouldShow: false } });
            setReporters({ ...reporters, [type]: { name: type, shouldShow: false } });
            setCost({ ...costs, [type]: { name: type, shouldShow: false } });
            setManagerRatio({ ...managerRatio, [type]: { name: type, shouldShow: false } });
        }

        if (structureMap[type]?.id) {
            getSelectedStructureFilter({
                moduleType: 'overall',
                structureNumber: type,
                structureId: structureMap[type]?.id,
            });
        }
    };

    const onTargetChange = ({ key, value }) => {
        const newTargets = Object.fromEntries(Object.entries({ ...targets, [key]: value }).filter(([_, v]) => v));
        setTargets(newTargets);
    };

    const isTargetVisible = data => {
        return Object.values(activeStructures).filter(el => el.id).length === 0;
    };

    const formatDataView = (_data, y) => {
        let structuresCount = 0;
        let max = 0;
        const data = { ..._data };
        Object.keys(data).forEach(el => {
            const _value = Array.isArray(data[el].value) ? data[el].value.length : data[el].value || '0';
            if (data[el].id) {
                structuresCount += 1;
            }
            if (_value > max) {
                max = _value;
            }
        });

        if (structuresCount === 0 || structuresCount === 3) {
            Object.keys(data).forEach(el => {
                data[el].shouldShow = true;
                data[el].max = max;
            });
        } else {
            Object.keys(data).forEach(el => {
                data[el].shouldShow = !!data[el].id;
                data[el].max = max;
            });
        }

        return { ...data };
    };

    const onResetTarget = () => {
        setDialog(true);
    };

    const isDefaultView = activeStructures => {
        return Object.keys(activeStructures).every(el => !activeStructures[el].id);
    };

    return (
        <div className="design-progress__view">
            {isLoading || designProgressLoading ? <Loader showBackground background="transparent" /> : null}
            <Structures
                referer="overall"
                structures={structures}
                onChange={onStructureChange}
                activeStructures={activeStructures}
                isEditable={isEditable}
                ranges={ranges}
                onRangeChange={onRangeChange}
                rangeError={rangeError}
                onResetTarget={onResetTarget}
            />
            {isDefaultView(activeStructures) ? (
                <div className="default-view">
                    <div>
                        <img src={overallDefault} alt="Default Overall View" />
                    </div>
                    <div className="label">Select design structure(s) to get started</div>
                </div>
            ) : (
                <Fragment>
                    <section className="report__one-third-view">
                        <div>
                            <Ticket
                                title={executiveDashboard.SPAN_OF_CONTROL_UC}
                                isEditable={isEditable}
                                onTargetChange={onTargetChange}
                                selecetedTarget={targets[slugify(executiveDashboard.SPAN_OF_CONTROL_UC)]}
                            >
                                <SpanOfControl
                                    data={spans}
                                    isTargetVisible={isTargetVisible(spans)}
                                    ranges={ranges}
                                    target={targets[slugify(executiveDashboard.SPAN_OF_CONTROL_UC)]}
                                />
                            </Ticket>
                        </div>
                        <div>
                            <Ticket
                                title={executiveDashboard.LAYERS}
                                isEditable={isEditable}
                                onTargetChange={onTargetChange}
                                selecetedTarget={targets[slugify(executiveDashboard.LAYERS)]}
                            >
                                <Layers
                                    data={layers}
                                    isTargetVisible={isTargetVisible(layers)}
                                    ranges={ranges}
                                    countFilter={countFilterValue}
                                    target={targets[slugify(executiveDashboard.LAYERS)]}
                                />
                            </Ticket>
                        </div>
                        <div>
                            <Ticket
                                title={executiveDashboard.FTEs}
                                isEditable={isEditable}
                                onTargetChange={onTargetChange}
                                selecetedTarget={targets[slugify(executiveDashboard.FTEs)]}
                            >
                                <FTEs
                                    data={ftes}
                                    isTargetVisible={isTargetVisible(ftes)}
                                    ranges={ranges}
                                    target={targets[slugify(executiveDashboard.FTEs)]}
                                />
                            </Ticket>
                        </div>
                    </section>
                    <section className="report__one-third-view">
                        <div>
                            <Ticket
                                title={executiveDashboard.COST}
                                isEditable={isEditable}
                                onTargetChange={onTargetChange}
                                selecetedTarget={targets[slugify(executiveDashboard.COST)]}
                                placeholder={'Target value (in million)'}
                            >
                                <Cost data={costs} target={targets[slugify(executiveDashboard.COST)]} ranges={ranges} />
                            </Ticket>
                        </div>
                        <div>
                            <Ticket
                                title={executiveDashboard.RATIO_OF_IC_MANAGERS}
                                isEditable={isEditable}
                                onTargetChange={onTargetChange}
                                selecetedTarget={targets[slugify(executiveDashboard.RATIO_OF_IC_MANAGERS)]}
                                placeholder={'Enter target value (in %)'}
                            >
                                <ManagersRatio
                                    data={managerRatio}
                                    isEditable={isEditable}
                                    isTargetVisible={isTargetVisible(spans)}
                                    ranges={ranges}
                                    target={targets[slugify(executiveDashboard.RATIO_OF_IC_MANAGERS)]}
                                />
                            </Ticket>
                        </div>
                        <div>
                            <Ticket
                                title={executiveDashboard.NUMBER_OF_TEAMS_WITH_LESS_THAN_EQUAL_TO_DIRECT_REPORTS}
                                isEditable={isEditable}
                                onTargetChange={onTargetChange}
                                selecetedTarget={
                                    targets[
                                        slugify(
                                            executiveDashboard.NUMBER_OF_TEAMS_WITH_LESS_THAN_EQUAL_TO_DIRECT_REPORTS
                                        )
                                    ]
                                }
                            >
                                <DirectReports
                                    data={reporters}
                                    isTargetVisible={isTargetVisible(reporters)}
                                    ranges={ranges}
                                    target={
                                        targets[
                                            slugify(
                                                executiveDashboard.NUMBER_OF_TEAMS_WITH_LESS_THAN_EQUAL_TO_DIRECT_REPORTS
                                            )
                                        ]
                                    }
                                />
                            </Ticket>
                        </div>

                        <Dialog
                            id="reset-target-dialog"
                            okBtnText="Yes, Continue"
                            open={dialog}
                            onSave={() => {
                                setDialog(false);
                                setTargets({});
                                updateRanges({
                                    min: 30,
                                    midLow: 30,
                                    midHigh: 60,
                                    max: 60,
                                });
                            }}
                            onCancel={() => {
                                setDialog(false);
                            }}
                        >
                            <h2 className="text-center">Are you sure you want to reset all target values?</h2>
                        </Dialog>
                    </section>
                </Fragment>
            )}
        </div>
    );
};

export default Overall;
