import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getOpportunityOverviewApi } from '../../../StructureContent/apis';
import { getAnalyticsFilterString } from '../../../../common/utilities';
import opportunityOverviewConfig from '../configs/opprtunityOverview.config';
import Shimmer from '../../../../components/shimmer';
import NoDataFound from '../../../../components/NoDataFound';
import executiveDashboard from '../../executiveDashboard.enum';
import { numFormatter } from '../../../../utils/functions';

function N1Leaders({ isFiltersApplied, filters, structureId, refreshCount }) {
    const [isLoading, setIsLoading] = useState(true);
    const [N1Leaders, setN1Leaders] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const filterString = getAnalyticsFilterString({ ...filters, structureId });

        getOpportunityOverviewApi(filterString, '&filter[group_by]=manager')
            .then(({ data }) => {
                setIsLoading(false);
                const isNoDataPresent = data.series.data.every(el => !el.y);
                let N1LeadersSeries = [];
                if (!isNoDataPresent) {
                    N1LeadersSeries = data.series.data.map(el => {
                        return {
                            name: el.name,
                            y: el.y || null,
                            color: el.y > 0 ? '#a0cf63' : '#d55347',
                            dataLabels: {
                                style: {
                                    color: 'black',
                                },
                                formatter: function() {
                                    return `<span class="label">${el.y < 0 ? '-' : ''}$ ${numFormatter(
                                        Math.abs(el.y),
                                        2
                                    )}</span>`;
                                },
                            },
                        };
                    });
                    N1LeadersSeries.push({
                        name: 'Total',
                        isSum: true,
                        color: '#a0cf63',
                        className: 'total',
                        dataLabels: {
                            useHTML: true,
                            formatter: function() {
                                return `<span class='tooltip'>$ ${numFormatter(Math.abs(this.point.y), 2)}</span>`;
                            },
                        },
                    });
                }
                setN1Leaders(N1LeadersSeries);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [JSON.stringify(filters), refreshCount]);

    return (
        <Fragment>
            {isLoading ? (
                <Shimmer />
            ) : N1Leaders.length ? (
                <div>
                    <HighchartsReact
                        options={{
                            ...opportunityOverviewConfig,
                            series: [
                                {
                                    data: N1Leaders,
                                },
                            ],
                            tooltip: {
                                usHTML: true,
                                formatter: function() {
                                    return `${this.point.name} : ${this.point.y < 0 ? '-' : ''} $ ${numFormatter(
                                        Math.abs(this.point.y),
                                        2
                                    )}`;
                                },
                            },
                        }}
                        highcharts={Highcharts}
                    />
                </div>
            ) : (
                <NoDataFound
                    mainMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                    }`}
                    secondaryMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                    }`}
                />
            )}
        </Fragment>
    );
}

export default N1Leaders;
