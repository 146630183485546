import { Tooltip, makeStyles, withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

export const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export const useOrgShapeStyles = makeStyles(theme => ({
    table: {
        '& .tableRow': {
            '&:nth-child(even)': {
                background: 'none',
            },
            '& .cell': {
                width: 150,
            },
            display: 'flex',
            '& .bar': {
                borderRadius: 2,
            },
        },
        '& .fixedCell': {
            position: 'sticky',
            width: 90,
            top: 0,
            left: 0,
            background: '#fff',
            color: '#C3C3C3',
            borderRight: '2px solid #D2D2D2',
            borderBottom: 'none',
            '&.boldCell': {
                borderRight: 'none',
            },
        },
        '& .borderedCell': {
            borderBottom: '2px solid #D2D2D2',
        },
        '& .legendRow': {
            display: 'flex',
            '& .cell': {
                width: 150,
            },
            '& .boldCell': {
                color: '#9F9F9F',
                fontWeight: 500,
            },
            '& .twice': {
                borderBottom: 'none',
            },
            '&.firstLegendRow': {
                background: 'none',
                '& .twice': {
                    color: '#B3B3B3',
                    textTransform: 'capitalize',
                    textAlign: 'center',
                },
            },
            '&.secondLegendRow': {
                background: 'none',
                '& .twice': {
                    color: '#0C4054',
                    fontWeight: 600,
                    paddingLeft: 50,
                },
            },
        },
    },
}));

export const IOSSwitch = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: '#7C98A2',
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#083D52',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#9DB0B9',
                borderColor: '#9DB0B9',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #CBD6D9`,
        borderRadius: 16 / 2,
        opacity: 0.8,
        backgroundColor: '#C5D2D5',
    },
    checked: {},
}))(Switch);
