import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        width: 130,
        boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 2%)',
        maxHeight: 300,
        overflowY: 'auto',
    },
    listRoot: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    list: {
        paddingLeft: 0,
    },
});

const MenuList = ({ anchorEl, handleClose, filterData, filterRows, clearSelectedFilter }) => {
    const classes = useStyles();

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            getContentAnchorEl={null}
        >
            <div
                style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    justifyContent: 'flex-end',
                    paddingRight: 15,
                    color: '#235465',
                    fontWeight: 600,
                }}
                onClick={clearSelectedFilter}
            >
                Clear Filters
            </div>
            <div style={{ border: '1px solid lightgray', margin: 2 }}>
                {filterData &&
                    filterData.map((data, index, array) => (
                        <MenuItem
                            style={array.length - 1 !== index ? { borderBottom: '1px solid lightgray' } : {}}
                            classes={{ root: classes.listRoot, gutters: classes.list }}
                        >
                            <Checkbox
                                name={data.id}
                                checked={data.value}
                                style={{
                                    color: '#235465',
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={filterRows}
                            />
                            {data.id < 0 ? 'Select All' : data.id}
                        </MenuItem>
                    ))}
            </div>
        </Menu>
    );
};

export default MenuList;
