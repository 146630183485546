import { CUSTOM_KEYS_SLIDE_1, CUSTOM_KEYS_SLIDE_2, KEYS_SLIDE_1, KEYS_SLIDE_2, Slides } from '../../constants';
import { backgroundArr } from '../../../../utils/constants';
import { toFixed } from '../../../../utils/functions';
import { ColumnPaginationKeys, SHARED_STATE, getMaxFte, getPagination, getTwoDigit, totalValue } from './shared';
// eslint-disable-next-line

const getWidth = (v1, v2, sum = v1 + v2) => {
    const value = v1 + v2;
    const width = (1.17 / sum ) * (value);
    if (Number.isNaN(width)) {
        return 0;
    }
    if (width <= 0.31) {
        if(value < 10) return 0.15;
        if(value < 100) return 0.21;
        if(value < 1000) return 0.26;
        return 0.31
    }
    return width;
};

/*
    All the mentioned constant below are in ratio of PPT dimensions
    distance from left: 1.53
    width of each column: 1.19
    gap between each column: 0.22
*/
const getLeftPosition = (currentWidth, id) => 
    1.53 + (id * (1.19 + 0.22)) + (1.19 - currentWidth) / 2;

// eslint-disable-next-line
export const generateOrgShapePPT = async (slideState, { ftes, layers, total }, mergeWithMainState) => {
    const leadersLength = total.length;
    const maxFte = getMaxFte(layers, total);
    const filtersAppliedLength = SHARED_STATE.FILTER_STRING.split(':').length;
    if (layers.length > 0) {
        let tempLength = 5;
        let y = 0;
        let j = y;
        const subTitleName = `#${SHARED_STATE.FILTER_STRING.split(':')[filtersAppliedLength - 1]} by layer and`;
        const sourceInfoObject = {
            name: KEYS_SLIDE_2[CUSTOM_KEYS_SLIDE_2.applied_filters_comment].name,
            value: SHARED_STATE.FILTER_STRING,
        };
        for (let i = 0; i < Math.ceil(leadersLength / 5); i++) {
            // for group total row name:
            const lastIndex = Math.min(leadersLength, (i + 1)* 5);
            const groupTotalName = {};
            groupTotalName.name = 'Total';
            groupTotalName.value = 'Total';
            groupTotalName.bold = true;

            const groupTotalRow = {};
            groupTotalRow.name = 'Group_Total';
            if (layers.length > 7 && layers.length <= 11) {
                groupTotalRow.height = 0.5 - 0.13;
                groupTotalRow.top_position = 6.5;
            }
            slideState[i].data.push(groupTotalName, groupTotalRow);

            let x = 0;
            for (; j < tempLength; j++) {
                if (j >= leadersLength) {
                    break;
                }
                const orgData = {};
                // leaders name row
                orgData.name = `BU${x + 1}`;
                if (total[j].name.length > 36) {
                    orgData.value = `${total[j].name}`.substring(0, 36) + '...';
                } else {
                    orgData.value = `${total[j].name}`;
                }
                orgData.left_position = 1.55 + x * 1.4;
                // showing hidden row
                orgData.hidden = false;

                // pushing the created object into slideState
                slideState[i].data.push(orgData);

                // for creating data of all the layers
                for (let l = 0; l < layers.length; l++) {
                    const orgData1 = {}; // for the leader data

                    if (j === 0 || x === 0) {
                        const groupData = {}; // for the number of layer in PPT.
                        // for showing the group layer first
                        groupData.name = `Group_${l + 1}`;
                        groupData.hidden = false;
                        if (layers.length === 1) {
                            groupData.height = 2;
                            groupData.top_position = 1.88;
                        } else if (layers.length < 7) {
                            groupData.height = 1.6 - 0.2 * (layers.length - 1);
                            groupData.top_position = 1.88 + l * (4.32 / layers.length);
                        } else if (layers.length > 7 && layers.length <= 11) {
                            const extraLayerCount = 11 - layers.length;
                            groupData.height = 0.5 - 0.13 / (extraLayerCount + 1);
                            groupData.top_position = 1.88 + l * (0.52 - 0.13 / (extraLayerCount + 1));
                        }

                        // change the layers value (will show effect at the time of filters applied)
                        const layerValue = {};
                        layerValue.name = `Layer_${l + 1}`;
                        layerValue.value = layers[l].name + 1;

                        slideState[i].data.push(groupData, layerValue);
                    }

                    // leader data row
                    orgData1.name = `BU${x + 1}_${l + 1}`;
                    orgData1.value = toFixed(
                        totalValue(
                            layers[l].data[total[j].id]?.data.managers,
                            layers[l].data[total[j].id]?.data.nonManagers
                        ),
                        0
                    );
                    const width = getWidth(
                        layers[l].data[total[j].id]?.data.managers,
                        layers[l].data[total[j].id]?.data.nonManagers,
                        maxFte
                    );
                    orgData1.width = width;
                    orgData1.left_position = getLeftPosition(width, x);
                    orgData1.bgcolor = backgroundArr[j % backgroundArr.length];
                    orgData1.text_color = '#ffffff';
                    orgData1.hidden = false;

                    // for group total row data:
                    if (l === layers.length - 1 && j < leadersLength) {
                        const groupTotalData = {};
                        groupTotalData.name = `BU${x + 1}_Total`;
                        groupTotalData.value = ftes[j];
                        groupTotalData.left_position = 1.7 + x * 1.4;
                        groupTotalData.width = 0.7;
                        groupTotalData.bold = true;
                        groupTotalData.font_size = 9;
                        groupTotalData.text_color = '#000000';
                        slideState[i].data.push(groupTotalData);
                    }

                    // pushing the created leader object into the respected slide.
                    slideState[i].data.push(orgData1);
                }
                x++;
            }

            // this is used to delete the extra columns shown without the data form the last slide
            if (i < Math.ceil(leadersLength / 5)) {
                if (j === leadersLength) {
                    for (let k = x; k < 5; k++) {
                        const orgData3 = {}; // object for the header
                        orgData3.name = `BU${k + 1}`;
                        orgData3.hidden = true;
                        // pushing the data into respective slides
                        slideState[i].data.push(orgData3);

                        for (let l = 0; l < layers.length; l++) {
                            const orgData2 = {}; // object for the header data
                            orgData2.name = `BU${k + 1}_${l + 1}`;
                            orgData2.hidden = true;
                            // pushing the data into respective slides
                            slideState[i].data.push(orgData2);
                        }
                    }
                }
            }
            slideState[i].data.push(sourceInfoObject);
            if (slideState[i].slideName === Slides.functions) {
                slideState[i].data.push({
                    name: '3. Subtitle',
                    value: `${subTitleName} Function`,
                });
            } else {
                slideState[i].data.push({
                    name: '3. Subtitle',
                    value: `${subTitleName} CEO-1 Leader`,
                });
            }
            if(leadersLength > 5) {
                slideState[i].data.push(
                    ...getPagination(
                        ColumnPaginationKeys,
                        {
                            hidden: false,
                            total: leadersLength,
                            min: getTwoDigit((i * 5) + 1),
                            max: getTwoDigit(lastIndex)
                        }
                    )
                )
            }
            else {
                slideState[i].data.push({
                    ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.column_group],
                    hidden: true
                });
            }
            y = j;
            tempLength += 5;
        }
    }
    mergeWithMainState(slideState, 'Org_shape');
};
