import { fromJS } from 'immutable';
import {
    GET_AUTH_TOKEN,
    GET_CLIENT,
    GET_PHASE,
    GET_PRIVILEGES_FAILED,
    GET_PRIVILEGES_PENDING,
    GET_PRIVILEGES_SUCCESS,
    REFRESH_AUTH_TOKEN,
} from './constants';

const INITIAL_STATE = fromJS({
    isLoading: false,
    isPhasesFetched: false,
    isAnalyticsAllowed: false,
    isSummaryReportAllowed: false,
    isExecutiveDashboardAllowed: false,
    error: '',
    phases: [],
    privileges: {},
    isPrivilegesFetched: false,
    expiryTime: 0,
});

const reducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case `${GET_CLIENT}_PENDING`:
        case `${GET_AUTH_TOKEN}_PENDING`: {
            return state.set('isLoading', true).set('error', '');
        }
        case `${GET_CLIENT}_SUCCESS`: {
            return state.set('isLoading', false);
        }
        case `${GET_AUTH_TOKEN}_SUCCESS`: {
            return state.set('isLoading', false).set('expiryTime', payload.expiryTime);
        }
        case `${GET_AUTH_TOKEN}_FAILED`:
        case `${GET_CLIENT}_FAILED`: {
            return state.set('isLoading', false).set('error', payload.detail);
        }
        case `${GET_PHASE}_PENDING`: {
            return state
                .set('isLoading', true)
                .set('error', '')
                .set('phases', []);
        }
        case `${GET_PHASE}_SUCCESS`: {
            return state
                .set('isLoading', false)
                .set('isPhasesFetched', true)
                .set('phases', payload);
        }
        case `${GET_PRIVILEGES_SUCCESS}`: {
            return state.set('privileges', fromJS(payload)).set('isPrivilegesFetched', true);
        }
        case `${GET_PRIVILEGES_PENDING}`: {
            return state.set('privileges', fromJS({})).set('isPrivilegesFetched', false);
        }
        case `${GET_PRIVILEGES_FAILED}`: {
            return state.set('privileges', fromJS({})).set('isPrivilegesFetched', false);
        }
        case `${GET_PHASE}_FAILED`: {
            return state
                .set('isLoading', false)
                .set('isPhasesFetched', true)
                .set('error', payload.detail);
        }
        case `${REFRESH_AUTH_TOKEN}_SUCCESS`: {
            return state.set('expiryTime', payload.expiryTime);
        }
        default:
            return state;
    }
};

export default reducer;
