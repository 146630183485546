import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import c from 'classnames';
import summaryDefault from '../../assets/summaryDefault.svg';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import TopContent from './TopContent';
import TableContent from './TableContent';
import { REPORTS_LINK, ROLES_DATA_API, SITE_TXT, TABLE_DATA_API } from '../../utils/constants';
import './index.scss';
import FiltersContainer from '../Filters/index.container';

function StructureContent(props) {
    const { STRUCTURE_COMPARE, STRUCTURE_SELECT, COMPARE_TXT, REPORT_SUB } = SITE_TXT;
    const [compareClicked, setCompareClicked] = useState(false);
    const {
        setStrCompFetched,
        getStructureCompare,
        getStructureSummary,
        strCompareFetched,
        isLoading,
        phaseLoading,
        loaderCount,
        strValues,
        strCompareData,
        strSummaryData,
        totalCount,
        totalCompleted,
        sortTable,
        primaryFilters,
        history,
        clrManagerFilters,
        privileges,
        resetStrCompFetched,
        updateFilters,
        setFiltersToApply,
        setFiltersTray,
        filterList,
        filtersToApply,
        isFiltersReadyToApply,
        resetFilters,
        refreshCount,
        filtersTray,
    } = props;

    const [str1, setStr1] = useState('');
    const [str2, setStr2] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const { filters = {}, changes = [] } = strSummaryData;
    const { chanageTypes: oldFilters = [] } = filters;
    const [attributes, setAttributes] = useState(oldFilters);
    const enableCompare = str1 && str2 && str1 !== str2;

    useEffect(() => {
        const { filters: oldFiltersUsed = {} } = strSummaryData;
        const { chanageTypes: oldAtt = [] } = oldFiltersUsed;
        if (attributes.length !== oldAtt.length) {
            setAttributes(oldAtt);
        }
        if (!enableCompare) {
            resetStrCompFetched();
        }
        // eslint-disable-next-line
    }, [strSummaryData]);

    const updateReport = index => {
        resetFilters();
        const links = Object.values(REPORTS_LINK);
        const route = links.filter(el => el.value === index);
        history.push(route[0].routeValue);
    };

    const getFilteredResults = (filterToApply = {}) => {
        ROLES_DATA_API.forEach(apiToUse => getStructureCompare(apiToUse, str1, str2, filterToApply));
        TABLE_DATA_API.forEach(apiToUse => getStructureSummary(apiToUse, str1, str2, filterToApply));
    };

    const resetManagerFilter = () => {
        // clear manager data
        clrManagerFilters();
        const el = document.getElementById('search');
        if (el) {
            el.value = '';
        }
    };

    useEffect(() => {
        if (isFiltersReadyToApply) {
            getFilteredResults(filtersToApply);
        }
    }, [isFiltersReadyToApply, JSON.stringify(filtersToApply), refreshCount]);

    const compareData = () => {
        if (strCompareFetched) {
            getFilteredResults(filtersToApply);
        } else {
            setStrCompFetched();
        }
        setCompareClicked(true);
    };

    const phaseUpdated = () => {
        setFilterApplied(false);
        setStr1('');
        setStr2('');
        setCompareClicked(false);
    };

    const getReportOptions = () => {
        const reportOptions = [];
        Object.keys(REPORTS_LINK).forEach(el => {
            if (privileges[el]) {
                reportOptions.push(REPORTS_LINK[el]);
            }
        });
        return reportOptions;
    };

    const updateAttributes = index => {
        const { isSelected } = attributes[index];
        const updatedAttributes = [
            ...attributes.slice(0, index),
            {
                ...attributes[index],
                isSelected: !isSelected,
            },
            ...attributes.slice(index + 1),
        ];
        setAttributes(updatedAttributes);
    };

    const toggleSelectAllAttributes = () => {
        const isSelected = !!attributes.filter(({ isSelected: oldState }) => !oldState).length;
        const updatedAttributes = attributes.map(original => ({
            ...original,
            isSelected,
        }));
        setAttributes(updatedAttributes);
    };

    const handleClick = () => {
        setDrawerOpen(!drawerOpen);
    };

    const updateChildren = (mainParent = []) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = false;
            if (innerChild.children) {
                updateChildren(innerChild.children);
            }
        });
    };

    const mainTableData = changes.filter(({ changeType }) => {
        const filterInUse = attributes.find(({ name }) => name === changeType) || {};
        const { isSelected: toSelectOrNot = false } = filterInUse;
        return toSelectOrNot;
    });

    const original = strValues.find(({ id = null }) => id === str1);
    const target = strValues.find(({ id = null }) => id === str2);

    const { name: sourceStructure = '' } = original || {};
    const { name: targetStructure = '' } = target || {};

    const apiIsLoading = totalCount !== totalCompleted;

    return (
        <Fragment>
            <Box className="structureContent clearFix">
                {isLoading || phaseLoading || loaderCount ? <Loader showBackground background="transparent" /> : null}
                <div className="topHeaders">
                    <Header />
                    <SubHeader
                        showExport
                        enableCompare={!!enableCompare && strCompareFetched}
                        filterApplied={filterApplied}
                        setDrawerOpen={handleClick}
                        primaryFilters={primaryFilters}
                        getFilteredResults={getFilteredResults}
                        apiIsLoading={apiIsLoading}
                        phaseUpdated={phaseUpdated}
                        resetManagerFilter={resetManagerFilter}
                    />
                    {totalCount && apiIsLoading ? (
                        <div className="progress" style={{ width: `${(totalCompleted * 100) / totalCount}%` }} />
                    ) : null}
                </div>
                <section className="selectContent">
                    <FormControl className="reportLink">
                        <InputLabel shrink id="reportLink">
                            {REPORT_SUB}
                        </InputLabel>
                        <Select
                            labelId="reportLink"
                            id="reportLinkSelect"
                            value={getReportOptions().find(el => el.name === 'Summary of changes').value}
                            onChange={event => updateReport(event.target.value)}
                        >
                            {getReportOptions().map((report, repIndex) => (
                                <MenuItem key={report.value} value={report.value}>
                                    {report.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <span className="subHeading">{STRUCTURE_COMPARE}</span>
                    <Select value={str1} onChange={event => setStr1(event.target.value)} displayEmpty>
                        <MenuItem value="">{STRUCTURE_SELECT}</MenuItem>
                        {strValues.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select value={str2} onChange={ev => setStr2(ev.target.value)} displayEmpty>
                        <MenuItem value="">{STRUCTURE_SELECT}</MenuItem>
                        {strValues.map(({ name, id }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    <div
                        onClick={compareData}
                        className={c('compareBtn', { enableCompare: enableCompare && !apiIsLoading })}
                    >
                        {COMPARE_TXT}
                    </div>
                </section>
                {strCompareFetched && (str1 || str2) && (
                    <Fragment>
                        <TopContent
                            updateFilters={updateFilters}
                            setFiltersToApply={setFiltersToApply}
                            setFiltersTray={setFiltersTray}
                            filterList={filterList}
                            filtersToApply={filtersToApply}
                            sourceStructure={sourceStructure}
                            targetStructure={targetStructure}
                            strCompareData={strCompareData}
                            apiIsLoading={apiIsLoading}
                            filtersTray={filtersTray}
                        />

                        <FiltersContainer
                            drawerOpen={drawerOpen}
                            showStaticFilters
                            structureId={str1}
                            setDrawerOpen={setDrawerOpen}
                        />

                        <TableContent
                            sourceStructure={sourceStructure}
                            targetStructure={targetStructure}
                            mainTableData={mainTableData}
                            attributes={attributes}
                            sortTable={sortTable}
                            apiIsLoading={apiIsLoading}
                            updateAttributes={updateAttributes}
                            toggleSelectAllAttributes={toggleSelectAllAttributes}
                            strCompareData={strCompareData}
                        />
                    </Fragment>
                )}
                {!compareClicked || (!str1 && !str2) ? (
                    <div className="default-view">
                        <div>
                            <img src={summaryDefault} alt="Summary Default View" />
                        </div>
                        <div className="label">Select structures to get started</div>
                    </div>
                ) : null}
            </Box>
        </Fragment>
    );
}

StructureContent.propTypes = {
    getStructureCompare: PropTypes.func.isRequired,
    getStructureSummary: PropTypes.func.isRequired,
    clrManagerFilters: PropTypes.func.isRequired,
    sortTable: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    phaseLoading: PropTypes.bool.isRequired,
    strCompareFetched: PropTypes.bool.isRequired,
    strValues: PropTypes.array.isRequired,
    strCompareData: PropTypes.object.isRequired,
    strSummaryData: PropTypes.object.isRequired,
    loaderCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    totalCompleted: PropTypes.number.isRequired,
    primaryFilters: PropTypes.array.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default StructureContent;
