import React, { Component } from 'react';
import './index.scss';
import { Tooltip } from '@material-ui/core';

class Tabs extends Component {
    constructor(props) {
        super(props);
        const { selected } = this.props;
        this.state = {
            selected: selected || 0,
        };
    }

    handleChange(index, tabname) {
        this.setState({ selected: index });
        if (this.props.handleChange) {
            this.props.handleChange(index, tabname);
        }
    }

    render() {
        const { children, disabled, classes } = this.props;
        return (
            <div>
                <ul className={`inline ${disabled ? 'disabled' : ''} ${classes || ''}`}>
                    {children.map((elem, index) => {
                        if (elem) {
                            const style = index === this.state.selected ? 'selected' : '';
                            if (elem.props.title) {
                                return elem.props.hoverTitle ? (
                                    <Tooltip title={elem.props.hoverTitle || ''}>
                                        <li
                                            title={elem.props.showTitleOnHover ? elem.props.title : ''}
                                            className={style}
                                            key={index}
                                            onClick={this.handleChange.bind(this, index, elem.props.title)}
                                        >
                                            {elem.props.title}
                                        </li>
                                    </Tooltip>
                                ) : (
                                    <li
                                        title={elem.props.showTitleOnHover ? elem.props.title : ''}
                                        className={style}
                                        key={index}
                                        onClick={this.handleChange.bind(this, index, elem.props.title)}
                                    >
                                        {elem.props.title}
                                    </li>
                                );
                            }
                            return <li />;
                        }
                        return null;
                    })}
                </ul>
                <div className="tab">{children[this.state.selected]}</div>
            </div>
        );
    }
}

export default Tabs;
