import React, { Fragment, useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import executiveDashboard from '../../../executiveDashboard.enum';
import { BarChart } from '../../../../DesignPage/charts/ChartComp';
import Shimmer from '../../../../../components/shimmer';
import './index.scss';
import { getAnalyticsApi } from '../../../../StructureContent/apis';
import formattedUtilities, { getAnalyticsFilterString } from '../../../../../common/utilities';
import NoDataFound from '../../../../../components/NoDataFound';

const locationsDefault = {
    0: 'Region',
    1: 'Country',
    2: 'City',
    3: 'Location',
    4: 'Not Categorized',
};

const Locations = ({ structureId, filtersApplied, countFilter, isFiltersApplied, refreshCount }) => {
    const [charts, setCharts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isShimmers, setShimmers] = useState(false);
    const [history, setHistory] = useState([]);
    const [plotBand, setPlotBand] = useState([]);
    const [rank, setRank] = useState(0);
    const [selectedLocation, setLocation] = useState({});

    useEffect(() => {
        setCharts([]);
        setPlotBand([]);
        setHistory([]);
        setRank(0);
        setLocation({});
        getLocations(structureId, filtersApplied, 0);
    }, [JSON.stringify(filtersApplied), refreshCount]);

    const getLocations = (structureId, filtersToApply, rank, filter_location_id = -1, clear = false) => {
        setLoading(true);
        setShimmers(true);

        const filters = { ...filtersToApply };

        if (filter_location_id > -1) {
            filters.location_id = [+filter_location_id];
        }

        const filterString = getAnalyticsFilterString({
            ...filters,
            structureId,
            group_by: [`location[${rank}]`, `location[${rank}]`],
        });

        getAnalyticsApi({ method: 'pivotTableJson', filterString })
            .then(({ data }) => {
                const locations = [];

                Object.entries(data).forEach(([locid, { name, data: locdata }]) => {
                    const fteShare = Object.values(locdata).reduce((acc, cur) => {
                        acc += cur?.data?.managers + cur?.data?.nonManagers;
                        return acc;
                    }, 0);
                    locations.push({
                        location: name,
                        fteShare,
                        category: locationsDefault[rank],
                        haschild: rank < 3,
                        locid,
                    });
                });

                const [categories, series, lvl, firstChildMap] = getCatgoriesnSeries(locations, rank);
                setShimmers(false);

                if (categories.length) {
                    if (!rank) {
                        charts.length = 0;
                    }
                    const k = charts.length === 0 || clear ? 0 : 1;
                    charts[k] = { categories, series, level: lvl, firstChildMap };

                    if (clear) {
                        charts.length = 1;
                    }
                    setCharts(charts);
                    setRank(rank);

                    if (charts.length === 1) {
                        setLocation({ ...firstChildMap, rank });
                        onNextClick(firstChildMap.category, firstChildMap.locid, 0);
                    }
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setShimmers(false);
            });
    };

    const getCatgoriesnSeries = (locations, rank) => {
        const categories = [];
        const series = [];
        const firstChildMap = {
            hasAnyChild: false,
        };
        let level = '';

        locations.forEach(el => {
            if (!level) {
                level = el.category;
            }
            if (el.haschild && !firstChildMap.hasAnyChild) {
                firstChildMap.hasAnyChild = true;
                firstChildMap.category = el.location;
                firstChildMap.locid = el.locid;
            }
            categories.push(el.location);
            series.push({ y: el.fteShare, haschild: el.haschild, locid: el.locid, rank });
        });

        return [categories, series, level, firstChildMap];
    };

    const onNextClick = (location, locid, index = 0, locationRank = 0) => {
        let lHistory = history;
        setTimeout(() => {
            if (charts.length > 1) {
                charts.pop();
            }

            if (+index === 0) {
                lHistory = [{ id: locid, title: location, rank: locationRank }];
            } else {
                lHistory.push({ id: locid, title: location, rank: locationRank });
            }

            if (+index === 0) {
                // when chart id = 0
                // first chart
                const inc = charts[0].categories.findIndex(el => el === lHistory[0].title);

                setPlotBand([
                    {
                        color: '#f6f7f8',
                        from: -0.07 * charts[0].categories.length + inc,
                        to: 0.07 * charts[0].categories.length + inc,
                    },
                ]);
            }

            setLocation({ category: location, locid, rank });
            setHistory(lHistory);
            setLoading(true);
            setCharts(charts);
            getLocations(structureId, filtersApplied, +locationRank + 1, locid);
        }, 10);
    };

    const onBackClick = () => {
        const bckIndex = history.length - 2;
        setLoading(true);
        setTimeout(() => {
            const chartHistory = charts.slice(0, -1);
            const lHistory = history.slice(0, -1);

            setHistory(lHistory);
            setCharts(chartHistory);
            setLocation({
                category: history[bckIndex].title,
                locid: history[bckIndex].id,
                rank: history[bckIndex].rank,
            });
            getLocations(structureId, filtersApplied, rank - 1, history[bckIndex].id);
        }, 0);
    };

    function formatter() {
        window.drillLocation = function(category, locid, idx, rank) {
            onNextClick(category, locid, idx, rank);
        };

        const { chartSystemId } = this.series.chart.userOptions.chart;
        const { haschild, locid, rank } = this.point.options;
        const idx = chartSystemId.slice(-1);
        const { category, y } = this.point;
        const svg = haschild
            ? `<span onclick="drillLocation('${category.name}', ${locid}, '${idx}', '${rank}')"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"></path></svg></span>`
            : '';
        return `<span class="location__custom-label" style="position:relative">
                    <span style="left:${
                        this.point.dlBox.height
                    }px;position: absolute;top:15px">${formattedUtilities.floatIntValueFormatter(y)}</span>
                    <span style="left:${this.point.series.chart.clipBox.height -
                        35}px;position: absolute;top:15px">${svg}</span>
                </span>`;
    }

    return (
        <Fragment>
            <div className="execDashboard-reports__label">{executiveDashboard.LOCATIONS}</div>
            {isShimmers && charts.length === 0 ? (
                <Shimmer />
            ) : (
                <Fragment>
                    <div className="cholder" id="cholder">
                        {loading && (
                            <div className="loadr">
                                <CircularProgress />
                            </div>
                        )}

                        {charts.length > 0 ? (
                            charts.map(({ series, categories, level, firstChildMap, rank }, index) => {
                                const plotBands = !index ? plotBand : [];

                                const max = Math.max(...series.map(el => el.y)) * 1.1;

                                return (
                                    <div
                                        className={`chart-main ${index ? 'chart2s' : ''} ${
                                            !index && !firstChildMap.hasAnyChild ? 'chart-full-length' : ''
                                        }`}
                                        key={`chart_${index}`}
                                        id={`loc_chart_${index}`}
                                        style={{ backgroundColor: !index ? '#FFF' : '#f6f7f8' }}
                                    >
                                        {categories.length && (
                                            <ChartHeader
                                                header={[level, countFilter]}
                                                history={history}
                                                index={index}
                                                onBackClick={onBackClick}
                                            />
                                        )}
                                        <div className="locations-chart">
                                            <BarChart
                                                chartConfig={{
                                                    backgroundColor: !index ? '#FFF' : '#f6f7f8',
                                                    chartSystemId: `chart_${index}`,
                                                    height: series.length * 50 > 400 ? series.length * 50 : 400,
                                                    // width:'100%',
                                                    className: 'locations-chart__view',
                                                }}
                                                xAxis={{
                                                    offset: !index ? 40 : 20,
                                                    categories: categories.map(fn => {
                                                        return { name: fn };
                                                    }),

                                                    labels: {
                                                        useHTML: true,
                                                        formatter: function() {
                                                            return `<div class="data-label" style="width: 100px;text-align: left;white-space: normal">${this.value.name}</div>`;
                                                        },
                                                    },
                                                    plotBands,
                                                }}
                                                yAxis={{ max }}
                                                series={[
                                                    {
                                                        data: series,
                                                        color: !index ? '#083d52' : '#6e879a',
                                                        dataLabels: {
                                                            enabled: true,
                                                            align: 'left',
                                                            x: 10,
                                                            y: -20,
                                                            inside: true,
                                                            color: '#000000',
                                                            useHTML: true,
                                                            formatter,
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <NoDataFound
                                mainMessage={`${
                                    isFiltersApplied
                                        ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                                        : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                                }`}
                                secondaryMessage={`${
                                    isFiltersApplied
                                        ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                                        : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                                }`}
                            />
                        )}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

const ChartHeader = ({ header, history, index, onBackClick }) => {
    return (
        <>
            <div className="chart-header">
                {header.map((loc, i) => (
                    <div className="loc-col" key={`loc_${loc}_${i}`}>
                        {loc}
                    </div>
                ))}
                {!(!index || history.length <= 1) && (
                    <div className="loc-col" style={{ textAlign: 'center' }}>
                        <div className="back-button" onClick={onBackClick}>
                            Back to {history[history.length - 1].title}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Locations;
