import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Shimmer from '../../../../components/shimmer';
import executiveDashboard from '../../executiveDashboard.enum';
import NoDataFound from '../../../../components/NoDataFound';
import functionDistributionConfig from '../configs/functionalDistribution.config';
import { getAnalyticsApi } from '../../../StructureContent/apis';
import formattedUtilities, { getAnalyticsFilterString } from '../../../../common/utilities';
import { colorsGray } from '../configs/colors.config';
import './index.scss';

function FunctionalDistribution({ isFiltersApplied, filters, structureId, countFilterValue, refreshCount }) {
    const [isLoading, setIsLoading] = useState(true);
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [roles, setRoles] = useState({});
    const [functionMapping, setFunctionMapping] = useState({});
    const [functionIdMapping, setFunctionIdMapping] = useState({});
    const group_by = ['function[0]', 'manager[1]'];
    const NA = 'Not Categorized';

    const getColor = idx => {
        idx = idx >= colorsGray.length ? idx % colorsGray.length : idx;
        return colorsGray[idx];
    };

    const reformSeriesData = series => {
        function sort(dataL, dataS) {
            const dataSy = dataS.order;
            const dataLy = dataL.order;
            return dataLy < dataSy ? -1 : dataLy > dataSy ? 1 : 0;
        }

        const allSeries = series;
        const stackingMatrix = [];

        allSeries.forEach(function(series, sIndex) {
            const newSeriesData = [...series.data].sort(sort);
            const s = newSeriesData.map(el => {
                return {
                    series: sIndex,
                    y: el,
                };
            });
            stackingMatrix.push(s);
        });

        const stackData = [];
        stackingMatrix.forEach(function(stack, index) {
            let stackY = 0;

            stack = stack.sort(sort);
            stack.forEach(function(point) {
                const pointY = +point.y.y.toFixed(1);
                stackData.push({
                    color: point?.y?.color || getColor(index),
                    low: stackY,
                    high: stackY + pointY,
                    name: allSeries[point.series].name,
                    x: index,
                    cat: point?.y?.cat,
                    total: stackY + pointY,
                    dataLabels: {
                        enabled: point?.y?.order === 100,
                        inside: false,
                        allowOverlap: false,
                        crop: false,
                        overflow: 'none',
                        filter: {
                            property: 'y',
                            operator: '>',
                            value: 0,
                        },
                        formatter: function() {
                            if (this.total !== this.y) return '';
                            return `<b>${formattedUtilities.floatIntValueFormatter(stackY)}</b>`;
                        },
                    },
                });
                stackY = +(stackY + pointY).toFixed(1);
            });
        });

        const seriesData = new Array(allSeries.length - 1).fill({ data: [] });
        return [
            {
                type: 'columnrange',
                data: stackData,
            },
            ...seriesData,
        ];
    };

    const getSeries = data => {
        const rolesFilled = { ...roles };
        let series = [];
        // set roles filled with 0
        Object.keys(rolesFilled).forEach(key => {
            rolesFilled[key] = new Array(Object.keys(data).length).fill({ y: 0, order: -1 });
        });

        // fill data with real numbers
        Object.keys(data).forEach((key, idx) => {
            const { name, data: functions } = data[key];
            Object.keys(functions).forEach((el, roleidx) => {
                let { name: roleName } = functions[el];
                const { data: roleData } = functions[el];
                roleName = roleName || NA;
                if (roleName && typeof roleName === 'string' && el) {
                    let roleKey = `${roleName}__${name}`;
                    roleKey = !rolesFilled[roleKey] ? `${roleName}__${functionIdMapping[el]}` : roleKey;
                    const fte = roleData.managers + roleData.nonManagers + rolesFilled[roleKey][idx].y;
                    rolesFilled[roleKey][idx] =
                        functionMapping[roleKey] === name
                            ? { y: fte, color: '#476982', order: 100, cat: name }
                            : { y: fte, color: getColor(roleidx), order: 0, cat: name };
                }
            });
        });

        series = Object.keys(rolesFilled).map(el => {
            return {
                name: el,
                data: rolesFilled[el],
            };
        });

        series = reformSeriesData(series);
        return series;
    };

    const getRolesAndMapping = ({ managers }) => {
        const roles = {};
        const functionMapping = {};
        const functionIdMapping = {};
        const categories = [];

        managers.forEach((manager, id) => {
            const key = manager?.function?.part_of?.name;
            const value = manager?.name || NA;
            const roleKey = `${value}__${key}`;
            functionMapping[roleKey] = key;
            functionIdMapping[manager.id] = key;
            roles[roleKey] = id;
            categories.push({ name: value, function: key });
        });

        return [roles, functionMapping, functionIdMapping, categories];
    };

    useEffect(() => {
        setIsLoading(true);
        const filterString = getAnalyticsFilterString({
            span_of_control: ['too_few', 'too_many', 'in_range', 'non_manager', 'unknown'],
            algorithms: filters?.algorithms,
            structureId,
            group_by,
            layer_id: 1,
            perPage: 1000,
        });

        getAnalyticsApi({ method: 'managersJson', filterString })
            .then(({ data }) => {
                const [roles, functionMapping, functionIdMapping, categories] = getRolesAndMapping(data);
                setFunctionMapping(functionMapping);
                setFunctionIdMapping(functionIdMapping);
                setRoles(roles);
                setCategories(categories);
                setSeries([]);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [JSON.stringify(filters), refreshCount]);

    useEffect(() => {
        if (Object.keys(roles).length) {
            setIsLoading(true);
            const filterString = getAnalyticsFilterString({
                ...filters,
                structureId,
                group_by,
            });

            getAnalyticsApi({ method: 'pivotTableJson', filterString })
                .then(({ data }) => {
                    const series = getSeries(data);
                    setIsLoading(false);
                    setSeries(series);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [roles]);

    return (
        <Fragment>
            {isLoading ? (
                <Shimmer />
            ) : series.length ? (
                <div id="function_distribution" className="function-distribution__chart">
                    <div className="legends">
                        <span className="dot brick">
                            <span className="fill grey1" />
                        </span>
                        <span className="dot brick">
                            <span className="fill grey2" />
                        </span>
                        <span className="dot brick">
                            <span className="fill grey3" />
                        </span>
                        <span className="label"># of {countFilterValue} outside leader’s function</span>
                    </div>
                    <HighchartsReact
                        options={{
                            ...functionDistributionConfig,
                            chart: {
                                ...functionDistributionConfig.chart,
                                width:
                                    categories.length * 100 < 0.4 * document.body.clientWidth
                                        ? 0.4 * document.body.clientWidth
                                        : categories.length * 100,
                            },
                            xAxis: { ...functionDistributionConfig.xAxis, categories },
                            series,
                            yAxis: {
                                ...functionDistributionConfig.yAxis,
                                title: {
                                    ...functionDistributionConfig.yAxis.title,
                                    text: `<span style="font-weight:bold;font-family: 'Lato', sans-serif;">${countFilterValue}</span>`,
                                },
                            },
                        }}
                        highcharts={Highcharts}
                    />
                </div>
            ) : (
                <NoDataFound
                    mainMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                    }`}
                    secondaryMessage={`${
                        isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                    }`}
                />
            )}
        </Fragment>
    );
}

export default FunctionalDistribution;
