import React, { Fragment } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';

import './index.scss';

const useStyles = depth => {
    return makeStyles({
        content: {
            paddingLeft: `${depth * 15}px`,
        },
    });
};

const depthMap = {
    0: 'first-row row',
};

const RenderChapters = ({ _chapters, onLabelClick, depth }) => {
    const classes = useStyles(depth)();
    return (
        <Fragment>
            {_chapters.map(elem => {
                return (
                    <TreeItem
                        onClick={event => onLabelClick(event, elem.id, elem.chapter_name)}
                        nodeId={elem.id}
                        label={elem.chapter_name}
                        classes={{
                            content: classes.content,
                        }}
                        className={depthMap[depth] || 'default-row row'}
                    >
                        {elem.child.length > 0 && (
                            <RenderChapters _chapters={elem.child} onLabelClick={onLabelClick} depth={depth + 1} />
                        )}
                    </TreeItem>
                );
            })}
        </Fragment>
    );
};

const Chapters = ({ chapters, onChapterSelect }) => {
    const onLabelClick = (event, id, name) => {
        event.preventDefault();
        onChapterSelect(id, name);
    };

    return (
        <div className="chapters">
            <TreeView
                defaultSelected={chapters[Object.keys(chapters)[0]].id}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowDropUpIcon />}
            >
                <RenderChapters _chapters={Object.values(chapters)} depth={0} onLabelClick={onLabelClick} />
            </TreeView>
        </div>
    );
};

export default Chapters;
