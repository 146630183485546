import React from 'react';
import executiveDashboard from '../../../executiveDashboard.enum';
import Shimmer from '../../../../../components/shimmer';
import formattedUtilities from '../../../../../common/utilities';
import NoDataFound from '../../../../../components/NoDataFound';
import { CostChart } from '../../../../DesignPage/charts/ChartComp';
import { costChartConfig } from '../../../../DesignPage/constants';

const N1Leaders = props => {
    const setExecutiveN1Managers = () => {
        const executiveN1ManagersData = { seriesData: [] };
        props.executiveN1Managers.managers.forEach(execManager => {
            executiveN1ManagersData.seriesData.push({
                name: execManager.rolename,
                empname: execManager.empname,
                y: execManager.filter_fte,
                color: '#083d52',
                label: execManager.total_span,
            });
        });

        return executiveN1ManagersData;
    };

    return (
        <section className="execDashboard-reports__N1-managers" key={executiveDashboard.N1_LEADERS}>
            <div className="title__functions">Total {props.countFilter}</div>
            <div className="execDashboard-reports__N1-manager-chart">
                {props.executiveN1Managers.isLoading ? (
                    <Shimmer />
                ) : setExecutiveN1Managers().seriesData.length ? (
                    <CostChart
                        key={executiveDashboard.N1_LEADERS}
                        costchartConfig={{
                            ...costChartConfig,
                            chart: {
                                type: 'column',
                                height: 400,
                                width:
                                    setExecutiveN1Managers().seriesData.length * 100 < 0.85 * document.body.clientWidth
                                        ? 0.85 * document.body.clientWidth
                                        : setExecutiveN1Managers().seriesData.length * 100,
                            },
                            credits: {
                                enabled: false,
                            },
                            title: {
                                text: '',
                            },
                        }}
                        yAxis={{
                            title: {
                                text: '',
                                useHTML: true,
                                margin: 30,
                            },
                            max:
                                Math.max.apply(
                                    Math,
                                    setExecutiveN1Managers().seriesData.map(function(o) {
                                        return o.y;
                                    })
                                ) * 1.1,
                        }}
                        seriesData={[
                            {
                                borderRadiusTopLeft: '20%',
                                borderRadiusTopRight: '20%',
                                showInLegend: false,
                                data: setExecutiveN1Managers().seriesData,
                                dataLabels: [
                                    {
                                        useHTML: true,
                                        enabled: true,
                                        align: 'left',
                                        color: '#000000',
                                        x: 10,
                                        formatter: function() {
                                            return `<span>
                                        ${formattedUtilities.floatIntValueFormatter(this.point.y)}</span>`;
                                        },
                                    },
                                ],
                            },
                        ]}
                        xAxis={{
                            categories: setExecutiveN1Managers().seriesData.map(function(fn) {
                                return {
                                    name: fn.name,
                                    span: fn.label,
                                    empname: fn.empname,
                                };
                            }),
                            labels: {
                                useHTML: true,
                                formatter: function() {
                                    return `<div class="data-label">
                                            <div class="data-label__text" 
                                                    style="height: 50px;    
                                                    ">
                                                
                                                <div title=  ${
                                                    this.value.name ? this.value.name.replace(/[ ]/g, '\u00a0') : ''
                                                }
                                        style="overflow: hidden;
                                                cursor: hand;
                                            display: -webkit-box;
                                            width: 100%;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;">${
                                                this.value.name !== 'Unknown' ? this.value.name : ''
                                            }</div>
                                                               
                                            <div title= ${
                                                this.value.empname ? this.value.empname.replace(/[ ]/g, '\u00a0') : ''
                                            } style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">${
                                        this.value.empname ? '(' + this.value.empname + ')' : ''
                                    }</div>
                                            </div>
                                            <div class="data-label__span">${parseFloat(this.value.span || 0).toFixed(
                                                1
                                            )}</div>
                                        </div>`;
                                },
                            },
                        }}
                        plotOptions={{
                            series: {
                                groupPadding: 0,
                                pointWidth: 50,
                                dataLabels: {
                                    enabled: true,
                                },
                            },
                        }}
                        tooltip={{
                            useHTML: true,
                            shadow: false,
                            enabled: false,
                            borderWidth: 0,
                            followPointer: false,
                            backgroundColor: 'rgba(0,0,0,0)',
                            formatter: function() {
                                return `<div class="execDashboard-reports__bar-tooltip"><div>${
                                    this.point.name
                                }</div><div>FTEs: ${formattedUtilities.floatIntValueFormatter(
                                    this.point.y
                                )}</div></div>`;
                            },
                        }}
                        legends={[]}
                    />
                ) : (
                    <NoDataFound
                        mainMessage={`${
                            props.isFiltersApplied
                                ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                                : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                        }`}
                        secondaryMessage={`${
                            props.isFiltersApplied
                                ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                                : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                        }`}
                    />
                )}
            </div>
            <div className="legendContainer">
                <div className="legendGroups ">
                    <div className="legends">
                        <div
                            className="circle"
                            style={{
                                background: 'rgb(206, 215, 219)',
                            }}
                        />
                        <div>{executiveDashboard.SPAN_OF_CONTROL}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default N1Leaders;
