import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import StructureContent from './StructureContent';
import structuresRedux from './reducer';
import structureSagas from './sagas';
import {
    getStructureCompare,
    getStructureSummary,
    setNewFilters,
    sortTable,
    getPrimaryFilters,
    getManagerFilters,
    clrManagerFilters,
    clrSelectedManager,
    resetStrCompFetched,
    setStrCompFetched,
} from './actions';

import {
    selectLoading,
    loaderCount,
    selectStructures,
    strCompared,
    strCompare,
    strSummary,
    totalCompleted,
    totalCount,
    getFilters,
} from './selector';

import {
    selectExecutiveDashboardAllowed,
    selectLoginLoading,
    selectPrivileges,
    selectSummaryReportAllowed,
} from '../Login/selector';
import {
    selectFilters,
    selectFiltersReadyToApply,
    selectFiltersToApply,
    selectFiltersTray,
    selectRefreshCount,
} from '../Filters/selectors/selector';
import { resetFilters, setFiltersToApply, updateFilters, setFiltersTray } from '../Filters/actions/actions';

const stateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    phaseLoading: selectLoginLoading(),
    strValues: selectStructures(),
    strCompareFetched: strCompared(),
    strCompareData: strCompare(),
    strSummaryData: strSummary(),
    loaderCount: loaderCount(),
    totalCount: totalCount(),
    totalCompleted: totalCompleted(),
    primaryFilters: getFilters(),
    isSummaryReportAllowed: selectSummaryReportAllowed(),
    isExecutiveDashboardAllowed: selectExecutiveDashboardAllowed(),
    privileges: selectPrivileges(),
    filtersToApply: selectFiltersToApply(),
    filterList: selectFilters(),
    isFiltersReadyToApply: selectFiltersReadyToApply(),
    refreshCount: selectRefreshCount(),
    filtersTray: selectFiltersTray(),
});

const dispatchToProps = {
    getStructureCompare,
    getStructureSummary,
    setNewFilters,
    sortTable,
    getPrimaryFilters,
    getManagerFilters,
    clrManagerFilters,
    clrSelectedManager,
    resetStrCompFetched,
    setFiltersToApply,
    setFiltersTray,
    updateFilters,
    resetFilters,
    setStrCompFetched,
};

export { structureSagas, structuresRedux };

export default connect(stateToProps, dispatchToProps)(StructureContent);
