import React, { useEffect, useState } from 'react';
import './index.scss';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { cloneDeep } from 'lodash';
import { calculation_assumptions } from '../../common/staticFilters';

const FiltersTypeMap = {
    function_id: 'Characteristics',
    location_id: 'Characteristics',
    manager_id: 'Characteristics',
    lookup_id: 'Characteristics',
    archetypes: 'Manager Analysis',
    span_of_control: 'Manager Analysis',
    span_ranges: 'Manager Analysis',
    algorithms: 'Calculation Assumptions',
    open_roles: 'Calculation Assumptions',
    count_by: 'Calculation Assumptions',
    contractors: 'Calculation Assumptions',
    assistant: 'Calculation Assumptions',
};

const DesignFilterTray = ({
    leadersFiltersTray,
    overallFiltersTray,
    functionsFiltersTray,
    designProgressState,
    designProgressOverallActiveStructures,
    designProgressLeadersActiveStructures,
    staticFilters,
    clrSelectedManager,
    updateMultiFilters,
    updateStaticFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    designFilters,
}) => {
    const [crossClickCount, setCrossClickCount] = useState(0);
    const stringVals = [
        'algorithms',
        'direct_reports',
        'open_roles',
        'contractors',
        'assistant',
        'count_by',
        'span_ranges',
    ];
    const filters =
        designProgressState === 'overall'
            ? overallFiltersTray
            : designProgressState === 'functions'
            ? functionsFiltersTray
            : leadersFiltersTray;
    const [scrolled, setScrolled] = React.useState(false);

    const handleScroll = () => {
        setScrolled(window.scrollY > 100);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });
    useEffect(() => {
        if (crossClickCount) {
            applyFilters();
        }
    }, [crossClickCount]);

    const activeStructures =
        designProgressState === 'overall'
            ? designProgressOverallActiveStructures
            : designProgressLeadersActiveStructures;

    const [showFilters, setShowFilters] = useState(false);
    const formatFilters = filters => {
        const formattedTray = {};
        let count = 0;
        Object.keys(filters).forEach(el => {
            Object.keys(filters[el]).forEach(elem => {
                const type = FiltersTypeMap[elem];
                if (type) {
                    if (formattedTray.hasOwnProperty(type)) {
                        if (formattedTray[type].hasOwnProperty(el)) {
                            if (typeof filters[el][elem] === 'object') {
                                formattedTray[type][el].push(...filters[el][elem].flat());
                            } else {
                                formattedTray[type][el].push(filters[el][elem]);
                            }
                        } else {
                            if (typeof filters[el][elem] === 'object') {
                                formattedTray[type][el] = [...filters[el][elem].flat()];
                            } else {
                                formattedTray[type][el] = [filters[el][elem]];
                            }
                        }
                    } else {
                        if (typeof filters[el][elem] === 'object') {
                            formattedTray[type] = {
                                [el]: [...filters[el][elem].flat()],
                            };
                        } else {
                            formattedTray[type] = {
                                [el]: [filters[el][elem]],
                            };
                        }
                    }
                }
            });
        });

        Object.keys(formattedTray).forEach(el => {
            if (el === 'Characteristics') {
                Object.keys(formattedTray[el]).forEach((elem, index) => {
                    count += formattedTray[el][elem].length;
                });
            } else {
                Object.keys(formattedTray[el]).forEach((elem, index) => {
                    if (!index) {
                        count += formattedTray[el][elem].length;
                    }
                });
            }
        });

        return { formattedTray, count };
    };

    const { formattedTray, count } = formatFilters(filters);

    const getFiltersTrayView = (formattedFiltersTray, activeStructures) => {
        const tray = [];
        Object.keys(formattedFiltersTray).forEach(el => {
            if (el === 'Characteristics') {
                tray.push(<div className="filter-category">{el}</div>);
                Object.keys(formattedFiltersTray[el]).forEach(elem => {
                    tray.push(<div className="filter-structure">{activeStructures[elem].name}</div>);
                    formattedFiltersTray[el][elem].forEach(filter => {
                        tray.push(
                            <div className="filter-item">
                                <span className="filter-name">{filter?.name}</span>{' '}
                            </div>
                        );
                    });
                });
            } else {
                tray.push(<div className="filter-category">{el}</div>);
                Object.keys(formattedFiltersTray[el]).forEach((elem, index) => {
                    if (!index) {
                        formattedFiltersTray[el][elem].forEach(filter => {
                            tray.push(
                                <div className="filter-item">
                                    <span className="filter-name">{filter?.name}</span>{' '}
                                </div>
                            );
                        });
                    }
                });
            }
        });

        return tray;
    };

    const handleChecked = ({ checked }, indexArr, filtersOf, isStatic = false, component = 'checkbox') => {
        const f = isStatic ? staticFilters[designProgressState] : designFilters[designProgressState][filtersOf];
        const updatedFilters = cloneDeep(f);
        let { children = [] } = updatedFilters[indexArr[0]];
        if (indexArr.length === 2) {
            const innerChildMain = children[indexArr[1]];
            updateChildren(innerChildMain.children, checked);

            if (component === 'radio') {
                // make is selected false to prev filter option
                children = children.map(item => {
                    item.isSelected = false;
                    return item;
                });
            }

            innerChildMain.isSelected = checked;
            updatedFilters[indexArr[0]].selectedOption = innerChildMain;
            updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
        } else if (indexArr.length === 3) {
            const innerChild = children[indexArr[1]].children[indexArr[2]];
            innerChild.isSelected = checked;
            if (checked) {
                children[indexArr[1]].isSelected = checked;
                updatedFilters[indexArr[0]].isSelected = checked;
            } else {
                children[indexArr[1]].isSelected = !!children[indexArr[1]].children.filter(
                    ({ isSelected }) => isSelected
                ).length;
                updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
            }
            updateChildren(innerChild.children, checked);
        } else {
            const innerChild2 = children[indexArr[1]].children[indexArr[2]].children[indexArr[3]];
            innerChild2.isSelected = checked;
            if (checked) {
                children[indexArr[1]].children[indexArr[2]].isSelected = checked;
                children[indexArr[1]].isSelected = checked;
                updatedFilters[indexArr[0]].isSelected = checked;
            } else {
                children[indexArr[1]].children[indexArr[2]].isSelected = !!children[indexArr[1]].children[
                    indexArr[2]
                ].children.filter(({ isSelected }) => isSelected).length;
                children[indexArr[1]].isSelected = !!children[indexArr[1]].children.filter(
                    ({ isSelected }) => isSelected
                ).length;
                updatedFilters[indexArr[0]].isSelected = !!children.filter(({ isSelected }) => isSelected).length;
            }
            updateChildren(innerChild2.children, checked);
        }

        if (isStatic) {
            updateStaticFilters({ filters: updatedFilters, module: designProgressState });
        } else {
            updateMultiFilters({ filters: updatedFilters, module: designProgressState, structureNumber: filtersOf });
        }
    };

    const applyFilters = () => {
        const filtersToApply = {
            structure1: {},
            structure2: {},
            structure3: {},
        };
        const filtersTray = {
            structure1: {},
            structure2: {},
            structure3: {},
        };
        const selectInnerChild = (structureNumber, mainParent = [], mainParentKey = '', isStatic = false) => {
            mainParent
                .filter(({ isSelected = false }) => isSelected)
                .forEach(({ children = [], key = '', id = '', lookup_category, name = '', keyToUse = '' }) => {
                    if (children.length) {
                        selectInnerChild(structureNumber, children, keyToUse, isStatic);
                    } else if (stringVals.includes(key)) {
                        filtersToApply[structureNumber][key] = id;
                        filtersTray[structureNumber][key] = [{ id, name, key: mainParentKey, isStatic }];
                    } else if (filtersToApply[structureNumber][key]) {
                        if (key === 'lookup_id') {
                            if (filtersToApply[structureNumber][key][lookup_category]) {
                                filtersToApply[structureNumber][key][lookup_category].push(id);
                                filtersTray[structureNumber][key][lookup_category].push({
                                    id,
                                    name,
                                    key: mainParentKey,
                                    isStatic,
                                });
                            } else {
                                filtersToApply[structureNumber][key][lookup_category] = [id];
                                filtersTray[structureNumber][key][lookup_category] = [
                                    { id, name, key: mainParentKey, isStatic },
                                ];
                            }
                        } else {
                            filtersToApply[structureNumber][key].push(id);
                            filtersTray[structureNumber][key].push({ id, name, key: mainParentKey, isStatic });
                        }
                    } else {
                        if (key === 'lookup_id') {
                            filtersToApply[structureNumber][key] = {
                                [lookup_category]: [id],
                            };
                            filtersTray[structureNumber][key] = {
                                [lookup_category]: [{ id, name, key: mainParentKey, isStatic }],
                            };
                        } else {
                            filtersToApply[structureNumber][key] = [id];
                            filtersTray[structureNumber][key] = [{ id, name, key: mainParentKey, isStatic }];
                        }
                    }
                });
        };

        Object.entries(designFilters[designProgressState]).forEach(([structureNumber, strFilters]) => {
            selectInnerChild(structureNumber, strFilters);
        });

        selectInnerChild('structure1', staticFilters[designProgressState], '', true);
        selectInnerChild('structure2', staticFilters[designProgressState], '', true);
        selectInnerChild('structure3', staticFilters[designProgressState], '', true);

        Object.entries(filtersToApply).forEach(([structureNumber, strFilters]) => {
            if (strFilters.lookup_id) {
                filtersToApply[structureNumber].lookup_id = Object.keys(filtersToApply[structureNumber].lookup_id).map(
                    category => filtersToApply[structureNumber].lookup_id[category]
                );
                filtersTray[structureNumber].lookup_id = Object.keys(filtersTray[structureNumber].lookup_id).map(
                    category => filtersTray[structureNumber].lookup_id[category]
                );
            }
        });

        setMultiFiltersToApply({ filters: filtersToApply, module: designProgressState });
        setMultiFiltersTray({ filters: filtersTray, module: designProgressState });
    };

    const getParentName = (name, arrList) => {
        let output = '';
        const getInnerChild = (name, arr, pName) => {
            const parentName = pName || name;
            (arr.children || []).forEach(el => {
                if (el.name === name) {
                    output = parentName;
                } else if (el.children?.length) {
                    return getInnerChild(name, el, pName || el.name);
                }
            });
        };

        getInnerChild(name, arrList);
        return output;
    };

    const removeSelected = (event, key, name, filtersOf, isStaticFilter) => {
        const mainIndex = isStaticFilter
            ? staticFilters[designProgressState].findIndex(el => el.keyToUse === key)
            : designFilters[designProgressState][filtersOf].findIndex(el => el.keyToUse === key);

        const filters = designFilters[designProgressState];
        const childName = isStaticFilter ? name : getParentName(name, filters[filtersOf][mainIndex]);
        const isStatic = isStaticFilter;

        event.stopPropagation();
        const f = isStatic ? staticFilters[designProgressState] : filters[filtersOf];
        const childIndex = f[mainIndex].children.findIndex(({ name }) => name === childName);
        if (['manager'].includes(f[mainIndex].keyToUse)) {
            clrSelectedManager({ module: designProgressState, structureNumber: filtersOf });
        } else {
            handleChecked({ checked: false }, [mainIndex, childIndex], filtersOf, isStatic);
        }

        setTimeout(() => {
            setCrossClickCount(crossClickCount + 1);
        }, 1000);
    };

    const updateChildren = (mainParent = [], isSelected) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = isSelected;
            if (innerChild.children) {
                updateChildren(innerChild.children, isSelected);
            }
        });
    };

    const resetAllFilters = () => {
        const resetFilters = (key, isStatic = false) => {
            const filters = designFilters[designProgressState];
            const updatedFilters = isStatic ? cloneDeep(staticFilters[key]) : cloneDeep(filters[key]);
            updatedFilters.forEach((ele, index) => {
                updatedFilters[index].isSelected = false;

                let children = [];

                if (!['manager'].includes(updatedFilters[index].keyToUse)) {
                    children = updatedFilters[index].children;
                } else {
                    updatedFilters[index].children = children;
                    updatedFilters[index].selectedOption = {};
                }
                updateChildren(children, false);
                if (ele.name === 'counts') {
                    updatedFilters[index].isSelected = true;
                    updatedFilters[index].children = calculation_assumptions.counts.children;
                }
                if (ele.name === 'Archetype_Algorithm') {
                    updatedFilters[index].isSelected = true;
                    updatedFilters[index].children = calculation_assumptions.Archetype_Algorithm.children;
                }
            });

            return updatedFilters;
        };

        updateMultiFilters({
            filters: resetFilters('structure1'),
            module: designProgressState,
            structureNumber: 'structure1',
        });
        updateMultiFilters({
            filters: resetFilters('structure2'),
            module: designProgressState,
            structureNumber: 'structure2',
        });
        updateMultiFilters({
            filters: resetFilters('structure3'),
            module: designProgressState,
            structureNumber: 'structure3',
        });
        updateStaticFilters({ filters: resetFilters(designProgressState, true), module: designProgressState });

        setMultiFiltersToApply({
            filters: {
                structure1: { count_by: 'fte', algorithms: 'conservative' },
                structure2: { count_by: 'fte', algorithms: 'conservative' },
                structure3: { count_by: 'fte', algorithms: 'conservative' },
            },
            module: designProgressState,
        });
        setMultiFiltersTray({
            filters: {
                structure1: {
                    count_by: [{ id: 'fte', name: 'FTEs' }],
                    algorithms: [{ id: 'conservative', name: 'Conservative' }],
                },
                structure2: {
                    count_by: [{ id: 'fte', name: 'FTEs' }],
                    algorithms: [{ id: 'conservative', name: 'Conservative' }],
                },
                structure3: {
                    count_by: [{ id: 'fte', name: 'FTEs' }],
                    algorithms: [{ id: 'conservative', name: 'Conservative' }],
                },
            },
            module: designProgressState,
        });
    };

    return (
        <div className={`filter-tray ${scrolled ? 'sticky' : ''}`}>
            <div className="filter-tray__header">
                <div className="toggler">
                    <span onClick={() => setShowFilters(!showFilters)}>
                        {count} filters applied
                        <span>{showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                    </span>
                </div>
                <div className="clear">
                    <span onClick={resetAllFilters}>CLEAR ALL FILTERS</span>
                </div>
            </div>
            <div className={`filter-tray__content ${showFilters ? 'show' : 'hide'}`}>
                {getFiltersTrayView(formattedTray, activeStructures)}
            </div>
        </div>
    );
};

export default DesignFilterTray;
