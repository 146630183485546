import React from 'react';
import Select from 'react-select';
import InfoIcon from '@material-ui/icons/Info';
import Range from '../../containers/ExecutiveDashboard/DesignProgress/Overall/Range';
import Tooltip from '../Tooltip';

const Structures = ({
    referer,
    structures,
    onChange,
    onLeaderChange,
    activeStructures,
    isEditable,
    ranges,
    onRangeChange,
    rangeError,
    onResetTarget,
    showLeaders,
}) => {
    const structuresList = [{ name: 'Please Select...', id: '' }, ...structures].map(el => {
        return { label: el.name, value: el.id };
    });

    const leadersList = [{ label: 'Manager Role Level1', value: 1 }];
    return (
        <section className="select-structures">
            <div className="structures">
                {showLeaders && (
                    <div>
                        <div className="label">Leaders</div>
                        <Select
                            options={leadersList}
                            placeholder="Select Leader"
                            value={leadersList.filter(el => el.value === activeStructures.structure3.id)[0]}
                            defaultValue={{ label: 'Manager Role Level1', value: 1 }}
                            onChange={leader => {
                                onLeaderChange(leader.value);
                            }}
                        />
                    </div>
                )}
                <div>
                    <div className="label" style={{ position: 'relative' }}>
                        Design Structure 1
                        {!showLeaders && (
                            <Tooltip title={<div>Select structure as baseline for comparison</div>}>
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                        cursor: 'pointer',
                                        marginLeft: '5px',
                                        position: 'absolute',
                                        top: '6px',
                                    }}
                                >
                                    <InfoIcon style={{ fill: '#517180' }} />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                    <Select
                        options={structuresList}
                        placeholder="Select Structure"
                        value={structuresList.filter(el => el.value === activeStructures.structure1.id)[0]}
                        defaultValue={{ label: 'Select Structure', value: '' }}
                        onChange={structure => {
                            onChange(
                                {
                                    ...activeStructures,
                                    structure1: { id: structure.value, name: structure.label },
                                },
                                'structure1'
                            );
                        }}
                    />
                </div>
                <div>
                    <div className="label">Design Structure 2</div>
                    <Select
                        options={structuresList}
                        placeholder="Select Structure"
                        value={structuresList.filter(el => el.value === activeStructures.structure2.id)[0]}
                        defaultValue={{ label: 'Select Structure', value: '' }}
                        onChange={structure => {
                            onChange(
                                {
                                    ...activeStructures,
                                    structure2: { id: structure.value, name: structure.label },
                                },
                                'structure2'
                            );
                        }}
                    />
                </div>
                <div>
                    <div className="label">Design Structure 3</div>
                    <Select
                        options={structuresList}
                        placeholder="Select Structure"
                        value={structuresList.filter(el => el.value === activeStructures.structure3.id)[0]}
                        defaultValue={{ label: 'Select Structure', value: '' }}
                        onChange={structure => {
                            onChange(
                                {
                                    ...activeStructures,
                                    structure3: { id: structure.value, name: structure.label },
                                },
                                'structure3'
                            );
                        }}
                    />
                </div>
            </div>
            <div className="space" />
            <div className="range" style={{ position: 'relative' }}>
                {isEditable && rangeError && <div className="ranges-error">* Please enter the valid ranges</div>}
                <Range isEditedMode={isEditable} referer={referer} ranges={ranges} onRangeChange={onRangeChange} />
            </div>
            {isEditable && (
                <div className="reset-targets">
                    <span onClick={onResetTarget}>Reset All</span>
                </div>
            )}
        </section>
    );
};

export default Structures;
