import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setOrgshapeFunctionsData, setOrgshapeLeadersData } from '../../StructureContent/actions';
import { selectPrivileges } from '../../Login/selector';
import Analysis from './index';
import { selectFiltersToApply, selectRefreshCount } from '../../Filters/selectors/selector';
import { getOrgshapeFunctionsData, getOrgshapeLeadersData } from '../../StructureContent/selector';

const stateToProps = createStructuredSelector({
    filtersToApply: selectFiltersToApply(),
    refreshCount: selectRefreshCount(),
    privileges: selectPrivileges(),
    getOrgshapeFunctionsData: getOrgshapeFunctionsData(),
    getOrgshapeLeadersData: getOrgshapeLeadersData(),
});

const dispatchToProps = {
    setOrgshapeFunctionsData,
    setOrgshapeLeadersData,
}

// export default connect(stateToProps, dispatchToProps)(Analysis);
export default connect(stateToProps, dispatchToProps)(Analysis);
