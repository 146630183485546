import React from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const MultiSelect = props => {
    const handleChange = values => {
        props.onChange(values);
    };

    return (
        <ReactMultiSelectCheckboxes
            className={props.className || ''}
            options={props.options}
            placeholderButtonLabel={props.placeholder}
            onChange={handleChange}
            width="500px"
            value={props.selectedOptions}
            styles={props.styles}
            noOptionsMessage={props.noOptionsMessage}
        />
    );
};

export default MultiSelect;
