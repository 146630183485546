import { SET_PPT_JSON, GENERATE_PPT_REQUEST, GENERATE_PPT_SUCCESS } from './types';

export const reducer = (initialState = { downloading: false, downloaded: false }, action) => {
    switch (action.type) {
        case GENERATE_PPT_REQUEST:
            return { ...initialState, downloading: true, downloaded: false };
        case GENERATE_PPT_SUCCESS:
            return { ...initialState, downloading: false, downloaded: true };
        default:
            return initialState;
    }
};

export default reducer;
