import { fetchWebApi, postWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getStructuresApi = (clientId, phaseId) => {
    const { apiBasePath, structures } = endpoints;
    const url = apiBasePath + structures(clientId, phaseId);
    return fetchWebApi(url).request;
};

export const getCompareApi = (attribute, str1, str2, options = {}) => {
    const { apiBasePath, comparePath } = endpoints;
    const url = `${apiBasePath}${comparePath}/${attribute}?src_structure_id=${str1}&tgt_structure_id=${str2}`;
    return postWebApi(url, options, true).request;
};

export const getManagersStatsApi = (str, options = {}) => {
    const { apiBasePath, managersStats } = endpoints;
    const url = `${apiBasePath}${managersStats}${str}`;
    return postWebApi(url, options).request;
};

export const getManagersFTESApi = (str, options = {}) => {
    const { apiBasePath, managersFTES } = endpoints;
    const url = `${apiBasePath}${managersFTES}${str}`;
    return postWebApi(url, options).request;
};

export const getNonManagersStatsApi = (str, options = {}) => {
    const { apiBasePath, nonManagersStats } = endpoints;
    const url = `${apiBasePath}${nonManagersStats}${str}`;
    return postWebApi(url, options).request;
};

export const getTotalFTESApi = (str, options = {}) => {
    const { apiBasePath, totalFTES } = endpoints;
    const url = `${apiBasePath}${totalFTES}${str}`;
    return postWebApi(url, options).request;
};

export const getLayersApi = (str, options = {}) => {
    const { apiBasePath, layers } = endpoints;
    const url = `${apiBasePath}${layers}${str}`;
    return postWebApi(url, options).request;
};

export const getSpanReportsApi = (str, options = {}) => {
    const { apiBasePath, spanReports } = endpoints;
    const url = `${apiBasePath}${spanReports}${str}`;
    return postWebApi(url, options).request;
};

export const getExecutiveFunctionsApi = (str, options = {}) => {
    const { apiBasePath, executiveFunctions } = endpoints;
    const url = `${apiBasePath}${executiveFunctions}${str}`;
    return postWebApi(url, options).request;
};

export const getExecutiveN1ManagersApi = (str, options = {}) => {
    const { apiBasePath, executiveN1_Managers } = endpoints;
    const url = `${apiBasePath}${executiveN1_Managers}${str}`;
    return postWebApi(url, options).request;
};

export const getExecutiveLocationsApi = (str, location, options = {}) => {
    const { apiBasePath, executiveLocations } = endpoints;
    const qs = `?structure_id=${str}&location=${location}`;
    const url = `${apiBasePath}${executiveLocations}${qs}`;
    return postWebApi(url, options).request;
};

export const getAnalyticsApi = ({ method, filterString }) => { 
    const {
        apiAnalyticsBasePath,
        layersJson,
        spansJson,
        pivotTableJson,
        opportunityOverviewJson,
        managersJson,
        filterJson,
    } = endpoints;
    const methods = {
        layersJson,
        spansJson,
        pivotTableJson,
        opportunityOverviewJson,
        managersJson,
        filterJson,
    };
    const phase = window.localStorage.getItem('phaseId');
    const qs = `?${filterString}&phase_id=${phase}`;
    const url = `${apiAnalyticsBasePath}${methods[method](phase)}${qs}`;
    return fetchWebApi(url, '', 'main').request;
};

export const getOrgFilters = ({ structureId, method, filterString }) => {
    let { apiAnalyticsFilterBasePath } = endpoints;
    const { filterFunctions, filterLocations, filterLookups, filterRoles } = endpoints;
    const methods = {
        filterFunctions,
        filterLocations,
        filterLookups,
        filterRoles,
    };

    apiAnalyticsFilterBasePath = method.endsWith('Lookups')
        ? apiAnalyticsFilterBasePath.replace('/v2', '')
        : apiAnalyticsFilterBasePath;
    const qs = `?${filterString}`;
    const url = `${apiAnalyticsFilterBasePath}${methods[method](structureId)}${qs}`;
    return fetchWebApi(url, '', 'main').request;
};

export const getOrgResources = ({ id, method, filterString = '' }) => {
    const {
        apiAnalyticsFilterBasePath,
        filterParts,
        orgPhases,
        orgTeams,
        orgChapters,
        orgRoles,
        orgStructuredTeams,
        uniqueJobTitles,
    } = endpoints;
    const methods = {
        clients: orgPhases,
        structures: orgTeams,
        teams: orgStructuredTeams,
        chapters: orgChapters,
        roles: orgRoles,
        rolesByChapter: orgRoles,
        uniqueJobTitles: uniqueJobTitles,
    };

    const api = methods[method] || filterParts;
    if (method === 'structures') {
        filterString +=
            '&include=lead,positions.role.allocations,positions.allocation_intervals,positions.criteria,positions.lookups,subteams*.lead,role,subteams*.positions.role.allocations,subteams*.positions.allocation_intervals,subteams*.positions.criteria,subteams*.positions.lookups&page%5Bsize%5D=1000&stats%5Btotal%5D=count&extra_fields%5Broles%5D=allocation';
    }

    if (method === 'teams') {
        filterString +=
            '&include=lead%2Clocation%2Clocation.parts%2Clocation.part_of*%2Cpositions.chapter%2Cpositions.role.employee%2Cpositions.role.chapter%2Cpositions.allocation_intervals%2Cpositions.location%2Cpositions.criteria%2Cpositions.lookups%2Csubteams*.lead%2Crole%2Cpositions.role.positions.team%2Csubteams*.positions.role.positions.team%2Csubteams*.positions.chapter%2Csubteams*.positions.role.employee%2Csubteams*.positions.role.chapter%2Csubteams*.positions.role.allocations%2Csubteams*.positions.role.employee%2Csubteams*.positions.allocation_intervals%2Csubteams*.positions.location%2Csubteams*.location%2Csubteams*.positions.criteria%2Csubteams*.positions.lookups&page%5Bsize%5D=1000&stats%5Btotal%5D=count&extra_fields%5Broles%5D=allocation';
        method = id;
    }
    if (method === 'chapters') {
        filterString +=
            'include=parts*,positions,roles,roles.chapter,roles.positions&sort=created_at&page[size]=1000&stats[total]=count';
        method = id;
    }

    if (method === 'roles') {
        filterString +=
            'include=employee,chapter,location,location.parts,location.part_of*,positions.allocation_intervals,positions.team,allocations&page%5Bsize%5D=30&page%5Bnumber%5D=1&stats%5Btotal%5D=count&query=%7Bcritical_role%3Dtrue%7D';
        method = id;
    }
    if (method === 'rolesByChapter') {
        filterString += 'include=employee,positions,chapter.leads&page[size]=1000&stats[total]=count';
        method = id;
    }
    if (method === 'uniqueJobTitles') {
        method = id;
    }
    const qs = `?${filterString}`;
    const url = `${apiAnalyticsFilterBasePath}${api(method, id)}${qs}`;
    return fetchWebApi(url, '', 'main').request;
};

export const getOpportunityOverviewApi = (filterString, option) => {
    const { apiAnalyticsBasePath, opportunityOverviewJson } = endpoints;
    const phase = window.localStorage.getItem('phaseId');
    const qs = `?${filterString}&phase_id=${phase}&value_type=currency&chart_for=OpportunityOverview${option}`;
    const url = `${apiAnalyticsBasePath}${opportunityOverviewJson(phase)}${qs}`;
    return fetchWebApi(url, '', 'main').request;
};

export const getTargetsApi = (clientId, phaseId, leaderId = '') => {
    const { apiBasePath, getTargets } = endpoints;
    let qs = `?client_id=${clientId}&phase_id=${phaseId}`;
    qs = leaderId ? `${qs}&file_type=${leaderId}` : qs;
    const url = `${apiBasePath}${getTargets}${qs}`;
    return fetchWebApi(url).request;
};

export const setTargetsApi = options => {
    const { apiBasePath, setTargets } = endpoints;
    const url = `${apiBasePath}${setTargets}`;
    return postWebApi(url, options).request;
};

export const getGuidanceApi = (clientId, phaseId, structureId = '') => {
    const { apiBasePath, getGuidance } = endpoints;
    const qs = `?client_id=${clientId}&phase_id=${phaseId}&structure_id=${structureId}`;
    const url = `${apiBasePath}${getGuidance}${qs}`;
    return fetchWebApi(url).request;
};

export const setGuidanceApi = options => {
    const { apiBasePath, setGuidance } = endpoints;
    const url = `${apiBasePath}${setGuidance}`;
    return postWebApi(url, options).request;
};
