import { CUSTOM_KEYS_SLIDE_1, KEYS_SLIDE_1 } from "../../constants";


// eslint-disable-next-line
export const SHARED_STATE = {
    FILTER_STRING: '',
};

export const commonSlide1Data = [
    {
        name: 'checkme',
        is_delete: 'true',
    },
    {
        name: KEYS_SLIDE_1.slide_title.name,
    },
    {
        name: KEYS_SLIDE_1.total_layers.name,
        value: '5',
        existing_value: KEYS_SLIDE_1.total_layers.existing_value,
    },
    { name: KEYS_SLIDE_1.applied_filters_comment.name },
    { name: KEYS_SLIDE_1.total_fte.name },
    { name: KEYS_SLIDE_1.total_non_managers.name },
    { name: KEYS_SLIDE_1.total_managers.name },
    { name: KEYS_SLIDE_1.average_span.name },
    { name: KEYS_SLIDE_1.ideal_span.name },
    { name: KEYS_SLIDE_1.total_ftes_or_headcount.name },
    {
        name: 'Checkme',
        is_delete: 'true',
    },
];

export const commonSlide1CommentData = [
    {
        name: KEYS_SLIDE_1.total_layers_comment.name,
        value: '5',
        existing_value: KEYS_SLIDE_1.total_layers_comment.existing_value,
    },
    {
        name: KEYS_SLIDE_1.average_span_comment.name,
        value: '2',
        existing_value: KEYS_SLIDE_1.average_span_comment.existing_value,
    },
    {
        name: KEYS_SLIDE_1.ideal_span_comment.name,
        value: '3',
        existing_value: KEYS_SLIDE_1.ideal_span_comment.existing_value,
    },
    {
        name: 'comment',
        value: '3rd',
        existing_value: 'WW',
    },
];

export const commonSlide1OppData = [
    { name: KEYS_SLIDE_1.total_managers_cost.name },
    { name: KEYS_SLIDE_1.total_managers_opp.name },
    { name: KEYS_SLIDE_1.total_managers_cost_opp.name },
]

export const SLIDE_WISE_DATA = {
    overview1: [...commonSlide1Data],
    overview1_comment: [...commonSlide1Data, ...commonSlide1CommentData],
    overview1_manager: [...commonSlide1Data, ...commonSlide1OppData],
    overview1_manager_comment: [...commonSlide1Data, ...commonSlide1CommentData, ...commonSlide1OppData]
}

export const PPT_SLIDES_CATEGORIES = {
    layers: {
        layers_report_opp: "Layers with Opportunity",
        layers_report_without_opp: "Layers without Opportunity"
    },
    managerial_stacking: "Managerial Stacking",
    pay_distribution: "Pay Distribution",
    title_distribution: "Title Distribution",
    span_of_control_overview: "Span of Control - Overview",
    org_shape_function: "Org Shape (By Function)",
    org_shape_leader: "Org Shape (By Leaders)"
};

export const getTwoDigit = value => {
    if (value > 9) return value;
    return `0${value}`
}

export const totalValue = (managers, nonManagers) => {
    const total = 0;
    // eslint-disable-next-line
    if (!isNaN(managers + nonManagers)) {
        return managers + nonManagers;
    }
    return total;
};

export const getMaxFte = (layers, total) => {
    let maxFte = -Infinity;
    total.forEach(t => {
        layers.forEach(l => {
            const data = l.data[t.id]?.data;
            const value = data ? totalValue(data.managers, data.nonManagers) : 0;
            maxFte = Math.max(maxFte, value);
        });
    });
    if (maxFte === -Infinity) return 0;
    return maxFte;
}

export const RowPaginationKeys = [
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.row_group],
        key: 'hidden'
    },
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.total_rows],
        key: 'total'
    },
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.rows_range_min],
        key: 'min'
    },
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.rows_range_max],
        key: 'max'
    }
];

export const ColumnPaginationKeys = [
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.column_group],
        key: 'hidden'
    },
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.total_columns],
        key: 'total'
    },
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.columns_range_min],
        key: 'min'
    },
    {
        ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.columns_range_max],
        key: 'max'
    }
];

export const getPagination = (keys, data) =>
    keys.map(({ name, existing_value, key }) => {
        if (name === KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.row_group].name || name === KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.column_group].name)
            return {
                name,
                hidden: data[key],
                ...(data.hiddenColumn)
            }
        return { name, existing_value, value: data[key] }
    });

export const PPT_SLIDES_CATEGORIES_KEYS = {
    layers: "layers",
    managerial_stacking: "managerial_stacking",
    pay_distribution: "pay_distribution",
    title_distribution: "title_distribution",
    span_of_control_overview: "span_of_control_overview",
    org_shape_function: "org_shape_function",
    org_shape_leader: "org_shape_leader"
};
