import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Menu, Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import c from 'classnames';

import { FilterIcon } from '../../components/Header/assets/svg';
import { SortIcon } from './assets/svg';
import { numFormatter } from '../../utils/functions';

let timeoutInner = null;
const PAGINATION_COUNT = 10;

function TableContent({
    attributes,
    updateAttributes,
    toggleSelectAllAttributes,
    mainTableData,
    sourceStructure,
    targetStructure,
    sortTable,
    apiIsLoading,
    strCompareData,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [keyToSort, setKeyToSort] = useState('');
    const [sortType, setSortType] = useState(1);
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const selectedAtt = attributes.filter(({ isSelected }) => isSelected).length;
    const disSelectedAtt = attributes.filter(({ isSelected }) => !isSelected).length;
    const [page, setPage] = useState(1);
    const [pagedRows, setPagedRows] = useState([]);
    const [summaryAggregatedData, setSummaryAggregatedData] = useState([]);

    const updateFilter = index => {
        clearTimeout(timeoutInner);
        timeoutInner = setTimeout(() => {
            setAnchorEl(null);
        }, 4000);
        updateAttributes(index);
    };

    const setCurrentPage = (e, value) => {
        setPage(value);
        setPagedRows(mainTableData.slice(PAGINATION_COUNT * (value - 1), PAGINATION_COUNT * value));
    };

    useEffect(() => {
        setPage(1);
    }, [attributes]);

    useEffect(() => {
        setPagedRows(mainTableData.slice(0, PAGINATION_COUNT));
    }, [mainTableData]);

    const sortTableByKey = keyToUse => {
        const sortToUse = keyToSort === keyToUse ? -1 * sortType : 1;
        setSortType(sortToUse);
        setKeyToSort(keyToUse);
        const updatedTable = [...mainTableData].sort((a, b) => {
            const aConst = a[keyToUse] ? a[keyToUse].toLowerCase() : '';
            const bConst = b[keyToUse] ? b[keyToUse].toLowerCase() : '';

            if (aConst > bConst) {
                return sortToUse;
            }
            if (aConst < bConst) {
                return -1 * sortToUse;
            }
            return 0;
        });
        sortTable(updatedTable);
        setPage(1);
    };

    const showDummyContent = () => {
        return [1, 2, 3, 4, 5].map(dummyKey => (
            <section key={dummyKey} className="dummyKeySection">
                <div className="role">
                    <div className="dummyContent" />
                </div>
                <div className="name">
                    <div className="dummyContent" />
                </div>
                <div className="changeType">
                    <div className="dummyContent" />
                </div>
                <div>
                    <div className="dummyContent" />
                </div>
                <div>
                    <div className="dummyContent" />
                </div>
            </section>
        ));
    };

    useEffect(() => {
        setSummaryAggregatedData(strCompareData.attributes || []);
    }, [strCompareData]);

    return (
        <div className="clearFix">
            <div className="tableContent clearFix">
                <div className="tableHead">
                    <div className="role" onClick={() => sortTableByKey('role')}>
                        Role Title <SortIcon fillInner={keyToSort === 'role' ? '#093b5e' : '#a3a3a3'} />
                    </div>
                    <div className="name" onClick={() => sortTableByKey('employeeName')}>
                        Employee Name <SortIcon fillInner={keyToSort === 'employeeName' ? '#093b5e' : '#a3a3a3'} />
                    </div>
                    <div>
                        Change Type
                        <span className="filterIcon" aria-controls="filter-menu" onClick={handleClick}>
                            <FilterIcon />
                        </span>
                        <Menu
                            id="filter-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            PaperProps={{ style: { minWidth: 250, maxHeight: 400 } }}
                        >
                            <MenuItem onClick={toggleSelectAllAttributes} classes={{ root: 'selectAll' }}>
                                <Checkbox checked={!attributes.filter(({ isSelected }) => !isSelected).length} />
                                Select All
                            </MenuItem>
                            {attributes.map(({ name, isSelected }, index) => (
                                <MenuItem
                                    key={name}
                                    onClick={() => updateFilter(index)}
                                    classes={{ root: c({ disableSelect: isSelected && selectedAtt === 1 }) }}
                                >
                                    <Checkbox checked={isSelected} />
                                    {name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <div className="noPadding">
                        Change From
                        <span>
                            <br />({sourceStructure})
                        </span>
                    </div>
                    <div className="noPadding">
                        Change To
                        <span>
                            <br />({targetStructure})
                        </span>
                    </div>
                </div>
                {pagedRows.map(
                    (
                        {
                            role = '',
                            employeeName = '',
                            changeType = '',
                            changeSubType = '',
                            changedFrom = '-',
                            changedTo = '-',
                        },
                        index
                    ) => (
                        <section key={index}>
                            <div className="role">
                                <div>{role}</div>
                            </div>
                            <div className="name">
                                <div>{employeeName}</div>
                            </div>
                            <div className={c('changeType', { changeSubType })}>
                                <div>
                                    {changeType}
                                    {changeSubType && (
                                        <span>
                                            <br />({changeSubType})
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div>
                                    {changeSubType === 'Salary Change'
                                        ? `${numFormatter(changedFrom, 2, true)}M`
                                        : changedFrom}
                                </div>
                            </div>
                            <div>
                                <div>
                                    {changeSubType === 'Salary Change'
                                        ? `${numFormatter(changedTo, 2, true)}M`
                                        : changedTo}
                                </div>
                            </div>
                        </section>
                    )
                )}
                {!disSelectedAtt && !mainTableData.length && apiIsLoading ? showDummyContent() : null}
                {!mainTableData.length && !apiIsLoading ? <section className="noData">No Data found</section> : null}
            </div>
            {mainTableData.length > PAGINATION_COUNT ? (
                <div className="paginationOuter">
                    <Pagination
                        count={Math.ceil(mainTableData.length / PAGINATION_COUNT)}
                        page={page}
                        onChange={setCurrentPage}
                    />
                    <div className="textContent">
                        Showing {PAGINATION_COUNT * (page - 1) + 1} to{' '}
                        {PAGINATION_COUNT * page < mainTableData.length
                            ? PAGINATION_COUNT * page
                            : mainTableData.length}{' '}
                        out of {mainTableData.length} results
                    </div>
                </div>
            ) : null}

            <table id="table-download">
                <thead>
                    <tr>
                        {/* <th>Layer</th> */}
                        <th>Client Specific ID</th>
                        <th>Role Title</th>
                        <th>Layer</th>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        {/* <th>Manager ID</th>
                        <th>Manager Name</th> */}
                        <th>Change Type</th>
                        <th>Change Action</th>
                        <th>Change From ({sourceStructure})</th>
                        <th>Change To ({targetStructure})</th>
                    </tr>
                </thead>
                <tbody>
                    {mainTableData.map((
                        {
                            // layer = '',
                            csId = '',
                            role = '',
                            employeeNumber = '',
                            employeeName = '',
                            changeTypeExport = '',
                            changeActionExport = '',
                            changedFrom = '-',
                            changedTo = '-',
                            layerFrom = null,
                        },
                        index // managerName = '', // managerPid = '',
                    ) => (
                        <tr key={index}>
                            {/* <td>{layer}</td> */}
                            <td>{csId}</td>
                            <td>{role}</td>
                            <td>{layerFrom}</td>
                            <td>{employeeNumber}</td>
                            <td>{employeeName}</td>
                            {/* <td>{managerPid}</td>
                                    <td>{managerName}</td> */}
                            <td>{changeTypeExport}</td>
                            <td>{changeActionExport}</td>
                            <td>{changedFrom}</td>
                            <td>{changedTo}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table id="table-agregated-download">
                <thead>
                    <tr>
                        <th>Change Type</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {summaryAggregatedData.map(({ name = '', cost = 0 }, index) => (
                        <tr key={index}>
                            <td>{name}</td>
                            <td>{cost}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

TableContent.propTypes = {
    sourceStructure: PropTypes.string.isRequired,
    targetStructure: PropTypes.string.isRequired,
    attributes: PropTypes.array.isRequired,
    mainTableData: PropTypes.array.isRequired,
    sortTable: PropTypes.func.isRequired,
    updateAttributes: PropTypes.func.isRequired,
    toggleSelectAllAttributes: PropTypes.func.isRequired,
    apiIsLoading: PropTypes.bool.isRequired,
};

export default TableContent;
