import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { clrManagerFilters } from '../StructureContent/actions';

import { getFilters, getPhaseStructureInfo, strCompared } from '../StructureContent/selector';
import TeamChapters from './index';
import { selectPrivileges } from '../Login/selector';
import { resetFilters } from '../Filters/actions/actions';
import { selectRefreshCount } from '../Filters/selectors/selector';

const stateToProps = createStructuredSelector({
    strCompareFetched: strCompared(),
    primaryFilters: getFilters(),
    privileges: selectPrivileges(),
    phaseStructureInfo: getPhaseStructureInfo(),
    refreshCount: selectRefreshCount(),
});

const dispatchToProps = {
    clrManagerFilters,
    resetFilters,
};

export default connect(stateToProps, dispatchToProps)(TeamChapters);
