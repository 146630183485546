import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Apps, Person as PersonIcon, ArrowDropDown } from '@material-ui/icons';
import { Box, MenuItem, Menu } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import AuthStore from '../../common/AuthStore';
import { orgAuthURL, logoutURL } from '../../utils/functions';
import logo from './assets/logo.svg';
import './index.scss';

function Header({ strValues, resetLogOutLoader, privileges }) {
    const { clientName = '', userName = '' } = AuthStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDesign, checkDesignLink] = useState(false);
    const [showDiagnostics, checkDiagnosticsLink] = useState(false);
    const [showDirectory, checkDirectoryLink] = useState(false);
    const [showIntegrativeWorkspace, checkIntegrativeWorkspaceLink] = useState(false);
    const [showAdminNav, checkAdminLink] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { id: defaultStructure = 0 } = strValues[0] || {};

    const signUserOut = () => {
        localStorage.removeItem('structureId');
        resetLogOutLoader(true);
        AuthStore.deleteAccessToken();
        window.location.href = logoutURL();
    };

    useEffect(() => {
        checkDesignLink(privileges.designLinkAccess);
        checkDiagnosticsLink(privileges.diagnosticLinkAccess);
        checkDirectoryLink(privileges.directoryLinkAccess);
        checkIntegrativeWorkspaceLink(privileges.integrativeWorkspaceLinkAccess);
        checkAdminLink(privileges.isAdminAccess);
        // eslint-disable-next-line
    }, []);

    const signOut = () => {
        setAnchorEl(null);
        signUserOut();
    };

    const { orgAccessToken = '', phaseId = '' } = AuthStore;
    const { basePath = '', orglabPath = '/!/', adminPath = '' } = orgAuthURL();

    const goToOrglab = page => {
        if (page === 'home') {
            window.location.replace(`${basePath}${orglabPath}#jwt=${orgAccessToken}`);
        }
    };

    return (
        <Box className="header clearFix">
            <span onClick={() => goToOrglab('home')} rel="noopener noreferrer" className="leftSide">
                <img src={logo} alt="" />
            </span>
            <ul className="leftContent">
                {showDiagnostics && (
                    <li>
                        <Link
                            href={`${basePath}/!/phases/${phaseId}/layers?structure_id=${defaultStructure}#jwt=${orgAccessToken}`}
                            rel="noopener noreferrer"
                        >
                            diagnostics
                        </Link>
                    </li>
                )}
                {showIntegrativeWorkspace && (
                    <li>
                        <Link
                            href={`${basePath}/_/design/phase/${phaseId}/workspace?structure_id=${defaultStructure}#jwt=${orgAccessToken}`}
                            rel="noopener noreferrer"
                        >
                            design
                        </Link>
                    </li>
                )}
                <li className="disabled-link">
                    <Link href={`${window.location.href}`} rel="noopener noreferrer">
                        analytics
                    </Link>
                    <span className="active-link" />
                </li>
                {showAdminNav && (
                    <li>
                        <Link
                            target="_blank"
                            href={`${adminPath}?structure_id=${defaultStructure}#jwt=${orgAccessToken}`}
                            rel="noopener noreferrer"
                        >
                            admin
                        </Link>
                    </li>
                )}
                {/* {showDirectory && (
                    <li>
                        <Link href={`${basePath}/_/directory/phase/${phaseId}/`} rel="noopener noreferrer">
                            directory
                        </Link>
                    </li>
                )}
                {showIntegrativeWorkspace && (
                    <li>
                        <Link href={`${basePath}/_/design/phase/${phaseId}/workspace/`} rel="noopener noreferrer">
                            Integrative Workspace
                        </Link>
                    </li>
                )} */}
            </ul>
            <ul className="rightContent">
                <li>
                    <span className="topText">{clientName}</span>
                    <span className="bottomText">ORGANIZATION</span>
                    <span className="iconWrap">
                        <Apps />
                    </span>
                </li>
                <li aria-controls="logout-menu" onClick={handleClick}>
                    <span className="topText">{userName}</span>
                    <span className="bottomText">{privileges.isAdminAccess ? 'ADMIN' : 'USER'}</span>
                    <span className="iconWrap">
                        <PersonIcon />
                    </span>
                    <span className="dropIconWrap">
                        <ArrowDropDown />
                    </span>
                </li>
            </ul>
            <Menu id="logout-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={signOut} classes={{ root: 'selectAll' }}>
                    Sign Out
                </MenuItem>
            </Menu>
        </Box>
    );
}
Header.propTypes = {
    strValues: PropTypes.array.isRequired,
    privileges: PropTypes.array.isRequired,
    resetLogOutLoader: PropTypes.func.isRequired,
};

export default Header;
