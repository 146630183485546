import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    getPhaseStructuresFailed,
    getPhaseStructuresSuccess,
    getReportSummaryFailed,
    getReportSummarySuccess,
    getStructureCompareFailed,
    getStructureCompareSuccess,
    getStructureSummaryFailed,
    getStructureSummarySuccess,
    setTargets,
    updateRanges,
} from './actions';
import {
    GET_PHASE_STRUCTURES,
    GET_REPORT_SUMMARY,
    GET_STRUCTURE_COMPARE,
    GET_STRUCTURE_SUMMARY,
    UPDATE_TARGETS,
    GET_TARGETS,
} from './constants';
import {
    getCompareApi,
    getLayersApi,
    getManagersFTESApi,
    getManagersStatsApi,
    getNonManagersStatsApi,
    getSpanReportsApi,
    getTotalFTESApi,
    setTargetsApi,
    getTargetsApi,
    getAnalyticsApi,
} from './apis';
import { getAnalyticsFilterString } from '../../common/utilities';

export function* getPhaseStructures({ clientId, phaseId }) {
    try {
        const filterString = getAnalyticsFilterString({ filters: 'structures' });
        const { data } = yield call(getAnalyticsApi, { method: 'filterJson', filterString });
        const structures = {
            data: (data.structures || []).map(s => ({ id: s.id, name: s.label })),
        };
        yield put(getPhaseStructuresSuccess(structures));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getPhaseStructuresFailed({ detail, status }));
    }
}

export function* getStructureCompareData({ attribute, str1, str2, primaryFilters }) {
    try {
        const { data } = yield call(getCompareApi, attribute, str1, str2, { primary_filters: primaryFilters });
        yield put(getStructureCompareSuccess(data));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getStructureCompareFailed({ detail, status }));
    }
}

export function* getStructureSummary({ attribute, str1, str2, primaryFilters }) {
    try {
        const { data } = yield call(getCompareApi, attribute, str1, str2, { primary_filters: primaryFilters });
        const { data: returnedData = {} } = data;
        yield put(getStructureSummarySuccess(returnedData));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getStructureSummaryFailed({ detail, status }));
    }
}

export function* getReportDetails({ id, primaryFilters }) {
    try {
        const [managersStats, managersFTES, nonManagersStats, totalFTES, layers, spanReports] = yield all([
            call(getManagersStatsApi, id, { primary_filters: primaryFilters }),
            call(getManagersFTESApi, id, { primary_filters: primaryFilters }),
            call(getNonManagersStatsApi, id, { primary_filters: primaryFilters }),
            call(getTotalFTESApi, id, { primary_filters: primaryFilters }),
            call(getLayersApi, id, { primary_filters: primaryFilters }),
            call(getSpanReportsApi, id, { primary_filters: primaryFilters }),
        ]);

        const reportDetails = {
            cost: {
                totalCost: totalFTES.data.data.totalCost,
                totalICCost: totalFTES.data.data.totalCost - managersStats.data.data.totalManagerCost,
                totalManagerCost: managersStats.data.data.totalManagerCost,
                totalManagerContractorCost: managersStats.data.data.totalManagerContractorCost,
                totalICContractorCost: nonManagersStats.data.data.totalICContractorCost,
            },
            ftes: {
                total: totalFTES.data.data.totalFTEs,
                totalICContractors: nonManagersStats.data.data.totalICContractors,
                totalICs: totalFTES.data.data.totalFTEs - managersFTES.data.data.totalManagers,
                totalManagerContractors: managersStats.data.data.totalManagerContractors,
                totalManagers: managersFTES.data.data.totalManagers,
            },
            stats: {
                avgSpan: spanReports.data.data.avgSpan,
                totalFTEs: totalFTES.data.data.totalFTEs,
                totalLayers: layers.data.data.totalLayers,
                uniqueJobs: layers.data.data.uniqueJobs,
                managerUnder4: spanReports.data.data.managerUnder4,
                managerUnder2: spanReports.data.data.managerUnder2,
            },
        };

        yield put(getReportSummarySuccess({ data: reportDetails }));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response;
        const { errors = [] } = errorData;
        const detail = errors[0] || '';
        yield put(getReportSummaryFailed({ detail, status }));
    }
}

function* getTargetsCall() {
    try {
        const clientId = localStorage.getItem('clientId');
        const phaseId = localStorage.getItem('phaseId');
        const { data } = yield call(getTargetsApi, clientId, phaseId);
        localStorage.setItem('executive_targets', JSON.stringify(data.data));
        yield put(setTargets(data.data));
        if (data?.data?.range) {
            yield put(updateRanges(data.data.range));
        }
    } catch ({ response }) {
        yield put(setTargets({}));
    }
}

function* updateTargets({ payload }) {
    try {
        const clientId = localStorage.getItem('clientId');
        const phaseId = localStorage.getItem('phaseId');
        yield call(setTargetsApi, { client_id: clientId, phase_id: phaseId, config: payload });
        yield put(setTargets(payload));
    } catch ({ response }) {
        yield put(setTargets({}));
    }
}

const structureSagas = [
    takeLatest(`${GET_PHASE_STRUCTURES}_PENDING`, getPhaseStructures),
    takeEvery(`${GET_STRUCTURE_COMPARE}_PENDING`, getStructureCompareData),
    takeEvery(`${GET_STRUCTURE_SUMMARY}_PENDING`, getStructureSummary),
    takeEvery(`${GET_REPORT_SUMMARY}_PENDING`, getReportDetails),
    takeLatest(UPDATE_TARGETS, updateTargets),
    takeLatest(GET_TARGETS, getTargetsCall),
];
export default structureSagas;
