import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import './index.scss';
import Stats from './Stats';
import DatePickers from '../../components/DatePicker';
import { REPORTS_LINK, SITE_TXT } from '../../utils/constants';
import CriticalRoleCapacity from './CriticalRoleCapacity';
import TopValues from './TopValues';
import CapabilityGroups from './CapabilityGroups';
import { getOrgResources } from '../StructureContent/apis';
import NoDataFound from '../../components/NoDataFound';

const intoMillion = value => parseFloat(value / 1000000);

const ExecutiveDashboardTeams = ({
    strCompareFetched,
    primaryFilters,
    clrManagerFilters,
    privileges,
    resetFilters,
    history,
    phaseStructureInfo,
    refreshCount,
}) => {
    const [filterApplied, setFilterApplied] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [structureId, setStructureId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [teamsStats, setTeamsStats] = useState({});
    const { REPORT_SUB } = SITE_TXT;

    useEffect(() => {
        if (structureId) {
            getTeamsData(date);
        }
    }, [structureId, date, refreshCount]);

    const getTeamsData = date => {
        setIsLoading(true);
        setTeamsStats({});
        Promise.all([
            getOrgResources({
                id: structureId,
                method: 'structures',
                filterString: `query={start_date <=date('${date}'),start_date %2B duration>= date('${date}')}`,
            }),
            getOrgResources({
                id: structureId,
                method: 'roles',
            }),
            getOrgResources({
                id: structureId,
                method: 'chapters',
            }),
        ])
            .then(response => {
                const teamsResponse = response[0].data;
                const criticalRolesResponse = response[1].data;
                const stats = getTeamsStats(teamsResponse, criticalRolesResponse);
                stats.capabilityData = getCapabilityData(response[2].data);

                setTeamsStats(stats);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const getCapabilityData = ({ data: chapters, included = [] }) => {
        const chaptersMap = chapters.reduce((acc, chapter) => {
            acc[chapter.id] = { name: chapter?.attributes?.name, filled: 0, unfilled: 0 };
            return acc;
        }, {});

        included.forEach(el => {
            if (el.type === 'roles') {
                const hasFilledRoleKey = el.relationships.positions.data.length > 0 ? 'filled' : 'unfilled';
                chaptersMap[el.relationships.chapter.data.id][hasFilledRoleKey] += 1;
            }
        });

        return chaptersMap;
    };

    const phaseUpdated = () => {
        setFilterApplied(false);
    };

    const setFiltersData = filtersUsed => {
        setFilters(filtersUsed);
    };

    const getFilteredResults = (filterToApply = {}) => {
        setFiltersData({ ...filterToApply });
    };

    const compareData = str1 => {
        if (str1) {
            setFilters({ ...filters });
        }
    };

    const setStructureData = id => {
        setStructureId(id);
        setFilterApplied(primaryFilters.filter(({ isSelected }) => isSelected));
    };

    const resetManagerFilter = () => {
        // clear manager data
        clrManagerFilters();
        const el = document.getElementById('search');
        if (el) {
            el.value = '';
        }
    };

    const handleClick = () => setDrawerOpen(!drawerOpen);

    const getReportOptions = () => {
        const reportOptions = [];
        Object.keys(REPORTS_LINK).forEach(el => {
            if (privileges[el]) {
                reportOptions.push(REPORTS_LINK[el]);
            }
        });
        return reportOptions;
    };

    const updateReport = index => {
        resetFilters();
        const links = Object.values(REPORTS_LINK);
        const route = links.filter(el => el.value === index);
        history.push(route[0].routeValue);
    };

    const getTeamsStats = (teamsResponse, criticalRolesResponse) => {
        let teamsCount = 0;
        let teamsBudget = 0;
        let totalPositions = 0;
        let filledPositions = 0;
        let unfilledPositions = 0;
        const totalCriticalRoles = criticalRolesResponse.data.length;
        let filledCriticalRoles = 0;
        let unfilledCriticalRoles = 0;
        const subTeamsList = [];
        const teamsList = {};
        const criticalRoleMap = {};
        const roleLocationMap = {};
        const allRoleLocationMap = {};
        const allocationMap = {};
        const filledPositionRoleMap = {};
        const filledPositionAllocationMap = {};
        const { data = [], included = [] } = teamsResponse;
        data.forEach(el => {
            teamsBudget += el.attributes.strategic_value;
            teamsList[el.id] = {
                id: el.id,
                name: el.attributes.name,
                budget: el.attributes.strategic_value ? intoMillion(el.attributes.strategic_value) : 0,
                filled: 0,
                unfilled: 0,
                positions: el.relationships.positions.data || [],
            };
        });
        included.forEach(el => {
            if (el.type === 'teams') {
                teamsCount++;
                subTeamsList.push(el);
                teamsList[el.id] = {
                    id: el.id,
                    name: el.attributes.name,
                    budget: el.attributes.strategic_value ? intoMillion(el.attributes.strategic_value) : 0,
                    filled: 0,
                    unfilled: 0,
                    positions: el.relationships.positions.data || [],
                };
            }
            if (el.type === 'positions') {
                totalPositions++;
                if (el.relationships.role.data) {
                    filledPositions++;
                    filledPositionRoleMap[el.id] = el.relationships.role.data.id;
                    filledPositionAllocationMap[el.id] = el.relationships.allocation_intervals.data[0].id;
                } else {
                    unfilledPositions++;
                }
            }
            if (el.type === 'roles' && el.attributes.critical_role) {
                criticalRoleMap[el.id] = { name: el.attributes.name, id: el.id };
            }
            if (el.type === 'allocation_intervals') {
                allocationMap[el.id] = { allocation: el.attributes.allocation };
            }
        });
        const criticalRoleCapacityMap = {};

        Object.entries(teamsList).forEach(([key, value]) => {
            value.positions.forEach(position => {
                if (filledPositionRoleMap[position.id]) {
                    teamsList[key] = { ...teamsList[key], filled: teamsList[key].filled + 1 };
                } else {
                    teamsList[key] = { ...teamsList[key], unfilled: teamsList[key].unfilled + 1 };
                }
            });
        });

        [...data, ...subTeamsList].forEach((team, index) => {
            (team.relationships.positions.data || []).forEach(({ id: positionId }) => {
                const criticalRolePosition = criticalRoleMap[filledPositionRoleMap[positionId]]
                    ? { id: positionId }
                    : undefined;
                if (criticalRolePosition) {
                    const roleId = filledPositionRoleMap[criticalRolePosition.id];
                    const allocation_assigned =
                        (allocationMap[filledPositionAllocationMap[criticalRolePosition.id]].allocation || 0) * 100;
                    if (criticalRoleCapacityMap.hasOwnProperty(roleId)) {
                        const cumulativeAllocation =
                            allocation_assigned +
                            (criticalRoleCapacityMap[roleId]['teams'][team.id]
                                ? criticalRoleCapacityMap[roleId]['teams'][team.id].allocation
                                : 0);

                        criticalRoleCapacityMap[roleId]['teams'][team.id] = {
                            teamName: team.attributes.name,
                            teamValue: team.attributes.strategic_value,
                            allocation: cumulativeAllocation,
                        };

                        criticalRoleCapacityMap[roleId]['totalAllocation'] += allocation_assigned;
                    } else {
                        criticalRoleCapacityMap[roleId] = {
                            role_name: criticalRoleMap[roleId].name,
                            teams: {
                                [team.id]: {
                                    teamName: team.attributes.name,
                                    teamValue: team.attributes.strategic_value,
                                    allocation: allocation_assigned,
                                },
                            },
                            totalAllocation: allocation_assigned,
                        };
                    }
                }
            });
            const criticalRolePosition = team.relationships.positions.data.find(
                ({ id: positionId }) => criticalRoleMap[filledPositionRoleMap[positionId]]
            );
        });

        criticalRolesResponse.data.forEach(role => {
            if (role.relationships.location?.data?.id) {
                roleLocationMap[role.id] = role.relationships.location?.data?.id;
            }

            if (criticalRoleMap[role.id]) {
                filledCriticalRoles++;
            } else {
                unfilledCriticalRoles++;
            }
        });

        (criticalRolesResponse.included || []).forEach(location => {
            if (location.type === 'locations') {
                allRoleLocationMap[location.id] = {
                    name: location.attributes.name,
                    part: location.relationships.part_of?.data?.id || null,
                };
            }
        });

        Object.entries(roleLocationMap).forEach(([roleId, locationId]) => {
            if (criticalRoleCapacityMap[roleId]) {
                const locations = [];
                if (allRoleLocationMap[locationId]?.part) {
                    locations.push(allRoleLocationMap[allRoleLocationMap[locationId].part].name);
                }
                locations.push(allRoleLocationMap[locationId].name);
                criticalRoleCapacityMap[roleId]['location'] = locations;
            }
        });

        return {
            teamsCount: teamsCount + data.length,
            teamsBudget,
            totalPositions,
            filledPositions,
            unfilledPositions,
            totalCriticalRoles,
            filledCriticalRoles,
            unfilledCriticalRoles,
            criticalRoleCapacityMap,
            teamsList,
        };
    };

    const hasStructure = phaseStructureInfo.isApiCallSuccess && phaseStructureInfo.anyStructurePresent;

    return (
        <Box className="teams-dashboard clearFix">
            <div className="topHeaders">
                <Header />
                <SubHeader
                    showStructures
                    enableCompare={strCompareFetched}
                    filterApplied={filterApplied}
                    setDrawerOpen={handleClick}
                    primaryFilters={primaryFilters}
                    getFilteredResults={getFilteredResults}
                    phaseUpdated={phaseUpdated}
                    getCurrentStructure={compareData}
                    setStructureId={setStructureData}
                    resetManagerFilter={resetManagerFilter}
                    enableRefreshTab
                    enableFiltersTab
                />
            </div>

            <section className="selection-content">
                <FormControl className="reportLink">
                    <InputLabel shrink id="reportLink">
                        {REPORT_SUB}
                    </InputLabel>
                    <Select
                        labelId="reportLink"
                        id="reportLinkSelect"
                        value={getReportOptions().find(el => el.name === 'Executive dashboard (Teams)').value}
                        onChange={event => updateReport(event.target.value)}
                    >
                        {getReportOptions().map((report, repIndex) => (
                            <MenuItem key={report.value} value={report.value}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {hasStructure && <DatePickers label="Teams as of" setDate={setDate} value={date} />}
            </section>

            {hasStructure && (
                <div className="teams-dashboard__report-view">
                    <Stats teams={teamsStats} isLoading={isLoading} />
                    <section className="block">
                        <TopValues isLoading={isLoading} teamsList={teamsStats.teamsList || {}} />
                    </section>
                    <section className="block">
                        <CapabilityGroups isLoading={isLoading} capabilityData={teamsStats.capabilityData || {}} />
                    </section>
                    <section className="block">
                        <CriticalRoleCapacity
                            isLoading={isLoading}
                            criticalRoleCapacityMap={teamsStats.criticalRoleCapacityMap || {}}
                        />
                    </section>
                </div>
            )}

            {phaseStructureInfo.isApiCallSuccess && !phaseStructureInfo.anyStructurePresent && (
                <div>
                    <NoDataFound
                        mainMessage={`No Structure found for the selected design session`}
                        secondaryMessage={`Please select a different design session.`}
                    />
                </div>
            )}
        </Box>
    );
};

export default ExecutiveDashboardTeams;
