import React, { useEffect, useState, Fragment } from 'react';
import { Box, MenuItem, Menu, CircularProgress, Divider, makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import c from 'classnames';
import XLSX from 'xlsx';

import {
    downloadNnReport,
    getGeneratingNnReportStatus,
    startGeneratingNnReport,
    getRootRoleId,
} from '../../containers/NnReport/apis';
import { ExportIcon, FilterIcon } from '../Header/assets/svg';
import AuthStore from '../../common/AuthStore';

import './index.scss';
import ExportPopup from './Export/ExportPopup';

const MAX_NN_REQUEST_COUNT = 650; // 10 minutes
const NN_REQUEST_INTERVAL = 4000; // ms (do not change)
const NN_REPORT_CACHE_STATE_KEY = 'NN_REPORT_JOBS';

const useStyles = makeStyles(theme => ({
    padding: {
      padding: "11px 12px 11px 12px",
    },
    root: {
        width: '166px'
    }
}));

function SubHeader({
    structure,
    enableCompare,
    setDrawerOpen,
    filterApplied,
    phases,
    apiIsLoading,
    showExport,
    getPhaseStructures,
    phaseUpdated,
    isPhasesFetched,
    getPhase,
    strValues,
    showStructures,
    getCurrentStructure,
    enableRefreshTab,
    getRefreshedData,
    enableFiltersTab,
    setStructureId,
    setStructure,
    resetFilters,
    showResult,
    showPPTExportButton,
    generatePPTExport,
    pptDownloading,
    generateChapterExport,
    showTeamChapterExport,
    goToInputSection,
    privileges,
    pptSlideList,
    setPptSlideList,
    updatePptSlideList,
    disableExport,
    showExecutivePopup
}) {
    const { clientId = '', phaseId = '', orgAccessToken = '' } = AuthStore;
    const exportMenuClasses = useStyles();
    const [structureInUse, setCurrentStructure] = useState('');
    const [phaseAnchor, setPhaseAnchor] = useState(null);
    const [strAnchor, setStrAnchor] = useState(null);
    const [exportAnchor, setExportAnchor] = useState(null);
    const [nnReportAnchor, setNnReportAnchor] = useState(null);
    const [exportPopup, setExportPopup] = useState(false);
    /*
    const [nnReportLoading, setNnReportLoading] = useState(false);
    const [nnReportJobId, setNNReportJobId] = useState('');
    */

    const [nnReportJobId, setNnReportJobId] = useState(localStorage.getItem('nnReportJobId') || '');
    const [nnReportLoading, setNnReportLoading] = useState(localStorage.getItem('nnReportLoading') === 'true' || false);

    const openPhases = Boolean(phaseAnchor);
    const openStructures = Boolean(strAnchor);
    const openExport = Boolean(exportAnchor);
    const openNnReport = Boolean(nnReportAnchor);

    const handlePhaseClick = event => setPhaseAnchor(event.currentTarget);
    const handleStructureClick = event => setStrAnchor(event.currentTarget);
    const handleExportClick = event => setExportAnchor(event.currentTarget);
    const handleNnReportClick = event => setNnReportAnchor(event.currentTarget);

    const closeExport = () => setExportAnchor(null);
    const closePhases = () => setPhaseAnchor(null);
    const closeStructures = () => setStrAnchor(null);
    const closeNnReport = () => {
        setNnReportAnchor(null);
        closeExport();
    };

    const updateStructure = ({ name = '', id = '',selected=false }) => {
        if(id == structure?.id && selected){
            closeStructures();
            return ;
        }
        localStorage.setItem('structureId', JSON.stringify({ name, id }));
        resetFilters();
        setStructure({ name, id });
        getCurrentStructure(id);
        setCurrentStructure(name);
        if (setStructureId) {
            setStructureId(id);
        }
        closeStructures();
        goToInputSection();
    };

    useEffect(() => {
        if (!isPhasesFetched) {
            getPhase(clientId);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const _structureIdMap = JSON.parse(localStorage.getItem('structureId'));
        const { name = '', id = '' } =
            (_structureIdMap && Object.keys(_structureIdMap).length && _structureIdMap) || strValues[0] || {};
        if (id) {
            updateStructure({ name, id, selected:false });
        } else {
            if (setStructureId) setStructureId('');
        }
        // eslint-disable-next-line
    }, [strValues.length]);

    const setNewPhase = newPhaseId => {
        if(newPhaseId == phaseId){
            closePhases();
            return ;
        }
        resetFilters(true);
        localStorage.removeItem('structureId');
        closePhases();
        setCurrentStructure('');
        AuthStore.phaseId = newPhaseId;
        getPhaseStructures(clientId, newPhaseId);
        phaseUpdated();
        goToInputSection();
    };

    const refresh = () => {
        getRefreshedData();
    };

    const getTodayValue = () => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = `0${dd}`;
        }

        if (mm < 10) {
            mm = `0${mm}`;
        }
        today = `${mm}_${dd}_${yyyy}`;
        return today;
    };

    const getExport = () => {
        if (document.getElementById('table-download')) {
            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.table_to_sheet(document.getElementById('table-download'));
            XLSX.utils.book_append_sheet(wb, ws1, 'Summary');
            XLSX.writeFile(wb, `changes_${getTodayValue()}.xlsx`);
        }
    };

    // ppt generate logic
    const pptGenerateHandler = () => {
        generatePPTExport();
        if(showExecutivePopup){
            closeExportPopup();
        }
        closeExport();
    };

    const closeExportPopup = () => {
        setExportPopup(false);
        updatePptSlideList();
    };

    const getChapterExport = () => {
        generateChapterExport(getTodayValue());
    };
    const phaseSelected = phases.find(({ phase_id: id = null }) => `${id}` === phaseId);
    const { phase_name: phaseInUse = '' } = phaseSelected || {};

    /** @param {'role_name' | 'role_id' | 'employee_name' | 'employee_id'} selectedType */
    const exportNnReport = async selectedType => {
        if (!selectedType || !phaseId || !structure?.id || !orgAccessToken) return;
        const rootRoleId = await getRootRoleId(orgAccessToken, structure?.id);
        if (!rootRoleId || !rootRoleId.included || !rootRoleId.included[0].id) {
            console.warn(`root role id not found ${rootRoleId}`);
            return;
        }
        const resp = await startGeneratingNnReport(orgAccessToken, {
            filter: selectedType,
            phaseId,
            structureId: structure.id,
            rootRoleId: rootRoleId.included[0].id,
        });
        // setNNReportJobId(resp?.jobId || '');
        setNnReportJobId(resp?.jobId || '');
        setNnReportLoading(true);
        // closes the drop down dialogue
        closeNnReport();
    };

    /**
     * @param {string} jobId
     * */
    const cacheRequest = jobId => {
        const json = localStorage.getItem(NN_REPORT_CACHE_STATE_KEY);
        try {
            /** @type {{jobId: string, downloaded: boolean}[]} cache */
            let cache = [];
            const parsed = JSON.parse(json);
            if (Array.isArray(parsed)) {
                cache = parsed;
            }
        } catch (err) {
            console.warn('Could not be cached', err);
            return;
        }
        localStorage.setItem();
    };

    useEffect(() => {
        if (nnReportLoading) {
            localStorage.setItem('nnReportJobId', nnReportJobId);
            localStorage.setItem('nnReportLoading', true);
            let time = 0;
            const endStatusCalls = async (callDownload, intervalTimer) => {
                clearInterval(intervalTimer);
                setNnReportLoading(false);
                // clear local storage in case of 'ERROR' or 'COMPLETED'
                localStorage.removeItem('nnReportJobId');
                localStorage.removeItem('nnReportLoading');
                if (callDownload) {
                    const date = new Date();
                    const mmddyyyy = `${date.getMonth() + 1}${date.getDate()}${date.getFullYear()}`;

                    let nnReportName = 'nn-report';
                    
                    if (structure.name) nnReportName += `-${structure.name}`;
                    nnReportName += `-${mmddyyyy}`;

                    const response = await downloadNnReport(orgAccessToken, nnReportJobId);
                    if (!response) return;
                    const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
                    const objectUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = objectUrl;
                    a.download = nnReportName + '.csv';
                    a.click();
                }
            };
            const intervalTimer = setInterval(async () => {
                time++;
                const response = await getGeneratingNnReportStatus(orgAccessToken, nnReportJobId);
                /** @type  {'COMPLETED' | 'ERROR' | 'QUEUED' | 'PROCESSING' | ''} */
                const status = response.status || '';
                if (status === 'COMPLETED' || status === 'ERROR') {
                    if (status === 'ERROR') {
                        console.error(response?.additionalInfo);
                    }
                    endStatusCalls(status === 'COMPLETED', intervalTimer);
                } else if (time > MAX_NN_REQUEST_COUNT) {
                    endStatusCalls(false, intervalTimer);
                }
            }, NN_REQUEST_INTERVAL);
            return () => clearInterval(intervalTimer);
        }
    }, [nnReportLoading]);

    return (
        <Box className={c('subHeader clearFix', { showStructures })}>
            {phases.length ? (
                <Fragment>
                    <div aria-controls="phase-menu" onClick={handlePhaseClick} className="phaseLink">
                        <span>{phaseInUse}</span>
                        <div>Design Session</div>
                        <ArrowDropDown />
                    </div>
                    <Menu id="phase-menu" anchorEl={phaseAnchor} open={openPhases} onClose={closePhases}>
                        {phases.map(({ phase_name: phaseName, phase_id: phaseVal }) => (
                            <MenuItem key={phaseVal} onClick={() => setNewPhase(phaseVal)}>
                                {phaseName}
                            </MenuItem>
                        ))}
                    </Menu>
                </Fragment>
            ) : null}

            {showStructures && strValues.length ? (
                <Fragment>
                    <div aria-controls="str-menu" onClick={handleStructureClick} className="phaseLink strLink">
                        <span>{structureInUse}</span>
                        <div>Structure</div>
                        <ArrowDropDown />
                    </div>
                    <Menu id="str-menu" anchorEl={strAnchor} open={openStructures} onClose={closeStructures}>
                        {strValues.map(({ name, id }) => (
                            <MenuItem key={id} onClick={() => updateStructure({ name, id,selected:true })}>
                                {name}
                            </MenuItem>
                        ))}
                    </Menu>
                </Fragment>
            ) : null}

            <ul>
                {showTeamChapterExport && (
                    <li onClick={() => getChapterExport()} className={c({ enableCompare: true })}>
                        <ExportIcon /> <span>Export</span>
                    </li>
                )}

                {showExport && (
                    <li onClick={() => getExport()} className={c({ enableCompare: true })}>
                        <ExportIcon /> <span>Export</span>
                    </li>
                )}

                {showPPTExportButton && (
                    <Fragment>
                        {(privileges?.exportPPTAccess || privileges?.NNexportPPTAccess) && (
                            <li
                                onClick={handleExportClick}
                                className={c({
                                    enableCompare: !(pptDownloading || nnReportLoading || disableExport),
                                    disableExport: pptDownloading || nnReportLoading || disableExport,
                                })}
                            >
                                {pptDownloading || nnReportLoading || disableExport ? (
                                    <CircularProgress color={'theme'} size={20} />
                                ) : (
                                    <ExportIcon />
                                )}{' '}
                                <span>Export </span>
                            </li>
                        )}
                        <Menu
                            id="export-menu"
                            anchorEl={exportAnchor}
                            keepMounted
                            open={openExport}
                            onClose={closeExport}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            MenuListProps={{
                                classes: {
                                    root: exportMenuClasses.root,
                                    padding: exportMenuClasses.padding
                                },
                            }}
                            style={{ top: 40}}
                        >
                            {privileges?.exportPPTAccess && (
                                <MenuItem onClick={() => {
                                    if(showExecutivePopup){
                                        setExportPopup(true)
                                    }
                                    else{
                                        pptGenerateHandler();
                                    }       
                                }}>Export PPT</MenuItem>
                            )}
                            <Divider style={{ margin: '4px 0px'}}/>
                            {privileges?.NNexportPPTAccess && (
                                <MenuItem onClick={handleNnReportClick}>N-N Report</MenuItem>
                            )}
                        </Menu>
                        <Menu
                            id="nn-report-export-menu"
                            anchorEl={nnReportAnchor}
                            keepMounted
                            open={openNnReport}
                            onClose={closeNnReport}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <MenuItem onClick={() => exportNnReport('role_id')}>Orglab Id</MenuItem>
                            <MenuItem onClick={() => exportNnReport('role_name')}>Role Title</MenuItem>
                            <MenuItem onClick={() => exportNnReport('employee_id')}>Employee Id</MenuItem>
                            <MenuItem onClick={() => exportNnReport('employee_name')}>Employee Name</MenuItem>
                            <MenuItem />
                        </Menu>
                        <ExportPopup
                            open={exportPopup}
                            onCancel={closeExportPopup}
                            onExport={pptGenerateHandler}
                            slides={pptSlideList}
                            setSlideList={setPptSlideList}
                        /> 
                    </Fragment>
                )}

                <li
                    onClick={refresh}
                    className={c(
                        'refreshTab',
                        { enableCompare: enableCompare && !apiIsLoading },
                        { enableRefreshTab: enableRefreshTab }
                    )}
                >
                    <RefreshIcon /> <span>Refresh</span>
                </li>
                {showResult !== false && (
                    <li
                        className={c({
                            active: filterApplied,
                            enableCompare,
                            enableFiltersTab,
                        })}
                        onClick={setDrawerOpen}
                    >
                        <FilterIcon /> <span>Filter</span>
                    </li>
                )}
            </ul>
        </Box>
    );
}

SubHeader.defaultProps = {
    showExport: false,
    apiIsLoading: false,
    showStructures: false,
    getCurrentStructure: () => {
        // This is intentional
    },
    phaseUpdated: () => {
        // This is intentional
    },
};

SubHeader.propTypes = {
    showExport: PropTypes.bool,
    apiIsLoading: PropTypes.bool,
    showStructures: PropTypes.bool,
    phaseUpdated: PropTypes.func,
    getCurrentStructure: PropTypes.func,
    isPhasesFetched: PropTypes.bool.isRequired,
    getPhase: PropTypes.func.isRequired,
    setDrawerOpen: PropTypes.func.isRequired,
    getPhaseStructures: PropTypes.func.isRequired,
    enableCompare: PropTypes.bool.isRequired,
    enableRefreshTab: PropTypes.bool.isRequired,
    enableFiltersTab: PropTypes.bool.isRequired,
    filterApplied: PropTypes.bool.isRequired,
    phases: PropTypes.array.isRequired,
    strValues: PropTypes.array.isRequired,
    pptSlideList: PropTypes.array.isRequired,
};

export default SubHeader;
