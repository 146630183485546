import React from 'react';
import './index.scss';

const BarStack = ({ data = [], color = '#eaeaea', actualVal = [], countFilter }) => {
    return (
        <div className="bar-stack">
            {data.map((el, index) => {
                return (
                    <div className="bar-container">
                        <div
                            className="bar"
                            title={`${actualVal[index] ? `${countFilter}:${actualVal[index]}` : ''}`}
                            style={{ width: `${el.value}%`, backgroundColor: color }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default BarStack;
