export const ERROR_400_MSG =
    "The page you are looking for doesn't exist. You may have mistyped the address or the page may have moved.";

export const SITE_TXT = {
    STRUCTURE_COMPARE: 'Choose structures to compare',
    STRUCTURE_CHOOSE: 'Choose structure',
    STRUCTURE_SELECT: 'Select Structure',
    COMPARE_TXT: 'Compare',
    CONTINUE_TXT: 'Continue',
    CLIENT_NAME: 'Client name',
    REPORT_SUB: 'Report',
};

export const REPORTS_LINK = {
    executiveDashboardAccess: {
        name: 'Executive dashboard',
        routeValue: '/executive-dashboard',
        value: 0,
    },
    teamsDashboardAccess: {
        name: 'Executive dashboard (Teams)',
        routeValue: '/teams',
        value: 1,
    },
    supplyDemandAccess: {
        name: 'Chapter supply/demand by teams',
        routeValue: '/supply-demand',
        value: 2,
    },
    summaryReportAccess: {
        name: 'Summary of changes',
        routeValue: '/compare',
        value: 3,
    },
    teamChaptersAccess: {
        // name: 'Distribution of team positions by chapter',
        name: 'Tribe x Chapter Table',
        routeValue: '/team-chapters',
        value: 4,
    },
    addressableOpportunityAccess: {
        name: 'Addressable opportunity',
        routeValue: '/addressable-opportunity',
        value: 5,
    },
};
export const ROLES_DATA_API = ['manager-fte-share', 'total-fte-share', 'non-manager-fte-share', 'span-of-control'];

export const TABLE_DATA_API = [
    'role-upgraded-downgraded',
    'role-added-deleted',
    'role-attributes',
    'role-lookup',
    'role-function',
    'role-location',
    'role-archetype',
    'emp-change',
    'role-manager',
    'employee-attributes',
    'span-change',
    'layer-change',
    'top-manager-change',
];

export const backgroundArr = ['#093D52', '#476982', '#6E879A', '#BBD5E9', '#A2CF63', '#486520', '#2B3D12'];
