import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import addressableOpportunityConst from './addressableOpportunity.enum';
import Tooltip from '../../components/Tooltip';

function InputTable({ inputDetails, onInputChange }) {
    return (
        <table className="input-table">
            <thead>
                {addressableOpportunityConst.inputTableHeading.map((info, index) => (
                    <th>
                        {index ? (
                            <>
                                <strong>Step {index} </strong>:{' '}
                            </>
                        ) : (
                            ''
                        )}
                        {info}
                    </th>
                ))}
            </thead>
            <tbody>
                <tr>
                    {addressableOpportunityConst.inputTableFixedRow.map(info => (
                        <td>{info}</td>
                    ))}
                </tr>
                <tr>
                    <td>
                        <div>
                            ASSUMPTION
                            <Tooltip title={<div>{addressableOpportunityConst.toolTipInfo}</div>}>
                                <span className="tooltip-info">
                                    <InfoIcon className="tooltip-icon" />
                                </span>
                            </Tooltip>
                        </div>
                    </td>
                    <td>
                        <span className="input-wrapper">
                            <span className="percent-sign">%</span>
                            <input
                                type="number"
                                max={100}
                                value={inputDetails.overspan_manager}
                                onChange={e => onInputChange('overspan_manager', e.target.value)}
                            />
                        </span>
                    </td>
                    <td>
                        <span className="input-wrapper">
                            <input
                                type="number"
                                value={inputDetails.geo_exception}
                                onChange={e => onInputChange('geo_exception', e.target.value)}
                            />
                        </span>
                    </td>
                    <td>
                        <span className="input-wrapper">
                            <span className="percent-sign">%</span>
                            <input
                                className="small"
                                max={100}
                                type="number"
                                value={inputDetails.hidden_players}
                                onChange={e => onInputChange('hidden_players', e.target.value)}
                            />
                        </span>
                    </td>
                    <td>
                        <div className="individual-contribution-input">
                            <label htmlFor="player_coach">Player Coach</label>
                            <span className="input-wrapper">
                                <span className="percent-sign">%</span>
                                <input
                                    className="small"
                                    max={100}
                                    type="number"
                                    value={inputDetails.archetypes[1]}
                                    onChange={e => onInputChange('archetypes', e.target.value, 1)}
                                />
                            </span>
                        </div>
                        <div className="individual-contribution-input">
                            <label htmlFor="coach">Coach</label>
                            <span className="input-wrapper">
                                <span className="percent-sign">%</span>
                                <input
                                    className="small"
                                    max={100}
                                    type="number"
                                    value={inputDetails.archetypes[2]}
                                    onChange={e => onInputChange('archetypes', e.target.value, 2)}
                                />
                            </span>
                        </div>
                        <div className="individual-contribution-input">
                            <label htmlFor="supervisor">Supervisor</label>
                            <span className="input-wrapper">
                                <span className="percent-sign">%</span>
                                <input
                                    className="small"
                                    max={100}
                                    type="number"
                                    value={inputDetails.archetypes[3]}
                                    onChange={e => onInputChange('archetypes', e.target.value, 3)}
                                />
                            </span>
                        </div>
                        <div className="individual-contribution-input">
                            <label htmlFor="facilitator">Facilitator</label>
                            <span className="input-wrapper">
                                <span className="percent-sign">%</span>
                                <input
                                    className="small"
                                    max={100}
                                    type="number"
                                    value={inputDetails.archetypes[4]}
                                    onChange={e => onInputChange('archetypes', e.target.value, 4)}
                                />
                            </span>
                        </div>
                        <div className="individual-contribution-input">
                            <label htmlFor="coordinator">Coordinator</label>
                            <span className="input-wrapper">
                                <span className="percent-sign">%</span>
                                <input
                                    className="small"
                                    max={100}
                                    type="number"
                                    value={inputDetails.archetypes[5]}
                                    onChange={e => onInputChange('archetypes', e.target.value, 5)}
                                />
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default InputTable;
