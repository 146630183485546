import React, { Fragment } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { toFixed } from '../../../../utils/functions';
import { backgroundArr } from '../../../../utils/constants';
import Shimmer from '../../../../components/shimmer';
import executiveDashboard from '../../executiveDashboard.enum';
import NoDataFound from '../../../../components/NoDataFound';
import './index.scss';
import { LightTooltip, useOrgShapeStyles } from './styles';
import { getMaxFte } from '../../../PPTExport/actions/exec-dashboard/shared';

function OrgShapeN1Leaders({ isFiltersApplied, getOrgshapeLeadersData, countFilterValue }) {
    const { total = [], layers, ftes, isNotCategorized, loading=true } = getOrgshapeLeadersData;
    const classes = useOrgShapeStyles();

    const maxFte = getMaxFte(layers, total);

    const getWidth = (v1, v2, sum = v1 + v2) => {
        const width = ((v1 + v2) * 100) / sum;
        if (width < 0.1) {
            return `calc(${Math.ceil(width)}%)`;
        }
        if (Number.isNaN(width)) {
            return 0;
        }
        return `calc(${width}%)`;
    };

    return (
        <Fragment>
            {loading ? (
                <Shimmer />
            ) : layers?.length > 0 && total?.length > 0 ? (
                <TableContainer component={Paper} className={'tableContainer'}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableBody>
                            {layers.length > 0 &&
                                layers.map((layer, idx, arr) => {
                                    return (
                                        <TableRow className={'tableRow'} key={`layer_${idx}`}>
                                            <TableCell className={`fixedCell`} style={{ width: '90px' }}>
                                                {' '}
                                                {`Layer ${layer.name + 1}`}{' '}
                                            </TableCell>
                                            {total.length > 0 &&
                                                total.map((fnId, id) => {
                                                    return (
                                                        <TableCell
                                                            className={`cell ${idx === arr.length - 1 ? 'borderedCell' : ''
                                                                }`}
                                                            style={{
                                                                minWidth: '150px',
                                                                width: `calc(100%/${total.length}`,
                                                            }}
                                                        >
                                                            <LightTooltip
                                                                title={toFixed(
                                                                    `${layer.data[fnId.id]?.data.managers +
                                                                    layer.data[fnId.id]?.data.nonManagers}`,
                                                                    2
                                                                )}
                                                            >
                                                                <span
                                                                    className="bar"
                                                                    style={{
                                                                        height: '20px',
                                                                        display: 'block',
                                                                        margin: '0 auto',
                                                                        backgroundColor:
                                                                            backgroundArr[id % backgroundArr.length],
                                                                        width: `${getWidth(
                                                                            layer.data[fnId.id]?.data.managers,
                                                                            layer.data[fnId.id]?.data.nonManagers,
                                                                            maxFte
                                                                        )}`,
                                                                    }}
                                                                >
                                                                    {''}
                                                                </span>
                                                            </LightTooltip>
                                                        </TableCell>
                                                    );
                                                })}
                                            <TableCell
                                                className={` ${idx === arr.length - 1 ? 'borderedCell' : ''}`}
                                                style={{
                                                    minWidth: `${isNotCategorized ? '150px' : 0}`,
                                                    width: `${total.length === 0
                                                            ? '90%'
                                                            : `${isNotCategorized ? `calc(100%/${total.length}` : 0}`
                                                        }`,
                                                    padding: `${isNotCategorized ? '16px' : 0}`,
                                                }}
                                            >
                                                <LightTooltip
                                                    title={toFixed(
                                                        `${arr[0]?.data['']?.data.managers +
                                                        arr[0]?.data['']?.data.nonManagers}`,
                                                        2
                                                    )}
                                                >
                                                    <span
                                                        className="bar"
                                                        style={{
                                                            height: '20px',
                                                            display: 'block',
                                                            margin: '0 auto',
                                                            backgroundColor: `${layer.name === 0 && '#3BB36C'}`,
                                                            width: `${getWidth(
                                                                arr[0]?.data['']?.data.managers,
                                                                arr[0]?.data['']?.data.nonManagers,
                                                                maxFte
                                                            )}`,
                                                        }}
                                                    >
                                                        {''}
                                                    </span>
                                                </LightTooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            <TableRow className={'legendRow firstLegendRow'}>
                                <TableCell className={`fixedCell boldCell removeBorder`}>{`By Leaders`}</TableCell>
                                {total.length > 0 &&
                                    total.map((leader, id) => {
                                        return (
                                            <TableCell
                                                key={id}
                                                className={`cell twice`}
                                                style={{
                                                    minWidth: '150px',
                                                    width: `calc(100%/${total.length}`,
                                                    overflowWrap: 'break-word',
                                                }}
                                            >
                                                <span> {`${leader.name}`} </span>
                                                <span>
                                                    {`${leader.employee && leader.employee.name
                                                            ? `(${leader.employee.name})`
                                                            : ''
                                                        }`}
                                                </span>
                                            </TableCell>
                                        );
                                    })}
                                <TableCell
                                    className={`cell twice`}
                                    style={{
                                        minWidth: `${isNotCategorized ? '150px' : 0}`,
                                        width: `${total.length === 0
                                                ? '90%'
                                                : `${isNotCategorized ? `calc(100%/${total.length}` : 0}`
                                            }`,
                                        padding: `${isNotCategorized ? '16px' : 0}`,
                                    }}
                                >
                                    <span>{`${isNotCategorized ? 'Not Categorized' : ''}`}</span>
                                </TableCell>
                            </TableRow>

                            <TableRow className={'legendRow secondLegendRow'}>
                                <TableCell className={`boldCell fixedCell`}>{`Total ${countFilterValue}`}</TableCell>
                                {ftes.map((total, i) => {
                                    return (
                                        <TableCell
                                            key={i}
                                            className={`cell twice`}
                                            style={{
                                                minWidth: '150px',
                                                width: `${ftes.length === 0 ? '90%' : `${100 / ftes.length}%`}`,
                                                textAlign: 'center',
                                                paddingLeft: 16,
                                            }}
                                        >
                                            {total}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataFound
                    mainMessage={`${isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                        }`}
                    secondaryMessage={`${isFiltersApplied
                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                        }`}
                />
            )}
        </Fragment>
    );
}
export default OrgShapeN1Leaders;
