import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import formattedUtilities from '../../../../common/utilities';
import FTEsIcon from '../../../../assets/fteIcon.svg';
import LayersIcon from '../../../../assets/layersIcon.svg';
import SpanOfControlIcon from '../../../../assets/spanOfControlIcon.svg';
import UniqueJobTitlesIcon from '../../../../assets/uniqueJobTitlesIcon.svg';
import SummaryInfoCard from '../../../../components/InfoCard';
import CustomToolTip from '../../../../components/Tooltip';
import './SummaryDetails.scss';

const SummaryDetails = ({ isLoading, reportSummary, countFilter, uniqueJobs, isUniqueJobsLoading }) => {
    const { stats, ftes } = reportSummary;
    const { avgSpan, totalFTEs, totalLayers, managerUnder4, managerUnder2 } = stats;
    const { totalManagers } = ftes;
    return (
        <section className="execDashboard-summary">
            <div className="execDashboard-summary__item">
                <SummaryInfoCard
                    title={`Total ${countFilter}`}
                    value={
                        formattedUtilities.floatIntValueFormatter(totalFTEs)
                            ? formattedUtilities.floatIntValueFormatter(totalFTEs).toLocaleString()
                            : '-'
                    }
                    icon={FTEsIcon}
                    isLoading={isLoading}
                />
            </div>
            <div className="execDashboard-summary__item">
                <SummaryInfoCard
                    title="Total Layers"
                    value={parseInt(totalLayers, 10) || '-'}
                    icon={LayersIcon}
                    isLoading={isLoading}
                />
            </div>
            <div className="execDashboard-summary__item avgSpan">
                <SummaryInfoCard
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Average Span of Control</span>
                            <CustomToolTip
                                title={
                                    <div className="execDashboard-summary__item-additional-info">
                                        <div>
                                            <div className="percentage-info">{`~${formattedUtilities.floatIntValueFormatter(
                                                parseInt(managerUnder4 || 0, 10) / parseInt(totalManagers, 10)
                                            ) * 100}% (${managerUnder4 || 0})`}</div>
                                            <div>Managers have 4 or few direct reports</div>
                                        </div>
                                        <div>
                                            <div className="percentage-info">{`~${formattedUtilities.floatIntValueFormatter(
                                                parseInt(managerUnder2 || 0, 10) / parseInt(totalManagers, 10)
                                            ) * 100}% (${managerUnder2 || 0})`}</div>
                                            <div>Managers have 2 or few direct reports</div>
                                        </div>
                                    </div>
                                }
                            >
                                <span className="avg-span__icon info-icon">
                                    <InfoIcon />
                                </span>
                            </CustomToolTip>
                        </div>
                    }
                    value={avgSpan ? formattedUtilities.floatIntValueFormatter(avgSpan, true) : '-'}
                    icon={SpanOfControlIcon}
                    isLoading={isLoading}
                />
            </div>
            <div className="execDashboard-summary__item">
                <SummaryInfoCard
                    title="Unique Job Titles"
                    value={parseInt(uniqueJobs, 10) ? parseInt(uniqueJobs, 10).toLocaleString() : '-'}
                    icon={UniqueJobTitlesIcon}
                    isLoading={isUniqueJobsLoading}
                />
            </div>
        </section>
    );
};

export default SummaryDetails;
