import React, { Fragment } from 'react';
import executiveDashboard from '../../../executiveDashboard.enum';
import Shimmer from '../../../../../components/shimmer';
import { Piechart } from '../../../../DesignPage/charts/ChartComp';
import formattedUtilities from '../../../../../common/utilities';
import { pieChartConfig } from '../../../../DesignPage/constants';
import NoDataFound from '../../../../../components/NoDataFound';

const FTES = props => {
    const { isLoading, ftes, countFilter, isFiltersApplied } = props;
    const { totalICContractors, totalICs, totalManagerContractors, totalManagers } = ftes;
    return (
        <Fragment>
            <div className="execDashboard-reports__label">{`Total ${countFilter}`}</div>
            {isLoading ? (
                <Shimmer />
            ) : totalManagers + totalICs ? (
                <section className={`pieCharts__container ${totalICs === totalManagers ? ' adjust-container' : ''}`}>
                    <div className="pieCharts">
                        <div className="piechart__managers">
                            <div className="pie-chart-arrow-label managers">
                                <div className="value">
                                    {formattedUtilities
                                        .floatIntValueFormatter(totalManagers - totalManagerContractors)
                                        .toLocaleString() || '0'}
                                </div>
                                <div className="label">{executiveDashboard.MANAGERS}</div>
                                <div className="arrow-down" />
                            </div>
                            <Piechart
                                tooltip={{
                                    useHTML: true,
                                    followPointer: false,
                                    enabled: false,
                                    formatter: function() {
                                        return `${
                                            this.point.name
                                        }<br/><span>${countFilter}: ${formattedUtilities.floatIntValueFormatter(
                                            this.point.y
                                        )}</span></span>`;
                                    },
                                }}
                                seriesData={[
                                    {
                                        y: totalManagers,
                                        color: '#6e879b',
                                        name: executiveDashboard.MANAGERS,
                                        dashStyle: 'Solid',
                                        borderColor: '#6e879b',
                                        borderWidth: 1,
                                    },
                                ]}
                                piechartConfig={{
                                    ...pieChartConfig,
                                    credits: {
                                        enabled: false,
                                    },
                                    chart: {
                                        type: 'pie',
                                        height: totalManagers > totalICs ? 250 : 200,
                                        width: totalManagers > totalICs ? 250 : 200,
                                    },
                                }}
                                plotOptions={{
                                    pie: {
                                        borderWidth: 0,
                                        cursor: 'pointer',
                                        dataLabels: {
                                            enabled: true,
                                            useHTML: true,
                                            distance: totalManagers > totalICs ? -150 : -120,
                                            color: 'white',
                                            formatter: function() {
                                                return `<div class="pieChart-label">
                                                              <div class="percentage">${formattedUtilities.floatIntValueFormatter(
                                                                  ((totalManagers - totalManagerContractors) /
                                                                      (totalManagers + totalICs)) *
                                                                      100
                                                              )}%
                                                              </div>                                      
                                                        </div>`;
                                            },
                                        },
                                    },
                                }}
                                legends={[]}
                            />
                            {totalManagerContractors > 0 && (
                                <div
                                    className={`manager-contractors ${
                                        totalManagers > totalICs ? 'adjust-contractors' : ''
                                    }`}
                                >
                                    {formattedUtilities.floatIntValueFormatter(
                                        (totalManagerContractors / (totalManagers + totalICs)) * 100
                                    )}
                                    %
                                    <div className="pie-chart-arrow-label-contractor managers">
                                        <div className="value">
                                            {formattedUtilities
                                                .floatIntValueFormatter(totalManagerContractors)
                                                .toLocaleString() || '0'}
                                        </div>
                                        <div className="label">{executiveDashboard.CONTRACTORS}</div>
                                        <div className="arrow-down" />
                                    </div>
                                </div>
                            )}
                        </div>
                        +
                        <div className="piechart__ic">
                            <div className="pie-chart-arrow-label ic">
                                <div className="value">
                                    {formattedUtilities
                                        .floatIntValueFormatter(totalICs - totalICContractors)
                                        .toLocaleString() || '0'}
                                </div>
                                <div className="label">{executiveDashboard.INDIVIDUAL_CONTRIBUTORS}</div>
                                <div className="arrow-down" />
                            </div>
                            <Piechart
                                tooltip={{
                                    useHTML: true,
                                    followPointer: false,
                                    enabled: false,
                                    formatter: function() {
                                        return `${
                                            this.point.name
                                        }<br/><span>${countFilter}: ${formattedUtilities.floatIntValueFormatter(
                                            this.point.y
                                        )}</span></span>`;
                                    },
                                }}
                                seriesData={[
                                    {
                                        y: totalICs,
                                        color: '#8dc541',
                                        name: executiveDashboard.INDIVIDUAL_CONTRIBUTORS,
                                        dashStyle: 'Solid',
                                        borderColor: '#8dc541',
                                        borderWidth: 1,
                                    },
                                ]}
                                piechartConfig={{
                                    ...pieChartConfig,
                                    credits: {
                                        enabled: false,
                                    },
                                    chart: {
                                        type: 'pie',
                                        height: totalManagers < totalICs ? 250 : 200,
                                        width: totalManagers < totalICs ? 250 : 200,
                                    },
                                }}
                                plotOptions={{
                                    pie: {
                                        borderWidth: 0,
                                        cursor: 'pointer',
                                        dataLabels: {
                                            enabled: true,
                                            useHTML: true,
                                            distance: totalManagers < totalICs ? -150 : -120,
                                            color: 'white',
                                            formatter: function() {
                                                return `<div class="pieChart-label">
                                                             <div class="percentage">${formattedUtilities.floatIntValueFormatter(
                                                                 ((totalICs - totalICContractors) /
                                                                     (totalManagers + totalICs)) *
                                                                     100
                                                             )}%
                                                             </div>      
                                                        </div>`;
                                            },
                                        },
                                    },
                                }}
                                legends={[]}
                            />
                            {totalICContractors > 0 && (
                                <div
                                    className={`ic-contractors ${
                                        totalManagers >= totalICs ? 'adjust-contractors' : ''
                                    }`}
                                >
                                    {formattedUtilities.floatIntValueFormatter(
                                        (totalICContractors / (totalManagers + totalICs)) * 100
                                    )}
                                    %
                                    <div className="pie-chart-arrow-label-contractor ic">
                                        <div className="value">
                                            {formattedUtilities
                                                .floatIntValueFormatter(totalICContractors)
                                                .toLocaleString() || '0'}
                                        </div>
                                        <div className="label">{executiveDashboard.CONTRACTORS}</div>
                                        <div className="arrow-down" />
                                    </div>
                                </div>
                            )}
                        </div>
                        =
                        <div className="total">
                            <div>Total</div>
                            <div>
                                {formattedUtilities.floatIntValueFormatter(totalManagers + totalICs).toLocaleString()}
                            </div>
                        </div>
                    </div>
                    <div className={`total-ftes ${totalManagers > totalICs ? 'adjust-total' : ''}`}>
                        <div className="total-managers">
                            <div className="label">Total {executiveDashboard.MANAGERS}</div>
                            <div className="value">
                                {formattedUtilities.floatIntValueFormatter(totalManagers).toLocaleString() || '0'}
                            </div>
                        </div>
                        <div className="total-ic">
                            <div className="label">Total {executiveDashboard.INDIVIDUAL_CONTRIBUTORS}</div>
                            <div className="value">
                                {formattedUtilities.floatIntValueFormatter(totalICs).toLocaleString() || '0'}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <div className="costchart" style={{ padding: '77px 20px' }}>
                    <NoDataFound
                        mainMessage={`${
                            isFiltersApplied
                                ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                                : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                        }`}
                        secondaryMessage={`${
                            isFiltersApplied
                                ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                                : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                        }`}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default FTES;
