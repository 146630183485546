import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SubHeader from './SubHeader';

import { getPhaseStructures, setStructure } from '../../containers/StructureContent/actions';
import { getFilters, getStructure, selectStructures } from '../../containers/StructureContent/selector';
import { getPhase } from '../../containers/Login/actions';
import { getPhases, selectPhasesFetched, selectPrivileges } from '../../containers/Login/selector';
import { getRefreshedData, resetFilters } from '../../containers/Filters/actions/actions';
import pptDownloadingSelector from '../../containers/PPTExport/selector';
import { goToInputSection } from '../../containers/AddressableOpportunity/actions';

const stateToProps = createStructuredSelector({
    phases: getPhases(),
    isPhasesFetched: selectPhasesFetched(),
    primaryFilters: getFilters(),
    strValues: selectStructures(),
    pptDownloading: pptDownloadingSelector(),
    privileges: selectPrivileges(),
    structure: getStructure(),
});

const dispatchToProps = {
    getPhase,
    getPhaseStructures,
    setStructure,
    resetFilters,
    getRefreshedData,
    goToInputSection,
};

export default connect(stateToProps, dispatchToProps)(SubHeader);
