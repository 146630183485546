import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_rounded from 'highcharts-rounded-corners';
import drilldown from 'highcharts/modules/drilldown';
import Legends from '../Components/Legends';

HC_rounded(Highcharts);
drilldown(Highcharts);

const CostChart = ({
    costchartConfig,
    seriesData,
    legends,
    plotOptions,
    dataLabels = {},
    xAxis = {},
    yAxis = {},
    tooltip = {},
    callback = function() {
        // This is intentional
    },
}) => {
    return (
        <div className="costchart boxShadow">
            <HighchartsReact
                highcharts={Highcharts}
                callback={callback}
                options={{
                    ...costchartConfig,
                    xAxis,
                    yAxis,
                    plotOptions,
                    dataLabels,
                    tooltip,
                    series: seriesData,
                }}
            />
            <Legends nameAndcolors={legends} />
        </div>
    );
};

CostChart.propTypes = {
    costchartConfig: PropTypes.object.isRequired,
    seriesData: PropTypes.array.isRequired,
    legends: PropTypes.array.isRequired,
};

export default CostChart;
