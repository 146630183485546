import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Login from './Login';
import login from './reducer';
import loginSagas from './sagas';
import { getClient, getPrivileges, getToken } from './actions';

import {
    selectLoginLoading,
    selectError,
    selectPhasesFetched,
    selectSummaryReportAllowed,
    selectExecutiveDashboardAllowed,
    selectPrivileges,
    selectAnalyticsClientFetched,
    selectPrivilegesFetched,
} from './selector';

const stateToProps = createStructuredSelector({
    isLoading: selectLoginLoading(),
    errorMsg: selectError(),
    isPhasesFetched: selectPhasesFetched(),
    isSummaryReportAllowed: selectSummaryReportAllowed(),
    isExecutiveDashboardAllowed: selectExecutiveDashboardAllowed(),
    isAnalyticsClientFetched: selectAnalyticsClientFetched(),
    privileges: selectPrivileges(),
    isPrivilegesFetched: selectPrivilegesFetched(),
});

const dispatchToProps = { getClient, getToken, getPrivileges };

export { loginSagas, login };

export default connect(stateToProps, dispatchToProps)(Login);
