import axios, { CancelToken } from 'axios';
import AuthStore from '../common/AuthStore';

function getConfig(token = '', tokentype = 'analytics', addExtraToken = false) {
    // tokentype
    // analytics - of executive dashboard
    // main - of orglab

    const { accessToken = '', orgAccessToken = '', clientId = '' } = AuthStore;
    const Authorization = token || (tokentype === 'main' ? `Bearer ${orgAccessToken}` : accessToken);
    let cancel;
    // Return the cancel function and add it to the header.
    // See: https://github.com/mzabriskie/axios#cancellation
    const config = {
        cancelToken: new CancelToken(c => {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization,
            'X-Frame-Options': 'deny',
            'X-Content-Type-Options': 'nosniff',
            'Referrer-Policy': 'strict-origin-when-cross-origin',
            'Content-Security-Policy': "default-src 'self'",
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Authorize-As-Client': clientId,
        },
    };

    if (addExtraToken) config.headers['x-org-token'] = `Bearer ${orgAccessToken}`;

    return {
        cancel,
        config,
    };
}

export function webApiGet(url, token, tokentype, addExtraToken = false) {
    const config = getConfig(token, tokentype, addExtraToken);
    return {
        request: axios.get(url, config.config),
        cancel: config.cancel,
    };
}

export function webApiPost(url, options, addExtraToken) {
    let config;
    if (addExtraToken) config = getConfig('', '', true);
    else config = getConfig();

    if (options?.primary_filters?.manager_id) {
        options.primary_filters.manager_id = options.primary_filters.manager_id.map(value => value.split('|')[0]);
    }

    return {
        request: axios.post(url, options, config.config),
        cancel: config.cancel,
    };
}
