import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import './index.scss';

const CustomToolTipAddContent = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
        padding: 0,
    },
}))(Tooltip);

const CustomToolTip = withStyles(theme => ({
    arrow: {
        color: theme.palette.common.white,
        '&:before': {
            border: '1px solid #E6E8ED',
        },
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid #E6E8ED',
        fontSize: 11,
        padding: 10,
        boxShadow: '0 2px 6px 0 rgba(9, 61, 82, 0.19)',
    },
}))(Tooltip);

const TextBox = ({ heading, structureId, onSetGuidance, id, isEditMode, guidance, fteEnvelope }) => {
    const [editMode, setEditMode] = useState(isEditMode);
    const [open, setOpen] = React.useState(false);
    const [guidanceValue, setGuidanceValue] = React.useState(guidance[id]);
    const handleTooltipClose = () => {
        setOpen(false);
        if (guidance[id]) {
            setEditMode(true);
        }
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const onSetValue = (selectedStructureId, id) => {
        onSetGuidance(selectedStructureId, { ...guidance, [id]: guidanceValue, fteEnvelope: { ...fteEnvelope } });
        setEditMode(true);
    };

    const onGuidanceChange = event => {
        setGuidanceValue(event.target.value);
    };

    const onEdit = (event, val) => {
        event.preventDefault();
        event.stopPropagation();
        setEditMode(val);
        handleTooltipOpen();
    };

    const getToolTipContent = () => {
        return (
            <div className="text-modal">
                <div className="heading">{heading}</div>
                <textarea
                    className="content"
                    defaultValue={guidanceValue || guidance[id] || ''}
                    value={guidanceValue}
                    onChange={event => onGuidanceChange(event)}
                />
                <div className="actions">
                    <div className="cancel" onClick={handleTooltipClose}>
                        Cancel
                    </div>
                    <div className="save" onClick={() => onSetValue(structureId, id)}>
                        Save
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="text-box">
            {editMode ? (
                <CustomToolTip title={guidanceValue} arrow placement="top">
                    <div className="text-area freeze">
                        <span>
                            {guidanceValue || (
                                <span style={{ position: 'relative', top: '10px' }}>Click here to enter content</span>
                            )}
                        </span>
                        <span className="edit">
                            <EditIcon onClick={event => onEdit(event, false)} />
                        </span>
                    </div>
                </CustomToolTip>
            ) : (
                <div className="text-area add">
                    <CustomToolTipAddContent id="text-tool-tip" open={open} title={getToolTipContent()}>
                        <span onClick={handleTooltipOpen}>
                            Click here to enter content
                            <span className="edit">
                                <EditIcon onClick={event => onEdit(event, false)} />
                            </span>
                        </span>
                    </CustomToolTipAddContent>
                </div>
            )}
        </div>
    );
};

export default TextBox;
