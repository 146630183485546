import { PPT_SLIDES_CATEGORIES, PPT_SLIDES_CATEGORIES_KEYS } from "../PPTExport/actions/exec-dashboard/shared";
import { toFixed } from "../../utils/functions";

export const isSpanDataPresent = series => {
    let isDataPresent = false;
    series.forEach(el => {
        el.data.forEach(item => {
            if (item !== 0) {
                isDataPresent = true;
            }
        });
    });

    return isDataPresent;
};

export const isManagerialStackingPresent = data =>
    Object.keys(data)?.map(key => {
        const { name, data: titles } = data[key];
        return Object.keys(titles).some(el => titles[el].name === name);
    }).some(s => s);

export const getOptions = slidesMap => Object.keys(PPT_SLIDES_CATEGORIES).map(slide => {
    const obj = {
        id: slide,
        checked: false,
        children: [],
        indeterminate: false,
        disabled: !slidesMap[slide]
    }
    if (slide === PPT_SLIDES_CATEGORIES_KEYS.layers) {
        const fteCount = slidesMap[slide] && slidesMap[slide].totals ? slidesMap[slide].totals.fteCount : 0;
        return {
            ...obj,
            label: "Layers",
            disabled: !fteCount,
            children: Object.keys(PPT_SLIDES_CATEGORIES[slide])?.map(sb => ({
                ...obj,
                id: sb,
                label: PPT_SLIDES_CATEGORIES[slide][sb],
                disabled: !fteCount,
            }))
        }
    }
    if (slide === PPT_SLIDES_CATEGORIES_KEYS.org_shape_function || slide === PPT_SLIDES_CATEGORIES_KEYS.org_shape_leader) {
        return {
            ...obj,
            disabled: !(slidesMap[slide] && slidesMap[slide].layers?.length > 0 && slidesMap[slide].total?.length > 0),
            label: PPT_SLIDES_CATEGORIES[slide],
        }
    }
    if (slide === PPT_SLIDES_CATEGORIES_KEYS.span_of_control_overview) {
        return {
            ...obj,
            label: PPT_SLIDES_CATEGORIES[slide],
            disabled: !(slidesMap[slide] && slidesMap[slide].series?.length && isSpanDataPresent(slidesMap[slide].series))
        }
    }
    if (slide === PPT_SLIDES_CATEGORIES_KEYS.pay_distribution || slide === PPT_SLIDES_CATEGORIES_KEYS.title_distribution) {
        return {
            ...obj,
            label: PPT_SLIDES_CATEGORIES[slide],
            disabled: !Object.keys(slidesMap[slide])?.length
        }
    }
    if (slide === PPT_SLIDES_CATEGORIES_KEYS.managerial_stacking) {
        return {
            ...obj,
            label: PPT_SLIDES_CATEGORIES[slide],
            disabled: slidesMap[slide] && !isManagerialStackingPresent(slidesMap[slide])
        }
    }
    return {
        ...obj,
        label: PPT_SLIDES_CATEGORIES[slide],
    }
});

export const getOrgShapeTotals = (layers, groupByArr, isCategorized) => {
    const ids = [];
    const selectedIds = [];
    let categorizedValue = 0;
    let isNotCategorized = false;
    layers?.length > 0 &&
        layers.forEach(item => {
            if (isCategorized && item.name === 0) {
                categorizedValue = 1;
            }
            groupByArr?.length > 0 &&
                groupByArr.forEach(fnId => {
                    if (Object.keys(item.data).includes(fnId.id || fnId.id.toString())) {
                        if (!ids.includes(fnId.id)) {
                            ids.push(fnId.id);
                            selectedIds.push(
                                isCategorized
                                ? { id: fnId.id, name: fnId.name, employee: fnId.employee }
                                : { id: fnId.id, name: fnId.attributes.name }
                            );
                        }
                    }
                });
        });
    if (categorizedValue) {
        isNotCategorized = true;
    } else {
        isNotCategorized = false;
    }
    return {
        total: selectedIds.slice().sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
        isNotCategorized
    }
}

export const getOrgShapeFtes = (totals, layers, isNotCategorized) => {
    let sum = 0;
    let notCategorizedSum = -1;
    const ftes = [];
    totals?.length > 0 &&
        totals.forEach(fnId => {
            layers?.length > 0 &&
                layers.forEach((item, id, arr) => {
                    if (isNotCategorized) {
                        notCategorizedSum = arr[0]?.data['']?.data.managers + arr[0]?.data['']?.data.nonManagers;
                    }
                    if (Object.keys(item.data).includes(fnId.id.toString())) {
                        sum += item.data[fnId.id].data.managers + item.data[fnId.id].data.nonManagers;
                    }
                });
            ftes.push(toFixed(sum, 2));
            sum = 0;
        });

    isNotCategorized && layers?.length > 0
        ? (notCategorizedSum =
            layers[0]?.data['']?.data.managers + layers[0]?.data['']?.data.nonManagers)
        : (notCategorizedSum = 0);

    if (isNotCategorized && notCategorizedSum > -1) {
        ftes.push(toFixed(notCategorizedSum, 2));
    }
    if(!isNotCategorized && !ftes.length) {
        ftes.push(0);
    }
    return ftes;
}
