import React, { Fragment } from 'react';
import './index.scss';
import Badge from '../../../../../components/Badge';
import executiveDashboard from '../../../executiveDashboard.enum';
import { getBadgeType, getPercentage } from '../../../../../common/utilities';
import BarStack from '../../../../../components/BarStack';

const defaultLayers = [
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 70 },
];
const Layers = ({ data, isTargetVisible, ranges, target, countFilter }) => {
    const getTarget = () => {
        const _targetData = [];
        for (let i = 0; i < target; i += 1) {
            _targetData.push({ value: (100 * (i + 1)) / parseInt(target, 10) });
        }
        return _targetData;
    };

    return (
        <div className="design-process__layers">
            <div className="design-process__layers-structures">
                <span className="y-label">Layers</span>
                {Object.keys(data).map((el, index) => {
                    const { id, value, shouldShow, actualVal } = data[el];
                    return (
                        <section
                            key={index}
                            className={`design-process__layers-structure ${shouldShow ? '' : 'hidden'}`}
                        >
                            <div className="layers-count__label">{value?.length || '-'}</div>
                            <BarStack
                                countFilter={countFilter}
                                actualVal={actualVal || []}
                                data={value?.length > 0 ? value : defaultLayers}
                                color={value?.length > 0 ? '#6e879a' : '#eaeaea'}
                            />
                        </section>
                    );
                })}
                <section className={`design-process__layers-structure ${target || isTargetVisible ? '' : 'hidden'}`}>
                    <div className="layers-count__label">{target || ''}</div>
                    <BarStack data={target ? getTarget() : defaultLayers} color={target ? '#bbd5e9' : '#eaeaea'} />
                </section>
            </div>
            <div className="design-progress__layers-labels">
                {Object.keys(data).map((el, index) => {
                    const { id, name, value = [], shouldShow } = data[el];
                    return (
                        <div key={`layers_label_${id}${index}`} className={`structure ${shouldShow ? '' : 'hidden'}`}>
                            <div className="label">{name}</div>

                            <div className="value">
                                {target > 0 && value.length > 0 && (
                                    <Fragment>
                                        {!index && <div className="difference-target">(%) of the target</div>}
                                        <Badge
                                            percentage={`${getPercentage(value.length, target)}%`}
                                            type={getBadgeType(value.length, target, ranges)}
                                        />
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    );
                })}
                <div className={`structure ${target || isTargetVisible ? '' : 'hidden'}`}>
                    <div className="label">{executiveDashboard.TARGET_TEXT}</div>
                </div>
            </div>
        </div>
    );
};

export default Layers;
