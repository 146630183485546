import { GET_REPORT, GO_TO_INPUT, RESET_STATE, IS_INPUT_SCREEN } from './constants';

export const getAddressableReport = payload => ({
    type: `${GET_REPORT}_PENDING`,
    payload,
});

export const getReportSuccess = payload => ({
    type: `${GET_REPORT}_SUCCESS`,
    payload,
});

export const getReportFailed = (payload = {}) => ({
    type: `${GET_REPORT}_FAILED`,
    payload,
});

export const goToInputSection = () => ({
    type: `${GO_TO_INPUT}`,
});

export const resetState = () => ({
    type: `${RESET_STATE}`,
});
export const setIsInputScreenAction = payload => ({
    type: `${IS_INPUT_SCREEN}`,
    payload,
});
