const stackingConfig = {
    chart: {
        type: 'column',
    },
    title: {
        text: '',
    },
    xAxis: {
        title: {
            text: 'Benchmark Titles',
        },
        labels: {
            autoRotation: false,
        },
    },
    yAxis: {
        title: {
            text: '<span style="font-weight:bold;font-family: \'Lato\', sans-serif;">Total Stacked</span>',
        },
        gridLineColor: '#197F07',
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
            {
                color: '#FFFFFF',
                width: 1,
                value: 0,
            },
        ],
    },
    legend: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        series: {
            pointWidth: 40,
        },
    },
};

export default stackingConfig;
