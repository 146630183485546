import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import AuthStore from '../../common/AuthStore';
import Loader from '../../components/Loader';
import './index.scss';

function LoginContent({ isLoading, history, getToken, getClient, privileges, isPrivilegesFetched }) {
    useEffect(() => {
        if (isPrivilegesFetched) {
            if (privileges.executiveDashboardAccess) {
                history.push('/executive-dashboard');
            } else if (privileges.summaryReportAccess) {
                history.push('/compare');
            } else if (privileges.teamsDashboardAccess) {
                history.push('/teams');
            } else if (privileges.supplyDemandAccess) {
                history.push('/supply-demand');
            } else if (privileges.teamChaptersAccess) {
                history.push('/team-chapters');
            } else {
                history.push('/404-not-found');
            }
        }
        // eslint-disable-next-line
    }, [isPrivilegesFetched]);

    useEffect(() => {
        const jwtMatches = window.location.hash.match(/jwt=([a-zA-Z0-9_.-]+)/);
        if (jwtMatches) {
            localStorage.removeItem('structureId');
            AuthStore.deleteAccessToken();
            const clientId = new URL(window.location).searchParams.get('clientId');
            const phaseId = new URL(window.location).searchParams.get('phaseId');
            const accessToken = `Bearer ${jwtMatches[1]}`;
            AuthStore.accessToken = accessToken;
            AuthStore.orgAccessToken = jwtMatches[1];
            AuthStore.clientId = clientId;
            AuthStore.phaseId = phaseId;
            getToken();
        } else {
            const { clientId = '' } = AuthStore;
            getClient(clientId);
        }
        // eslint-disable-next-line
    }, []);
    return (
        <Box className="loginPage clearFix">
            {isLoading ? <Loader showBackground background="transparent" /> : null}
        </Box>
    );
}

LoginContent.propTypes = {
    getClient: PropTypes.func.isRequired,
    getToken: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default LoginContent;
