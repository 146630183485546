import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const SearchInput = ({ type, id, label, variant, size, placeholder, onChange }) => (
    <TextField
        type={type}
        id={id}
        label={label}
        variant={variant}
        size={size}
        placeholder={placeholder}
        fullWidth
        onChange={onChange}
        autoComplete="off"
    />
);

SearchInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
};

SearchInput.defaultProps = {
    variant: 'outlined',
    placeholder: 'Type to search...',
    size: 'small',
    type: 'text',
};

export default SearchInput;
