import React, { Fragment } from 'react';
import Progress from '../../../../../components/Progress';
import Badge from '../../../../../components/Badge';
import executiveDashboard from '../../../executiveDashboard.enum';
import './index.scss';
import { getPercentage, getMaxWidth, getBadgeType } from '../../../../../common/utilities';

const DirectReports = ({ data, isTargetVisible, ranges, target }) => {
    let maxValue = target;
    return (
        <div className="design-progress-direct-reports">
            {Object.keys(data).map((el, index) => {
                const { id, name, value, max, shouldShow } = data[el];
                maxValue = target > max ? target : max;
                return (
                    <section
                        key={`direct_report_${id}${index}`}
                        className={`direct-reports__structure ${shouldShow ? '' : 'hidden'}`}
                    >
                        <div className="direct-reports__structure-name">
                            <div className="label">{name}</div>
                            <div className="value">{value ? value.toFixed(1) : '-'}</div>
                        </div>
                        <div className="direct-reports__structure-progress">
                            <Progress
                                type={'rounded'}
                                width={`${value ? getMaxWidth(value, maxValue) : 100}%`}
                                color={value ? '#6e879a' : '#eaeaea'}
                            />
                        </div>

                        <div className="direct-reports__structure-percentage">
                            {target > 0 && value > 0 && (
                                <Fragment>
                                    {!index && <div className="difference-target">(%) of the target</div>}
                                    <Badge
                                        type={getBadgeType(value, target, ranges)}
                                        percentage={`${getPercentage(value, target)}%`}
                                    />
                                </Fragment>
                            )}
                        </div>
                    </section>
                );
            })}

            <section className={`direct-reports__structure ${target || isTargetVisible ? '' : 'hidden'}`}>
                <div className="direct-reports__structure-name">
                    <div className="label">{executiveDashboard.TARGET_TEXT}</div>
                    <div className="value">{target ? Number(target).toFixed(1) : '~'}</div>
                </div>
                <div className="direct-reports__structure-progress">
                    <Progress
                        width={`${target ? getMaxWidth(target, maxValue) : 100}%`}
                        color={target ? '#bbd5e9' : '#eaeaea'}
                        type="rounded"
                    />
                </div>
                <div className="direct-reports__structure-percentage" />
            </section>
        </div>
    );
};

export default DirectReports;
