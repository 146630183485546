export const spanDistributionConfig = {
    chart: {
        type: 'column',
    },
    title: {
        text: '',
    },
    xAxis: {
        categories: ['1-2', '3-4', '5-6', '7-8', '9-10', '11-15', '16+', 'Total'],
    },
    yAxis: {
        title: {
            text: '<span style="font-weight:bold;font-family: \'Lato\', sans-serif;">No. of Managers</span>',
        },
        gridLineColor: '#197F07',
        gridLineWidth: 0,
        lineWidth: 1,
        stackLabels: {
            enabled: true,
        },
        plotLines: [
            {
                color: '#FFFFFF',
                width: 1,
                value: 0,
            },
        ],
    },
    credits: {
        enabled: false,
    },
    legend: {
        reversed: true,
    },
    plotOptions: {
        series: {
            stacking: 'normal',
            pointPadding: -0.2,
            dataLabels: {
                enabled: false,
                crop: false,
                overflow: 'none',
            },
        },
    },
};

export const spanDistributionMatrix = {
    'Managing Too Few': { color: '#d55347' },
    'Managing Too Many': { color: '#7ab8df' },
    'Managing In Range': { color: '#a0cf63' },
    Unknown: { color: '#d8d8d8' },
};
