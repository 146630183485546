import React from 'react';

export const FilterIcon = () => (
    <svg width="17" height="13" viewBox="0 0 14 10">
        <path
            fillOpacity=".7"
            fillRule="evenodd"
            d="M5.444 10h3.112V8.333H5.444V10zM0 0v1.667h14V0H0zm2.333 5.833h9.334V4.167H2.333v1.666z"
        />
    </svg>
);

export const ExportIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0L24 0 24 24 0 24z" />
            <path fill="#000" fillOpacity=".7" d="M19 10h-4V4H9v6H5l7 7 7-7zM5 19v2h14v-2H5z" />
        </g>
    </svg>
);

export const MenuIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <defs>
            <filter id="prefix__a">
                <feColorMatrix
                    in="SourceGraphic"
                    values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
                />
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-1319 -8)">
            <g>
                <path
                    fill="#4C4C4C"
                    fillRule="nonzero"
                    d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"
                    transform="translate(1319 8)"
                />
                <path d="M0 0L24 0 24 24 0 24z" transform="translate(1319 8)" />
            </g>
        </g>
    </svg>
);
