import { combineReducers } from 'redux-immutable';
import { structuresRedux } from '../containers/StructureContent';
import { login } from '../containers/Login';
import loader from './loader';
import filtersReducer from '../containers/Filters/reducers/reducers';
import addressableOpportunityReducer from '../containers/AddressableOpportunity/reducer';
import pptReducer from '../containers/PPTExport/reducer';

const rootReducer = combineReducers({
    loader,
    login,
    structuresRedux,
    filtersReducer,
    addressableOpportunityReducer,
    pptReducer,
});

export default rootReducer;
