import React, { useEffect, useState } from 'react';
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import edit from '../../../assets/editIcon.svg';
import executiveDashboard from '../executiveDashboard.enum';
import Overall from './Overall';
import './index.scss';
import Loader from '../../../components/Loader';
import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tabs/Tab';
import Leaders from './Leaders';
import CustomToolTip from '../../../components/Tooltip';
import { setMultiFiltersTray } from '../../Filters/actions/actions';
import Functions from './Functions';

const DesignProgress = ({
    designProgressLoading,
    structures,
    setDesignProgressEditMode,
    designProgressEditMode,
    overallFiltersToApply,
    leadersFiltersToApply,
    functionsFiltersToApply,
    refreshCount,
    setDesignProgressOverallActiveStructures,
    setDesignProgressLeadersActiveStructures,
    setDesignProgressFunctionsActiveStructures,
    designProgressOverallActiveStructures,
    designProgressLeadersActiveStructures,
    designProgressFunctionsActiveStructures,
    designProgressOverallRanges,
    designProgressLeadersRanges,
    designProgressFunctionsRanges,
    getSelectedStructureFilter,
    updateRanges,
    targets,
    getTargets,
    setTargets,
    updateTargets,
    privileges,
    setDesignProgressState,
    staticFilters,
    updateMultiFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    overallFiltersTray,
    leadersFiltersTray,
    functionsFiltersTray,
}) => {
    const useStyles = makeStyles(theme => ({
        root: {
            position: 'static',
        },
        dropdown: {
            position: 'absolute',
            top: 45,
            right: '9%',
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
        },
    }));
    const [isLoading, setLoading] = useState(false);
    const [tab, setActiveTab] = useState(0);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [leadersTargetDialog, setLeadersTargetDialog] = useState(false);
    const [bulkEditLeaderDialog, setBulkEditLeaderDialog] = useState(false);
    const [editRangeLeaderDialog, setEditRangeLeaderDialog] = useState(false);

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const [rangeError, setRangeError] = useState(false);

    useEffect(() => {
        getTargets();
        setDesignProgressState('overall');
    }, []);

    const updateChildren = (mainParent = [], isSelected) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = isSelected;
            if (innerChild.children) {
                updateChildren(innerChild.children, isSelected);
            }
        });
    };

    const onRangeChange = (evt, type, referer) => {
        if (referer === 'overall') {
            updateRanges({ ...designProgressOverallRanges, [type]: parseInt(evt.target.value, 10) }, referer);
        }
    };

    const onTargetSave = () => {
        const { min, midLow, midHigh, max } =
            tab === 1
                ? designProgressLeadersRanges
                : tab === 2
                ? designProgressFunctionsRanges
                : designProgressOverallRanges;
        const validElements = [min, midLow, midHigh, max].every(el => {
            return el < 100 && el > 0;
        });

        if (min === midLow && midHigh === max && midHigh > midLow && validElements) {
            setLoading(true);
            setRangeError(false);
            setDesignProgressEditMode(false);
            // api call to save targets...
            if (!tab) {
                const rangeWithTargets = { ...targets, range: { ...designProgressOverallRanges } };
                localStorage.setItem(executiveDashboard.LOCAL_TARGET_KEY, JSON.stringify(rangeWithTargets));
                updateTargets(rangeWithTargets);
            }
        } else {
            setRangeError(true);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const onTargetCancel = () => {
        const lastSavedTargets = JSON.parse(localStorage.getItem(executiveDashboard.LOCAL_TARGET_KEY)) || {};
        setTargets(lastSavedTargets);
        setDesignProgressEditMode(false);
        setRangeError(false);
    };

    const handleTabChange = (index, tabName) => {
        setDesignProgressState(tabName.replace('BY ', '').toLowerCase());
        setActiveTab(index);
    };

    return (
        <div className="design-progress">
            {isLoading ? <Loader showBackground background="transparent" /> : null}
            {privileges.isAdminAccess && !designProgressEditMode && !tab && (
                <CustomToolTip title={<div>Edit Target Value</div>}>
                    <img
                        alt="edit"
                        src={edit}
                        className="edit"
                        onClick={() => {
                            window.scrollBy({
                                top: 100,
                                left: 0,
                                behavior: 'smooth',
                            });
                            setDesignProgressEditMode(true);
                        }}
                    />
                </CustomToolTip>
            )}

            {privileges.isAdminAccess && !designProgressEditMode && tab === 1 ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div className={classes.root}>
                        <img
                            alt="edit"
                            src={edit}
                            className={`edit ${
                                designProgressLeadersActiveStructures.structure1.id ||
                                designProgressLeadersActiveStructures.structure2.id ||
                                designProgressLeadersActiveStructures.structure3.id
                                    ? ''
                                    : 'disabled'
                            }`}
                            onClick={handleClick}
                        />
                        {open ? (
                            <div className={classes.dropdown + ' edit-options'}>
                                <div onClick={() => setBulkEditLeaderDialog(true)}>Bulk edit</div>
                                <div onClick={() => setLeadersTargetDialog(true)}>Edit by leader</div>
                                <div onClick={() => setEditRangeLeaderDialog(true)}>Edit range</div>
                            </div>
                        ) : null}
                    </div>
                </ClickAwayListener>
            ) : null}

            {privileges.isAdminAccess && !designProgressEditMode && tab === 2 ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div className={classes.root}>
                        <img
                            alt="edit"
                            src={edit}
                            className={`edit ${
                                designProgressFunctionsActiveStructures.structure1.id ||
                                designProgressFunctionsActiveStructures.structure2.id ||
                                designProgressFunctionsActiveStructures.structure3.id
                                    ? ''
                                    : 'disabled'
                            }`}
                            onClick={handleClick}
                        />
                        {open ? (
                            <div className={classes.dropdown + ' edit-options'}>
                                <div onClick={() => setBulkEditLeaderDialog(true)}>Bulk edit</div>
                                <div onClick={() => setLeadersTargetDialog(true)}>Edit by function</div>
                                <div onClick={() => setEditRangeLeaderDialog(true)}>Edit range</div>
                            </div>
                        ) : null}
                    </div>
                </ClickAwayListener>
            ) : null}

            <Tabs handleChange={handleTabChange} disabled={designProgressEditMode}>
                <Tab title={executiveDashboard.OVERALL}>
                    <Overall
                        designProgressLoading={designProgressLoading}
                        isEditable={designProgressEditMode}
                        structures={structures}
                        filters={overallFiltersToApply}
                        designFilters={overallFiltersTray}
                        refreshCount={refreshCount}
                        setActiveStructures={setDesignProgressOverallActiveStructures}
                        activeStructures={designProgressOverallActiveStructures}
                        ranges={designProgressOverallRanges}
                        activeRanges={designProgressOverallRanges}
                        onRangeChange={onRangeChange}
                        updateRanges={updateRanges}
                        rangeError={rangeError}
                        targets={targets}
                        setTargets={setTargets}
                        getSelectedStructureFilter={getSelectedStructureFilter}
                        updateMultiFilters={updateMultiFilters}
                        setMultiFiltersToApply={setMultiFiltersToApply}
                        setMultiFiltersTray={setMultiFiltersTray}
                    />
                </Tab>
                <Tab title={executiveDashboard.BY_LEADERS}>
                    <Leaders
                        isEditable={designProgressEditMode}
                        structures={structures}
                        filters={leadersFiltersToApply}
                        designFilters={leadersFiltersTray}
                        staticFilterCountsBy={staticFilters['leaders'].find(el => el.name === 'counts')?.children[0]}
                        refreshCount={refreshCount}
                        setActiveStructures={setDesignProgressLeadersActiveStructures}
                        activeStructures={designProgressLeadersActiveStructures}
                        ranges={designProgressLeadersRanges}
                        updateRanges={updateRanges}
                        leadersTargetDialog={leadersTargetDialog}
                        setLeadersTargetDialog={setLeadersTargetDialog}
                        bulkEditLeaderDialog={bulkEditLeaderDialog}
                        setBulkEditLeaderDialog={setBulkEditLeaderDialog}
                        editRangeLeaderDialog={editRangeLeaderDialog}
                        setEditRangeLeaderDialog={setEditRangeLeaderDialog}
                        getSelectedStructureFilter={getSelectedStructureFilter}
                        updateMultiFilters={updateMultiFilters}
                        setMultiFiltersToApply={setMultiFiltersToApply}
                        setMultiFiltersTray={setMultiFiltersTray}
                    />
                </Tab>
                <Tab title={executiveDashboard.BY_FUNCTIONS}>
                    <Functions
                        isEditable={designProgressEditMode}
                        structures={structures}
                        filters={functionsFiltersToApply}
                        designFilters={functionsFiltersTray}
                        staticFilterCountsBy={staticFilters['leaders'].find(el => el.name === 'counts')?.children[0]}
                        refreshCount={refreshCount}
                        setActiveStructures={setDesignProgressFunctionsActiveStructures}
                        activeStructures={designProgressFunctionsActiveStructures}
                        ranges={designProgressFunctionsRanges}
                        updateRanges={updateRanges}
                        leadersTargetDialog={leadersTargetDialog}
                        setLeadersTargetDialog={setLeadersTargetDialog}
                        bulkEditLeaderDialog={bulkEditLeaderDialog}
                        setBulkEditLeaderDialog={setBulkEditLeaderDialog}
                        editRangeLeaderDialog={editRangeLeaderDialog}
                        setEditRangeLeaderDialog={setEditRangeLeaderDialog}
                        getSelectedStructureFilter={getSelectedStructureFilter}
                        updateMultiFilters={updateMultiFilters}
                        setMultiFiltersToApply={setMultiFiltersToApply}
                        setMultiFiltersTray={setMultiFiltersTray}
                    />
                </Tab>
            </Tabs>

            {designProgressEditMode && (
                <div className="action-buttons">
                    <div className="action-buttons__container">
                        <button type="button" className="cancel" onClick={onTargetCancel}>
                            {executiveDashboard.CANCEL_BTN_TEXT}
                        </button>
                        <button type="button" className="save" onClick={onTargetSave}>
                            {executiveDashboard.SAVE_BTN_TEXT}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DesignProgress;
