const executiveDashboard = {
    MANAGERS: 'Managers',
    NONMANAGERS: 'Non Managers',
    MANAGER_CONTRACTORS: 'Manager Contractors',
    INDIVIDUAL_CONTRIBUTORS: 'Individual Contributors',
    INDIVIDUAL_CONTRIBUTORS_CONTRACTORS: 'Individual Contributors Contractors',
    CONTRACTORS: 'Contractors',
    TOTAL_COST: 'Total Cost',
    TOTAL_FTES: 'Total FTEs',
    CONTRACTOR_COST: 'Contractor Cost',
    MANAGER_COST: 'Managers Cost',
    MANAGER_CONTRACTOR_COST: 'Managers Contractor Cost',
    INDIVIDUAL_CONTRIBUTORS_COST: 'Individual Contributors Cost',
    INDIVIDUAL_CONTRIBUTORS_CONTRACTOR_COST: 'Individual Contributors Contractor Cost',
    AVG_SPAN_AND: 'Avg Span and ',
    FUNCTIONS: 'Functions',
    N1_LEADERS: 'N-1 Leaders',
    SPAN_OF_CONTROL: 'Span Of Control',
    LOCATIONS: 'Locations',
    NO_DATA_FOUND_MAIN_MESSAGE: 'Sorry, no data found !',
    NO_DATA_FOUND_SUB_MESSAGE: "What you searched for was unfortunately not found or doesn't exist.",
    NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE: 'No result found for the applied filter!',
    NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE: 'Try adjusting your filter to find what you are looking for.',
    TODAY: 'TODAY',
    ANALYSIS: 'ANALYSIS',
    DESIGN_PROGRESS: 'DESIGN PROGRESS',
    SPAN_DISTRIBUTION_FOR_ENTIRE_COMPANY: 'Span Distribution For Entire Company',
    TITLE_DISTRIBUTION: 'Title Distribution',
    MANAGING_TOO_MANY: 'Managing too many',
    MANAGING_IN_RANGE: 'Managing in range',
    MANAGING_TOO_FEW: 'Managing too few',
    UNKNOWN: 'Unknown',
    STACKING: 'Stacking',
    FUNCTIONAL_DISTRIBUTION: 'Functional Distribution',
    OPPORTUNITY_OVERVIEW: 'Opportunity Overview',
    SPAN_OF_CONTROL_UC: 'SPAN OF CONTROL',
    LAYERS: 'LAYERS',
    FTEs: 'FTEs',
    COST: 'TOTAL COST',
    RATIO_OF_IC_MANAGERS: 'RATIO OF MANAGERS / IC',
    NUMBER_OF_TEAMS_WITH_LESS_THAN_EQUAL_TO_DIRECT_REPORTS: 'MANAGERS WITH 2 OR LESS DIRECT REPORTS',
    OVERALL: 'OVERALL',
    BY_LEADERS: 'BY LEADERS',
    BY_FUNCTIONS: 'BY FUNCTIONS',
    CANCEL_BTN_TEXT: 'Cancel',
    SAVE_BTN_TEXT: 'Save Changes',
    LOCAL_TARGET_KEY: 'executive_targets',
    TARGET_TEXT: 'Target',
    AVERAGE_SPAN_LAYER: 'Average Span per manager by Layers',
    ORG_SHAPE: 'Org Shape',
    DISTRIBUTION: 'Distribution',
    BENCHMARK_TITLE: 'Benchmark Title',
    JOB_OR_PAY_GRADE: 'Job/Pay Grade'
};

export default executiveDashboard;
