export const manager_analysis = {
    archetype: {
        type: 'managerFilter',
        children: [
            { key: 'archetypes', id: 466, name: 'Player Coach' },
            { key: 'archetypes', id: 467, name: 'Coach' },
            { key: 'archetypes', id: 468, name: 'Supervisor' },
            { key: 'archetypes', id: 469, name: 'Facilitator' },
            { key: 'archetypes', id: 470, name: 'Coordinator' },
            { key: 'archetypes', id: 471, name: 'Unknown' },
        ],
    },
    span_of_control: {
        type: 'managerFilter',
        isSelected: true,
        children: [
            { key: 'span_of_control', id: 'too_few', name: 'Managing Too Few', isSelected: true },
            { key: 'span_of_control', id: 'in_range', name: 'Managing In Range', isSelected: true },
            { key: 'span_of_control', id: 'too_many', name: 'Managing Too Many', isSelected: true },
            { key: 'span_of_control', id: 'non_manager', name: 'Non-Manager', isSelected: true },
            { key: 'span_of_control', id: 'unknown', name: 'Unknown', isSelected: true },
        ],
    },
};

export const calculation_assumptions = {
    open_Roles: {
        type: 'calculationAssumptionsFilter',
        children: [
            { key: 'open_roles', id: 'included', name: 'Include Open Roles' },
            { key: 'open_roles', id: 'exclude_count', name: 'Exclude Open Roles from Counts' },
            { key: 'open_roles', id: 'exclude_count_span', name: 'Exclude Open Roles from Span and Counts' },
        ],
    },
    contractors: {
        type: 'calculationAssumptionsFilter',
        children: [
            { key: 'contractors', id: 'included', name: 'Include Contractors' },
            { key: 'contractors', id: 'exclude_count', name: 'Exclude Contractors from Counts' },
            { key: 'contractors', id: 'exclude_count_span', name: 'Exclude Contractors from Span and Counts' },
        ],
    },
    assistants: {
        type: 'calculationAssumptionsFilter',
        children: [
            { key: 'assistant', id: 'included', name: 'Include Assistants' },
            { key: 'assistant', id: 'exclude_count', name: 'Exclude Assistants from Counts' },
            { key: 'assistant', id: 'exclude_count_span', name: 'Exclude Assistants from Span and Counts' },
        ],
    },
    counts: {
        type: 'calculationAssumptionsFilter',
        isSelected: true,
        children: [
            { key: 'count_by', id: 'fte', name: 'FTE', isSelected: true },
            { key: 'count_by', id: 'headcount', name: 'HeadCount', isSelected: false },
        ],
    },
    Archetype_Algorithm: {
        type: 'calculationAssumptionsFilter',
        isSelected: true,
        children: [
            { key: 'algorithms', id: 'conservative', name: 'Conservative', isSelected: true },
            { key: 'algorithms', id: 'cost', name: 'Cost', isSelected: false },
            { key: 'algorithms', id: 'growth', name: 'Growth', isSelected: false },
        ],
    },
};

export const staticFilters = {
    ...manager_analysis,
    ...calculation_assumptions,
};
