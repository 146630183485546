import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Card from '../../../components/Card';
import Shimmer from '../../../components/shimmer';
import executiveDashboard from '../../ExecutiveDashboard/executiveDashboard.enum';
import NoDataFound from '../../../components/NoDataFound';
import './index.scss';

const formatTeams = capabilityData => {
    const categories = [];
    const filledSeries = [];
    const unfilledSeries = [];

    const sortedCapabilities = Object.values(capabilityData).sort((a, b) => {
        return a.filled + a.unfilled > b.filled + b.unfilled ? -1 : 1;
    });
    sortedCapabilities.forEach(({ name, filled, unfilled }) => {
        categories.push(name);
        filledSeries.push(filled);
        unfilledSeries.push(unfilled);
    });
    return {
        categories,
        filledSeries,
        unfilledSeries,
    };
};

const CapabilityGroups = ({ isLoading, capabilityData }) => {
    const { categories, filledSeries, unfilledSeries } = formatTeams(capabilityData);
    return (
        <Card title="Largest Capability Groups" classes={'top-values-card'}>
            {isLoading ? (
                <Shimmer />
            ) : Object.keys(capabilityData).length > 0 ? (
                <div className="capability-groups">
                    <div className="capability-groups__title">By Headcount</div>
                    <div className="capability-groups__chart">
                        {' '}
                        <HighchartsReact
                            options={{
                                chart: {
                                    type: 'column',
                                    height: 400,
                                },
                                title: {
                                    text: '',
                                },
                                subtitle: {
                                    text: '',
                                },
                                xAxis: {
                                    categories: categories.map(el => {
                                        return { name: el };
                                    }),
                                    labels: {
                                        useHTML: true,
                                        formatter: function() {
                                            return `<div title = '${this.value.name}' style="max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}">
                                                    ${this.value.name}
                                                </div>`;
                                        },
                                    },
                                },
                                yAxis: {
                                    min: 0,
                                    stackLabels: {
                                        enabled: true,
                                    },
                                    title: {
                                        text: '',
                                    },
                                },
                                legend: {
                                    enabled: false,
                                },
                                credits: {
                                    enabled: false,
                                },
                                series: [
                                    {
                                        name: 'Unstaffed',
                                        data: unfilledSeries,
                                        color: '#FF6E00',
                                        borderRadiusTopLeft: '10%',
                                        borderRadiusTopRight: '10%',
                                    },
                                    {
                                        name: 'Staffed',
                                        data: filledSeries,
                                        color: '#009688',
                                    },
                                ],
                                plotOptions: {
                                    column: {
                                        stacking: 'normal',
                                        pointWidth: 25,
                                    },
                                },
                                tooltip: {
                                    useHTML: true,
                                    formatter: function() {
                                        return `<div style="display:flex; align-items:center; font-weight:bold">
                                            <span style="width:12px; height: 12px; background-color: ${
                                                this.color
                                            }; border-radius:50%; display: inline-block;">&nbsp;</span> &nbsp; ${
                                            this.y
                                        } (${this.percentage.toFixed(1)}%) ${this.point?.series?.userOptions?.name}
                                        </div>`;
                                    },
                                },
                            }}
                            highcharts={Highcharts}
                        />
                    </div>
                    <div className="legendContainer">
                        <div className="legendGroups ">
                            <div className="legends">
                                <div
                                    className="circle"
                                    style={{
                                        background: '#FF6E00',
                                    }}
                                />
                                <div>Unstaffed</div>
                            </div>
                            <div className="legends">
                                <div
                                    className="circle"
                                    style={{
                                        background: '#009688',
                                    }}
                                />
                                <div>Staffed</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <NoDataFound
                    mainMessage={executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE}
                    secondaryMessage={executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE}
                />
            )}
        </Card>
    );
};

export default CapabilityGroups;
