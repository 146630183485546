import { fromJS } from 'immutable';

export const INITIAL_STATE = fromJS({
    count: 0,
    status: 0,
});

const loaderReducer = (state = INITIAL_STATE, { type, payload = {} }) => {
    const requestStatus = type.split('_').reverse()[0];
    switch (requestStatus) {
        case 'PENDING':
            return state.update('count', count => count + 1);
        case 'SUCCESS':
            return state.update('count', count => count - 1);
        case 'FAILED': {
            const { status = 0 } = payload;
            const statusToSet = state.status === 401 ? state.status : status;
            return state.update('count', count => count - 1).set('status', statusToSet);
        }
        default:
            return state;
    }
};

export default loaderReducer;
