import React from 'react';
import Select from 'react-select';
import Range from '../Overall/Range';

const STRUCTURES = ['structure1', 'structure2', 'structure3'];
const Structures = ({
    referer,
    structures,
    onChange,
    activeStructures,
    isEditable,
    ranges,
    onRangeChange,
    rangeError,
    onResetTarget,
    showLeaders,
}) => {
    const structuresList = [{ name: 'Please Select...', id: '' }, ...structures].map(el => {
        return { label: el.name, value: el.id };
    });

    const functionsList = [{ label: 'All', value: 1 }];
    return (
        <section className="select-structures">
            <div className="structures">
                <div>
                    <div className="label">Functions</div>
                    <Select
                        options={functionsList}
                        placeholder="Select Function"
                        defaultValue={functionsList[0]}
                        onChange={() => {}}
                    />
                </div>

                {STRUCTURES.map((struct, i) => (
                    <div>
                        <div className="label">{`Design Structure ${i + 1}`}</div>
                        <Select
                            options={structuresList}
                            placeholder="Select Structure"
                            value={structuresList.filter(el => el.value === activeStructures[struct].id)[0]}
                            defaultValue={{ label: 'Select Structure', value: '' }}
                            onChange={structure => {
                                onChange(
                                    {
                                        ...activeStructures,
                                        [struct]: { id: structure.value, name: structure.label },
                                    },
                                    struct
                                );
                            }}
                        />
                    </div>
                ))}
            </div>
            <div className="space" />
            <div className="range" style={{ position: 'relative' }}>
                {isEditable && rangeError && <div className="ranges-error">* Please enter the valid ranges</div>}
                <Range isEditedMode={isEditable} referer={referer} ranges={ranges} onRangeChange={onRangeChange} />
            </div>
            {isEditable && (
                <div className="reset-targets">
                    <span onClick={onResetTarget}>Reset All</span>
                </div>
            )}
        </section>
    );
};

export default Structures;
