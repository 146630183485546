import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DesignFiltersTray from './index';
import {
    multiSelectFilters,
    selectMultiFiltersTray,
    selectStaticFilters,
} from '../../containers/Filters/selectors/selector';
import {
    selectDesignProgressLeadersActiveStructures,
    selectDesignProgressOverallActiveStructures,
    selectDesignProgressState,
} from '../../containers/StructureContent/selector';
import {
    clrSelectedManager,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    updateMultiFilters,
    updateStaticFilters,
} from '../../containers/Filters/actions/actions';

const stateToProps = createStructuredSelector({
    designFilters: multiSelectFilters(),
    leadersFiltersTray: selectMultiFiltersTray('leaders'),
    overallFiltersTray: selectMultiFiltersTray('overall'),
    functionsFiltersTray: selectMultiFiltersTray('functions'),
    designProgressState: selectDesignProgressState(),
    designProgressOverallActiveStructures: selectDesignProgressOverallActiveStructures(),
    designProgressLeadersActiveStructures: selectDesignProgressLeadersActiveStructures(),
    staticFilters: selectStaticFilters(),
});

const dispatchToProps = {
    updateMultiFilters,
    updateStaticFilters,
    setMultiFiltersToApply,
    setMultiFiltersTray,
    clrSelectedManager,
};

export default connect(stateToProps, dispatchToProps)(DesignFiltersTray);
