import executiveDashboard from '../../../executiveDashboard.enum';

export function getMappedSeries(series, hasData) {
    return series.map(function(serie, i) {
        return {
            name: serie.name,
            data: serie.data.map(function({ y, name, isZero }, j) {
                return i % 2
                    ? {
                          y,
                          name,
                          color: `url(#${
                              hasData
                                  ? name.toLowerCase() === executiveDashboard.TARGET_TEXT.toLowerCase()
                                      ? 'target-strip-pattern'
                                      : 'strip-pattern'
                                  : 'strip-pattern-def'
                          })`,
                      }
                    : {
                          y,
                          name,
                          color: hasData
                              ? name.toLowerCase() === executiveDashboard.TARGET_TEXT.toLowerCase()
                                  ? '#bbd5e9'
                                  : !isZero
                                  ? '#dedede'
                                  : '#6E879A'
                              : '#dedede',
                      };
            }),
        };
    });
}

export const getValuesFromRatio = (ratio, value = 100) => {
    const ratioParts = ratio.split(':');
    if (ratioParts.length !== 2 || !+ratioParts[0] || !+ratioParts[1]) {
        return [0, 0];
    }

    const total = +ratioParts[0] + +ratioParts[1];
    return [Math.round((+ratioParts[0] / total) * value), Math.round((+ratioParts[1] / total) * value)];
};

export function calculateRatio(num_1, num_2) {
    for (let num = num_2; num > 1; num--) {
        if (num_1 % num === 0 && num_2 % num === 0) {
            num_1 /= num;
            num_2 /= num;
        }
    }

    return Math.round(num_1) + ':' + Math.round(num_2);
}

export const managerRatioConfig = {
    defs: {
        patterns: [
            {
                id: 'strip-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: '#083C52',
                    fill: '#6E879A',
                    strokeWidth: 3,
                },
            },
            {
                id: 'strip-pattern-def',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: '#999',
                    fill: '#dedede',
                    strokeWidth: 3,
                },
            },
            {
                id: 'target-strip-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: '#083C52',
                    fill: '#bbd5e9',
                    strokeWidth: 3,
                },
            },
        ],
    },

    chart: {
        type: 'column',
        height: 295,
        marginLeft: 50,
    },
    credits: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    yAxis: {
        title: {
            enabled: false,
        },
        labels: {
            enabled: false,
        },
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            pointWidth: 30,
            dataLabels: {
                allowOverlap: true,
            },
        },
    },
    title: {
        text: '',
    },
    xAxis: {},
};
