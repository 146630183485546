import { CUSTOM_KEYS_SLIDE_1, KEYS_SLIDE_1, Slides } from "../../constants"
import { toFixed } from "../../../../utils/functions";
import { ColumnPaginationKeys, RowPaginationKeys, getPagination, getTwoDigit } from "./shared";

export const TitleDistributionSlide = {
    "identify_shape_name": "checkme",
    "identify_shape_value": "title_distribution",
    "styles": [
        {
            "bgcolor": "FFFFFF",
            "text_color": "000000",
            "border_color": "FFFFFF,FFFFFF,000000,FFFFFF"
        },
        {
            "bgcolor": "FFFFFF",
            "text_color": "000000",
            "border_color": "FFFFFF,FFFFFF,FFFFFF,FFFFFF"
        },
        {
            "bgcolor": "#f2f2f2",
            "text_color": "000000",
            "border_color": "FFFFFF,FFFFFF,FFFFFF,FFFFFF"
        },
        {
            "bgcolor": "#7e0605",
            "text_color": "FFFFFF",
            "border_color": "FFFFFF,FFFFFF,FFFFFF,FFFFFF"
        },
        {
            "bgcolor": "#d6ebfa",
            "text_color": "000000",
            "border_color": "FFFFFF,FFFFFF,FFFFFF,FFFFFF"
        },
    ],
    "is_copy": true,
    "is_delete": false
};


const getTitleDistributionSlideData = slideName => ({
    "name": "",
    "type": "table",
    "meta_data": {
        "left_position": "5500",
        "top_position": slideName === Slides.managerial_stacking ? "15800" : "13800",
        "height": "2500",
        "width": "17404",
        "font_size": 10,
        "font_family": "Arial",
        "text_color": "000000",
        "bgcolor": "FFFFFF",
        "border_color": "000000",
        "border_width": "80",
        "alignment": "center",
        "v_alignment": "center",
        "margin": [
            1,
            0,
            1,
            0
        ]
    },
});

const headerRawData = {
    "row_index": 0,
    "column_width": "10000",
    "alignment": "center",
    "style": "0",
    "border_width": "150",
    "is_bold": "true"
};

const getDistinctFunctions = layers =>
    layers.reduce((fn, el) => {
        let data = el;
        if (el.data) {
            data = el.data;
        }
        data.forEach(({ key, name }) => {
            if (fn.findIndex(x => x.key == key) < 0) fn.push({ key, name });
        });
        return fn;
    }, []).sort((a, b) => a.name?.localeCompare(b.name));

const getHeader = (list, title) => [{ name: title }, ...list].map(({ name }, i) => ({
    ...headerRawData,
    value: name || 'Not Categorized',
    "column_index": i,
    "column_width": i ? 70000 / list.length : "10000",
}));

const getValue = (functionData, total, percentage) => {
    if (functionData) {
        const sum = functionData.data.managers + functionData.data.nonManagers;
        return percentage ? `${toFixed((sum / total) * 100, 2)} %` : toFixed(sum, 2);
    }
    return "-";
}

function countLines(sentence) {
    const maxLength = 12;
    const words = sentence.split(' '); // Split the sentence into words
    let lines = 1;
    let currentLineLength = 0;

    words.forEach(word => {
        if (word.length > maxLength) {
            // If the word itself is longer than maxLength, it needs to be broken down
            if (currentLineLength > 0) {
                // If there's already some text on the current line, move to the next line
                lines++;
                currentLineLength = 0;
            }
            // Break the word into chunks of maxLength
            while (word.length > 0) {
                const chunk = word.slice(0, maxLength);
                word = word.slice(maxLength);
                lines++;
            }
            currentLineLength = 0;
        } else {
            if (currentLineLength + word.length <= maxLength) {
                // If the word fits in the current line
                currentLineLength += word.length + 1; // +1 for the space
            } else {
                // If the word doesn't fit in the current line, move to the next line
                lines++;
                currentLineLength = word.length + 1; // +1 for the space
            }
        }
    });

    return lines;
}

const getDistinctFunctionValue = (layer, functions, row, lastIndex, slideName, rowHeight, percentage = false) => {
    const { data: flattenedData, name, key: layerKey } = layer
    const functionWiseLayer = [];
    const total_ftes_by_layers = flattenedData.reduce((total, fn) => total += fn.data.managers + fn.data.nonManagers, 0);
    functionWiseLayer.push({
        "value": typeof (name) === 'number' ? name + 1 : name,
        "row_index": row,
        "column_index": 0,
        "column_width": "10000",
        ...(rowHeight && { "row_height": rowHeight }),
        "alignment": "center",
        "style": row % 2 ? "1" : "2",
        "border_width": "150",
        ...(row === lastIndex && { "border_color": "FFFFFF,FFFFFF,000000,FFFFFF" })
    });

    functions.forEach(({ key, name }, i) => {
        const filteredData = flattenedData?.filter?.(data => data.key === key)?.[0];

        const value = name == "Total per benchmark title" ?
            toFixed(flattenedData.reduce((sum, el) => {
                sum += el.data.managers + el.data.nonManagers
                return sum;
            }, 0), 2)
            : getValue(filteredData, total_ftes_by_layers, percentage);

        functionWiseLayer.push({
            "value": value,
            "row_index": row,
            "column_index": i + 1,
            "column_width": 70000 / functions.length,
            ...(rowHeight && { "row_height": rowHeight }),
            "alignment": "center",
            "style": slideName == Slides.managerial_stacking ? ((key === layerKey && value !== "-") ? "3" : row % 2 ? "1" : "2") : (value !== "-") ? "4" : row % 2 ? "1" : "2",
            "border_width": "150",
            ...(row === lastIndex && { "border_color": "FFFFFF,FFFFFF,000000,FFFFFF" })
        })
    });

    return functionWiseLayer;
};
// Use this as a reference 
const getFunctionWiseValue = (data, functions, slideName, percentage = false) => {
    const functionWiseLayer = [];

    functionWiseLayer.push({
        "value": slideName === Slides.managerial_stacking ? "Total direct reports to" : "Total FTEs",
        "row_index": data.length + 1,
        "column_index": 0,
        "column_width": "10000",
        "alignment": "center",
        "style": "0",
        "border_width": "150",
        "is_bold": "true",
        "border_color": "FFFFFF,FFFFFF,FFFFFF,FFFFFF"
    });

    const functionTotalList = [];
    let allTotalFtes = 0;

    functions.forEach(f => {
        let total_ftes_by_function = 0;
        data.forEach(layer => {
            const functionData = layer.data.filter(l => l.key === f.key)?.[0];
            const fte = functionData ? (functionData.data.managers + functionData.data.nonManagers) : 0;
            total_ftes_by_function += fte;
        });
        functionTotalList.push({ function: f, totalFte: total_ftes_by_function });
        allTotalFtes += total_ftes_by_function;
    }, 0);

    functionTotalList.forEach(({ function: { key }, totalFte }, i) => {
        const value = key === "Total per benchmark title"
            ? toFixed(allTotalFtes, 2)
            : getValue(
                {
                    data: {
                        managers: totalFte,
                        nonManagers: 0
                    }
                },
                allTotalFtes,
                percentage
            );

        functionWiseLayer.push({
            "value": value,
            "row_index": data.length + 1,
            "column_index": i + 1,
            "column_width": 70000 / functions.length,
            "alignment": "center",
            "style": "0",
            "border_width": "150",
            "border_color": "FFFFFF,FFFFFF,FFFFFF,FFFFFF"
        })

    });

    return functionWiseLayer;
};

export const generateDistributionSlide = async ({ data, percentage }, addWithMainState, slideName) => {
    const slideDataList = [];
    let rowPerPage = 10;
    let rowHeight = 0;
    const slideData = data.filter(d => d.key === CUSTOM_KEYS_SLIDE_1.table_data)?.[0]?.value;
    if (slideData) {
        Object.keys(slideData).forEach(key => {
            const slide = slideData[key].data;
            const name = slideData[key].name;
            const updatedSlide = Object.keys(slide).map(s => ({
                data: slide[s].data,
                name: !slide[s].name ? "Not Categorized" : slide[s].name,
                key: s
            }));

            slideDataList.push({
                data: updatedSlide,
                name: (name || name == 0) ? name : 'Not Categorized',
                key
            });
        });
        let functionList;
        let workforcePercentage = 0;
        if (slideName === Slides.managerial_stacking) {
            slideDataList.sort((a, b) => a?.name?.localeCompare(b?.name));
            const layerNames = slideDataList.map(sd => sd.name);
            const maxLines = layerNames.reduce((max, l) => {
                max = Math.max(max, countLines(l));
                return max;
            }, 0);
            rowHeight = maxLines * 2000;
            rowPerPage = Math.floor(32000 / rowHeight);
            functionList = [
                ...getDistinctFunctions(slideDataList),
                {
                    key: "Total per benchmark title",
                    name: "Total per benchmark title"
                }
            ];
            let samePayGradeWorkforce = 0;
            const overallWorkeforce = slideDataList.reduce((totalWorkeforce, sdl) =>
                totalWorkeforce += sdl.data.reduce((nestedTotal, sdlc) => {
                    if (sdlc.name === sdl.name && sdlc.name !== 'Not Categorized' && sdl.name !== 'Not Categorized')
                        samePayGradeWorkforce += (sdlc.data.managers + sdlc.data.nonManagers) || 0;
                    nestedTotal += (sdlc.data.managers + sdlc.data.nonManagers) || 0;
                    return nestedTotal;
                }, 0), 0);
            workforcePercentage = toFixed((samePayGradeWorkforce / overallWorkeforce) * 100, 2);
        }
        else {
            functionList = getDistinctFunctions(slideDataList);
        }
        const pages = Math.ceil(slideDataList.length / rowPerPage);

        for (let p = 0; p < pages; p++) {
            const lastIndex = Math.min((p + 1) * rowPerPage, slideDataList.length)
            const slicedData = slideDataList.slice(p * rowPerPage, lastIndex);
            const columnGroups = Math.ceil(functionList.length / 7);

            for (let c = 0; c < columnGroups; c++) {
                const table_data = [];
                const cLastIndex = Math.min((c + 1) * 7, functionList.length);
                const slicedFunctions = functionList.slice(c * 7, cLastIndex);
                const headerList = getHeader(slicedFunctions, slideName == Slides.managerial_stacking ? `Benchmark Titles (${slideDataList.length})` : "Layers");
                table_data.push(...headerList);
                slicedData.forEach((layer, i) => {
                    table_data.push(...getDistinctFunctionValue(layer, slicedFunctions, i + 1, slicedData.length, slideName, rowHeight, percentage))
                });
                table_data.push(...getFunctionWiseValue(slideDataList, slicedFunctions, slideName, percentage));
                let slide = {
                    ...TitleDistributionSlide,
                    identify_shape_value: slideName,
                    data: [
                        {
                            "name": "checkme",
                            "is_delete": true
                        },
                        {
                            ...getTitleDistributionSlideData(slideName),
                            "table_data": [...table_data]
                        },
                        ...data.filter(({ key }) => key !== CUSTOM_KEYS_SLIDE_1.table_data)
                            .map(d => {
                                const { name, existing_value } = KEYS_SLIDE_1[d.key];
                                return { name, existing_value, value: d.value }
                            }),
                        ...getPagination(ColumnPaginationKeys, {
                            hidden: functionList.length <= 7,
                            total: functionList.length,
                            min: getTwoDigit((c * 7) + 1),
                            max: getTwoDigit(cLastIndex),
                        }),
                        ...getPagination(RowPaginationKeys, {
                            hidden: slideDataList.length <= rowPerPage,
                            total: slideDataList.length,
                            min: getTwoDigit((p * rowPerPage) + 1),
                            max: getTwoDigit(lastIndex),
                            hiddenColumn: functionList.length <= 7 ? { left_position: 8 } : undefined
                        })

                    ]
                };
                if (slideName === Slides.managerial_stacking) {
                    slide = {
                        ...slide,
                        data: [
                            ...slide.data,
                            {
                                ...KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.mng_stacking_percentage],
                                value: `${workforcePercentage}%`
                            }
                        ]
                    }
                }
                addWithMainState(slide);
            }
        }
    }
};