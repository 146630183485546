export const GET_PHASE_STRUCTURES = 'GET_PHASE_STRUCTURES';
export const GET_STRUCTURE_COMPARE = 'GET_STRUCTURE_COMPARE';
export const GET_STRUCTURE_SUMMARY = 'GET_STRUCTURE_SUMMARY';
export const SET_LOADER = 'SET_LOADER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SORT_RESULTS = 'SORT_RESULTS';
export const SET_STATIC_FILTERS = 'SET_STATIC_FILTERS';
export const GET_FILTERS = 'GET_FILTERS';
export const GET_FILTERS_CONTENT = 'GET_FILTERS_CONTENT';
export const RESET_LOGOUT_LOADER = 'RESET_LOGOUT_LOADER';
export const GET_REPORT_SUMMARY = 'GET_REPORT_SUMMARY';
export const RESET_STRUCTURE_COMPARE = 'RESET_STRUCTURE_COMPARE';
export const SET_STRUCTURE_COMPARE = 'SET_STRUCTURE_COMPARE';
export const SET_STRUCTURE = 'SET_STRUCTURE';
export const SET_DESIGN_PROGRESS_EDIT_MODE = 'SET_DESIGN_PROGRESS_EDIT_MODE';
export const SET_DESIGN_PROGRESS_OVERALL_ACTIVE_STRUCTURES = 'SET_DESIGN_PROGRESS_OVERALL_ACTIVE_STRUCTURES';
export const SET_DESIGN_PROGRESS_LEADERS_ACTIVE_STRUCTURES = 'SET_DESIGN_PROGRESS_LEADERS_ACTIVE_STRUCTURES';
export const SET_DESIGN_PROGRESS_FUNCTIONS_ACTIVE_STRUCTURES = 'SET_DESIGN_PROGRESS_FUNCTIONS_ACTIVE_STRUCTURES';
export const UPDATE_RANGES = 'UPDATE_RANGES';
export const GET_TARGETS = 'GET_TARGETS';
export const SET_TARGETS = 'SET_TARGETS';
export const UPDATE_TARGETS = 'UPDATE_TARGETS';
export const SET_DESIGN_PROGRESS_STATE = 'SET_DESIGN_PROGRESS_STATE';
export const SET_ORGSHAPE_FUNCTIONS_DATA = 'SET_ORGSHAPE_FUNCTIONS_DATA';
export const SET_ORGSHAPE_LEADERS_DATA = 'SET_ORGSHAPE_LEADERS_DATA';
