
import { numFormatter } from '../../../../utils/functions';
import { KEYS_SLIDE_1, CUSTOM_KEYS_SLIDE_1, Slides } from '../../constants';
import { RowPaginationKeys, SLIDE_WISE_DATA, getPagination, getTwoDigit } from './shared';

// eslint-disable-next-line
export const generateLayersPPT = async (valuesArray, clientName, addWithMainState, slideName) => {
    const slideState = {
        slideIndex: 1, // custom property
        identify_shape_name: 'checkme',
        identify_shape_value: slideName,
        is_copy: true,
        data: SLIDE_WISE_DATA[slideName],
    }
    let MIN_CURRENT_SPAN_OR_IDEAL_SPAN = Infinity;
    let MAX_CURRENT_SPAN_OR_IDEAL_SPAN = -Infinity;
    let MIN_NON_MANAGERS_OR_MANAGERS = Infinity;
    let MAX_NON_MANAGERS_OR_MANAGERS = -Infinity;
    const _BIGGEST_GAP_MAP = {
        BIGGEST_GAP: -Infinity,
        LAYER: -Infinity,
    };

    const { value: allLayers } = valuesArray.filter(
        obj => KEYS_SLIDE_1[obj.key].name === CUSTOM_KEYS_SLIDE_1.table_data
    )[0];

    const pages = Math.ceil(allLayers.length / 8);

    for (let i = 0; i < allLayers.length; i++) {
        const item = allLayers[i];
        MIN_CURRENT_SPAN_OR_IDEAL_SPAN = Math.min(
            Math.min(item.currentSpan, item.idealSpan),
            MIN_CURRENT_SPAN_OR_IDEAL_SPAN
        );
        MAX_CURRENT_SPAN_OR_IDEAL_SPAN = Math.max(
            Math.max(item.currentSpan, item.idealSpan),
            MAX_CURRENT_SPAN_OR_IDEAL_SPAN
        );
        MIN_NON_MANAGERS_OR_MANAGERS = Math.min(
            Math.min(item.nonManagers, item.managers),
            MIN_NON_MANAGERS_OR_MANAGERS
        );
        MAX_NON_MANAGERS_OR_MANAGERS = Math.max(
            Math.max(item.nonManagers, item.managers),
            MAX_NON_MANAGERS_OR_MANAGERS
        );
    }
    for (let j = 0; j < pages; j++) {
        let pageSlideState = { ...slideState };
        const tableData = [];
        const lastIndex = Math.min((j + 1) * 8, allLayers.length);
        const slicedLayers = allLayers.slice(j * 8, lastIndex);
        
        // iterate over values to be set (coming from components)
        valuesArray.forEach(obj => {
            // obj example: { key: "total_layers", value: 333 }
            const { name, existing_value } = KEYS_SLIDE_1[obj.key];

            if (name === CUSTOM_KEYS_SLIDE_1.table_data) {

                allLayers.forEach(layer => {
                    const diff = layer.idealSpan - layer.currentSpan;
                    if (diff > _BIGGEST_GAP_MAP.BIGGEST_GAP) {
                        _BIGGEST_GAP_MAP.BIGGEST_GAP = diff;
                        _BIGGEST_GAP_MAP.LAYER = layer.layer + 1;
                    }
                });

                for (let i = 0; i < slicedLayers.length; i++) {
                    const cells = {};
                    // const rightChart = {}
                    const layer = slicedLayers[i];

                    cells[`TextB${i + 1}`] = layer.layer + 1;
                    cells[`Text${i + 1}1`] = (layer.fteCount || 0).toFixed(1);
                    cells[`Text${i + 1}2`] = (layer.nonManagers || 0).toFixed(1);
                    cells[`Text${i + 1}5`] = (layer.managers || 0).toFixed(1);
                    if (slideName === Slides.overview1_manager || slideName === Slides.overview1_manager_comment) {
                        cells[`Text${i + 1}7`] = numFormatter(layer.managerCost, 2, true);
                        cells[`Text${i + 1}8`] = (layer.managerOpportunity || 0).toFixed(2);
                        cells[`Text${i + 1}9`] = numFormatter(layer.costOpportunity, 2, true);
                    }

                    // for right chart label values
                    for (const key in cells) {
                        if (cells.hasOwnProperty(key)) {
                            tableData.push({
                                name: key,
                                value: cells[key],
                            });
                        }
                    }

                    // currentSpan vs idealSpan right chart
                    tableData.push({
                        name: `Chart${i + 1}6`,
                        meta_data: {
                            min_bound: MIN_CURRENT_SPAN_OR_IDEAL_SPAN,
                            max_bound: MAX_CURRENT_SPAN_OR_IDEAL_SPAN + 20,
                        },
                        chart_data: [
                            {
                                series: 1,
                                index: 0,
                                row_index: 1,
                                column_index: 2,
                                value: (layer.currentSpan || 0).toFixed(1),
                                label: (layer.currentSpan || 0).toFixed(1),
                            },
                            {
                                series: 0,
                                index: 0,
                                row_index: 1,
                                column_index: 1,
                                value: (layer.idealSpan || 0).toFixed(1),
                                label: (layer.idealSpan || 0).toFixed(1),
                            },
                        ],
                    });

                    // managers vs non managers left chart
                    // for non managers
                    tableData.push({
                        name: `Chart${i + 1}3`,
                        meta_data: {
                            min_bound: MIN_NON_MANAGERS_OR_MANAGERS,
                            max_bound: MAX_NON_MANAGERS_OR_MANAGERS,
                        },
                        chart_data: [
                            {
                                series: 0,
                                index: 0,
                                row_index: 1,
                                column_index: 1,
                                value: (layer.nonManagers || 0).toFixed(1),
                            },
                        ],
                    });
                    // for managers
                    tableData.push({
                        name: `Chart${i + 1}4`,
                        meta_data: {
                            min_bound: MIN_NON_MANAGERS_OR_MANAGERS,
                            max_bound: MAX_NON_MANAGERS_OR_MANAGERS,
                        },
                        chart_data: [
                            {
                                series: 0,
                                index: 0,
                                row_index: 1,
                                column_index: 1,
                                value: (layer.managers || 0).toFixed(1),
                            },
                        ],
                    });

                    // add position for this row
                    tableData.push({
                        name: `G${i + 1}`,
                        hidden: false,
                    });

                    // clientname for subtitle
                    tableData.push({
                        name: KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.company_name_subtitle].name,
                        value: clientName,
                        existing_value: KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.company_name_subtitle].existing_value,
                    });

                    if (slideName === Slides.overview1_comment || slideName === Slides.overview1_manager_comment) {
                        // adding biggest gap to the data (json)
                        tableData.push({
                            name: KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.biggest_gap].name,
                            existing_value: KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.biggest_gap].existing_value,
                            value: _BIGGEST_GAP_MAP.LAYER,
                        });
                        // clientname for comment
                        tableData.push({
                            name: KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.company_name_comment].name,
                            value: clientName,
                            existing_value: KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.company_name_comment].existing_value,
                        });
                    }

                    if (i === slicedLayers.length - 1) {
                        tableData.push({
                            name: `line${i + 1}`,
                            hidden: true,
                        });
                    }
                } // end of for loop

                pageSlideState = { ...pageSlideState, data: [...pageSlideState.data, ...tableData] };


            } else {
                pageSlideState = {
                    ...pageSlideState,

                    data: pageSlideState.data.map(d => {
                        if (d.name === KEYS_SLIDE_1[CUSTOM_KEYS_SLIDE_1.slide_title].name) {
                            return { ...d, value: 'Layers overview' };
                        }
                        if (d.name === name && existing_value) {
                            if (d.existing_value === existing_value) {
                                return { ...d, value: obj.value };
                            }
                            return d;
                        }
                        if (d.name === name) {
                            return { ...d, value: obj.value };
                        }
                        return d;
                    }),
                };

                pageSlideState = {
                    ...pageSlideState,
                    data: pageSlideState.data.concat(
                        getPagination(RowPaginationKeys, {
                            hidden: allLayers.length <= 8,
                            total: allLayers.length,
                            min: getTwoDigit((j * 8) + 1),
                            max: getTwoDigit(lastIndex)
                        })
                    )
                }
            } // end of else
        }); // end of forEach
        addWithMainState(pageSlideState);
    }
};
