import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { REPORTS_LINK, SITE_TXT } from '../../utils/constants';
import './index.scss';
import executiveDashboard from './executiveDashboard.enum';
import featureToggles from '../../featureToggles';
import { getAnalyticsApi, getOrgFilters } from '../StructureContent/apis';
import formattedUtilities, { getAnalyticsFilterString } from '../../common/utilities';
import { numFormatter, toFixed } from '../../utils/functions';
import { CUSTOM_KEYS_SLIDE_1 } from '../PPTExport/constants';
import AuthStore from '../../common/AuthStore';
// components
import NavigationTabs from '../../components/NavigationTabs';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import TopContent from '../StructureContent/TopContent';
import TodayContainer from './Today/index.container';
import AnalysisContainer from './Analysis/index.container';
import DesignProgressContainer from './DesignProgress/index.container';
import FiltersContainer from '../Filters/index.container';
import DesignFiltersContainer from '../DesignFilters/index.container';
import NoDataFound from '../../components/NoDataFound';
import DesignFilterTrayContainer from '../../components/DesignFilterTray/index.container';
import { PPT_SLIDES_CATEGORIES, PPT_SLIDES_CATEGORIES_KEYS } from "../PPTExport/actions/exec-dashboard/shared";
import { getOptions, getOrgShapeFtes, getOrgShapeTotals } from './shared';

function ExecutiveDashboard(props) {
    const { REPORT_SUB } = SITE_TXT;
    const {
        strCompareFetched,
        strCompareData,
        isLoading,
        phaseLoading,
        loaderCount,
        totalCount,
        totalCompleted,
        history,
        primaryFilters,
        clrManagerFilters,
        privileges,
        filtersToApply,
        filterList,
        updateFilters,
        setFiltersToApply,
        setFiltersTray,
        isFiltersReadyToApply,
        structure,
        resetFilters,
        phaseStructureInfo,
        designProgressEditMode,
        setDesignProgressOverallActiveStructures,
        setDesignProgressLeadersActiveStructures,
        setDesignProgressFunctionsActiveStructures,
        filtersTray,
        setOrgshapeFunctionsData,
        setOrgshapeLeadersData,
        getOrgshapeFunctionsData,
        getOrgshapeLeadersData,
        refereshCount,
    } = props;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [structureId, setStructureId] = useState('');
    const [filterApplied, setFilterApplied] = useState(false);
    const [filters, setFilters] = useState({});
    const [functions, setFunctions] = useState([]);
    const [leaders, setLeaders] = useState([]);
    const [functionLayers, setFunctionLayers] = useState(null);
    const [leaderLayers, setLeaderLayers] = useState(null);
    const [totalFunctions, setTotalFunctions] = useState(null);
    const [totalLeaders, setTotalLeaders] = useState(null);
    const [ftes, setFtes] = useState(null);
    const [leaderFtes, setLeaderFtes] = useState(null);
    const [isNotCategorized, setIsNotCategorized] = useState(false);
    const [pptSlideList, setPptSlideList] = useState([]);
    const [slideData, setSlideData] = useState();
    const [dsPercentage, setDsPercentage] = useState(false);
    const [disableExport, setDisableExport] = useState(true);

    useEffect(() => {
        setTotalFunctions(null);
        setFunctionLayers(null);
        setFtes(null);
        setTotalLeaders(null);
        setLeaderLayers(null);
        setLeaderFtes(null);
    }, [JSON.stringify(filtersToApply)]);

    const stringVals = ['algorithms', 'direct_reports', 'open_roles', 'contractors', 'assistant', 'count_by'];
    useEffect(() => {
        setDesignProgressOverallActiveStructures({
            structure1: { id: '', name: '' },
            structure2: { id: '', name: '' },
            structure3: { id: '', name: '' },
        });
        setDesignProgressLeadersActiveStructures({
            structure1: { id: '', name: '' },
            structure2: { id: '', name: '' },
            structure3: { id: '', name: '' },
        });
        setDesignProgressFunctionsActiveStructures({
            structure1: { id: '', name: '' },
            structure2: { id: '', name: '' },
            structure3: { id: '', name: '' },
        });
    }, []);

    const updateReport = index => {
        resetFilters();
        const links = Object.values(REPORTS_LINK);
        const route = links.filter(el => el.value === index);
        history.push(route[0].routeValue);
    };

    const isDesignProgress = () => {
        return history.location.pathname === '/executive-dashboard/design-progress';
    };

    const selectFilters = () => {
        const filtersToApply = {};
        const selectInnerChild = (mainParent = []) => {
            mainParent
                .filter(({ isSelected = false }) => isSelected)
                .forEach(({ children = [], key = '', id = '', lookup_category }) => {
                    if (children.length) {
                        selectInnerChild(children);
                    } else if (stringVals.includes(key)) {
                        filtersToApply[key] = id;
                    } else if (filtersToApply[key]) {
                        if (key === 'lookup_id') {
                            if (filtersToApply[key][lookup_category]) {
                                filtersToApply[key][lookup_category].push(id);
                            } else {
                                filtersToApply[key][lookup_category] = [id];
                            }
                        } else {
                            filtersToApply[key].push(id);
                        }
                    } else {
                        if (key === 'lookup_id') {
                            filtersToApply[key] = {
                                [lookup_category]: [id],
                            };
                        } else {
                            filtersToApply[key] = [id];
                        }
                    }
                });
        };

        selectInnerChild(primaryFilters);

        if (filtersToApply.lookup_id) {
            filtersToApply.lookup_id = Object.keys(filtersToApply.lookup_id).map(
                category => filtersToApply.lookup_id[category]
            );
        }

        setFiltersData(filtersToApply);
    };

    const compareData = str1 => {
        if (str1) {
            setFilters({ ...filters });
        }
    };

    const updateChildren = (mainParent = []) => {
        mainParent.forEach(child => {
            const innerChild = child;
            innerChild.isSelected = false;
            if (innerChild.children) {
                updateChildren(innerChild.children);
            }
        });
    };

    const setFiltersData = filtersUsed => {
        setFilters(filtersUsed);
    };

    const resetManagerFilter = () => {
        // clear manager data
        clrManagerFilters();
        const el = document.getElementById('search');
        if (el) {
            el.value = '';
        }
    };

    const getFilteredResults = (filterToApply = {}) => {
        setFiltersData({ ...filterToApply });
    };

    const handleClick = () => setDrawerOpen(!drawerOpen);

    const phaseUpdated = () => {
        setFilterApplied(false);
    };

    const setStructureData = id => {
        setStructureId(id);
        setFilterApplied(primaryFilters.filter(({ isSelected }) => isSelected));
        selectFilters();
    };

    const getReportOptions = () => {
        const reportOptions = [];
        Object.keys(REPORTS_LINK).forEach(el => {
            if (privileges[el]) {
                reportOptions.push(REPORTS_LINK[el]);
            }
        });
        return reportOptions;
    };

    const apiIsLoading = totalCount !== totalCompleted;
    const isDesignProgressPage = isDesignProgress();

    const isFiltersReadyToApplyOn = isDesignProgressPage ? true : isFiltersReadyToApply;

    // for the functions for orgshape - functions api, layers api
    useEffect(() => {
        if (structureId) {
            // for the n1Leaders tab - correct
            const filterStringForLeaders = getAnalyticsFilterString({
                ...filtersToApply,
                structureId,
                group_by: ['layer', 'manager[1]'],
            });
            getAnalyticsApi({ method: 'pivotTableJson', filterString: filterStringForLeaders })
                .then(({ data }) => {
                    const layersValuesData = Object.values(data);
                    setLeaderLayers(layersValuesData);
                })
                .catch(() => { });

            // for the functions tab
            const filterString = getAnalyticsFilterString({
                ...filtersToApply,
                structureId,
                group_by: ['layer', 'function[0]'],
            });

            getAnalyticsApi({ method: 'pivotTableJson', filterString })
                .then(({ data }) => {
                    const layersValuesData = Object.values(data);
                    setFunctionLayers(layersValuesData);
                })
                .catch(() => { });

            // for the functions tab:
            getOrgFilters({ structureId, method: 'filterFunctions', filterString: `page[size]=1000` })
                .then(({ data }) => {
                    const sortedFunctionsData = data.data
                        .slice()
                        .sort((a, b) =>
                            a.attributes.name > b.attributes.name ? 1 : b.attributes.name > a.attributes.name ? -1 : 0
                        );
                    setFunctions(sortedFunctionsData);
                })
                .catch(() => {
                    setFunctions([]);
                });
        }
    }, [JSON.stringify(filtersToApply), refereshCount, structureId]);

    // for the n1Leaders tab as well.
    useEffect(() => {
        if (structureId) {
            const managerFilterString = getAnalyticsFilterString({
                span_of_control: ['too_few', 'too_many', 'in_range', 'non_manager', 'unknown'],
                structureId,
                phaseId: AuthStore.phaseId,
                group_by: ['manager[1]', 'manager[1]'],
                layer_id: 1,
                perPage: 1000,
            });
            getAnalyticsApi({ method: 'managersJson', filterString: managerFilterString })
                .then(({ data }) => {
                    const sortedLeadersData = data.managers
                        .slice()
                        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
                    setLeaders(_prev => {
                        return sortedLeadersData;
                    });
                })
                .catch(() => { });
        }
    }, [AuthStore.phaseId, structureId, refereshCount]);

    // for the functions tab:
    useEffect(() => {
        const { total } = getOrgShapeTotals(functionLayers, functions);
        setTotalFunctions(total);
    }, [functions, functionLayers]);

    // for n1Leaders tab:
    useEffect(() => {
        const { total, isNotCategorized } = getOrgShapeTotals(leaderLayers, leaders, true);
        setIsNotCategorized(isNotCategorized);
        setTotalLeaders(total);
    }, [leaders, leaderLayers]);

    // for the n1Leaders tab:
    useEffect(() => {
        const ftes = getOrgShapeFtes(totalLeaders, leaderLayers, true);
        setLeaderFtes(ftes);
    }, [leaderLayers, totalLeaders]);

    // // for the functions tab:
    useEffect(() => {
        const ftes = getOrgShapeFtes(totalFunctions, functionLayers);
        setFtes(ftes);
    }, [functionLayers, totalFunctions]);

    // // end of the orgshape functions

    useEffect(() => {
        // call the actions
        if (totalFunctions && functionLayers && ftes) {
            setOrgshapeFunctionsData({
                total: totalFunctions,
                layers: functionLayers,
                ftes,
                loading: false,
            });
        } else {
            setOrgshapeFunctionsData({
                total: [],
                layers: [],
                ftes: [],
                loading: true,
            });
        }
    }, [ftes, functionLayers, totalFunctions]);

    useEffect(() => {
        // call the actions
        if (totalLeaders && leaderLayers && leaderFtes) {
            setOrgshapeLeadersData({
                total: totalLeaders,
                layers: leaderLayers,
                ftes: leaderFtes,
                loading: false,
                isNotCategorized,
            });
        } else {
            setOrgshapeLeadersData({
                total: [],
                layers: [],
                ftes: [],
                loading: true,
                isNotCategorized
            });
        }
    }, [leaderFtes, leaderLayers, totalLeaders]);

    const updateOptions = (slideMap = slideData) => {
        setPptSlideList(getOptions(slideMap));
    }

    const prepareOrgLeadersData = async leaderLayers => {
        const emptyLeaders = {
            total: [],
            layers: [],
            ftes: [],
            loading: true,
            isNotCategorized: false
        };

        if (structureId) {
            const managerFilterString = getAnalyticsFilterString({
                span_of_control: ['too_few', 'too_many', 'in_range', 'non_manager', 'unknown'],
                structureId,
                phaseId: AuthStore.phaseId,
                group_by: ['manager[1]', 'manager[1]'],
                layer_id: 1,
                perPage: 1000,
            });
            const resp = await getAnalyticsApi({ method: 'managersJson', filterString: managerFilterString });
            if (resp.status === 200) {
                const { data } = resp;
                const leaders = data.managers
                    .slice()
                    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

                const { total, isNotCategorized } = getOrgShapeTotals(leaderLayers, leaders, true);
                const ftes = getOrgShapeFtes(total, leaderLayers, true);

                return {
                    total,
                    layers: leaderLayers,
                    ftes,
                    loading: false,
                    isNotCategorized,
                }
            }
            return emptyLeaders;
        }
        return emptyLeaders;
    }

    const prepareOrgFunctionsData = async functionLayers => {
        const resp = await getOrgFilters({ structureId, method: 'filterFunctions', filterString: `page[size]=1000` });
        if (resp.status === 200) {
            const { data } = resp;
            const functions = data.data
                .slice()
                .sort((a, b) =>
                    a.attributes.name > b.attributes.name ? 1 : b.attributes.name > a.attributes.name ? -1 : 0
                );
            const { total } = getOrgShapeTotals(functionLayers, functions);
            const ftes = getOrgShapeFtes(total, functionLayers);
            return {
                total,
                layers: functionLayers,
                ftes,
                loading: false,
            }
        }

        return {
            total: [],
            layers: [],
            ftes: [],
            loading: true,
        }

    }

    async function preparePPTData(structureId, filtersToApply) {
        let slidesMap = {
            layers: null,
            pay_distribution: null,
            title_distribution: null,
            span_of_control_overview: null,
            managerial_stacking: null,
            org_shape_leader: null,
            org_shape_function: null,
        }

        const filterString = getAnalyticsFilterString({ ...filtersToApply, structureId });
        const payFilterString = getAnalyticsFilterString({
            ...filtersToApply,
            structureId,
            group_by: ['layer', 'lookup(Job or Pay Grade)[0]'],
        });
        const titleFilterString = getAnalyticsFilterString({
            ...filtersToApply,
            structureId,
            group_by: ['layer', 'lookup(Benchmark Title)[0]'],
        });
        const managerialFilterString = getAnalyticsFilterString({
            ...filtersToApply,
            structureId,
            group_by: ['lookup(Benchmark Title)[0]', 'manager.lookup(Benchmark Title)[0]'],
        });

        const leadersFilterString = getAnalyticsFilterString({
            ...filtersToApply,
            structureId,
            group_by: ['layer', 'manager[1]'],
        });

        const functionsFilterString = getAnalyticsFilterString({
            ...filtersToApply,
            structureId,
            group_by: ['layer', 'function[0]'],
        });

        const layersPromise = getAnalyticsApi({ method: 'layersJson', filterString });
        const payPromise = getAnalyticsApi({ method: 'pivotTableJson', filterString: payFilterString });
        const titlePromise = getAnalyticsApi({ method: 'pivotTableJson', filterString: titleFilterString });
        const spanPromise = getAnalyticsApi({ method: 'spansJson', filterString });
        const managerPromise = getAnalyticsApi({ method: 'pivotTableJson', filterString: managerialFilterString });
        const leadersPromise = getAnalyticsApi({ method: 'pivotTableJson', filterString: leadersFilterString });
        const functionsPromise = getAnalyticsApi({ method: 'pivotTableJson', filterString: functionsFilterString });

        const promises = [layersPromise, payPromise, titlePromise, spanPromise, managerPromise, leadersPromise, functionsPromise];
        const keys = Object.keys(slidesMap);
        const resArr = await Promise.allSettled(promises);
        const promisesResp = [];
        await new Promise(async resolve => {
            for (let i = 0; i < resArr.length; i++) {
                if (resArr[i].value?.status === 200 && keys[i] === PPT_SLIDES_CATEGORIES_KEYS.org_shape_leader) {
                    promisesResp.push(prepareOrgLeadersData(Object.values(resArr[i].value?.data)));
                }
                else if (resArr[i].value?.status === 200 && keys[i] === PPT_SLIDES_CATEGORIES_KEYS.org_shape_function) {
                    promisesResp.push(prepareOrgFunctionsData(Object.values(resArr[i].value?.data)));
                }
                else if (resArr[i].value?.status === 200) {
                    promisesResp.push(new Promise((resolve, reject) => {
                        setTimeout(() => {
                            resolve(resArr[i].value?.data);
                        }, 100);
                    }))
                }

                if (i == resArr.length - 1) {
                    resolve();
                }
            }
        });
        const respArr = await Promise.all(promisesResp);
        respArr.forEach((res, i) => {
            slidesMap = {
                ...slidesMap,
                [keys[i]]: res,
            }
        });
        setSlideData({ ...slidesMap });
        updateOptions(slidesMap);
        setDisableExport(false);
    }

    useEffect(() => {
        if (structureId && Object.keys(filtersToApply)?.length) {
            setDisableExport(true);
            preparePPTData(structureId, filtersToApply);
        }
    }, [JSON.stringify(filtersToApply), refereshCount, structureId]);

    const getChild = obj => {
        if (Array.isArray(obj)) {
            return obj.map(e => getChild(e));
        }
        return obj.name;
    }

    // ppt generation logic
    const generatePPTExport = async slideData => {
        const selectedSlides = pptSlideList.filter(s => s.checked || s.indeterminate).map(slide => {
            if (slide.children.length) return slide.children.filter(sb => sb.checked).map(sb => sb.label);
            return slide.label;
        }).flat();

        const FiltersTypeMap = {
            function_id: 'Characteristics',
            location_id: 'Characteristics',
            manager_id: 'Characteristics',
            lookup_id: 'Characteristics',
            span_of_control: 'Manager Analysis',
            span_ranges: 'Manager Analysis',
            open_roles: 'Calculation Assumptions',
            count_by: 'Calculation Assumptions',
            contractors: 'Calculation Assumptions',
            assistant: 'Calculation Assumptions',
            // added manually to get rid of undefined error in the final filter string
            // added by @Karan Kumar
            algorithms: 'Archetype Algorithm',
        };

        // ***** start of creating filters string logic ****** //
        let _applied_filters_comment = 'Filters Applied ='; // for ppt
        const typeVsFilters = {};
        Object.keys(filtersTray).forEach(key => {
            if (!typeVsFilters[FiltersTypeMap[key]]) {
                typeVsFilters[FiltersTypeMap[key]] = [];
            }
            const arr = filtersTray[key].map(each => getChild(each));
            typeVsFilters[FiltersTypeMap[key]] = [...typeVsFilters[FiltersTypeMap[key]], ...arr.flat(Infinity)];
        });
        Object.keys(typeVsFilters).forEach(key => {
            _applied_filters_comment += ` ${key}: ${typeVsFilters[key].join(', ')}`;
        });
        const { clientName = '' } = AuthStore;
        if (!clientName) return;
        _applied_filters_comment = `Source: ${clientName} ${_applied_filters_comment}`;
        const commonKeys = [
            { key: CUSTOM_KEYS_SLIDE_1.applied_filters_comment, value: _applied_filters_comment }
        ];
        // ***** end of creating filters string logic ******* //

        const showLayersOpp = selectedSlides.includes(PPT_SLIDES_CATEGORIES.layers.layers_report_opp);
        const showLayersWithoutOpp = selectedSlides.includes(PPT_SLIDES_CATEGORIES.layers.layers_report_without_opp);
        let layersData;
        if (showLayersOpp || showLayersWithoutOpp) {
            const {
                layers: _layers,
                totals: { idealSpan, currentSpan },
            } = slideData.layers;
            const layers = _layers.filter(each => each.managers !== 0 || each.nonManagers !== 0);

            const _total_managers = layers.reduce((a, l) => a + l.managers, 0);
            const _total_non_managers = layers.reduce((a, l) => a + l.nonManagers, 0);
            const _average_span = toFixed(formattedUtilities.floatIntValueFormatter(currentSpan || 0));
            const _ideal_span = toFixed(formattedUtilities.floatIntValueFormatter(idealSpan || 0));
            const _total_managers_cost = layers.reduce((a, l) => a + l.managerCost, 0);
            const _total_managers_opp = layers.reduce((a, l) => a + l.managerOpportunity, 0);
            const _total_managers_cost_opp = layers.reduce((a, l) => a + l.costOpportunity, 0);

            const layerProps = [
                { key: CUSTOM_KEYS_SLIDE_1.total_layers, value: (layers.length || 0).toFixed(0) },
                {
                    key: CUSTOM_KEYS_SLIDE_1.total_ftes_or_headcount,
                    value: `Total ${(typeVsFilters['Calculation Assumptions'] || [])[0]}`,
                },
                { key: CUSTOM_KEYS_SLIDE_1.average_span, value: Number(_average_span || 0).toFixed(1) },
                { key: CUSTOM_KEYS_SLIDE_1.ideal_span, value: Number(_ideal_span || 0).toFixed(1) },
                { key: CUSTOM_KEYS_SLIDE_1.total_managers, value: Number(_total_managers || 0).toFixed(1) },
                { key: CUSTOM_KEYS_SLIDE_1.total_non_managers, value: Number(_total_non_managers || 0).toFixed(1) },
                {
                    key: CUSTOM_KEYS_SLIDE_1.total_fte,
                    value: Number(_total_managers + _total_non_managers || 0).toFixed(1),
                },
                { key: CUSTOM_KEYS_SLIDE_1.table_data, value: layers },
                ...commonKeys,
                { key: CUSTOM_KEYS_SLIDE_1.slide_title },
                { key: CUSTOM_KEYS_SLIDE_1.total_layers_comment, value: layers.length },
                { key: CUSTOM_KEYS_SLIDE_1.average_span_comment, value: _average_span },
                { key: CUSTOM_KEYS_SLIDE_1.ideal_span_comment, value: _ideal_span },
                { key: CUSTOM_KEYS_SLIDE_1.total_managers_cost, value: numFormatter(_total_managers_cost, 2, true) },
                { key: CUSTOM_KEYS_SLIDE_1.total_managers_opp, value: (_total_managers_opp || 0).toFixed(2) },
                { key: CUSTOM_KEYS_SLIDE_1.total_managers_cost_opp, value: numFormatter(_total_managers_cost_opp, 2, true) },
            ];

            layersData = {
                showLayersOpp: showLayersOpp,
                showLayersWithoutOpp: showLayersWithoutOpp,
                data: layerProps
            }
        }

        let payData;
        if (selectedSlides.includes(PPT_SLIDES_CATEGORIES.pay_distribution))
            payData = {
                data: [
                    ...commonKeys,
                    { key: CUSTOM_KEYS_SLIDE_1.table_data, value: slideData.pay_distribution }
                ],
                percentage: dsPercentage
            };

        let titleData;
        if (selectedSlides.includes(PPT_SLIDES_CATEGORIES.title_distribution))
            titleData = {
                data: [
                    ...commonKeys,
                    { key: CUSTOM_KEYS_SLIDE_1.table_data, value: slideData.title_distribution }
                ],
                percentage: dsPercentage
            };

        let managerialData;
        if (selectedSlides.includes(PPT_SLIDES_CATEGORIES.managerial_stacking)) {
            managerialData = {
                data: [
                    ...commonKeys,
                    { key: CUSTOM_KEYS_SLIDE_1.table_data, value: slideData.managerial_stacking },
                    { key: CUSTOM_KEYS_SLIDE_1.mng_stacking_comment, value: clientName }
                ],
                percentage: false
            }
        }

        let spanData;
        if (selectedSlides.includes(PPT_SLIDES_CATEGORIES.span_of_control_overview)) spanData = slideData.span_of_control_overview;

        let functionsData;
        if (privileges?.executiveDashboardOrgShapeAccess && selectedSlides.includes(PPT_SLIDES_CATEGORIES.org_shape_function)) {
            functionsData = slideData.org_shape_function;
        }

        let leadersData;
        if (privileges?.executiveDashboardOrgShapeAccess && selectedSlides.includes(PPT_SLIDES_CATEGORIES.org_shape_leader)) {
            leadersData = slideData.org_shape_leader;
        }

        // make calculations
        props.downloadPPTAction(
            {
                layersData,
                spanData,
                functionsData,
                leadersData,
                titleData,
                payData,
                managerialData
            },
            _applied_filters_comment
        );
        setPptSlideList(getOptions(slideData));
    };
    // end of ppt generate logic

    return (
        <Box className="structureContent execDashboard clearFix">
            {isLoading || phaseLoading || loaderCount ? <Loader showBackground background="transparent" /> : null}
            {designProgressEditMode && <div className="disabled-overlay" />}
            <div className={`${designProgressEditMode ? 'disabled' : ''}`}>
                <div className="topHeaders">
                    <Header defaultStructure={structureId} />
                    <SubHeader
                        showStructures
                        enableCompare={strCompareFetched}
                        filterApplied={filterApplied}
                        setDrawerOpen={handleClick}
                        primaryFilters={primaryFilters}
                        getFilteredResults={getFilteredResults}
                        phaseUpdated={phaseUpdated}
                        getCurrentStructure={compareData}
                        setStructureId={setStructureData}
                        resetManagerFilter={resetManagerFilter}
                        enableRefreshTab={!designProgressEditMode}
                        enableFiltersTab={!designProgressEditMode}
                        showPPTExportButton
                        generatePPTExport={() => generatePPTExport(slideData)}
                        pptSlideList={pptSlideList}
                        setPptSlideList={setPptSlideList}
                        updatePptSlideList={updateOptions}
                        disableExport={disableExport}
                        showExecutivePopup
                    />
                    {totalCount && apiIsLoading ? (
                        <div className="progress" style={{ width: `${(totalCompleted * 100) / totalCount}%` }} />
                    ) : null}
                </div>
                <section className="selectContent">
                    <FormControl className="reportLink">
                        <InputLabel shrink id="reportLink">
                            {REPORT_SUB}
                        </InputLabel>
                        <Select
                            labelId="reportLink"
                            id="reportLinkSelect"
                            value={getReportOptions().find(el => el.name === 'Executive dashboard').value}
                            onChange={event => updateReport(event.target.value)}
                        >
                            {getReportOptions().map((report, repIndex) => (
                                <MenuItem key={report.value} value={report.value}>
                                    {report.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </section>
                {structureId && (
                    <TopContent
                        updateFilters={updateFilters}
                        setFiltersToApply={setFiltersToApply}
                        setFiltersTray={setFiltersTray}
                        filterList={filterList}
                        filtersToApply={filtersToApply}
                        filtersTray={filtersTray}
                        strCompareData={strCompareData}
                    />
                )}

                {structure.id && (
                    <>
                        {isDesignProgressPage ? (
                            <div
                                style={{ display: `${isDesignProgressPage ? 'block' : 'none'}` }}
                                className="design-filters-wrapper"
                            >
                                <DesignFiltersContainer
                                    drawerOpen={drawerOpen}
                                    showStaticFilters
                                    structureId={structure.id}
                                    setDrawerOpen={setDrawerOpen}
                                    tabSelected={0}
                                />
                            </div>
                        ) : (
                            <div
                                style={{ display: `${!isDesignProgressPage ? 'block' : 'none'}` }}
                                className="normal-filters-wrapper"
                            >
                                <FiltersContainer
                                    drawerOpen={drawerOpen}
                                    showStaticFilters
                                    structureId={structure.id}
                                    setDrawerOpen={setDrawerOpen}
                                    tabSelected={0}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>

            {isDesignProgressPage && (
                <div className="design-filter-tray-wrapper">
                    <DesignFilterTrayContainer />
                </div>
            )}
            {structure?.id && isFiltersReadyToApplyOn ? (
                <div className="execDashboard__tabs">
                    <NavigationTabs
                        tabs={[
                            {
                                title: executiveDashboard.TODAY,
                                url: props.match.url,
                                path: props.match.path,
                                subUrl: '/today',
                                className: 'today',
                                disabled: designProgressEditMode,
                                render: props => (
                                    <TodayContainer
                                        {...props}
                                        structureId={structureId}
                                        isFiltersReadyToApply={isFiltersReadyToApplyOn}
                                    />
                                ),
                            },
                            {
                                title: executiveDashboard.ANALYSIS,
                                isFeatureOff: featureToggles.executiveDashBoardAnalysisOff,
                                url: props.match.url,
                                path: props.match.path,
                                subUrl: '/analysis',
                                className: 'analysis',
                                disabled: designProgressEditMode,
                                render: props => (
                                    <AnalysisContainer
                                        {...props}
                                        structureId={structureId}
                                        isFiltersReadyToApply={isFiltersReadyToApplyOn}
                                        dsPercentage={dsPercentage}
                                        setDsPercentage={setDsPercentage}
                                    />
                                ),
                            },
                            {
                                title: executiveDashboard.DESIGN_PROGRESS,
                                isFeatureOff: featureToggles.executiveDashBoardDesignProgress,
                                url: props.match.url,
                                path: props.match.path,
                                disabled: designProgressEditMode,
                                subUrl: '/design-progress',
                                className: 'designProgress',
                                render: props => (
                                    <DesignProgressContainer
                                        filterList={filterList}
                                        updateFilters={updateFilters}
                                        setFiltersToApply={setFiltersToApply}
                                    />
                                ),
                            },
                        ]}
                        redirect={{
                            from: '/executive-dashboard',
                            to: '/executive-dashboard/today',
                        }}
                    />
                </div>
            ) : null}
            {structure?.id && !isFiltersReadyToApplyOn ? <Loader showBackground background="transparent" /> : null}
            {phaseStructureInfo.isApiCallSuccess && !phaseStructureInfo.anyStructurePresent ? (
                <div>
                    <NoDataFound
                        mainMessage={`No Structure found for the selected design session`}
                        secondaryMessage={`Please select a different design session.`}
                    />
                </div>
            ) : null}
        </Box>
    );
}

ExecutiveDashboard.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    phaseLoading: PropTypes.bool.isRequired,
    strCompareFetched: PropTypes.bool.isRequired,
    loaderCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    totalCompleted: PropTypes.number.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default ExecutiveDashboard;
