import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill from 'highcharts-pattern-fill';
import { getBadgeType, getPercentage } from '../../../../../common/utilities';
import { managerRatioConfig, getMappedSeries } from './chart.config';
import executiveDashboard from '../../../executiveDashboard.enum';

import './index.scss';

HC_patternFill(Highcharts);

const ManagersRatio = ({ data, isEditable, ranges, target }) => {
    const categories = [];
    const totals = [];
    const series = [
        { name: 'Managers', data: [] },
        { name: 'Non Managers', data: [] },
    ];

    let hasData = false;

    Object.keys(data).forEach(el => {
        const catobj = { name: data[el].name, badge: 0 };
        if (data[el].shouldShow) {
            if (data[el].value) {
                const totalEmps = data[el].value[0] + data[el].value[1];

                series[0].data.push({
                    y: data[el].value[0] || (totalEmps === 0 ? 100 : 0),
                    name: catobj.name,
                    isZero: totalEmps > 0,
                });
                series[1].data.push({ y: data[el].value[1] || 0, name: catobj.name, isZero: totalEmps > 0 });
                totals.push(totalEmps || 100);
                catobj.badge =
                    target &&
                    target > 0 &&
                    Math.round(getPercentage(data[el].value[0], data[el].value[0] + data[el].value[1]));
                catobj.hasData = totalEmps > 0;
                hasData = true;
            } else {
                series[0].data.push({ y: 50, name: data[el].name });
                series[1].data.push({ y: 50, name: data[el].name });
                hasData = false;
                totals.push(100);
            }
            categories.push(catobj);
        }
    });

    if (target) {
        const tgt_ttl = Math.max(...totals);
        const tgt_mgr = Math.round(tgt_ttl * (target / 100));

        series[0].data.push({ y: tgt_mgr, name: executiveDashboard.TARGET_TEXT });
        series[1].data.push({ y: tgt_ttl - tgt_mgr, name: executiveDashboard.TARGET_TEXT });
        categories.push({ name: executiveDashboard.TARGET_TEXT, badge: false });
    }

    return (
        <div className="design-progress-managers-ratio">
            <div className={`managers-ratio-legends ${isEditable ? 'edit-mode' : ''}`}>
                <span className="dot">
                    <span className="fill bluegrey" />
                </span>
                <span className="dot">
                    <span className="fill skyblue" />
                </span>
                <span className="text">{executiveDashboard.MANAGERS}</span>
                <span className="space">&nbsp;</span>
                <span className="dot">
                    <span className="fill pattern1" />
                </span>
                <span className="text">{executiveDashboard.NONMANAGERS}</span>
            </div>
            {hasData && target > 0 && (
                <div className="badge-infotext">
                    <span>
                        (%) of the <br />
                        target
                    </span>
                </div>
            )}
            <HighchartsReact
                options={{
                    ...managerRatioConfig,
                    chart: {
                        ...managerRatioConfig.chart,
                    },
                    series: getMappedSeries(series, hasData),
                    tooltip: {
                        useHTML: true,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        shadow: false,
                        formatter: function() {
                            if (this?.x?.name === executiveDashboard.TARGET_TEXT || !hasData || !this.x?.hasData) {
                                return false;
                            }
                            return `<span style="background-color: white;padding:10px;border: 1px solid black">${this.series.name} : ${this.point.y}</span>`;
                        },
                    },
                    yAxis: {
                        ...managerRatioConfig.yAxis,
                        stackLabels: {
                            enabled: true,
                            useHTML: true,
                            formatter: function() {
                                const { x, axis, points } = this;
                                const categories = axis?.chart?.options?.xAxis[0]?.categories;
                                if (!points['0'] || points['0'].every(el => !el)) return '';

                                const point = points['0'];
                                const mgrs = point[1] - point[0];
                                const isTarget =
                                    categories[x]?.name.toLowerCase() === executiveDashboard.TARGET_TEXT.toLowerCase();
                                const cls = hasData ? (isTarget ? 'target' : '') : 'default';

                                return hasData || isTarget
                                    ? `<span class="label-badge ${cls}">${
                                          isTarget ? `${target}` : getPercentage(mgrs, point[1])
                                      }%</span>`
                                    : '~';
                            },
                        },
                    },
                    xAxis: {
                        ...managerRatioConfig.xAxis,
                        categories,
                        labels: {
                            autoRotation: false,
                            useHTML: true,
                            formatter: function() {
                                const badgeType = getBadgeType(this.value.badge, target, ranges);
                                return `<div class="data-label" style="text-align: center;">
                                <div class="data-label__text" title=${this.value.name}
                                    style="overflow: hidden;
                                        margin-top: 15px;
                                        display: -webkit-box;
                                        color: rgb(156, 156, 156);
                                        font-size:10px;
                                        font-weight:normal;
                                        width: 100%;
                                        word-break: break-all;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;">${this.value.name}
                                        </div>
                                        <div class="data-label__role">
                                        <div class='badge ${badgeType} ${
                                    hasData && target > 0 && this.value?.badge >= 0 && this.value.badge !== false
                                        ? ''
                                        : 'hidden'
                                }'>
                                            <div class="badge-count">${getPercentage(this.value.badge, target)}%</div>
                                        </div>
                                        </div>
                                </div>`;
                            },
                        },
                    },
                }}
                highcharts={Highcharts}
            />
        </div>
    );
};

export default ManagersRatio;
