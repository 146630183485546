const initialState = {
    reportSummary: {
        isLoading: true,
        cost: { totalCost: 0, totalICCost: 0, totalManagerCost: 0 },
        ftes: { total: 0, totalICContractors: 0, totalICs: 0, totalManagerContractors: 0, totalManagers: 0 },
        stats: { avgSpan: 0, totalFTEs: 0, totalLayers: 0, managerUnder4: 0, managerUnder2: 0 },
    },
    executiveFunctions: {
        isLoading: false,
        functions: [],
    },
    executiveN1Managers: {
        isLoading: false,
        managers: [],
    },
    uniqueJobs: 0,
};

export const locationsDefault = {
    Region: 0,
    Country: 1,
    City: 2,
    Location: 3,
    'Not Categorized': 4,
};

export default initialState;
