import React from 'react';
import './index.scss';
import Progress from '../../../../../components/Progress';
import Badge from '../../../../../components/Badge';
import formattedUtilities, { getBadgeType, getPercentage } from '../../../../../common/utilities';

const ProgressStats = ({ activeStructures, dataKey, dataKeyValue, leader, target, ranges }) => {
    const value = dataKeyValue === 'role_salary_value' ? leader[dataKeyValue] : leader[dataKey] || '';
    const _target = dataKeyValue === 'role_salary_value' ? target * 1000000 : target;
    const maxValue = Math.max(Number(value), Number(_target));

    return (
        <div className="progress-stats">
            {activeStructures[leader.elem].id && (
                <section className="structure">
                    <div className="structure-progress">
                        {leader[dataKey] ? (
                            <Progress width={`${getPercentage(Number(value), maxValue)}%`} color="#6e879a" />
                        ) : (
                            '-'
                        )}
                    </div>
                    <div className="structure-count">
                        {leader[dataKey]
                            ? dataKeyValue === 'role_salary_value'
                                ? leader[dataKey]
                                : formattedUtilities.floatIntValueFormatter(leader[dataKey])
                            : ''}
                    </div>
                    <div className="structure-badge">
                        {target && value >= 0 && (
                            <Badge
                                percentage={`${getPercentage(Number(value), Number(_target))}%`}
                                type={getBadgeType(Number(value), Number(_target), ranges)}
                            />
                        )}
                    </div>
                </section>
            )}
            {target && (
                <section className="structure">
                    <div className="structure-progress">
                        <Progress width={`${getPercentage(Number(_target), maxValue)}%`} color="#bbd5e9" />
                    </div>
                    <div className="structure-count">{formattedUtilities.floatIntValueFormatter(Number(target))}</div>
                    <div className="structure-badge" />
                </section>
            )}
        </div>
    );
};

export default ProgressStats;
