const graphConfig = yLabel => ({
    chart: {
        type: 'waterfall',
    },

    title: {
        text: '',
    },

    xAxis: {
        type: 'category',
    },

    yAxis: {
        title: {
            text: `<span style="font-weight:bold;font-family:'Lato', sans-serif;">${yLabel}</span>`,
        },
        gridLineColor: '#197F07',
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
            {
                color: '#000000',
                width: 1,
                value: 0,
            },
        ],
    },

    legend: {
        enabled: false,
    },

    credits: {
        enabled: false,
    },
    plotOptions: {
        series: {
            borderColor: '#ffffff',
            dataLabels: {
                enabled: true,
                crop: false,
                inside: false,
                overflow: 'none',
            },
        },
    },
});

export default graphConfig;
