const colors = [
    '#bbd6e9',
    '#466982',
    '#8dc541',
    '#46651f',
    '#a9cce7',
    '#6e879b',
    '#083d52',
    '#2c3e14',
    '#1610e0',
    '#98b8d0',
    '#87a3b9',
    '#385468',
    '#314a5b',
    '#2a3f4e',
    '#719e34',
    '#638a2e',
    '#557627',
    '#2d28e3',
    '#4540e6',
    '#2a3b13',
    '#1c270d',
    '#0e1406',
    '#031821',
    '#021219',
    '#020c10',
];

export default colors;

export const colorsGray = ['#71797E', '#C0C0C0', '#E5E4E2'];
