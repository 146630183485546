import React, { Fragment } from 'react';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import './index.scss';

const NavigationTabs = ({ tabs, history, redirect }) => {
    return (
        <Fragment>
            <ul className="navigation-tabs">
                {tabs.map(el => {
                    if (!el.isFeatureOff) {
                        return (
                            <li
                                className={`navigation-tabs__item ${
                                    history.location.pathname === `${el.url}${el.subUrl}` ? 'selected' : ''
                                } ${el.disabled ? 'disabled' : ''}`}
                            >
                                <Link to={{ state: { className: el.className }, pathname: `${el.url}${el.subUrl}` }}>
                                    {el.title}
                                </Link>
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
            <div>
                <Switch>
                    {tabs.map(el => {
                        return <Route path={`${el.url}${el.subUrl}`} render={el.render} />;
                    })}
                    {redirect ? <Redirect from={redirect.from} to={redirect.to} /> : null}
                </Switch>
            </div>
        </Fragment>
    );
};

export default withRouter(NavigationTabs);
