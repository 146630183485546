export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_PHASE = 'GET_PHASE';
export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN';
export const REFRESH_ORG_AUTH_TOKEN = 'REFRESH_ORG_AUTH_TOKEN';
export const GET_PRIVILEGES_FROM_ORG = 'GET_PRIVILEGES_FROM_ORG';
export const GET_PRIVILEGES_PENDING = 'GET_PRIVILEGES_PENDING';
export const GET_PRIVILEGES_SUCCESS = 'GET_PRIVILEGES_SUCCESS';
export const GET_PRIVILEGES_FAILED = 'GET_PRIVILEGES_FAILED';
export const GET_CLIENT_PRIVILEGES_FROM_ORG = 'GET_CLIENT_PRIVILEGES_FROM_ORG';
