import React from 'react';
import Progress from '../../../../../components/Progress';
import './index.scss';
import Badge from '../../../../../components/Badge';
import executiveDashboard from '../../../executiveDashboard.enum';
import formattedUtilities, { getPercentage, getMaxWidth, getBadgeType } from '../../../../../common/utilities';

const SpanOfControl = ({ data, isTargetVisible, ranges, target }) => {
    let maxValue = target;
    return (
        <div className="design-progress-span-of-control">
            {Object.keys(data).map((el, index) => {
                const { id, name, value, shouldShow, max } = data[el];
                maxValue = target > max ? target : max;

                return (
                    <section key={index} className={`span-of-control__structure ${shouldShow ? '' : 'hidden'}`}>
                        <div className="span-of-control__structure-name">
                            <div className="label" title={name}>
                                {name}
                            </div>
                            <div className="value">
                                {value ? formattedUtilities.floatIntValueFormatter(value) : '-'}
                            </div>
                        </div>
                        <div className="span-of-control__structure-progress">
                            <Progress
                                width={`${value ? getMaxWidth(value, maxValue) : 100}%`}
                                color={value ? '#6e879a' : '#eaeaea'}
                            />
                        </div>
                        <div className="span-of-control__structure-percentage">
                            {target > 0 && value > 0 && (
                                <>
                                    {!index && <div className="difference-target">(%) of the target</div>}
                                    <Badge
                                        type={getBadgeType(
                                            formattedUtilities.floatIntValueFormatter(value),
                                            target,
                                            ranges
                                        )}
                                        percentage={`${getPercentage(
                                            formattedUtilities.floatIntValueFormatter(value),
                                            target
                                        )}%`}
                                    />
                                </>
                            )}
                        </div>
                    </section>
                );
            })}

            <section className={`span-of-control__structure ${target || isTargetVisible ? '' : 'hidden'}`}>
                <div className="span-of-control__structure-name">
                    <div className="label">{executiveDashboard.TARGET_TEXT}</div>
                    <div className="value">{target || '~'}</div>
                </div>
                <div className="span-of-control__structure-progress">
                    <Progress
                        width={`${target ? getMaxWidth(target, maxValue) : 100}%`}
                        color={target ? '#bbd5e9' : '#eaeaea'}
                    />
                </div>
                <div className="span-of-control__structure-percentage" />
            </section>
        </div>
    );
};

export default SpanOfControl;
