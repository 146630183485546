import React, { Fragment } from 'react';
import './index.scss';
import leadersDefault from '../../../../../assets/leadersDefault.svg';
import DesignStructures from '../DesignStructures';
import LeadersName from '../LeadersName';
import ProgressStats from '../ProgressStats';
import LocalisedLoader from '../../../../../components/LocalisedLoader';
import NoDataFound from '../../../../../components/NoDataFound';

const headersConfig = [
    {
        title: 'Functions',
        width: '120px',
        fullWidth: true,
        component: LeadersName,
        labelKey: 'leaderType',
    },
    {
        title: 'Design Structures',
        width: '120px',
        fullWidth: true,
        component: DesignStructures,
    },
    {
        title: 'Span Of Control',
        width: '300px',
        component: ProgressStats,
        dataKey: 'currentSpan',
        targetKey: 'span-of-control',
    },
    {
        title: 'Layers',
        width: '300px',
        component: ProgressStats,
        dataKey: 'layer',
        targetKey: 'layers',
    },
    {
        title: 'FTEs',
        width: '300px',
        component: ProgressStats,
        dataKey: 'fte_share',
        targetKey: 'ftes',
    },
    {
        title: 'Total Cost (in millions)',
        width: '300px',
        component: ProgressStats,
        dataKey: 'role_salary',
        dataKeyValue: 'role_salary_value',
        targetKey: 'total-cost',
    },
    {
        title: 'Managers with 2 or less direct reports',
        width: '300px',
        component: ProgressStats,
        targetKey: 'managers-with-2-or-less-direct-reports',
        dataKey: 'directReporters',
    },
];

const showHeaders = (countFilterValue, targets, bulkTargets, layerId) => {
    return headersConfig.map(el => {
        const isDisclaimerVisible =
            Object.keys(targets).filter(elem => {
                return (
                    targets[elem][el.targetKey] &&
                    targets[elem][el.targetKey].value &&
                    targets[elem][el.targetKey].value !== '-'
                );
            }).length > 0 ||
            (bulkTargets[el.targetKey] && bulkTargets[el.targetKey].value && bulkTargets[el.targetKey].value !== '-');
        const title = el.title;
        return (
            <div className="leaders-stats-table__title-item" style={{ flex: `0 0 ${el.width}`, width: el.width }}>
                <div className={`title ${el.fullWidth ? 'fullWidth' : 'halfWidth'}`}>
                    {el.dataKey === 'fte_share' ? countFilterValue : title}
                </div>
                {!el.fullWidth && isDisclaimerVisible && <div className="disclaimer">(%) of the target</div>}
            </div>
        );
    });
};

const showStats = (activeStructures, leaders, targets, ranges, bulkTargets) => {
    return leaders.map(leader => {
        const leaderTarget = targets[leader.function_id] || bulkTargets;
        return (
            <div className="leaders-stats-table__data" key={leader.id}>
                {headersConfig.map(el => {
                    return (
                        <div
                            className="leaders-stats-table__data-item"
                            style={{ flex: `0 0 ${el.width}`, width: el.width }}
                        >
                            {el.component &&
                                React.createElement(el.component, {
                                    key: el.title,
                                    activeStructures,
                                    leader,
                                    dataKeyValue: el.dataKeyValue,
                                    dataKey: el.dataKey,
                                    ranges: ranges,
                                    target: leaderTarget
                                        ? leaderTarget[el.targetKey]
                                            ? leaderTarget[el.targetKey].value &&
                                              leaderTarget[el.targetKey].value !== '-'
                                                ? leaderTarget[el.targetKey].value
                                                : ''
                                            : ''
                                        : '',
                                })}
                        </div>
                    );
                })}
            </div>
        );
    });
};

const isDefaultView = activeStructures => {
    return Object.keys(activeStructures).every(el => !activeStructures[el].id);
};

const layerIdMap = {
    1: 'N-1 leaders',
    2: 'N-2 leaders',
    3: 'N-3 leaders',
};
const LeadersStats = ({ activeStructures, leaders, countFilterValue, targets, bulkTargets, ranges, layerId }) => {
    return (
        <div className="leaders-stats-table">
            {isDefaultView(activeStructures) ? (
                <div className="default-view">
                    <div>
                        <img src={leadersDefault} alt="Default Leaders View" />
                    </div>
                    <div className="label">Select design structure(s) to get started</div>
                </div>
            ) : !leaders.length ? (
                <div>
                    <NoDataFound
                        mainMessage={`No data Found`}
                        secondaryMessage={`Please select a different design structure.`}
                    />
                </div>
            ) : (
                <Fragment>
                    <div className="leaders-stats-table__title">
                        {showHeaders(countFilterValue, targets, bulkTargets, layerId)}
                    </div>
                    {showStats(activeStructures, leaders, targets, ranges, bulkTargets)}
                </Fragment>
            )}
        </div>
    );
};

export default LeadersStats;
