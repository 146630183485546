import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Legends = ({ nameAndcolors }) => {
    return (
        <div className="legendContainer">
            {nameAndcolors.map(ele => {
                return (
                    <div className={`legendGroups ${ele.border ? 'showBorder' : ''}`}>
                        {ele.data.map(val => {
                            return (
                                <div className="legends">
                                    <div className="circle" style={{ background: `${val.color}` }} />
                                    <div>{val.name}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

Legends.propTypes = {
    nameAndcolors: PropTypes.array.isRequired,
};

export default Legends;
