import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import loader from './loader.gif';

export default function Loader({ background, position, showBackground, className }) {
    const style = {
        position,
        height: '100%',
        width: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: background,
        zIndex: 999,
    };
    const imgstyle = {
        position: 'absolute',
        top: '50%',
        height: '250px',
        left: '50%',
        transform: 'Translate(-50%, -50%)',
    };
    const whiteBg = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: '#ffffff',
        opacity: '0.6',
    };
    return (
        <Box style={style} className={className || ''}>
            {showBackground && <div style={whiteBg} />}
            <img src={loader} style={imgstyle} alt="loading" />
        </Box>
    );
}

Loader.defaultProps = {
    background: 'white',
    position: 'fixed',
    showBackground: false,
};

Loader.propTypes = {
    background: PropTypes.string,
    position: PropTypes.string,
    showBackground: PropTypes.bool,
};
