export const FETCH_ALL_FILTERS_DESIGN_PROGRESS_PENDING = 'FETCH_ALL_FILTERS_DESIGN_PROGRESS_PENDING';
export const FETCH_ALL_FILTERS_PENDING = 'FETCH_ALL_FILTERS_PENDING';
export const FETCH_ALL_FILTERS_SUCCESS = 'FETCH_ALL_FILTERS_SUCCESS';
export const FETCH_ALL_FILTERS_FAILED = 'FETCH_ALL_FILTERS_FAILED';
export const FETCH_FILTERS_CONTENT_PENDING = 'FETCH_FILTERS_CONTENT_PENDING';
export const FETCH_FILTERS_CONTENT_SUCCESS = 'FETCH_FILTERS_CONTENT_SUCCESS';
export const FETCH_FILTERS_CONTENT_FAILED = 'FETCH_FILTERS_CONTENT_FAILED';
export const UPDATE_ALL_FILTERS = 'UPDATE_ALL_FILTERS';
export const UPDATE_MULTI_FILTERS = 'UPDATE_MULTI_FILTERS';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const SET_FILTERS_TO_APPLY = 'SET_FILTERS_TO_APPLY';
export const SET_FILTERS_TRAY = 'SET_FILTERS_TRAY';
export const REFRESH_DATA = 'REFRESH_DATA';
export const GET_FILTERS_MANAGER_PENDING = 'GET_FILTERS_MANAGER_PENDING';
export const GET_FILTERS_MANAGER_SUCCESS = 'GET_FILTERS_MANAGER_SUCCESS';
export const GET_FILTERS_MANAGER_CLEAR = 'GET_FILTERS_MANAGER_CLEAR';
export const GET_FILTERS_SELECTED_MANAGER_CLEAR = 'GET_FILTERS_SELECTED_MANAGER_CLEAR';
export const GET_SELECTED_STRUCTURE_FILTERS_PENDING = 'GET_SELECTED_STRUCTURE_FILTERS_PENDING';
export const GET_SELECTED_STRUCTURE_FILTERS_FAILED = 'GET_SELECTED_STRUCTURE_FILTERS_FAILED';
export const GET_SELECTED_STRUCTURE_FILTERS_SUCCESS = 'GET_SELECTED_STRUCTURE_FILTERS_SUCCESS';
export const SET_MULTI_FILTERS_TO_APPLY = 'SET_MULTI_FILTERS_TO_APPLY';
export const SET_MULTI_FILTERS_TRAY = 'SET_MULTI_FILTERS_TRAY';
export const GET_STATIC_FILTERS_SUCCESS = 'GET_STATIC_FILTERS_SUCCESS';
export const GET_STATIC_FILTERS_PENDING = 'GET_STATIC_FILTERS_PENDING';
export const UPDATE_STATIC_FILTERS = 'UPDATE_STATIC_FILTERS';
export const UPDATE_FILTERS_CHILDREN = 'UPDATE_FILTERS_CHILDREN';
