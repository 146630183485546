import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Header from './Header';

import { resetLogOutLoader } from '../../containers/StructureContent/actions';
import { selectStructures } from '../../containers/StructureContent/selector';
import { selectPrivileges } from '../../containers/Login/selector';

const stateToProps = createStructuredSelector({
    privileges: selectPrivileges(),
    strValues: selectStructures(),
});

const dispatchToProps = {
    resetLogOutLoader,
};

export default connect(stateToProps, dispatchToProps)(Header);
