import React, { Fragment, useEffect, useState } from 'react';
import compact from 'lodash/compact';
import SummaryDetails from './SummaryDetails';
import SummaryReports from './SummaryReports';
import { getAnalyticsApi, getOrgResources } from '../../StructureContent/apis';
import { getAnalyticsFilterString } from '../../../common/utilities';
import initialState from './initalState';

function Today({ filtersToApply, structureId, refreshCount }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isUniqueJobsLoading, setIsUniqueJobsLoading] = useState(false);
    const [reportSummary, setReportSummary] = useState({ ...initialState.reportSummary });
    const [uniqueJobTitles, setUniqueJobTitles] = useState({ ...initialState.uniqueJobs });
    const [executiveFunctions, setExecutiveFunctions] = useState({ ...initialState.executiveFunctions });
    const [executiveN1Managers, setExecutiveN1Managers] = useState({ ...initialState.executiveN1Managers });
    const [countFilterValue, setCountFilterValue] = useState('Headcount');

    useEffect(() => {
        const countFilter = filtersToApply.count_by;
        if (countFilter === 'fte') {
            setCountFilterValue('FTEs');
        } else {
            setCountFilterValue('HeadCount');
        }

        setIsLoading(true);
        setIsUniqueJobsLoading(true);
        setExecutiveN1Managers({ isLoading: true, managers: [] });
        setExecutiveFunctions({ isLoading: true, functions: [] });

        const filterString = getAnalyticsFilterString({ ...filtersToApply, structureId });
        getFunctions(filterString)
            .then(responses => {
                const [functions, subFunctions] = responses;
                const { data: functionsData } = functions;
                const { data: subFunctionsData } = subFunctions;
                const fn = [];

                Object.entries(subFunctionsData).forEach(([idx, { name, data }]) => {
                    const o = {
                        function: name,
                        span: functionsData[idx]?.data[idx]?.data?.currentSpan || 0,
                        fte:
                            functionsData[idx]?.data[idx]?.data?.managers +
                            functionsData[idx]?.data[idx]?.data?.nonManagers,
                        subFunctions: [],
                    };

                    Object.values(data).forEach(({ name, data }) => {
                        const fte = data?.managers + data?.nonManagers;
                        o.subFunctions.push({
                            function: name,
                            fte,
                            span: data?.currentSpan || 0,
                        });
                    });
                    fn.push(o);
                });
                setExecutiveFunctions({ isLoading: false, functions: fn });
            })
            .catch(() => {
                setExecutiveFunctions({ ...initialState.executiveFunctions });
            });

        getN1Leaders(filterString)
            .then(responses => {
                const [leaderRoles, managers] = responses;
                const { data: rolesData } = leaderRoles;
                const { data: managersData } = managers;

                const mgrsData = managersData.managers.map(({ id, name, employee }) => {
                    const stats = rolesData[id]?.data?.[id]?.data;
                    return {
                        roleid: id,
                        rolename: name || 'Unknown',
                        filter_fte: stats?.managers + stats?.nonManagers,
                        empname: employee?.name,
                        total_span: stats?.currentSpan,
                    };
                });

                setExecutiveN1Managers({ ...executiveN1Managers, managers: mgrsData });
            })
            .catch(() => {
                setExecutiveN1Managers({ isLoading: false, managers: [] });
            });

        getSummary(filterString)
            .then(responses => {
                const [layerResponse, withoutContractorResponss] = responses;
                const { data: layerData } = layerResponse;
                const { data: withoutContractorData } = withoutContractorResponss;

                const layers = layerData?.layers
                    ? Object.values(layerData.layers).reduce((acc, { managers, nonManagers }) => {
                          if (managers + nonManagers) acc += 1;
                          return acc;
                      }, 0)
                    : 0;

                const summary = {
                    cost: {
                        totalCost: layerData?.totals?.managerCost + layerData?.totals?.nonManagerCost,
                        totalICCost: layerData?.totals?.nonManagerCost,
                        totalManagerCost: layerData?.totals?.managerCost,
                        totalManagerContractorCost:
                            layerData?.totals?.managerCost - withoutContractorData?.totals?.managerCost,
                        totalICContractorCost:
                            layerData?.totals?.nonManagerCost - withoutContractorData?.totals?.nonManagerCost,
                    },
                    ftes: {
                        total: layerData?.totals?.fteCount,
                        totalICContractors: layerData?.totals?.nonManagers - withoutContractorData?.totals?.nonManagers,
                        totalICs: layerData?.totals?.nonManagers,
                        totalManagerContractors: layerData?.totals?.managers - withoutContractorData?.totals?.managers,
                        totalManagers: layerData?.totals?.managers,
                    },
                    stats: {
                        avgSpan: layerData?.totals?.currentSpan,
                        totalFTEs: layerData?.totals?.fteCount,
                        totalLayers: layers,
                        managerUnder4: (layerData?.totals?.spanBands[0] || 0) + (layerData?.totals?.spanBands[1] || 0),
                        managerUnder2: layerData?.totals?.spanBands[0] || 0,
                    },
                };

                setReportSummary(summary);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });

        getUniqueJobs(filterString)
            .then(responses => {
                const [uniqueJobsResponse] = responses;
                const { data: uniqueJobsData } = uniqueJobsResponse;

                const uniqueJobs = Object.values(uniqueJobsData).reduce((acc, { data }) => {
                    acc = acc.concat(Object.keys(data));
                    return acc;
                }, []);

                setUniqueJobTitles(compact([...new Set(uniqueJobs)]).length);
                setIsUniqueJobsLoading(false);
            })
            .catch(() => {
                setIsUniqueJobsLoading(false);
            });
    }, [JSON.stringify(filtersToApply), refreshCount]);

    const getUniqueJobs = filterString => {
        return Promise.all([
            getAnalyticsApi({
                method: 'pivotTableJson',
                filterString: filterString + '&group_by[]=layer&group_by[]=name',
            }),
            getOrgResources({
                id: structureId,
                method: 'uniqueJobTitles',
                filterString: filterString + '&group_by=name',
            }),
        ]);
    };

    const getSummary = filterString => {
        const filterStr = filterString.split('&');
        filterStr.push('filter[contractor]=false');
        const filterStringWoContractor = [...new Set(filterStr)].join('&');

        return Promise.all([
            getAnalyticsApi({ method: 'layersJson', filterString }),
            getAnalyticsApi({ method: 'layersJson', filterString: filterStringWoContractor }),
        ]);
    };

    const getN1Leaders = filterString => {
        const RolefilterString = filterString + '&group_by[]=manager[1]&group_by[]=manager[1]';
        const ManagerfilterString = filterString + '&page=1&perPage=1000&layer_id=1';
        return Promise.all([
            getAnalyticsApi({ method: 'pivotTableJson', filterString: RolefilterString }),
            getAnalyticsApi({ method: 'managersJson', filterString: ManagerfilterString }),
        ]);
    };

    const getFunctions = filterString => {
        const functionFilterString = filterString + '&group_by[]=function[0]&group_by[]=function[1]';
        return Promise.all([
            getAnalyticsApi({
                method: 'pivotTableJson',
                filterString: functionFilterString.replace('function[1]', 'function[0]'),
            }),
            getAnalyticsApi({ method: 'pivotTableJson', filterString: functionFilterString }),
        ]);
    };

    return (
        <Fragment>
            <SummaryDetails
                uniqueJobs={uniqueJobTitles}
                reportSummary={reportSummary}
                isLoading={isLoading}
                isUniqueJobsLoading={isUniqueJobsLoading}
                countFilter={countFilterValue}
            />
            {structureId && (
                <SummaryReports
                    isLoading={isLoading}
                    reportSummary={reportSummary}
                    executiveFunctions={executiveFunctions}
                    executiveN1Managers={executiveN1Managers}
                    executiveLocations={{ isLoading: false, locations: [] }}
                    countFilter={countFilterValue}
                    structureId={structureId}
                    filtersApplied={filtersToApply}
                    refreshCount={refreshCount}
                />
            )}
        </Fragment>
    );
}

export default Today;
