import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import PieChart from 'highcharts-react-official';
import Legends from '../Components/Legends';

const Piechart = ({ piechartConfig, seriesData, pieChartTitle, legends, plotOptions, tooltip }) => {
    return (
        <div className="piechart boxShadow">
            <PieChart
                highcharts={Highcharts}
                options={{
                    ...piechartConfig,
                    title: {
                        text: pieChartTitle,
                        verticalAlign: 'bottom',
                        y: 0,
                        style: {
                            fontSize: '20px',
                            color: '#3e3e3e',
                            fontWeight: 400,
                        },
                    },
                    tooltip,
                    plotOptions,
                    series: [
                        {
                            data: seriesData,
                            size: '100%',
                        },
                    ],
                }}
            />
            <Legends nameAndcolors={legends} />
        </div>
    );
};

Piechart.propTypes = {
    piechartConfig: PropTypes.object.isRequired,
    seriesData: PropTypes.array.isRequired,
    legends: PropTypes.array.isRequired,
};

export default Piechart;
