import React, { useState, useEffect } from 'react';
import c from 'classnames';
import './index.scss';

// packages
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { cloneDeep } from 'lodash';

// components
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import Loader from '../../components/Loader';
import Card from '../../components/Card';
import InputTable from './InputTable';
import OutputTable from './OutputTable';
import Shimmer from '../../components/shimmer';
import backArrowIcon from '../../assets/backArrowIcon.svg';
import FiltersContainer from '../Filters/index.container';
import TopContent from '../StructureContent/TopContent';

// others
import graphConfig from './graphConfig';
import { numFormatter } from '../../utils/functions';
import { REPORTS_LINK, SITE_TXT } from '../../utils/constants';
import { CUSTOM_KEYS_SLIDE_3 } from '../PPTExport/constants';
import { getAnalyticsFilterString } from '../../common/utilities';
import AuthStore from '../../common/AuthStore';

// enums
import addressableOpportunityConst from './addressableOpportunity.enum';

const DEFAULT_VALUE = {
    overspan_manager: '20',
    geo_exception: '1',
    hidden_players: '80',
    archetypes: {
        1: '50',
        2: '40',
        3: '30',
        4: '20',
        5: '10',
    },
};

function AddressableOpportunity({
    strCompareFetched,
    primaryFilters,
    clrManagerFilters,
    structure,
    privileges,
    resetFilters,
    history,
    refreshCount,
    getAddressableReport,
    isLoading,
    showResult,
    outputResult,
    userInput,
    goToInputSection,
    resetState,
    // update
    // structure,
    setFiltersToApply,
    setFiltersTray,
    updateFilters,
    filtersToApply,
    filtersTray,
    filterList,
    // isFiltersReadyToApply,
    strCompareData,
    downloadPPTAction,
    isInputScreenRdx,
    setIsInputScreenAction,
    addressableOpportunityFilterLoader,
}) {
    const { REPORT_SUB } = SITE_TXT;

    const [filterApplied, setFilterApplied] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [structureId, setStructureId] = useState('');
    const [resultBtnDisable, setResultBtnDisable] = useState(false);

    const [inputDetails, setInputDetails] = useState(DEFAULT_VALUE);
    const [fteChart, setFteChart] = useState(null);
    const [costChart, setCostChart] = useState(null);

    useEffect(() => {
        setInputDetails(old => ({ ...old, structure_id: structureId.toString() }));
    }, [structureId]);

    // this runs on filter change whenever user clicks on apply filters
    useEffect(() => {
        if (!showResult) return;
        // because in case of input screen, the results will be only calculated when the user clicks
        // on the "view results" button so we don't need to run this side effect method
        // it should only run on the output screen
        if (isInputScreenRdx) return;
        // NOTE: no need to pass filters as they are extracted in the getModelResult function iteslf
        getModelResult(null, true);
    }, [JSON.stringify(filtersToApply)]);

    useEffect(() => {
        const tempObj = {
            dataLabels: { style: { color: 'black' } },
        };
        if (Object.keys(outputResult).length) {
            setFteChart(
                addressableOpportunityConst.graphConst.map(item => {
                    let value = outputResult[item.label]?.fte || null;
                    if (item.label === 'archetypes') {
                        value =
                            outputResult.player_coach.fte +
                            outputResult.coach.fte +
                            outputResult.supervisor.fte +
                            outputResult.facilitator.fte +
                            outputResult.coordinator.fte;
                        value = parseFloat(value.toFixed(2));
                    }

                    if (item.label === 'total') {
                        return {
                            name: item.name,
                            isSum: true,
                            color: '#A2CF63',
                            className: 'total',
                            ...tempObj,
                        };
                    }

                    return {
                        name: item.name,
                        y: value,
                        color: outputResult[item.label]?.fte < 0 ? '#D55248' : '#A2CF63',
                        ...tempObj,
                    };
                })
            );

            setCostChart(
                addressableOpportunityConst.graphConst.map(item => {
                    let value = outputResult[item.label]?.cost || null;
                    if (item.label === 'archetypes') {
                        value =
                            outputResult.player_coach.cost +
                            outputResult.coach.cost +
                            outputResult.supervisor.cost +
                            outputResult.facilitator.cost +
                            outputResult.coordinator.cost;
                        value = parseFloat(value.toFixed(2));
                    }

                    if (item.label === 'total') {
                        return {
                            name: item.name,
                            isSum: true,
                            color: '#A2CF63',
                            className: 'total',
                            ...tempObj,
                        };
                    }

                    return {
                        name: item.name,
                        y: value,
                        color: outputResult[item.label]?.cost < 0 ? '#D55248' : '#A2CF63',
                        ...tempObj,
                    };
                })
            );
        }
    }, [outputResult]);

    useEffect(() => {
        return () => resetState();
    }, []);

    useEffect(() => {
        if (refreshCount) {
            if (showResult) getModelResult(null, true);
            else setInputDetails(DEFAULT_VALUE);
        }
    }, [refreshCount, showResult]);

    const validateForm = obj => {
        if (
            obj.overspan_manager !== '' &&
            obj.geo_exception !== '' &&
            obj.hidden_players !== '' &&
            Object.values(obj.archetypes).every(item => item !== '')
        ) {
            setResultBtnDisable(false);
        } else {
            setResultBtnDisable(true);
        }
    };

    const onInputChange = (name, value, index) => {
        if (['overspan_manager', 'hidden_players', 'archetypes'].includes(name) && Number(value) > 100) return;

        if (index) {
            const _updatedObj = {
                ...inputDetails,
                structure_id: structureId.toString(),
                [name]: { ...inputDetails[name], [index]: Number(value) },
            };
            validateForm(_updatedObj);
            setInputDetails(_updatedObj);
        } else {
            const _updatedObj = { ...inputDetails, [name]: Number(value), structure_id: structureId.toString() };
            validateForm(_updatedObj);
            setInputDetails(_updatedObj);
        }
    };

    const getModelResult = (e, showInput) => {
        if (e) {
            const updatedFilters = cloneDeep(filterList);
            updatedFilters.forEach((ele, index) => {
                if (ele.name === 'span_of_control') {
                    updatedFilters[index].isSelected = false;
                    updatedFilters[index].children.forEach(child => (child.isSelected = false));
                }
            });
            updateFilters(updatedFilters);
            setFiltersToApply({ count_by: 'fte', algorithms: 'conservative' });
            setFiltersTray({
                count_by: [{ id: 'fte', name: 'FTEs' }],
                algorithms: [{ id: 'conservative', name: 'Conservative' }],
            });
        }
        // include the filters if the user has any filters applied
        const filters = getAnalyticsFilterString(
            e
                ? { algorithms: filtersToApply.algorithms, count_by: filtersToApply.count_by, structureId }
                : { ...filtersToApply, structureId }
        );
        setIsInputScreenAction(false);
        getAddressableReport({
            input: { ...inputDetails, archetypes: Object.values(inputDetails.archetypes) },
            filters,
            showInput: showInput === undefined ? false : showInput,
        });
    };

    const goBack = () => {
        setIsInputScreenAction(true);
        goToInputSection();
    };

    const handleClick = () => setDrawerOpen(!drawerOpen);

    const getFilteredResults = (filterToApply = {}) => {
        setFiltersData({ ...filterToApply });
    };

    const setFiltersData = filtersUsed => {
        setFilters(filtersUsed);
    };

    const phaseUpdated = () => {
        setFilterApplied(false);
    };

    const compareData = str1 => {
        if (str1) {
            setFilters({ ...filters });
        }
    };

    const setStructureData = id => {
        setStructureId(id);
        setFilterApplied(primaryFilters.filter(({ isSelected }) => isSelected));
    };

    const resetManagerFilter = () => {
        // clear manager data
        clrManagerFilters();
        const el = document.getElementById('search');
        if (el) {
            el.value = '';
        }
    };

    const getReportOptions = () => {
        const reportOptions = [];
        Object.keys(REPORTS_LINK).forEach(el => {
            if (privileges[el]) {
                reportOptions.push(REPORTS_LINK[el]);
            }
        });

        return reportOptions;
    };

    const updateReport = index => {
        resetFilters();
        const links = Object.values(REPORTS_LINK);
        const route = links.filter(el => el.value === index);
        history.push(route[0].routeValue);
    };

    // ppt generation logic
    const generatePPTExport = async () => {
        const FiltersTypeMap = {
            function_id: 'Characteristics',
            location_id: 'Characteristics',
            manager_id: 'Characteristics',
            lookup_id: 'Characteristics',
            span_of_control: 'Manager Analysis',
            span_ranges: 'Manager Analysis',
            open_roles: 'Calculation Assumptions',
            count_by: 'Calculation Assumptions',
            contractors: 'Calculation Assumptions',
            assistant: 'Calculation Assumptions',
            // added manually to get rid of undefined error in the final filter string
            // added by @Karan Kumar
            algorithms: 'Archetype Algorithm',
        };
        let _applied_filters_comment = 'Filters Applied ='; // for ppt
        const typeVsFilters = {};
        Object.keys(filtersTray).forEach(key => {
            if (!typeVsFilters[FiltersTypeMap[key]]) {
                typeVsFilters[FiltersTypeMap[key]] = [];
            }
            const arr = filtersTray[key].map(each => each.name);
            typeVsFilters[FiltersTypeMap[key]] = [...typeVsFilters[FiltersTypeMap[key]], ...arr];
        });
        Object.keys(typeVsFilters).forEach(key => {
            _applied_filters_comment += ` ${key}: ${typeVsFilters[key].join(', ')}`;
        });
        const { clientName = '' } = AuthStore;
        _applied_filters_comment = `Source: ${clientName} ${_applied_filters_comment}`;

        const self = {
            fte: parseFloat(
                (
                    outputResult.player_coach.fte +
                    outputResult.coach.fte +
                    outputResult.supervisor.fte +
                    outputResult.facilitator.fte +
                    outputResult.coordinator.fte
                ).toFixed(2)
            ),
            cost: parseFloat(
                (
                    outputResult.player_coach.cost +
                    outputResult.coach.cost +
                    outputResult.supervisor.cost +
                    outputResult.facilitator.cost +
                    outputResult.coordinator.cost
                ).toFixed(2)
            ),
        };

        const total = {
            fte: parseFloat(
                (
                    outputResult.too_few.fte +
                    outputResult.overspan_manager.fte +
                    outputResult.geo_exception.fte +
                    outputResult.hidden_player.fte +
                    outputResult.player_coach.fte +
                    outputResult.coach.fte +
                    outputResult.supervisor.fte +
                    outputResult.facilitator.fte +
                    outputResult.coordinator.fte
                ).toFixed(2)
            ),
            cost: parseFloat(
                (
                    outputResult.too_few.cost +
                    outputResult.overspan_manager.cost +
                    outputResult.geo_exception.cost +
                    outputResult.hidden_player.cost +
                    outputResult.player_coach.cost +
                    outputResult.coach.cost +
                    outputResult.supervisor.cost +
                    outputResult.facilitator.cost +
                    outputResult.coordinator.cost
                ).toFixed(2)
            ),
        };
        // make calculations
        downloadPPTAction(
            {
                slide1: {
                    data: { ...outputResult, self, total },
                    extraData: [
                        { key: CUSTOM_KEYS_SLIDE_3.fte_total, value: total.fte },
                        { key: CUSTOM_KEYS_SLIDE_3.cost_total, value: total.cost },
                        { key: CUSTOM_KEYS_SLIDE_3.too_many_managers, value: userInput.overspan_manager },
                        // { key: CUSTOM_KEYS_SLIDE_3.too_many_managers, value: userInput.overspan_manager / 100},
                        { key: CUSTOM_KEYS_SLIDE_3.hidden_players, value: userInput.hidden_players },
                        // { key: CUSTOM_KEYS_SLIDE_3.hidden_players, value: userInput.hidden_players / 100},
                        { key: CUSTOM_KEYS_SLIDE_3.min_managers, value: userInput.geo_exception },
                        { key: CUSTOM_KEYS_SLIDE_3.player_coach, value: userInput.archetypes[0] },
                        { key: CUSTOM_KEYS_SLIDE_3.coach, value: userInput.archetypes[1] },
                        { key: CUSTOM_KEYS_SLIDE_3.supervisor, value: userInput.archetypes[2] },
                        { key: CUSTOM_KEYS_SLIDE_3.facilitator, value: userInput.archetypes[3] },
                        { key: CUSTOM_KEYS_SLIDE_3.coordinator, value: userInput.archetypes[4] },
                    ],
                },
            },
            _applied_filters_comment
        );
    };
    // end of ppt generate logic

    return (
        <Box className="addressable-opportunity-dashboard clearFix">
            <div className="topHeaders">
                <Header />
                <SubHeader
                    showStructures
                    enableCompare={strCompareFetched}
                    filterApplied={filterApplied}
                    setDrawerOpen={handleClick}
                    primaryFilters={primaryFilters}
                    getFilteredResults={getFilteredResults}
                    phaseUpdated={phaseUpdated}
                    getCurrentStructure={compareData}
                    setStructureId={setStructureData}
                    resetManagerFilter={resetManagerFilter}
                    enableRefreshTab
                    enableFiltersTab
                    showPPTExportButton={showResult}
                    generatePPTExport={generatePPTExport}
                    // showResult={showResult} // condition on icon
                    showResult
                />
                {structureId && (
                    <TopContent
                        updateFilters={updateFilters}
                        setFiltersToApply={setFiltersToApply}
                        setFiltersTray={setFiltersTray}
                        filterList={filterList}
                        filtersToApply={filtersToApply}
                        filtersTray={filtersTray}
                        strCompareData={strCompareData}
                        // "showResult" decides if we have to show the applied filters navbar or not
                        showResult={showResult}
                    />
                )}
                {structure.id && (
                    <div style={{ display: 'block' }} className="normal-filters-wrapper">
                        <FiltersContainer
                            drawerOpen={drawerOpen}
                            showStaticFilters
                            structureId={structure.id}
                            setDrawerOpen={setDrawerOpen}
                            tabSelected={0}
                        />
                    </div>
                )}
            </div>

            <section className="selection-content" style={{ marginTop: showResult ? 40 : 10 }}>
                <FormControl className="reportLink">
                    <InputLabel shrink id="reportLink">
                        {REPORT_SUB}
                    </InputLabel>
                    <Select
                        labelId="reportLink"
                        id="reportLinkSelect"
                        value={getReportOptions().find(el => el.name === 'Addressable opportunity').value}
                        onChange={event => updateReport(event.target.value)}
                    >
                        {getReportOptions().map((report, repIndex) => (
                            <MenuItem key={report.value} value={report.value}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </section>
            <section className="addressable-opportunity">
                {(isLoading || addressableOpportunityFilterLoader) && (
                    <Loader showBackground background="transparent" />
                )}
                {showResult ? (
                    <>
                        <span className="back-btn" onClick={goBack}>
                            <img src={backArrowIcon} alt="back-arrow-icon" /> Back
                        </span>

                        <OutputTable input={userInput} output={outputResult} />

                        <div className="output-graph">
                            <Card title={addressableOpportunityConst.chartHeading}>
                                {!fteChart ? (
                                    <Shimmer />
                                ) : (
                                    <HighchartsReact
                                        options={{
                                            ...graphConfig('FTE Opportunity'),
                                            series: [
                                                {
                                                    data: fteChart,
                                                },
                                            ],
                                            tooltip: {
                                                usHTML: true,
                                                formatter: function() {
                                                    return `${this.point.name} : ${
                                                        this.point.y < 0 ? '-' : ''
                                                    } $ ${numFormatter(Math.abs(this.point.y), 2)}`;
                                                },
                                            },
                                        }}
                                        highcharts={Highcharts}
                                    />
                                )}
                            </Card>
                        </div>
                        <div className="output-graph">
                            <Card title={''}>
                                {!costChart ? (
                                    <Shimmer />
                                ) : (
                                    <HighchartsReact
                                        options={{
                                            ...graphConfig('Cost'),
                                            series: [
                                                {
                                                    data: costChart,
                                                },
                                            ],
                                            tooltip: {
                                                usHTML: true,
                                                formatter: function() {
                                                    return `${this.point.name} : ${
                                                        this.point.y < 0 ? '-' : ''
                                                    } $ ${numFormatter(Math.abs(this.point.y), 2)}`;
                                                },
                                            },
                                        }}
                                        highcharts={Highcharts}
                                    />
                                )}
                            </Card>
                        </div>
                    </>
                ) : (
                    <>
                        <InputTable inputDetails={inputDetails} onInputChange={onInputChange} />
                        <div className={c('nextBtn', { enableBtn: !resultBtnDisable })} onClick={getModelResult}>
                            View Results
                        </div>
                    </>
                )}
            </section>
        </Box>
    );
}

export default AddressableOpportunity;
