import React from 'react';
import './index.scss';

const Progress = ({ width, color, type = 'edged' }) => {
    return (
        <div className="progress-bar">
            <div className={`progress-bar__count ${type}`} style={{ width: width, backgroundColor: color }} />
        </div>
    );
};

export default Progress;
