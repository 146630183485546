import React from 'react';
import { useDispatch } from 'react-redux';
import Shimmer from '../../../../components/shimmer';
import NoDataFound from '../../../../components/NoDataFound';
import executiveDashboard from '../../executiveDashboard.enum';
import formattedUtilities from '../../../../common/utilities';
import { toFixed } from '../../../../utils/functions';
import { pptJsonStructure } from '../../pptJsonStructure';
import './index.scss';

const Badge = ({ badge }) => <span className="value badge">{badge}</span>;

export default function Layers({ isFiltersApplied, isLoading, layers, fteCount, managers, currentSpan, idealSpan }) {
    const getWidth = (v1, v2) => {
        return (v1 / v2) * 100 + '%';
    };

    const getMaxDots = value => {
        const max = 10;
        return value > max ? max : value;
    };

    const getFraction = value => {
        return (value - Math.floor(value)) * 100;
    };

    const maxFte = Math.max(...layers.map(layer => layer.nonManagers));

    return isLoading ? (
        <div>
            <Shimmer />
        </div>
    ) : fteCount > 0 ? (
        <div className="avg_layers">
            <div className="tbl">
                <div className="row">
                    <div className="cell">Layers </div>
                    <div className="cell">Total FTEs </div>
                    <div className="cell thrice">Non-Managers </div>
                    <div className="cell thrice">Managers </div>
                    <div className="cell twice column">
                        Average Span per Manager
                        <div className="meta">
                            <span className="dot">
                                <span className="fill" />
                            </span>
                            <span>Current Span</span>
                            <span className="dot">
                                <span className="fill ideal" />
                            </span>
                            <span>Ideal Span</span>
                        </div>
                    </div>
                </div>
                {layers.map((layer, idx) => {
                    return (
                        <div key={`layer_${idx}`} className={`${layer.fteCount === 0 ? 'hidden' : ''} row`}>
                            <div className="cell">{idx + 1}</div>
                            <div className="cell">{toFixed(layer.fteCount)}</div>
                            <div className="cell thrice barholder right">
                                <span className="value">{toFixed(layer.nonManagers)}</span>
                                <span className="bar" style={{ width: getWidth(layer.nonManagers, maxFte) }}>
                                    &nbsp;
                                </span>
                            </div>
                            <div class="cell thrice barholder left">
                                <span className="bar" style={{ width: getWidth(layer.managers, maxFte) }}>
                                    &nbsp;
                                </span>
                                <span className="value">{toFixed(layer.managers)}</span>
                            </div>
                            <div className="cell twice spandots">
                                <div className="dotholder">
                                    <span className="value">
                                        {toFixed(formattedUtilities.floatIntValueFormatter(layer.currentSpan))}
                                    </span>
                                    {Array(getMaxDots(Math.floor(layer.currentSpan)))
                                        .fill(0)
                                        .map((el, index) => (
                                            <span key={`blu_${index}`} className="dot">
                                                <span className="fill">&nbsp;</span>
                                            </span>
                                        ))}
                                    {
                                        <span className="dot">
                                            <span
                                                className="fill"
                                                style={{ width: `${getFraction(layer.currentSpan)}%` }}
                                            >
                                                &nbsp;
                                            </span>
                                        </span>
                                    }
                                </div>
                                <div className="dotholder">
                                    <span className="value">
                                        {toFixed(formattedUtilities.floatIntValueFormatter(layer.idealSpan))}
                                    </span>
                                    {Array(getMaxDots(Math.floor(layer.idealSpan)))
                                        .fill(0)
                                        .map((el, index) => (
                                            <span key={`grn_${index}`} className="dot">
                                                <span className="fill ideal">&nbsp;</span>
                                            </span>
                                        ))}
                                    <span className="dot">
                                        <span
                                            className="fill ideal"
                                            style={{ width: `${getFraction(layer.idealSpan)}%` }}
                                        >
                                            &nbsp;
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {fteCount > 0 && (
                    <div class="row">
                        <div class="cell">Total </div>
                        <div class="cell">{toFixed(fteCount)} </div>
                        <div class="cell thrice barholder left">
                            <Badge badge={toFixed(fteCount - managers)} />
                            <span />
                        </div>
                        <div class="cell thrice barholder right">
                            <span />
                            <Badge badge={toFixed(managers)} />
                        </div>
                        <div class="cell twice spandots">
                            <Badge badge={toFixed(formattedUtilities.floatIntValueFormatter(currentSpan || 0))} />
                            <Badge badge={toFixed(formattedUtilities.floatIntValueFormatter(idealSpan || 0))} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <NoDataFound
            mainMessage={`${
                isFiltersApplied
                    ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                    : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
            }`}
            secondaryMessage={`${
                isFiltersApplied
                    ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                    : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
            }`}
        />
    );
}
