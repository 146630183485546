import { fromJS } from 'immutable';

import { GET_REPORT, GO_TO_INPUT, RESET_STATE, IS_INPUT_SCREEN } from './constants';

export const INITIAL_STATE = fromJS({
    loading: false,
    error: null,
    showResult: false,
    input: {},
    output: {},
    isInputScreen: true,
});

const reducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case `${GET_REPORT}_PENDING`: {
            return state
                .set('loading', true)
                .set('showResult', payload.showInput)
                .set('error', null)
                .set('input', payload.input);
        }
        case `${GET_REPORT}_SUCCESS`: {
            return state
                .set('output', payload)
                .set('loading', false)
                .set('showResult', true);
        }
        case `${GET_REPORT}_FAILED`: {
            return state.set('error', payload).set('loading', false);
        }
        case `${GO_TO_INPUT}`: {
            return state.set('showResult', false);
        }
        case `${IS_INPUT_SCREEN}`: {
            // this will be set to false when the user clicks "VIEW RESULTS"
            return state.set('isInputScreen', payload);
        }
        case `${RESET_STATE}`: {
            return state
                .set('loading', false)
                .set('error', null)
                .set('showResult', false)
                .set('input', {})
                .set('output', {});
        }
        default:
            return state;
    }
};

export default reducer;
