import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Table from '../../../../components/Table';

const DemandView = ({ columns, distributionTableData, distributionViewClickHandle }) => {
    const Styles = styled.div`
        height: calc(34vh - 20px);
        overflow-y: auto;

        table {
            border-spacing: 0;
            th,
            td {
                margin: 0;
                padding: 0.5rem;
                text-align: left;
            }
        }
    `;

    useEffect(() => {
        distributionViewClickHandle('demand');
    }, []);

    return (
        <Fragment>
            <div className="disclaimer">
                *Value shown here is the sum of demand at it's own level and teams below it
            </div>
            <Styles>
                <Table
                    className="demand-table"
                    columns={columns}
                    data={Object.values(JSON.parse(JSON.stringify(distributionTableData)))}
                />
            </Styles>
        </Fragment>
    );
};

export default DemandView;
