import axios from 'axios';
import AuthStore from '../../../common/AuthStore';
import endpoints from '../../../Endpoints';

// helper method to merge the slide state to the main state
// common method for both address-opp and exec-dashboard
// && s.identify_shape_value === slideState.identify_shape_value
export const mergeSlideStateToMainState = (STATE, slideState) => {
    STATE = {
        ...STATE,
        slides: STATE.slides.map(s => {
            if (s.slideIndex === 1 && s.identify_shape_value === slideState.identify_shape_value) {
                return slideState;
            }
            if (s.slideIndex === slideState.slideIndex && s.slideIndex !== 1) {
                return slideState;
            }
            return s;
        }),
    }; // end of map
    return STATE;
};

export const addSlideStateToMainState = (STATE, slideState, index) => {
    const prevSlides = STATE.slides;
    const startIndex = index || STATE.slides.length;
    prevSlides.splice(startIndex, 0, slideState)

    STATE = {
        ...STATE,
        slides: prevSlides,
    }; // end of map
    return STATE;
};

// this function is not the action, this only holds the logic to download the ppt
export const downloadPPT = async (fileName, state) => {
    try {
        // const { data: { id_token: token }, } = await axios.post(endpoints.pptGenerateLogin, {
        //     username: process.env.REACT_APP_PPT_USERNAME,
        //     password: process.env.REACT_APP_PPT_PASSWORD,
        // });
        const { accessToken } = AuthStore;
        const { data: { id_token: token },} = await axios.get(endpoints.pptGenerateLogin, {
            headers: {
                Authorization: accessToken,
            },
        });
        const mimetype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        const { data } = await axios.post(endpoints.pptGenerate, state, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const url = `data:${mimetype};base64,${data.FileData}`;
        // window.open(url, '_blank', 'noopener,noreferrer');

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.pptx`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
    } catch (error) {
        console.error(error);
    }
};
