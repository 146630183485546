import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import { downloadPPTAction } from '../PPTExport/actions';
import { downloadPPTAction } from '../PPTExport/actions/exec-dashboard';
// import pptReducer from '../PPTExport/reducer';

import ExecutiveDashboard from './ExecutiveDashboard';

import {
    clrManagerFilters,
    clrSelectedManager,
    getManagerFilters,
    getPrimaryFilters,
    getStructureCompare,
    setDesignProgressLeadersActiveStructures,
    setDesignProgressOverallActiveStructures,
    setDesignProgressFunctionsActiveStructures,
    setNewFilters,
    setStaticFilters,
    sortTable,
    setOrgshapeFunctionsData,
    setOrgshapeLeadersData,
} from '../StructureContent/actions';

import {
    getFilters,
    getPhaseStructureInfo,
    getStaticFiltersLoadedState,
    getStructure,
    loaderCount,
    selectDesignProgressEditMode,
    selectLoading,
    strCompare,
    strCompared,
    strSummary,
    totalCompleted,
    totalCount,
    getOrgshapeFunctionsData,
    getOrgshapeLeadersData,
} from '../StructureContent/selector';

import { selectLoginLoading, selectPrivileges } from '../Login/selector';
import {
    selectFilters,
    selectFiltersReadyToApply,
    selectFiltersToApply,
    selectFiltersTray,
    selectRefreshCount,
} from '../Filters/selectors/selector';
import { resetFilters, setFiltersToApply, updateFilters, setFiltersTray } from '../Filters/actions/actions';

const stateToProps = createStructuredSelector({
    isStaticFiltersLoaded: getStaticFiltersLoadedState(),
    isLoading: selectLoading(),
    phaseLoading: selectLoginLoading(),
    strCompareFetched: strCompared(),
    strCompareData: strCompare(),
    strSummaryData: strSummary(),
    loaderCount: loaderCount(),
    totalCount: totalCount(),
    totalCompleted: totalCompleted(),
    primaryFilters: getFilters(),
    privileges: selectPrivileges(),
    filtersToApply: selectFiltersToApply(),
    filtersTray: selectFiltersTray(),
    filterList: selectFilters(),
    isFiltersReadyToApply: selectFiltersReadyToApply(),
    structure: getStructure(),
    phaseStructureInfo: getPhaseStructureInfo(),
    designProgressEditMode: selectDesignProgressEditMode(),
    getOrgshapeFunctionsData: getOrgshapeFunctionsData(),
    getOrgshapeLeadersData: getOrgshapeLeadersData(),
    refreshCount: selectRefreshCount(),
    // pptReduxState: pptReducer(),
});

const dispatchToProps = {
    setNewFilters,
    sortTable,
    getPrimaryFilters,
    getManagerFilters,
    clrSelectedManager,
    getStructureCompare,
    clrManagerFilters,
    setStaticFilters,
    setFiltersToApply,
    setFiltersTray,
    updateFilters,
    resetFilters,
    setDesignProgressOverallActiveStructures,
    setDesignProgressLeadersActiveStructures,
    setDesignProgressFunctionsActiveStructures,
    downloadPPTAction,
    setOrgshapeFunctionsData,
    setOrgshapeLeadersData,
};

export default connect(stateToProps, dispatchToProps)(ExecutiveDashboard);
