/**
 * Used for exporting the addressable opportunity results table to excel file
 * @author Karan Kumar
 * @returns a map for the column names
 */
export const addressableOpportunityExcelColumns = {
    col_1_adjustment: 'Adjustment',
    col_2_rationale: 'Rationale',
    col_3_assumption: 'Assumption',
    col_4_fte_opp: 'FTE Opp.',
    col_5_cost_opp: 'Cost Opp.($M)',
};

/**
 * @author Karan Kumar
 * @param {number} value
 * @returns Cell string to be rendered in the excel file
 */
export const row_2_col_2 = value => {
    return `We address only ${value} of overspan people leaders b/c if managers can handle managing too many then no adjustment is necessary`;
};

/**
 * @author Karan Kumar
 * @param {number} value
 * @returns Cell string to be rendered in the excel file
 */
export const row_3_col_2 = value => {
    return `Remove the opportunity for managers in locations that only have ${value} manager and are not hidden players`;
};

/**
 * @author Karan Kumar
 * @param {number} value
 * @returns Cell string to be rendered in the excel file
 */
export const row_4_col_2 = value => {
    return `${value} of people leaders managing 1-2 are typically converted to doers`;
};
