import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import formattedUtilities from '../../../../common/utilities';
import { numFormatter } from '../../../../utils/functions';
import FTEIcon from '../../../../assets/fteIcon.svg';
import costIcon from '../../../../assets/costIcon.svg';
import managersIcon from '../../../../assets/managersIcon.svg';
import spanOfControlIcon from '../../../../assets/spanOfControlIcon.svg';
import opportunityIcon from '../../../../assets/opportunityIcon.svg';

const StatTitle = ({ title }) => (
    <div className="stat-box-title">
        <span>{title}</span>
    </div>
);
const StatBox = ({ title, value, icon, suffix = '', classes, isLoading }) => (
    <div className={`stat-box ${classes}`}>
        <div className="stat-icon-holder">
            <img src={icon} alt="stat-icon" />
        </div>
        <div className="stat-content">
            <span className="stat-title">{title}</span>
            <span className="stat-value">
                {isLoading ? <CircularProgress color={'theme'} size={20} /> : (value || 0) + suffix}
            </span>
        </div>
    </div>
);

const StatBox2 = ({ values, icon, classes, isLoading }) => (
    <div className={`stat-box ${classes}`}>
        <div className="stat-icon-holder">
            <img src={icon} alt="stat-icon" />
        </div>
        <div className="stat-content">
            {values.map(({ title, value, suffix = '' }, idx) => (
                <div key={`stat_${idx}`} className="stat-values">
                    <span className="stat-title">{title}</span>
                    <span className="stat-value">
                        {isLoading ? <CircularProgress color={'theme'} size={20} /> : (value || 0) + suffix}
                    </span>
                </div>
            ))}
        </div>
    </div>
);

export default function Stats({
    fteCount,
    managers,
    idealManagers,
    currentSpan,
    idealSpan,
    managerCost,
    nonManagerCost,
    managerOpportunity,
    costOpportunity,
    isLoading,
    countFilterValue,
}) {
    return (
        <>
            <div className="stats-holder one-third">
                <StatTitle title="Total" />
                <StatBox
                    title={`People (${countFilterValue})`}
                    value={formattedUtilities.floatIntValueFormatter(fteCount)}
                    icon={FTEIcon}
                    classes={'with-border'}
                    isLoading={isLoading}
                />
                <StatBox
                    title="Cost"
                    value={numFormatter(managerCost + nonManagerCost)}
                    icon={costIcon}
                    isLoading={isLoading}
                />
            </div>
            <div className="stats-holder one-half">
                <StatTitle title="Management" />
                <StatBox2
                    icon={managersIcon}
                    classes={'with-border'}
                    isLoading={isLoading}
                    values={[
                        { title: 'Managers', value: formattedUtilities.floatIntValueFormatter(managers) },
                        { title: 'Ideal Managers', value: formattedUtilities.floatIntValueFormatter(idealManagers) },
                    ]}
                />
                <StatBox
                    title="Manager Cost"
                    value={numFormatter(managerCost)}
                    icon={costIcon}
                    classes={'with-border'}
                    isLoading={isLoading}
                />
                <StatBox2
                    icon={spanOfControlIcon}
                    classes={'no-border'}
                    isLoading={isLoading}
                    values={[
                        { title: 'Current Span', value: formattedUtilities.floatIntValueFormatter(currentSpan, true) },
                        { title: 'Ideal Span', value: formattedUtilities.floatIntValueFormatter(idealSpan, true) },
                    ]}
                />
            </div>
            <div className="stats-holder one-third">
                <StatTitle title="Management Opportunity" />
                <StatBox2
                    icon={opportunityIcon}
                    classes={'no-border'}
                    isLoading={isLoading}
                    values={[
                        {
                            title: `Managers (${countFilterValue})`,
                            value: managerOpportunity ? numFormatter(managerOpportunity) : '0.0',
                        },
                        { title: 'Cost', value: costOpportunity ? numFormatter(costOpportunity) : '0.0' },
                    ]}
                />
            </div>
        </>
    );
}
