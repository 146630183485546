import React from 'react';
import PropTypes from 'prop-types';

export const InfoIcon = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g id="v2---Updated-Design-here" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="04-" transform="translate(-209.000000, -357.000000)">
                <g id="info-24px-copy-2" transform="translate(209.000000, 357.000000)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                    <path
                        d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,9 L11,9 L11,7 L13,7 L13,9 Z"
                        id="Shape"
                        fill="#2F3A4D"
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const SortIcon = ({ fillInner = '#a3a3a3' }) => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="v2---Updated-Design-here" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="17" transform="translate(-61.000000, -550.000000)">
                <g id="Group-5" transform="translate(-2.000000, 232.000000)">
                    <g id="Group-4" transform="translate(0.000000, 295.000000)">
                        <g id="swap_vert-24px" transform="translate(63.000000, 23.000000)">
                            <path
                                d="M13.3333333,14.175 L13.3333333,8.33333333 L11.6666667,8.33333333 L11.6666667,14.175 L9.16666667,14.175 L12.5,17.5 L15.8333333,14.175 L13.3333333,14.175 L13.3333333,14.175 Z M7.5,2.5 L4.16666667,5.825 L6.66666667,5.825 L6.66666667,11.6666667 L8.33333333,11.6666667 L8.33333333,5.825 L10.8333333,5.825 L7.5,2.5 Z"
                                id="Shape"
                                fill={fillInner}
                            />
                            <polygon id="Path" points="0 0 20 0 20 20 0 20" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

SortIcon.propTypes = {
    fillInner: PropTypes.string,
};

SortIcon.defaultProps = {
    fillInner: '#a3a3a3',
};
