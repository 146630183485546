import { createSelector } from 'reselect';

const structures = state => state.get('structuresRedux');

const selectLoading = () => createSelector(structures, state => state.toJS().isLoading);
const getStaticFiltersLoadedState = () => createSelector(structures, state => state.toJS().isStaticFiltersLoaded);
const loaderCount = () => createSelector(structures, state => state.toJS().count);
const totalCount = () => createSelector(structures, state => state.toJS().totalCount);
const totalCompleted = () => createSelector(structures, state => state.toJS().totalCompleted);
const selectStructures = () => createSelector(structures, state => state.toJS().structures);
const strCompared = () => createSelector(structures, state => state.toJS().strCompFetched);
const strSummaryFetched = () => createSelector(structures, state => state.toJS().strSummaryFetched);
const strCompare = () => createSelector(structures, state => state.toJS().structureCompare);
const strSummary = () => createSelector(structures, state => state.toJS().structureSummary);
const getFilters = () => createSelector(structures, state => state.toJS().primaryFilters);
const fetchReportSummary = () => createSelector(structures, state => state.toJS().reportSummary);
const getStructure = () => createSelector(structures, state => state.toJS().structure);
const getPhaseStructureInfo = () => createSelector(structures, state => state.toJS().phaseStructureInfo);
const selectDesignProgressEditMode = () => createSelector(structures, state => state.toJS().isDesignProgressInEditMode);
const selectDesignProgressOverallActiveStructures = () =>
    createSelector(structures, state => state.toJS().designProgressOverallActiveStructures);
const selectDesignProgressOverallRanges = () =>
    createSelector(structures, state => state.toJS().designProgressOverallRanges);
const getSelectedTargets = () => createSelector(structures, state => state.toJS().targets);
const selectDesignProgressLeadersActiveStructures = () =>
    createSelector(structures, state => state.toJS().designProgressLeadersActiveStructures);
const selectDesignProgressFunctionsActiveStructures = () =>
    createSelector(structures, state => state.toJS().designProgressFunctionsActiveStructures);
const selectDesignProgressLeadersRanges = () =>
    createSelector(structures, state => state.toJS().designProgressLeadersRanges);
const selectDesignProgressFunctionsRanges = () =>
    createSelector(structures, state => state.toJS().designProgressFunctionsRanges);

const selectDesignProgressState = () => createSelector(structures, state => state.toJS().designProgressState);
const getOrgshapeFunctionsData = () => createSelector(structures, state => state.toJS().orgShapeFunctionsData);
const getOrgshapeLeadersData = () => createSelector(structures, state => state.toJS().orgShapeN1LeadersData);

export {
    selectLoading,
    loaderCount,
    selectStructures,
    strCompared,
    strSummaryFetched,
    strCompare,
    strSummary,
    totalCompleted,
    totalCount,
    getFilters,
    fetchReportSummary,
    getStaticFiltersLoadedState,
    getStructure,
    getPhaseStructureInfo,
    selectDesignProgressEditMode,
    selectDesignProgressOverallActiveStructures,
    selectDesignProgressOverallRanges,
    getSelectedTargets,
    selectDesignProgressLeadersActiveStructures,
    selectDesignProgressFunctionsActiveStructures,
    selectDesignProgressLeadersRanges,
    selectDesignProgressFunctionsRanges,
    selectDesignProgressState,
    getOrgshapeFunctionsData,
    getOrgshapeLeadersData,
};
