import React, { Fragment } from 'react';
import executiveDashboard from '../../../executiveDashboard.enum';
import Shimmer from '../../../../../components/shimmer';
import { CostChart } from '../../../../DesignPage/charts/ChartComp';
import { costChartConfig } from '../../../../DesignPage/constants';
import NoDataFound from '../../../../../components/NoDataFound';

const Cost = props => {
    const { isLoading, cost, isFiltersApplied } = props;
    const { totalCost, totalICCost, totalManagerCost, totalManagerContractorCost, totalICContractorCost } = cost;
    return (
        <Fragment>
            <div className="execDashboard-reports__label">{executiveDashboard.TOTAL_COST}</div>
            {isLoading ? (
                <Shimmer />
            ) : totalCost ? (
                <CostChart
                    costchartConfig={{
                        ...costChartConfig,
                        chart: {
                            type: 'bar',
                            height: 350,
                        },
                        credits: {
                            enabled: false,
                        },
                    }}
                    yAxis={{
                        opposite: true,
                        offset: 0,
                        title: {
                            text: 'In million',
                        },
                    }}
                    xAxis={{
                        categories: [''],
                    }}
                    callback={function(chart) {
                        chart.renderer
                            .path(['M', 0, 140, 'L', chart.chartWidth, 140])
                            .attr({
                                'stroke-width': 2,
                                stroke: '#d5d5d5',
                                'stroke-dasharray': '5,5',
                            })
                            .add();
                    }}
                    seriesData={[
                        {
                            name: executiveDashboard.TOTAL_COST,
                            data: [totalCost],
                            color: '#000000',
                            showInLegend: false,
                            pointWidth: 30,
                            borderRadius: 15,
                            stack: executiveDashboard.TOTAL_COST,
                            className: `total-cost-bar ${totalCost ? '' : 'hide'}`,
                            cost: (Math.abs(Number(totalCost)) / 1.0e6).toFixed(1),
                            dataLabels: [
                                {
                                    useHTML: true,
                                    enabled: true,
                                    align: 'left',
                                    inside: true,
                                    x: 10,
                                    color: '#FFFFFF',
                                    formatter: function() {
                                        return `<span class='execDashboard-reports__costChartLabel'>${
                                            executiveDashboard.TOTAL_COST
                                        } : $ ${(Math.abs(Number(this.point.y)) / 1.0e6).toFixed(1)} M</span>`;
                                    },
                                },
                            ],
                        },
                        {
                            name: executiveDashboard.MANAGER_COST,
                            data: [totalManagerCost - totalManagerContractorCost],
                            color: '#a9cce7',
                            showInLegend: false,
                            className: `${totalManagerCost - totalManagerContractorCost ? '' : 'hide'}`,
                            stack: executiveDashboard.MANAGER_COST,
                            pointWidth: 25,
                            cost: (Math.abs(Number(totalManagerCost - totalManagerContractorCost)) / 1.0e6).toFixed(1),
                            borderRadiusTopLeft: 12,
                            borderRadiusTopRight: 12,
                            borderRadiusBottomLeft: totalManagerContractorCost ? 0 : 12,
                            borderRadiusBottomRight: totalManagerContractorCost ? 0 : 12,
                            dataLabels: [
                                {
                                    useHTML: true,
                                    enabled: !totalManagerContractorCost,
                                    align: 'left',
                                    inside: true,
                                    color: '#000000',
                                    y: -20,
                                    formatter: function() {
                                        return `<span class="execDashboard-reports__costChartLabel">${
                                            executiveDashboard.MANAGER_COST
                                        } : $ ${(Math.abs(Number(totalManagerCost)) / 1.0e6).toFixed(1)} M</span>`;
                                    },
                                },
                            ],
                        },
                        {
                            name: executiveDashboard.MANAGER_CONTRACTOR_COST,
                            data: [totalManagerContractorCost],
                            color: '#083d52',
                            showInLegend: false,
                            stack: executiveDashboard.MANAGER_COST,
                            className: `${totalManagerContractorCost ? '' : 'hide'}`,
                            cost: (
                                (Math.abs(Number(totalManagerCost)) / 1.0e6).toFixed(1) -
                                (Math.abs(Number(totalManagerCost - totalManagerContractorCost)) / 1.0e6).toFixed(1)
                            ).toFixed(1),
                            pointWidth: 25,
                            borderRadiusTopLeft: totalManagerCost - totalManagerContractorCost ? 0 : 12,
                            borderRadiusTopRight: totalManagerCost - totalManagerContractorCost ? 0 : 12,
                            borderRadiusBottomLeft: 12,
                            borderRadiusBottomRight: 12,
                            dataLabels: [
                                {
                                    useHTML: true,
                                    enabled: true,
                                    align: 'left',
                                    inside: true,
                                    color: '#000000',
                                    y: -20,
                                    formatter: function() {
                                        return `<span class='execDashboard-reports__costChartLabel'>${
                                            executiveDashboard.MANAGER_COST
                                        } : $ ${(Math.abs(Number(totalManagerCost)) / 1.0e6).toFixed(1)} M</span>`;
                                    },
                                },
                            ],
                        },
                        {
                            name: executiveDashboard.INDIVIDUAL_CONTRIBUTORS_COST,
                            data: [totalICCost - totalICContractorCost],
                            color: '#8dc541',
                            showInLegend: false,
                            className: `${totalICCost - totalICContractorCost ? '' : 'hide'}`,
                            pointWidth: 25,
                            borderRadiusTopLeft: 12,
                            borderRadiusTopRight: 12,
                            cost: (Math.abs(Number(totalICCost - totalICContractorCost)) / 1.0e6).toFixed(1),
                            borderRadiusBottomLeft: totalICContractorCost ? 0 : 12,
                            borderRadiusBottomRight: totalICContractorCost ? 0 : 12,
                            stack: executiveDashboard.INDIVIDUAL_CONTRIBUTORS_COST,
                            dataLabels: [
                                {
                                    useHTML: true,
                                    enabled: !totalICContractorCost,
                                    align: 'left',
                                    inside: true,
                                    color: '#000000',
                                    y: -20,
                                    formatter: function() {
                                        return `<span class="execDashboard-reports__costChartLabel">${
                                            executiveDashboard.INDIVIDUAL_CONTRIBUTORS_COST
                                        } : $ ${(Math.abs(Number(totalICCost)) / 1.0e6).toFixed(1)} M</span>`;
                                    },
                                },
                            ],
                        },
                        {
                            name: executiveDashboard.INDIVIDUAL_CONTRIBUTORS_CONTRACTOR_COST,
                            data: [totalICContractorCost],
                            color: '#083d52',
                            showInLegend: false,
                            className: `${totalICContractorCost ? '' : 'hide'}`,
                            stack: executiveDashboard.INDIVIDUAL_CONTRIBUTORS_COST,
                            pointWidth: 25,
                            cost: (
                                (Math.abs(Number(totalICCost)) / 1.0e6).toFixed(1) -
                                (Math.abs(Number(totalICCost - totalICContractorCost)) / 1.0e6).toFixed(1)
                            ).toFixed(1),
                            borderRadiusTopLeft: totalICCost - totalICContractorCost ? 0 : 12,
                            borderRadiusTopRight: totalICCost - totalICContractorCost ? 0 : 12,
                            borderRadiusBottomLeft: 12,
                            borderRadiusBottomRight: 12,
                            dataLabels: [
                                {
                                    useHTML: true,
                                    enabled: true,
                                    align: 'left',
                                    inside: true,
                                    color: '#000000',
                                    y: -20,
                                    formatter: function() {
                                        return `<span class='execDashboard-reports__costChartLabel'>${
                                            executiveDashboard.INDIVIDUAL_CONTRIBUTORS_COST
                                        } : $ ${(Math.abs(Number(totalICCost)) / 1.0e6).toFixed(1)} M</span>`;
                                    },
                                },
                            ],
                        },
                        {
                            name: executiveDashboard.MANAGER_COST,
                            data: [totalManagerCost],
                            className: 'hide',
                            showInLegend: false,
                            stack: executiveDashboard.INDIVIDUAL_CONTRIBUTORS_COST,
                        },
                    ]}
                    plotOptions={{
                        bar: {
                            stacking: 'normal',
                        },
                    }}
                    tooltip={{
                        useHTML: true,
                        followPointer: false,
                        formatter: function() {
                            return `<span class="execDashboard-reports__tooltip">${this.point.series.name} - ${this.point.series.options.cost} M</span>`;
                        },
                    }}
                    legends={[
                        {
                            data: [
                                { color: '#a9cce7', name: executiveDashboard.MANAGERS },
                                { color: '#8dc541', name: executiveDashboard.INDIVIDUAL_CONTRIBUTORS },
                                { color: '#083d52', name: executiveDashboard.CONTRACTORS },
                            ],
                        },
                    ]}
                />
            ) : (
                <div className="costchart" style={{ padding: '75px 20px' }}>
                    <NoDataFound
                        mainMessage={`${
                            isFiltersApplied
                                ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                                : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                        }`}
                        secondaryMessage={`${
                            isFiltersApplied
                                ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                                : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                        }`}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default Cost;
