import React, { Component, Fragment } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './SummaryReports.scss';
import { CostChart } from '../../../DesignPage/charts/ChartComp';
import { costChartConfig } from '../../../DesignPage/constants';
import executiveDashboard from '../../executiveDashboard.enum';
import Tabs from '../../../../components/Tabs';
import Tab from '../../../../components/Tabs/Tab';
import formattedUtilities from '../../../../common/utilities';
import Shimmer from '../../../../components/shimmer';
import Cost from './Cost';
import FTES from './FTEs';
import N1Leaders from './N1Leaders';
import Locations from './Locations';
import NoDataFound from '../../../../components/NoDataFound';

const colorCodes = ['#bbd6e9', '#a9cce7', '#6e879b', '#466982', '#083d52', '#8dc541', '#46651f', '#2c3e14', '#1610e0'];
class SummaryReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subFunctionsSeriesData: [],
            functionsToolTipMap: {},
        };
        this.setSubFunctionsMap = this.setSubFunctionsMap.bind(this);
        this.resetSubFunctionsMap = this.resetSubFunctionsMap.bind(this);
        this.setExecutiveN1Managers = this.setExecutiveN1Managers.bind(this);
        this.setExecutiveFunctions = this.setExecutiveFunctions.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.executiveFunctions) !== JSON.stringify(prevProps.executiveFunctions)) {
            this.setState({
                subFunctionsSeriesData: [],
                functionsToolTipMap: {},
            });
        }
    }

    setExecutiveFunctions() {
        const { executiveFunctions } = this.props;
        const executiveFunctionsData = { seriesData: [], drilldownData: [] };
        executiveFunctions.functions.forEach((execFunction, index) => {
            executiveFunctionsData.seriesData.push({
                name: execFunction.function,
                span: execFunction.span,
                y: execFunction.fte,
                color: colorCodes[index] || colorCodes[0],
                label: execFunction.span,
                pointWidth: 50,
                subFunctions: execFunction.subFunctions,
            });
        });
        return executiveFunctionsData;
    }

    setExecutiveN1Managers() {
        const { executiveN1Managers } = this.props;
        const executiveN1ManagersData = { seriesData: [] };
        executiveN1Managers.managers.forEach(execManager => {
            executiveN1ManagersData.seriesData.push({
                name: execManager.rolename,
                empname: execManager.empname,
                y: execManager.filter_fte,
                color: '#083d52',
                label: execManager.total_span,
            });
        });

        return executiveN1ManagersData;
    }

    setSubFunctionsMap(name) {
        if (!this.state.functionsToolTipMap[name]) {
            this.setState(() => ({ functionsToolTipMap: { [name]: name } }));
        }
    }

    resetSubFunctionsMap(name) {
        if (this.state.functionsToolTipMap[name]) {
            this.setState(state => ({
                functionsToolTipMap: { activeSubFunctions: state.functionsToolTipMap.activeSubFunctions },
            }));
        }
    }

    showSubFunctions(name) {
        if (!this.state.functionsToolTipMap.activeSubFunctions) {
            const selectedFunction = this.setExecutiveFunctions().seriesData.filter(elem => elem.name === name);
            let subFunctionsSeriesData = selectedFunction[0].subFunctions.map(elem => {
                return {
                    name: elem.function,
                    y: elem.fte,
                    color: '#bbd5e9',
                    pointWidth: 30,
                    isSubFunction: true,
                    span: elem.span,
                };
            });
            subFunctionsSeriesData = [selectedFunction[0], ...subFunctionsSeriesData];
            this.setState(state => ({
                functionsToolTipMap: { ...state.functionsToolTipMap, activeSubFunctions: name },
                subFunctionsSeriesData,
            }));
        }
    }

    hideSubFunctions() {
        if (this.state.functionsToolTipMap.activeSubFunctions) {
            this.setState(() => ({
                functionsToolTipMap: {},
                subFunctionSeriesData: [],
            }));
        }
    }

    render() {
        const _this = this;
        const { cost, ftes } = this.props.reportSummary;
        const isFiltersApplied = Object.keys(this.props.filtersApplied).length !== 0;

        return (
            <div className="execDashboard-reports">
                <div className="execDashboard-reports__left-view">
                    <div className="execDashboard-reports__charts">
                        <div className="total-fte-pieChart">
                            <FTES
                                isLoading={this.props.isLoading}
                                ftes={ftes}
                                countFilter={this.props.countFilter}
                                isFiltersApplied={isFiltersApplied}
                            />
                        </div>
                        <div className="total-cost-progress">
                            <Cost isLoading={this.props.isLoading} cost={cost} isFiltersApplied={isFiltersApplied} />
                        </div>
                    </div>
                </div>
                <div className="execDashboard-reports__right-view">
                    <div className="execDashboard-reports__charts">
                        <div className="execDashboard-reports__label">
                            {executiveDashboard.AVG_SPAN_AND} {_this.props.countFilter}
                        </div>
                        <div style={{ padding: '15px', border: '1px solid #b5c5ca', margin: '0 15px' }}>
                            {' '}
                            <Tabs selected={0} key={this.props.structureId}>
                                <Tab title={executiveDashboard.N1_LEADERS}>
                                    <N1Leaders
                                        countFilter={_this.props.countFilter}
                                        executiveN1Managers={this.props.executiveN1Managers}
                                    />
                                    <section
                                        className="execDashboard-reports__N1-managers"
                                        key={executiveDashboard.N1_LEADERS}
                                        style={{ display: 'none' }}
                                    >
                                        <div className="title__functions">Total {_this.props.countFilter}</div>
                                        <div className="execDashboard-reports__N1-manager-chart">
                                            {this.props.executiveN1Managers.isLoading ? (
                                                <Shimmer />
                                            ) : this.setExecutiveN1Managers().seriesData.length ? (
                                                <>
                                                    <CostChart
                                                        key={executiveDashboard.N1_LEADERS}
                                                        costchartConfig={{
                                                            ...costChartConfig,
                                                            chart: {
                                                                type: 'column',
                                                                height: 400,
                                                                width:
                                                                    this.setExecutiveN1Managers().seriesData.length *
                                                                        100 <
                                                                    0.85 * document.body.clientWidth
                                                                        ? 0.85 * document.body.clientWidth
                                                                        : this.setExecutiveN1Managers().seriesData
                                                                              .length * 100,
                                                            },
                                                            credits: {
                                                                enabled: false,
                                                            },
                                                            title: {
                                                                text: '',
                                                            },
                                                        }}
                                                        yAxis={{
                                                            title: {
                                                                text: '',
                                                                useHTML: true,
                                                                margin: 30,
                                                            },
                                                            max:
                                                                Math.max.apply(
                                                                    Math,
                                                                    this.setExecutiveN1Managers().seriesData.map(
                                                                        function(o) {
                                                                            return o.y;
                                                                        }
                                                                    )
                                                                ) * 1.1,
                                                        }}
                                                        seriesData={[
                                                            {
                                                                borderRadiusTopLeft: '20%',
                                                                borderRadiusTopRight: '20%',
                                                                showInLegend: false,
                                                                data: this.setExecutiveN1Managers().seriesData,
                                                                dataLabels: [
                                                                    {
                                                                        useHTML: true,
                                                                        enabled: true,
                                                                        align: 'left',
                                                                        color: '#000000',
                                                                        x: 10,
                                                                        formatter: function() {
                                                                            return `<span>${formattedUtilities.floatIntValueFormatter(
                                                                                this.point.y
                                                                            )}</span>`;
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        ]}
                                                        xAxis={{
                                                            categories: this.setExecutiveN1Managers().seriesData.map(
                                                                function(fn) {
                                                                    return {
                                                                        name: fn.name,
                                                                        span: fn.label,
                                                                        empname: fn.empname,
                                                                    };
                                                                }
                                                            ),
                                                            labels: {
                                                                useHTML: true,
                                                                formatter: function() {
                                                                    return `<div class="data-label">
                                                                        <div class="data-label__text" 
                                                                                style="height: 50px;    
                                                                                ">
                                                                           
                                                                            <div title=  ${
                                                                                this.value.name
                                                                                    ? this.value.name.replace(
                                                                                          /[ ]/g,
                                                                                          '\u00a0'
                                                                                      )
                                                                                    : ''
                                                                            }
                                                                            style="overflow: hidden;
                                                                                 cursor: hand;
                                                                                display: -webkit-box;
                                                                                width: 100%;
                                                                                -webkit-line-clamp: 2;
                                                                                -webkit-box-orient: vertical;">${
                                                                                    this.value.name !== 'Unknown'
                                                                                        ? this.value.name
                                                                                        : ''
                                                                                }</div>
                                                               
                                                                            <div title= ${
                                                                                this.value.empname
                                                                                    ? this.value.empname.replace(
                                                                                          /[ ]/g,
                                                                                          '\u00a0'
                                                                                      )
                                                                                    : ''
                                                                            } style="overflow: hidden;
                                                                                display: -webkit-box;
                                                                                -webkit-line-clamp: 2;
                                                                                -webkit-box-orient: vertical;">${
                                                                                    this.value.empname
                                                                                        ? '(' + this.value.empname + ')'
                                                                                        : ''
                                                                                }</div>
                                                                        </div>
                                                                        <div class="data-label__span">${parseFloat(
                                                                            this.value.span || 0
                                                                        ).toFixed(1)}</div>
                                                                    </div>`;
                                                                },
                                                            },
                                                        }}
                                                        plotOptions={{
                                                            series: {
                                                                groupPadding: 0,
                                                                pointWidth: 50,
                                                                dataLabels: {
                                                                    enabled: true,
                                                                },
                                                            },
                                                        }}
                                                        tooltip={{
                                                            useHTML: true,
                                                            shadow: false,
                                                            enabled: false,
                                                            borderWidth: 0,
                                                            followPointer: false,
                                                            backgroundColor: 'rgba(0,0,0,0)',
                                                            formatter: function() {
                                                                return `<div class="execDashboard-reports__bar-tooltip"><div>${
                                                                    this.point.name
                                                                }</div><div>FTEs: ${formattedUtilities.floatIntValueFormatter(
                                                                    this.point.y
                                                                )}</div></div>`;
                                                            },
                                                        }}
                                                        legends={[]}
                                                    />

                                                    <div className="legendContainer">
                                                        <div className="legendGroups ">
                                                            <div className="legends">
                                                                <div
                                                                    className="circle"
                                                                    style={{
                                                                        background: 'rgb(206, 215, 219)',
                                                                    }}
                                                                />
                                                                <div>{executiveDashboard.SPAN_OF_CONTROL}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <NoDataFound
                                                    mainMessage={`${
                                                        isFiltersApplied
                                                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                                                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                                                    }`}
                                                    secondaryMessage={`${
                                                        isFiltersApplied
                                                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                                                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                                                    }`}
                                                />
                                            )}
                                        </div>
                                    </section>
                                </Tab>
                                <Tab title={executiveDashboard.FUNCTIONS}>
                                    <section
                                        className="execDashboard-reports__functions"
                                        key={executiveDashboard.FUNCTIONS}
                                    >
                                        <div className="title__functions">Total {_this.props.countFilter}</div>
                                        <div className="execDashboard-reports__functions-chart">
                                            {this.props.executiveFunctions.isLoading ? (
                                                <Shimmer />
                                            ) : this.setExecutiveFunctions().seriesData.length ? (
                                                <Fragment>
                                                    <div
                                                        className={`execDashboard-reports__mainFunctions ${
                                                            _this.state.functionsToolTipMap.activeSubFunctions
                                                                ? 'hide'
                                                                : ''
                                                        }`}
                                                    >
                                                        <CostChart
                                                            key={executiveDashboard.FUNCTIONS}
                                                            costchartConfig={{
                                                                ...costChartConfig,
                                                                chart: {
                                                                    type: 'column',
                                                                    height: 400,
                                                                    className: 'executive-functions',
                                                                    width:
                                                                        this.setExecutiveFunctions().seriesData.length *
                                                                            100 <
                                                                        0.85 * document.body.clientWidth
                                                                            ? 0.85 * document.body.clientWidth
                                                                            : this.setExecutiveFunctions().seriesData
                                                                                  .length * 100,
                                                                },
                                                                credits: {
                                                                    enabled: false,
                                                                },
                                                                title: {
                                                                    text: '',
                                                                },
                                                            }}
                                                            seriesData={[
                                                                {
                                                                    name: executiveDashboard.FUNCTIONS,
                                                                    borderRadiusTopLeft: '20%',
                                                                    borderRadiusTopRight: '20%',
                                                                    data: this.setExecutiveFunctions().seriesData,
                                                                    showInLegend: false,
                                                                    dataLabels: [
                                                                        {
                                                                            align: 'top',
                                                                            useHTML: true,
                                                                            formatter: function() {
                                                                                window.setSubFunctionsMap = function(
                                                                                    name
                                                                                ) {
                                                                                    _this.setSubFunctionsMap(name);
                                                                                };
                                                                                window.resetSubFunctionsMap = function(
                                                                                    name
                                                                                ) {
                                                                                    _this.resetSubFunctionsMap(name);
                                                                                };
                                                                                window.showSubFunctions = function(
                                                                                    name
                                                                                ) {
                                                                                    _this.showSubFunctions(name);
                                                                                };
                                                                                return `<div class="viewSubFunctions">${
                                                                                    this.point.subFunctions
                                                                                        ? `<div class='viewSubFunctions__icon' onclick = "showSubFunctions('${
                                                                                              this.point.name
                                                                                          }')" onmouseover="setSubFunctionsMap('${
                                                                                              this.point.name
                                                                                          }')"  onmouseout="resetSubFunctionsMap('${
                                                                                              this.point.name
                                                                                          }')">${
                                                                                              _this.state
                                                                                                  .functionsToolTipMap[
                                                                                                  this.point.name
                                                                                              ]
                                                                                                  ? `<div class='viewSubFunctions__tooltip'>
                                                                                          View Sub Functions
                                                                                      </div>`
                                                                                                  : ''
                                                                                          }<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="
    transform: rotate(45deg);
"><path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"></path></svg></div>`
                                                                                        : ''
                                                                                }<div>${formattedUtilities.floatIntValueFormatter(
                                                                                    this.point.y
                                                                                )}</div></div>`;
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            ]}
                                                            xAxis={{
                                                                categories: this.setExecutiveFunctions().seriesData.map(
                                                                    function(fn) {
                                                                        return { name: fn.name, span: fn.span };
                                                                    }
                                                                ),
                                                                labels: {
                                                                    useHTML: true,
                                                                    formatter: function() {
                                                                        return `<div class="data-label">
                                                                        <div class="data-label__text" title=  ${
                                                                            this.value.name
                                                                                ? this.value.name.replace(
                                                                                      /[ ]/g,
                                                                                      '\u00a0'
                                                                                  )
                                                                                : ''
                                                                        }
                                                                            style="overflow: hidden;
                                                                                 cursor: hand;
                                                                                display: -webkit-box;
                                                                                width: 100%;
                                                                                -webkit-line-clamp: 3;
                                                                                -webkit-box-orient: vertical;">${
                                                                                    this.value.name
                                                                                }</div>
                                                                                ${
                                                                                    this.value.name !== 'UNKNOWN'
                                                                                        ? `<div class="data-label__span">${parseFloat(
                                                                                              this.value.span
                                                                                          ).toFixed(1)}</div>`
                                                                                        : ''
                                                                                }
                                                                        
                                                                    </div>`;
                                                                    },
                                                                },
                                                            }}
                                                            yAxis={{
                                                                title: {
                                                                    text: '',
                                                                },
                                                                max:
                                                                    Math.max.apply(
                                                                        Math,
                                                                        this.setExecutiveFunctions().seriesData.map(
                                                                            function(o) {
                                                                                return o.y;
                                                                            }
                                                                        )
                                                                    ) * 1.2,
                                                            }}
                                                            plotOptions={{
                                                                series: {
                                                                    dataLabels: {
                                                                        enabled: true,
                                                                        crop: false,
                                                                        overflow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                            tooltip={{
                                                                enabled: false,
                                                                useHTML: true,
                                                                followPointer: false,
                                                                shadow: false,
                                                                borderWidth: 0,
                                                                backgroundColor: 'rgba(0,0,0,0)',
                                                                formatter: function() {
                                                                    return `<div class="execDashboard-reports__bar-tooltip"><div>${
                                                                        this.point.name
                                                                    }</div><div>FTEs: ${formattedUtilities.floatIntValueFormatter(
                                                                        this.point.y
                                                                    )}</div></div>`;
                                                                },
                                                                style: {
                                                                    zIndex: 100,
                                                                },
                                                            }}
                                                            legends={[]}
                                                        />
                                                    </div>
                                                    {_this.state.functionsToolTipMap.activeSubFunctions && (
                                                        <div className="execDashboard-reports__subFunctions">
                                                            <CostChart
                                                                key={executiveDashboard.N1_LEADERS}
                                                                costchartConfig={{
                                                                    ...costChartConfig,
                                                                    chart: {
                                                                        type: 'column',
                                                                        height: 400,
                                                                        className: 'executive-functions',
                                                                        width:
                                                                            this.state.subFunctionsSeriesData.length *
                                                                                100 <
                                                                            0.85 * document.body.clientWidth
                                                                                ? 0.85 * document.body.clientWidth
                                                                                : this.state.subFunctionsSeriesData
                                                                                      .length * 100,
                                                                    },
                                                                    credits: {
                                                                        enabled: false,
                                                                    },
                                                                    title: {
                                                                        text: '',
                                                                    },
                                                                }}
                                                                callback={function(chart) {
                                                                    return chart.renderer
                                                                        .rect(
                                                                            chart.series[0].data[0].plotX + 100,
                                                                            10,
                                                                            chart.clipBox.width,
                                                                            chart.clipBox.height
                                                                        )
                                                                        .attr({
                                                                            fill: '#f4f9fd',
                                                                            stroke: '#f4f9fd',
                                                                            'stroke-width': 2,
                                                                        })
                                                                        .add();
                                                                }}
                                                                seriesData={[
                                                                    {
                                                                        borderRadiusTopLeft: '20%',
                                                                        borderRadiusTopRight: '20%',
                                                                        showInLegend: false,
                                                                        data: this.state.subFunctionsSeriesData,
                                                                        dataLabels: [
                                                                            {
                                                                                enabled: true,
                                                                                useHTML: true,
                                                                                align: 'top',
                                                                                x: -20,
                                                                                formatter: function() {
                                                                                    return `<span class="subFunction__fte-label">${formattedUtilities.floatIntValueFormatter(
                                                                                        this.point.y
                                                                                    )}</span>`;
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                ]}
                                                                yAxis={{
                                                                    title: {
                                                                        text: '',
                                                                    },
                                                                    max:
                                                                        Math.max.apply(
                                                                            Math,
                                                                            this.state.subFunctionsSeriesData.map(
                                                                                function(o) {
                                                                                    return o.y;
                                                                                }
                                                                            )
                                                                        ) * 1.2,
                                                                }}
                                                                plotOptions={{
                                                                    series: {
                                                                        groupPadding: 0,
                                                                        pointWidth: 50,
                                                                        dataLabels: {
                                                                            enabled: true,
                                                                            rotation: 270,
                                                                            crop: false,
                                                                        },
                                                                    },
                                                                }}
                                                                xAxis={{
                                                                    categories: this.state.subFunctionsSeriesData.map(
                                                                        function(fn) {
                                                                            return {
                                                                                name: fn.name,
                                                                                isSubFunction: fn.isSubFunction,
                                                                                span: fn.span,
                                                                            };
                                                                        }
                                                                    ),
                                                                    labels: {
                                                                        useHTML: true,
                                                                        formatter: function() {
                                                                            if (this.value.isSubFunction) {
                                                                                return `<div class="data-label">
                                                                            <div class="data-label__text" title=  ${
                                                                                this.value.name
                                                                                    ? this.value.name.replace(
                                                                                          /[ ]/g,
                                                                                          '\u00a0'
                                                                                      )
                                                                                    : ''
                                                                            }
                                                                            style="overflow: hidden;
                                                                                 cursor: hand;
                                                                                display: -webkit-box;
                                                                                width: 100%;
                                                                                -webkit-line-clamp: 3;
                                                                                -webkit-box-orient: vertical;">${
                                                                                    this.value.name
                                                                                }</div>${
                                                                                    this.value.name !== 'UNKNOWN'
                                                                                        ? `<div class="data-label__span">${parseFloat(
                                                                                              this.value.span
                                                                                          ).toFixed(1)}</div>`
                                                                                        : ''
                                                                                }</div>`;
                                                                            }
                                                                            return `<div class="data-label">
                                                                          <div class="data-label__text" title=  ${
                                                                              this.value.name
                                                                                  ? this.value.name.replace(
                                                                                        /[ ]/g,
                                                                                        '\u00a0'
                                                                                    )
                                                                                  : ''
                                                                          }
                                                                            style="overflow: hidden;
                                                                                 cursor: hand;
                                                                                display: -webkit-box;
                                                                                width: 100%;
                                                                                -webkit-line-clamp: 3;
                                                                                -webkit-box-orient: vertical;">${
                                                                                    this.value.name
                                                                                }</div>
                                                                           ${
                                                                               this.value.name !== 'UNKNOWN'
                                                                                   ? `<div class="data-label__span">${parseFloat(
                                                                                         this.value.span
                                                                                     ).toFixed(1)}</div>`
                                                                                   : ''
                                                                           }
                                                                       
                                                                    </div>`;
                                                                        },
                                                                    },
                                                                }}
                                                                tooltip={{
                                                                    enabled: false,
                                                                    useHTML: true,
                                                                    shadow: false,
                                                                    borderWidth: 0,
                                                                    followPointer: false,
                                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                                    formatter: function() {
                                                                        return `<div class="execDashboard-reports__bar-tooltip"><div>${
                                                                            this.point.name
                                                                        }</div><div>FTEs: ${formattedUtilities.floatIntValueFormatter(
                                                                            this.point.y
                                                                        )}</div></div>`;
                                                                    },
                                                                }}
                                                                legends={[]}
                                                            />
                                                        </div>
                                                    )}
                                                    {_this.state.functionsToolTipMap.activeSubFunctions && (
                                                        <div
                                                            className="subFunctions-close"
                                                            style={{ left: 0.78 * document.body.clientWidth + 'px' }}
                                                            onClick={() => this.hideSubFunctions()}
                                                        >
                                                            {' '}
                                                            <CloseIcon />
                                                        </div>
                                                    )}
                                                    <div className="legendContainer">
                                                        <div className="legendGroups ">
                                                            <div className="legends">
                                                                <div
                                                                    className="circle"
                                                                    style={{
                                                                        background: 'rgb(206, 215, 219)',
                                                                    }}
                                                                />
                                                                <div>{executiveDashboard.SPAN_OF_CONTROL}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ) : (
                                                <NoDataFound
                                                    mainMessage={`${
                                                        isFiltersApplied
                                                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_MAIN_MESSAGE
                                                            : executiveDashboard.NO_DATA_FOUND_MAIN_MESSAGE
                                                    }`}
                                                    secondaryMessage={`${
                                                        isFiltersApplied
                                                            ? executiveDashboard.NO_DATA_FOUND_FILTERS_APPLIED_SUB_MESSAGE
                                                            : executiveDashboard.NO_DATA_FOUND_SUB_MESSAGE
                                                    }`}
                                                />
                                            )}
                                        </div>
                                    </section>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>

                {this.props.structureId && (
                    <div className="execDashboard-reports__locations">
                        <div className="execDashboard-reports__charts">
                            <Locations
                                structureId={this.props.structureId}
                                countFilter={this.props.countFilter}
                                filtersApplied={this.props.filtersApplied}
                                isFiltersApplied={isFiltersApplied}
                                refreshCount={this.props.refreshCount}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SummaryReports;
