import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { getOrgResources } from '../../StructureContent/apis';
import Loader from '../../../components/Loader';
import ChipDetail from '../../../components/ChipDetails';
import './index.scss';
import StaffedView from './StaffedView';
import NoDataFound from '../../../components/NoDataFound';
import DemandView from './DemandView';

let apiCall = 1;
let rolesApiAccumulativeData = null;

const ChaptersDetails = ({
    chapter,
    chapterObjArr,
    totalDemandValue,
    demand,
    totalFteDemand,
    fteDemand,
    structureId,
    rolesPositionMap,
    allocation_intervalsMap,
    positionAllocationMap,
    teamWiseDemand,
    distributionTableData,
    showDistributionTable,
    closeDistributionTable,
    expandIdList,
    chapterPositionsMap,
    teamsMap,
    teamsNameMap,
    positionTeam,
    refreshCount,
    positionRoleMap,
    showFteValues,
    lessThan100Allocated,
    moreThan100Allocated,
    equalTo100Allocated,
    currentlyStaffed,
    fteCurrentlyStaffed,
}) => {
    const { totalDemand } = totalDemandValue;
    const [supply, setSupply] = useState([]);
    const [totalSupply, setTotalSupply] = useState(0);
    const [staffed, setStaffed] = useState(0);
    // const [lessThan100Allocated, setLessThan100Allocated] = useState(0);
    // const [equalTo100Allocated, setEqualTo100Allocated] = useState(0);
    // const [moreThan100Allocated, setMoreThan100Allocated] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [staffedDistribution, setStaffedDistribution] = useState({});
    const [fteSupply, setFteSupply] = useState(0);
    const [totalFteSupply, setTotalFteSupply] = useState(0);
    const [fteStaffed, setFteStaffed] = useState(0);
    const [isSubchapter, setIsSubchapter] = useState(true);
    const [isParentchapter, setIsParentchapter] = useState(false);
    useEffect(() => {
        const { id } = chapter;
        if (id) {
            rolesApiAccumulativeData = null;
            apiCall = 1;
            chapterObjArr.forEach(itm => {
                if (itm.id == id) {
                    if (itm.level === 1) {
                        setIsParentchapter(true);
                        const currentParentId = itm.parentId ? itm.parentId : itm.id;
                        fetchSupply(currentParentId);
                    } else {
                        setIsParentchapter(false);
                    }
                    if (itm.child === 0) {
                        setIsSubchapter(false);
                        const currentParentId = itm.parentId ? itm.parentId : itm.id;
                        if (itm.level !== 1) fetchSupply(currentParentId);
                    } else {
                        setIsSubchapter(true);
                    }

                    if (itm.level >= 2 && itm.child >= 1) {
                        fetchSupply(itm.id);
                    }
                }
            });
        }
    }, [chapter.id]);
    const fetchSupply = id => {
        setLoading(true);
        getOrgResources({
            id: structureId,
            method: 'rolesByChapter',
            filterString: `query=chapter.part_of*0..{id=[${id}]}&page[number]=${apiCall}&`,
        })
            .then(({ data }) => {
                const totalCalls = Math.ceil(data.meta.stats.total.count / 1000);
                if (!rolesApiAccumulativeData) rolesApiAccumulativeData = data;
                else {
                    rolesApiAccumulativeData.data = [...rolesApiAccumulativeData.data, ...data.data];
                    rolesApiAccumulativeData.included = [...rolesApiAccumulativeData.included, ...data.included];
                }
                if (totalCalls > 1 && apiCall < totalCalls) {
                    apiCall++;
                    fetchSupply(chapter.id);
                } else {
                    setLoading(false);
                    fetchStaffedRoles(rolesApiAccumulativeData);
                    let _totalFteSupply = 0;
                    let _fteSupply = 0;
                    let roleCount = 0;
                    rolesApiAccumulativeData.data.forEach(role => {
                        _totalFteSupply += role.attributes.fte_share;
                        if (role.relationships.chapter.data.id == chapter.id) {
                            roleCount++;
                            _fteSupply += role.attributes.fte_share;
                        }
                    });
                    setTotalFteSupply(_totalFteSupply);
                    setFteSupply(_fteSupply);
                    setTotalSupply(rolesApiAccumulativeData.data.length);
                    setSupply(roleCount);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const fetchStaffedRoles = _supply => {
        let _staffed = 0;
        let _fteStaffed = 0;
        // let _moreThan100Allocation = 0;
        // let _lessThan100Allocation = 0;
        // let _equalTo100Allocation = 0;
        const chapterRolesMap = {};
        const chaptersMap = {};
        const roleFteMap = {};

        (_supply.included || []).forEach(ele => {
            if (ele.type === 'chapters') {
                chaptersMap[ele.id] = { name: ele.attributes.name };
            }
        });
        (_supply.data || []).forEach(el => {
            if (chapterRolesMap[el.relationships.chapter.data.id]) {
                chapterRolesMap[el.relationships.chapter.data.id] = {
                    ...chapterRolesMap[el.relationships.chapter.data.id],
                    supply: [...chapterRolesMap[el.relationships.chapter.data.id].supply, { [el.id]: true }],
                };
            } else {
                chapterRolesMap[el.relationships.chapter.data.id] = {
                    chapter_name: chaptersMap[el.relationships.chapter.data.id].name,
                    supply: [{ [el.id]: true }],
                    more_than_100_allocated: 0,
                    less_than_100_allocated: 0,
                    equal_to_100_allocated: 0,
                    teams: [],
                };
            }
            roleFteMap[el.id] = el.attributes.fte_share;
        });

        (_supply.data || []).forEach(el => {
            if (rolesPositionMap[el.id]) {
                rolesPositionMap[el.id].forEach(position => {
                    _fteStaffed += roleFteMap[(positionRoleMap[position.id] || { id: '' }).id];
                });
                _staffed++;
                const _chapterId = el.relationships.chapter.data.id;
                let _allocation = 0;
                chapterRolesMap[_chapterId].staffed = _staffed;
                rolesPositionMap[el.id].forEach(elem => {
                    _allocation += (
                        allocation_intervalsMap[positionAllocationMap[elem.id].allocationId] || { allocation: 0 }
                    ).allocation;
                });

                if (_allocation > 1) {
                    // _moreThan100Allocation++;
                    chapterRolesMap[_chapterId].more_than_100_allocated =
                        chapterRolesMap[_chapterId].more_than_100_allocated + 1;
                } else if (_allocation === 1) {
                    // _equalTo100Allocation++;
                    chapterRolesMap[_chapterId].equal_to_100_allocated =
                        chapterRolesMap[_chapterId].equal_to_100_allocated + 1;
                } else {
                    // _lessThan100Allocation++;
                    chapterRolesMap[_chapterId].less_than_100_allocated =
                        chapterRolesMap[_chapterId].less_than_100_allocated + 1;
                }

                chapterRolesMap[_chapterId].teams = [...chapterRolesMap[_chapterId].teams, ...rolesPositionMap[el.id]];
            }
        });
        const staffedRoles = {};

        Object.keys(chapterRolesMap).forEach(elem => {
            staffedRoles[elem] = {
                ...chapterRolesMap[elem],
                teams: getTeams(chapterRolesMap[elem].teams),
            };
        });

        setStaffedDistribution(staffedRoles);

        // setMoreThan100Allocated(_moreThan100Allocation);
        // setLessThan100Allocated(_lessThan100Allocation);
        // setEqualTo100Allocated(_equalTo100Allocation);
        setStaffed(_staffed);
        setFteStaffed(_fteStaffed);
    };

    const getTeams = positions => {
        const teams = {};
        positions.forEach(el => {
            if (positionTeam[el.id] && !teams[positionTeam[el.id]]) {
                teams[positionTeam[el.id]] = { team_name: teamsMap[positionTeam[el.id]].name, id: positionTeam[el.id] };
            }
        });
        return Object.values(teams);
    };

    const distributionViewClickHandle = type => {
        // e.preventDefault();
        teamWiseDemand(type);
    };

    const columns = React.useMemo(() => [
        {
            id: 'expander',
            HeaderComponent: () => {
                return <span>TEAMS</span>;
            },
            accessor: 'name',
            width: 250,
            Cell: ({ row }) => {
                return (
                    <div
                        style={{
                            paddingLeft: `${
                                Object.keys(row.original.subRows || []).length ? row.depth * 8 + 14 : row.depth * 8 + 34
                            }px`,
                            display: 'flex',
                        }}
                        className={`${Object.keys(row.original.subRows || []).length ? 'has-icon' : ''}`}
                    >
                        {Object.keys(row.original.subRows || []).length ? (
                            <span {...row.getToggleRowExpandedProps()} title="">
                                {row.isExpanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </span>
                        ) : null}

                        <span>
                            <span style={{ verticalAlign: 'sub' }}>{row.original.name}</span>
                            <span style={{ color: 'grey', fontWeight: 400, display: 'block', textAlign: 'left' }}>
                                Level {row.original.hierarchyLevel || 1}
                            </span>
                        </span>
                    </div>
                );
            },
        },
        {
            HeaderComponent: () => {
                return <span>DEMAND*</span>;
            },
            accessor: showFteValues ? 'sumOfTheChildren.fteDemand' : 'sumOfTheChildren.demand',
            width: 100,
        },
        {
            HeaderComponent: () => {
                return <span>CURRENTLY STAFFED</span>;
            },
            accessor: showFteValues ? 'sumOfTheChildren.staffed' : 'sumOfTheChildren.staffed',
            width: 100,
        },
        {
            HeaderComponent: () => {
                return <span>{`UNDER-ALLOCATED (<100%)`}</span>;
            },
            accessor: 'sumOfTheChildren.less_than_100_percent_allocated',
            width: 100,
        },
        {
            HeaderComponent: () => {
                return <span>{`FULLY ALLOCATED (=100%)`}</span>;
            },
            accessor: 'sumOfTheChildren.equal_to_100_percent_allocated',
            width: 100,
        },
        {
            HeaderComponent: () => {
                return <span>{`OVER-ALLOCATED (>100%)`}</span>;
            },
            accessor: 'sumOfTheChildren.more_than_100_percent_allocated',
            width: 100,
        },
    ]);

    const Styles = styled.div`
        padding: 1rem;

        table {
            border-spacing: 0;
            th,
            td {
                margin: 0;
                padding: 0.5rem;
                text-align: left;
            }
        }
    `;

    return (
        <div className="chapters-view">
            {isLoading ? (
                <Loader showBackground background="transparent" />
            ) : (
                <Fragment>
                    <div className="chapters-view__heading">{chapter.name}</div>

                    <div className="chapters-view_content">
                        <div className="content-item">
                            <ChipDetail
                                dataConfig={[
                                    {
                                        header: 'SUPPLY',
                                        value: isParentchapter
                                            ? isSubchapter
                                                ? `${
                                                      showFteValues
                                                          ? parseFloat(totalFteSupply).toFixed(2)
                                                          : totalSupply
                                                  } 
                                            (${
                                                showFteValues
                                                    ? parseFloat((totalFteSupply - fteSupply).toFixed(2))
                                                    : totalSupply - supply
                                            } in lower sub-chapters)`
                                                : `${
                                                      showFteValues
                                                          ? parseFloat(totalFteSupply).toFixed(2)
                                                          : totalSupply
                                                  }`
                                            : isSubchapter
                                            ? `${showFteValues ? parseFloat(totalFteSupply).toFixed(2) : totalSupply} 
                                            (${
                                                showFteValues
                                                    ? parseFloat((totalFteSupply - fteSupply).toFixed(2))
                                                    : totalSupply - supply
                                            } in lower sub-chapters)`
                                            : `${showFteValues ? parseFloat(fteSupply).toFixed(2) : supply}`,
                                    },
                                    {
                                        header: 'DEMAND',
                                        value: isParentchapter
                                            ? isSubchapter
                                                ? `${
                                                      showFteValues
                                                          ? parseFloat(totalFteDemand).toFixed(2)
                                                          : totalDemand
                                                  } 
                                            (${
                                                showFteValues
                                                    ? parseFloat((totalFteDemand - fteDemand).toFixed(2))
                                                    : totalDemand - demand
                                            } in lower sub-chapters)`
                                                : `${
                                                      showFteValues
                                                          ? parseFloat(totalFteDemand).toFixed(2)
                                                          : totalDemand
                                                  }`
                                            : isSubchapter
                                            ? `${showFteValues ? parseFloat(totalFteDemand).toFixed(2) : totalDemand} 
                                            (${
                                                showFteValues
                                                    ? parseFloat((totalFteDemand - fteDemand).toFixed(2))
                                                    : totalDemand - demand
                                            } in lower sub-chapters)`
                                            : `${showFteValues ? parseFloat(fteDemand).toFixed(2) : demand}`,
                                    },

                                    {
                                        header: 'BALANCE',
                                        value: (
                                            <span
                                                className={
                                                    (showFteValues
                                                      ? (!isParentchapter && !isSubchapter
                                                            ? fteSupply
                                                            : totalFteSupply) -
                                                            (!isParentchapter && !isSubchapter
                                                                ? fteDemand
                                                                : totalFteDemand) ==
                                                        0
                                                      : (!isParentchapter && !isSubchapter ? supply : totalSupply) -
                                                            (!isParentchapter && !isSubchapter
                                                                ? demand
                                                                : totalDemand) ==
                                                        0)
                                                        ? ''
                                                        : (showFteValues
                                                            ? (!isParentchapter && !isSubchapter
                                                                  ? fteSupply
                                                                  : totalFteSupply) -
                                                                  (!isParentchapter && !isSubchapter
                                                                      ? fteDemand
                                                                      : totalFteDemand) <
                                                              0
                                                            : (!isParentchapter && !isSubchapter
                                                                  ? supply
                                                                  : totalSupply) -
                                                                  (!isParentchapter && !isSubchapter
                                                                      ? demand
                                                                      : totalDemand) <
                                                              0)
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                            >
                                                {showFteValues
                                                    ? parseFloat(
                                                          (
                                                              (!isParentchapter && !isSubchapter
                                                                  ? fteSupply
                                                                  : totalFteSupply) -
                                                              (!isParentchapter && !isSubchapter
                                                                  ? fteDemand
                                                                  : totalFteDemand)
                                                          ).toFixed(2)
                                                      )
                                                    : (!isParentchapter && !isSubchapter ? supply : totalSupply) -
                                                      (!isParentchapter && !isSubchapter ? demand : totalDemand)}
                                            </span>
                                        ),
                                    },
                                    {
                                        header: 'CURRENTLY STAFFED',
                                        value: `${showFteValues ? currentlyStaffed : currentlyStaffed} roles`,
                                    },
                                    { header: 'UNDER-ALLOCATED (<100%)', value: `${lessThan100Allocated} roles` },
                                    { header: 'FULLY ALLOCATED (=100%)', value: `${equalTo100Allocated} roles` },
                                    { header: 'OVER-ALLOCATED (>100%)', value: `${moreThan100Allocated} roles` },
                                ]}
                            />

                            {Object.keys(teamsNameMap).length > 0 ? (
                                <DemandView
                                    columns={columns}
                                    distributionTableData={distributionTableData}
                                    distributionViewClickHandle={distributionViewClickHandle}
                                />
                            ) : (
                                <NoDataFound
                                    mainMessage={`No teams found for the selected structure`}
                                    secondaryMessage={`Please select a different structure.`}
                                />
                            )}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default ChaptersDetails;
