import React from 'react';
import './index.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import TotalTeamsIcon from '../../../assets/totalTeamsIcon.svg';
import TotalValueIcon from '../../../assets/totalValueIcon.svg';
import TotalPositionsIcon from '../../../assets/totalPositionsIcon.svg';
import TotalCriticalRolesIcon from '../../../assets/totalCriticalRolesIcon.svg';

const StatTitle = ({ title, isLoading }) => (
    <div className="stat-box-title">
        <span>{title}</span>
    </div>
);

const StatBox = ({ title, value, icon, suffix = '', classes, isLoading }) => (
    <div className={`stat-box ${classes}`}>
        <div className="stat-icon-holder">
            <img src={icon} alt="stat-icon" />
        </div>
        <div className="stat-content">
            <span className="stat-title">{title}</span>
            <span className="stat-value">
                {isLoading ? <CircularProgress color={'theme'} size={20} /> : (value || 0) + suffix}
            </span>
        </div>
    </div>
);

const StatBox2 = ({ values, icon, classes, isLoading }) => (
    <div className={`stat-box ${classes}`}>
        {icon && (
            <div className="stat-icon-holder">
                <img src={icon} alt="stat-icon" />
            </div>
        )}
        <div className="stat-content">
            {values.map(({ title, value, suffix = '' }, idx) => (
                <div key={`stat_${idx}`} className="stat-values">
                    <span className="stat-title">{title}</span>
                    <span className="stat-value">
                        {isLoading ? <CircularProgress color={'theme'} size={20} /> : (value || 0) + suffix}
                    </span>
                </div>
            ))}
        </div>
    </div>
);

const Stats = ({ teams, isLoading }) => {
    const {
        teamsCount,
        teamsBudget,
        totalPositions,
        filledPositions,
        unfilledPositions,
        totalCriticalRoles,
        filledCriticalRoles,
        unfilledCriticalRoles,
    } = teams;
    return (
        <div className="teams-stats">
            <div className="teams-stats__view">
                <StatTitle title="Teams" />
                <StatBox
                    title="Total"
                    value={teamsCount}
                    icon={TotalTeamsIcon}
                    classes={'with-border'}
                    isLoading={isLoading}
                />
                <StatBox
                    title="Value"
                    value={`$${(teamsBudget / 1000000).toFixed(2)}M`}
                    icon={TotalValueIcon}
                    isLoading={isLoading}
                />
            </div>
            <div className="teams-stats__view">
                <StatTitle title="Positions" />
                <StatBox
                    title="Total"
                    value={totalPositions}
                    icon={TotalPositionsIcon}
                    classes={'with-border'}
                    isLoading={isLoading}
                />
                <StatBox2
                    isLoading={isLoading}
                    values={[
                        {
                            title: 'Filled',
                            value: `${filledPositions}  (${
                                totalPositions ? ((filledPositions / totalPositions) * 100).toFixed(1) : '0'
                            }%)`,
                        },
                        {
                            title: 'Unfilled',
                            value: `${unfilledPositions}  (${
                                totalPositions ? ((unfilledPositions / totalPositions) * 100).toFixed(1) : '0'
                            }%)`,
                        },
                    ]}
                />
            </div>
            <div className="teams-stats__view">
                <StatTitle title="Critical Roles" />
                <StatBox
                    title="Total"
                    value={totalCriticalRoles}
                    icon={TotalCriticalRolesIcon}
                    classes={'with-border'}
                    isLoading={isLoading}
                />
                <StatBox2
                    isLoading={isLoading}
                    values={[
                        {
                            title: 'Staffed',
                            value: `${filledCriticalRoles}  (${
                                totalCriticalRoles ? ((filledCriticalRoles / totalCriticalRoles) * 100).toFixed(1) : '0'
                            }%)`,
                        },
                        {
                            title: 'Unstaffed',
                            value: `${unfilledCriticalRoles}  (${
                                totalCriticalRoles
                                    ? ((unfilledCriticalRoles / totalCriticalRoles) * 100).toFixed(1)
                                    : '0'
                            }%)`,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default Stats;
